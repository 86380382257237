const image = {
  logo: require('./logo.png').default,
  logo2x: require('./logo2x.svg').default,
  introLogo: require('./introLogo.png').default,
  phone: require('./phone.svg').default,
  videoIcon: require('./video.svg').default,
  img: require('./img.png').default,
  send: require('./buttonIcon.svg').default,
  liveuser: require('./liveuser.png').default,
  eye: require('./eye.svg').default,
  jhon: require('./jhondoe.png').default,
  post: require('./post.png').default,
  home: require('./navIcons/homeIcon.svg').default,
  inbox: require('./navIcons/inbox.svg').default,
  explore: require('./navIcons/exploreIcon.svg').default,
  like: require('./navIcons/likeIcon.svg').default,
  hand: require('./navIcons/likeHandIcon.svg').default,
  reel: require('./navIcons/reelIcon.svg').default,
  user: require('./navIcons/userIcon.svg').default,
  subscribe: require('./navIcons/subscribeIcon.svg').default,
  bell: require('./bell.svg').default,
  mail: require('./mail.svg').default,
  setting: require('./setting.svg').default,
  search: require('./searchIconBlue.svg').default,
  apple: require('./appleStore.svg').default,
  google: require('./googlePlay.svg').default,
  searchIcon: require('./searchIcon.svg').default,
  bgHome: require('./home.png').default,
  heart: require('./heart.png').default,
  drink: require('./bottle.png').default,
  bag: require('./bag.png').default,
  earth: require('./earth.png').default,
  comb: require('./comb.png').default,
  gender: require('./gender.png').default,
  bgUser: require('./user.png').default,
  previewCircle: require('./previewCircle.svg').default,
  thumb: require('./thumb.svg').default,
  reelImage: require('./reel.png').default,
  mute: require('./mute.svg').default,
  navigation: require('./navigation.svg').default,
  eyeBlue: require('./eyeBlue.svg').default,
  gem: require('./gem.svg').default,
  streamer: require('./streamer.png').default,
  stream: require('./stream.jpg').default,
  adduser: require('./bottomTab/adduser.svg').default,
  blockstar: require('./bottomTab/blockstar.svg').default,
  camera: require('./bottomTab/camera.svg').default,
  likebn: require('./bottomTab/like.svg').default,
  vs: require('./bottomTab/vs.svg').default,
  warning: require('./warningIcon.svg').default,
  modbot: require('./modbot.svg').default,
  idea: require('./idea.svg').default,
  // downArrowBlue: require('./downArrowBlue.svg').default,
  downArrowBlack: require('./blackDropDown.png').default,
  find: require('./find.svg').default,
  sendBlue: require('./sendBlue.svg').default,
  gift1: require('./gifts/gift1.png').default,
  gift2: require('./gifts/gift2.png').default,
  gift3: require('./gifts/gift3.png').default,
  gift4: require('./gifts/gift4.png').default,
  gift5: require('./gifts/gift5.png').default,
  gift6: require('./gifts/gift6.png').default,
  gift7: require('./gifts/gift7.png').default,
  gift8: require('./gifts/gift8.png').default,
  left: require('./left.png').default,
  right: require('./right.png').default,
  actor: require('./actor.png').default,
  date: require('./date.svg').default,
  quickMessage: require('./quickMessage.svg').default,
  stopwatch: require('./stopwatch.svg').default,
  superlike: require('./superlike.svg').default,
  dotedwatch: require('./dotedwatch.svg').default,
  dotedwatchblue: require('./dottedwatchblue.svg').default,
  message: require('./message.png').default,
  videoBlue: require('./videoBlue.svg').default,
  phoneBlue: require('./phoneBlue.png').default,
  googleIcon: require('./googleIcon.svg').default,
  facebook: require('./facebookIcon.svg').default,
  storyImg: require('./story.png').default,
  blackWatch: require('./blackWatch.svg').default,
  noButton: require('./noButton.svg').default,
  superLikePink: require('./superLikePink.svg').default,
  dedicatedHeart: require('./dedicatedHeart.svg').default,
  bulb: require('./bulb.svg').default,
  vsRed: require('./vsRed.svg').default,
  recordDot: require('./recordDot.svg').default,
  liveIcon: require('./liveIcon.svg').default,
  video: require('./video.mp4').default,
  unMute: require('./unMute.png').default,
  liveloopActive: require('./liveloopActive.png').default,
  heartIcon: require('./heartIcon.png').default,
  blueSendButton: require('./blueSendButton.png').default,
  rArrow: require('./rArrow.png').default,
  unCheck: require('./unCheck.png').default,
  checkIcon: require('./checkIcon.png').default,
  sparkActive: require('./sparkActive.png').default,
  vsWhite: require('./vsWhite.png').default,
  downArrowBlue: require('./downArrowBlue.png').default,
  threeDots: require('./threeDots.png').default,
  pGender: require('./pGender.png').default,
  pLocation: require('./pLocation.png').default,
  pHeight: require('./pHeight.png').default,
  pCompany: require('./pCompany.png').default,
  pJob: require('./pJob.png').default,
  pSchool: require('./pSchool.png').default,
  flag: require('./flag.png').default,
  girl: require('./girl.png').default,
  supIcon: require('./supIcon.png').default,
  live: require('./liveIcon.png').default,
  superLikeFilled: require('./superLikeFilled.png').default,
  pinkHeart: require('./pinkHeart.png').default,
  likePng: require('./like.png').default,
  videoCaption: require('./videoCaption.svg').default,
  checkBoxChecked: require('./checkBoxChecked.svg').default,
  splashVideo: require('./splashVideo.mp4').default,
  genderBg: require('./genderBg.png').default,
  bodyBg: require('./bodyBG.png').default,
  baby: require('./baby.png').default,
  cameraBg: require('./cameraBg.png').default,
  imagePicker: require('./imagePicker.png').default,
  addImgae: require('./addImage.png').default,
  addPrompt: require('./addPrompt.png').default,
  bars: require('./navIcons/bars.svg').default,
  introAdmin: require('./introAdmin.svg').default,
  upload: require('./upload.png').default,
  chatBubble: require('./chatBubble.svg').default,
  arrowRight: require('./arrowRight.svg').default,
};
export default image;
