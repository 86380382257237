import React, { useState, useRef } from 'react';

import {
  Grid,
  Typography,
  makeStyles,
  Button,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import VideoThumbnail from 'react-video-thumbnail';
import VideoRecorder from 'react-video-recorder/lib/video-recorder';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';

import { Header } from '../../components/header/Header';

import { uploadVideo, deleteVideo } from '../../http';
import { Input } from '../../components/Textfield/Input';

const headerItems = [
  {
    label: 'Help',
    to: '/helpcenter',
  },
];

const CameraVideoUpload = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const state = location.state;
  const [video, setVideo] = useState('');
  const { title } = useSelector(state => state.utils.prompt);
  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false);
  const [other, setOther] = useState(false);
  const [openCaption, setOpenCaption] = useState(false);
  const caption = useRef(null);

  const converBase64ToFile = async base64 => {
    const res = await fetch(base64);
    const blob = await res.blob();
    const file = new File([blob], 'cover', { type: 'image/png' });
    return file;
  };

  const handleUploadVideo = async () => {
    setLoading(true);
    setOpenCaption(false);
    const _thumbnail = await converBase64ToFile(thumbnail);
    // setImageFile(thumbnail);
    const data = new FormData();
    data.append('prompt', video);
    data.append('cover', _thumbnail);
    data.append('question', title);
    data.append('step', '/home');
    data.append('type', 'CAMERA');
    data.append('isEmail', state?.isEmail ? false : true);
    data.append('caption', caption.current.value);

    try {
      await uploadVideo(data);
      if (state?.deleteId) {
        await deleteVideo(state.deleteId);
      }
      history.replace('/home');
    } catch (err) {
      console.log('in catch block');
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUserAnother = () => {
    const btn = document.getElementsByClassName('button__Button-hkteey-0')[0];
    btn.click();
    setOther(false);
    setVideo(null);
    setThumbnail(null);
    setOpenCaption(false);
  };
  if (!title) {
    return <Redirect to='/uploads' />;
  }

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      direction='column'
      className={classes.container}
    >
      <Header headerItems={headerItems} transparent />
      <Typography variant='h1' className='title'>
        {title}
      </Typography>
      <Grid className='video-container'>
        <VideoRecorder
          isOnInitially={true}
          timeLimit={15000}
          onRecordingComplete={videoBlob => {
            setOther(true);
            setVideo(videoBlob);
            setOpenCaption(true);
          }}
        />
      </Grid>
      {openCaption && (
        <Grid container justifyContent='center'>
          <div
            style={{ width: '100%', maxWidth: '350px', marginBottom: '20px' }}
          >
            <Input
              ref={caption}
              placeholder='Add a caption'
              style={{ maxWidth: '350px' }}
            />
          </div>
        </Grid>
      )}
      <Grid item container justifyContent='center' style={{ gap: '20px' }}>
        <Button
          className='upload-btn other'
          variant='contained'
          color='primary'
          onClick={handleUserAnother}
          disabled={!other}
        >
          Upload another
        </Button>
        <Button
          className='upload-btn'
          variant='contained'
          color='primary'
          onClick={handleUploadVideo}
          disabled={!video && !thumbnail}
        >
          Upload
        </Button>
      </Grid>

      <div style={{ maxHeight: '0px', overflow: 'hidden' }}>
        {video && (
          <VideoThumbnail
            videoUrl={URL.createObjectURL(video)}
            thumbnailHandler={thumbnail => setThumbnail(thumbnail)}
          />
        )}
      </div>
      <Dialog open={loading} className={classes.loadingDialog}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className='loading-container'
        >
          <h2>Your video is uploading. Please wait...</h2>
          <CircularProgress className='loader' />
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default CameraVideoUpload;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.lightPink,
    '& .title': {
      fontSize: '35px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
      },
    },
    '& .video-container': {
      width: '892px',
      height: '643px',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '15px',
      marginBlock: '46px 34px',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        height: '400px',
        width: '550px',
        marginBlock: '20px 16px',
      },
    },
    '& .upload-btn': {
      width: '250px',
      height: '65px',
      borderRadius: '33px',
      textTransform: 'none',
      fontSize: '21px',
      background: theme.palette.common.green,
      color: '#ffffff',
      '&:hover': {
        background: theme.palette.common.green,
      },
      '&:disabled': {
        background: theme.palette.primary.main,
      },
      [theme.breakpoints.down('lg')]: {
        height: '40px',
        width: '200px',
        fontSize: '16px',
      },
    },
    '& .button__Button-hkteey-0': {
      background: 'red',
      display: 'none',
    },
  },
  loadingDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      backgroundColor: theme.palette.common.lightPink,
    },
    '& .loading-container': {
      height: '300px',
      paddingInline: '30px',
      gap: '30px',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  contentContainer: {
    padding: '2rem 3rem',
    minWidth: '420px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '350px',
      padding: '1rem 1.5rem',
    },
    '& h1.title': {
      textAlign: 'center',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBlock: '3rem 1rem',
      [theme.breakpoints.down('lg')]: {
        marginBlock: '1.5rem 0.5rem',
      },
      '&.justify-center': {
        justifyContent: 'center',
        gap: '20px',
      },
      '& > div': {
        cursor: 'pointer',
      },
      '& .dialog-icons': {
        color: theme.palette.primary.main,
        fontSize: '7rem',
      },
    },
    '& .uploaded-video': {
      height: '640px',
      width: '428px',
      objectFit: 'cover',
      margin: '1rem 0',
      borderRadius: '4px',
      [theme.breakpoints.down('lg')]: {
        width: '300px',
        height: '447.97px',
      },
    },
  },
  btn: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '700',
    borderRadius: '26px',
    border: `2px solid ${theme.palette.primary.main}`,
    height: '50px',
    width: '200px',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.card-btn': {
      marginBottom: '44px',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '20px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '150px',
      height: '40px',
    },

    '& .continue-btn': {
      marginTop: '1rem',
    },
  },
}));
