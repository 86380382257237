import React, { useState, useRef, useEffect, useContext } from 'react';
import { useStyles } from './InboxStyles';
import {
  Grid,
  InputBase,
  Button,
  IconButton,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  useTheme,
  useMediaQuery,
  Collapse,
  CircularProgress,
  Dialog,
  Snackbar,
  Hidden,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  ExpandMore,
  Mic,
  EmojiEmotionsSharp as Emoji,
  GifSharp,
  AccessTime,
  Done,
  DoneAll,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import image from '../../../assets/index';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import GifPicker from 'react-giphy-picker';
import Audio from '../../../components/audio/Audio';
import Video from '../../../components/videoPlayer/Video';
import { getUserById } from '../../../http';
import { db } from '../../../firebaseInit';
import { Recorder } from 'react-voice-recorder';
import 'react-voice-recorder/dist/index.css';
import { useSelector, useDispatch } from 'react-redux';
import { SocketContext } from '../../../http/socket';
import GifImage from '../../../components/GifImage/GifImage';
import VideoRecorder from 'react-video-recorder';
import DateScheduler from '../../../components/dateSchedular/DateScheduler';
import { onMessage, onFileMessage } from '../../../utils/firestoreFunctions';
import { setChatState, setChatVisit } from '../../../store/inbox';
import BlockAndReport from '../../../components/blockandreport/BlockAndReport';
import Unblock from '../../../components/unblockDialog/Unblock';
import QuickMessageDialog from '../../../components/quickMessageDialog/QuickMessageDialog';
import { getCity, toRad } from '../../../utils/index';
import GeoCode from 'react-geocode';

GeoCode.setApiKey(process.env.REACT_APP_GEOCODE_API);

export const Inbox = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { chats, userUnMatched, setUserUnMatched, getChat } = props;
  // console.log(chats);
  const dispatch = useDispatch();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const currentUser = useSelector(state => state.auth.user.data);
  const chatState = useSelector(state => state.inbox);
  const socket = useContext(SocketContext);
  const [two, setTwo] = useState(false);
  const [active, setActive] = useState(-1);
  const [blocked, setBlocked] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);
  const [showRecorder, setShowRecorder] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [user, setUser] = useState(null);
  const [unMatch, setUnMatch] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [activeChat, setActiveChat] = useState({
    chatId: '',
    userId: '',
  });

  const [showChats, setShowChats] = useState(null);

  const handleClick = () => {
    setShowChats(true);
  };
  const handleClose = () => {
    setShowChats(false);
  };

  const [alert, setAlert] = useState(false);
  const [allow, setAllow] = useState(false);
  const scroll = useRef();
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });
  const [userCity, setUserCity] = useState('');
  const [quickMessageValue, setQuickMessageValue] = useState('');
  const [quickMessage, setQuickMessage] = useState(false);
  const refTwo = useRef();
  const firstChat = useRef({
    chatId: null,
    userId: null,
  });
  const handleOpenVideoDialog = () => {
    setOpenVideoDialog(true);
  };
  const onEmojiClick = (e, emoji) => {
    setMessage(message + emoji.emoji);
  };
  const handleMessage = e => {
    setMessage(e.target.value);
  };

  const handleTwo = () => {
    setTwo(!two);
    refTwo.current.classList.toggle(`${classes.rotate}`);
  };

  const handleIfBlocked = () => {
    const thisChat = chats.find(c => c.chatId === activeChat.chatId);
    if ((thisChat && thisChat.block.to) || thisChat.block.from) {
      return thisChat.block;
    } else {
      return false;
    }
  };

  const handleChatClick = (index, chatId, uId) => {
    setActive(index);
    localStorage.setItem('index', `${index}`);
    setActiveChat({
      chatId: chatId,
      userId: uId,
    });
    setChat([]);
    const data = {
      chatId: chatId,
      userId: uId,
      activeIndex: index,
    };
    dispatch(setChatState(data));
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  const handleAudioStop = data => {
    setAudioDetails(data);
  };

  const handleVideoUpload = async () => {
    const { chatId, userId } = activeChat;
    if (currentUser.block.indexOf(userId) === -1) {
      const doc = {
        content: 'pending',
        idFrom: currentUser._id,
        idTo: userId,
        thumb: 0,
        type: 1,
      };
      setOpenVideoDialog(false);
      onFileMessage(
        doc,
        chatId,
        socket,
        currentUser._id,
        chat.length === 0 ? currentUser._id : userId,
        recordedVideo
      );
    } else {
      setBlocked(true);
      console.log('this user is blocked');
    }
  };

  const handleAudioUpload = async file => {
    const { chatId, userId } = activeChat;
    if (currentUser.block.indexOf(userId) === -1) {
      const doc = {
        content: 'pending',
        idFrom: currentUser._id,
        idTo: userId,
        thumb: 0,
        type: 2,
      };
      setShowRecorder(false);
      onFileMessage(
        doc,
        chatId,
        socket,
        userId,
        chat.length === 0 ? currentUser._id : userId,
        file
      );
    } else {
      setBlocked(true);
      console.log('this user is blocked');
    }
  };

  const handleCountDown = data => {
    // console.log(data);
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  let lat = localStorage.getItem('latitude');
  let lon = localStorage.getItem('Longitude');

  const calcDistance = (lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat);
    var dLon = toRad(lon2 - lon);
    var lat1 = toRad(lat);
    var lat2 = toRad(lat);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1.60934).toFixed(1);
  };

  const handleSendMessage = () => {
    const { chatId, userId } = activeChat;
    if (!handleIfBlocked()) {
      const doc = {
        content: message,
        filename: '',
        idFrom: currentUser._id,
        idTo: userId,
        thumb: 0,
        type: 0,
      };
      onMessage(
        doc,
        chatId,
        socket,
        userId,
        chat.length === 0 ? currentUser._id : userId
      );
      setMessage('');
      setShowEmoji(false);
    } else {
      setBlocked(handleIfBlocked());
      console.log('this user is blocked');
      setMessage('');
      setShowEmoji(false);
    }
  };

  const allowChat = (first, second) => {
    if (first === 'female') {
      return setAllow(true);
    }
    if (first === 'male' && second === 'female') {
      if (!chat.length) {
        return setAllow(false);
      }
      for (let i = 0; i < chat.length; i++) {
        if (chat[i].idFrom === activeChat.userId) {
          setAllow(true);
          return true;
        } else {
          setAllow(false);
        }
      }
    } else {
      return setAllow(true);
    }
  };

  const handleGif = gif => {
    const { chatId, userId } = activeChat;
    if (currentUser.block.indexOf(userId) === -1) {
      const doc = {
        content: gif.original.webp,
        filename: '',
        idFrom: currentUser._id,
        idTo: userId,
        thumb: 0,
        type: 3,
      };
      onMessage(
        doc,
        chatId,
        socket,
        userId,
        chat.length === 0 ? currentUser._id : userId
      );
      setMessage('');
      setShowGif(false);
    } else {
      setBlocked(true);
      console.log('this user is blocked');
    }
  };

  const handleUnmatchUser = () => {
    setUnMatch(true);
  };

  const handleBlockAndReport = () => {
    setOpenReportDialog(true);
  };
  const handleDeleteChat = () => {};

  const handleSendQuickMessage = () => {
    const { chatId, userId } = activeChat;
    if (chat.map(c => c.idFrom === currentUser._id).length === 0) {
      if (currentUser.block.indexOf(userId) === -1) {
        const doc = {
          content: quickMessageValue,
          filename: '',
          idFrom: currentUser._id,
          idTo: userId,
          thumb: 0,
          type: 0,
        };
        onMessage(
          doc,
          chatId,
          socket,
          userId,
          chat.length === 0 ? currentUser._id : userId
        );
        setMessage('');
        setShowEmoji(false);
        setQuickMessage(false);
      } else {
        setBlocked(true);
        console.log('this user is blocked');
        setMessage('');
        setShowEmoji(false);
      }
    } else {
      setAlert(true);
    }
  };

  const getLocalTime = timestamp => {
    let date = new Date(timestamp).toLocaleTimeString();
    date = date.split(':');
    let hours = date[0];
    let minutes = date[1];
    if (hours === '00') {
      return `${12}:${minutes} am`;
    } else if (hours > 12) {
      return `${hours - 12}:${minutes} pm`;
    } else {
      return `${hours}:${minutes} pm`;
    }
  };

  const getMsgCurrentStatus = status => {
    if (status === 'send') {
      return <Done style={{ fontSize: '15px' }} />;
    }

    if (status === 'delivered') {
      return <DoneAll style={{ fontSize: '15px' }} />;
    }

    if (status === 'seen') {
      return <DoneAll style={{ color: 'blue', fontSize: '15px' }} />;
    }
  };

  useEffect(() => {
    const { chatId, userId } = activeChat;
    if (chatId !== '' && userId !== '') {
      socket.emit('chatStatus', {
        chatId: chatId,
        userId: currentUser._id,
        userTo: userId,
        userStatus: 1,
      });

      (async () => {
        const { data } = await getUserById(userId);
        setUser(data.data);

        // console.log(chatId);

        db.collection('messages')
          .doc(chatId)
          .collection(chatId)
          .onSnapshot(snapshot => {
            let messages = [];
            snapshot.docs.forEach(doc => {
              const data = doc.data();
              const isPending = doc.metadata.hasPendingWrites;
              data.isPending = isPending;
              if (
                (doc.exitst &&
                  data.idFrom !== currentUser._id &&
                  data.status === 'delivered') ||
                data.status === 'send'
              ) {
                doc.ref.update({ status: 'seen' });
              }

              messages.push({ doc, ...data });
            });
            setChat([...messages]);
            if (scroll.current)
              scroll.current.scrollIntoView({ behavior: 'smooth' });
          });

        allowChat(
          currentUser.identify.gender.toLowerCase(),
          data.data.identify.gender.toLowerCase()
        );
      })();
    }
  }, [activeChat]);

  useEffect(() => {
    if (chatState.chatVisit > 0) {
      dispatch(setChatVisit(chatState.chatVisit + 1));
      const chatId = chatState.chatId;
      const userId = chatState.userId;
      const index = chatState.activeIndex;
      // console.log("here iam " + index);
      localStorage.setItem('index', index);
      setActive(index * 1);
      setActiveChat({
        chatId: chatId,
        userId: userId,
      });
    } else {
      dispatch(setChatVisit(chatState.chatVisit + 1));
      setTimeout(() => {
        const { chatId, userId } = firstChat.current;
        if (chatId !== null && userId !== null) {
          setActive(0);
          localStorage.setItem('index', 0);
          setActiveChat({
            chatId: chatId,
            userId,
          });
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getCity(user?.location?.lat, user?.location?.lon, setUserCity);
    }
  }, [user]);

  useEffect(() => {
    if (chat.length && user) {
      allowChat(
        currentUser.identify.gender.toLowerCase(),
        user.identify.gender.toLowerCase()
      );
    }
  }, [chat, user]);
  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item container alignItems='center' justifyContent='space-between'>
        <Typography className={classes.title} style={{ width: 'auto' }}>
          Inbox
        </Typography>
        <Grid item style={{ position: 'relative' }}>
          <Hidden mdUp>
            <IconButton onClick={handleClick}>
              <img src={image.chatBubble} style={{ width: '20px' }} />
            </IconButton>
          </Hidden>
          <Hidden mdUp>
            <Grid
              container
              className={`${classes.mobileChats} ${showChats ? 'active' : ''} `}
            >
              <Grid
                item
                style={{
                  width: '100%',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                }}
              >
                <Grid
                  item
                  container
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography variant='h4'>Chat</Typography>
                  </Grid>
                  <IconButton onClick={handleClose}>
                    <img src={image.arrowRight} alt='' />
                  </IconButton>
                </Grid>
                <div className={classes.mobileScrollDiv}>
                  <List
                    classes={{ root: classes.list }}
                    style={{ paddingInline: '5px' }}
                  >
                    {chats.map((chat, index) => {
                      if (index === 0) {
                        firstChat.current.chatId = chat.chatId;
                        firstChat.current.userId = chat.matched_ids.to;
                      }
                      return (
                        <ListItem
                          selected={active === index}
                          key={chat.chatId}
                          disableGutters
                          dense
                          alignItems='center'
                          classes={{ root: classes.listItemRoot }}
                          onClick={() => {
                            handleChatClick(
                              index,
                              chat.chatId,
                              chat.matched_ids.to
                            );
                            handleClose();
                          }}
                        >
                          <ListItemAvatar className={classes.listItemAvatar}>
                            <Avatar
                              className={classes.listImage}
                              src={
                                chat.matched_username.to.toLowerCase() ===
                                'team intro'
                                  ? image.introAdmin
                                  : `${process.env.REACT_APP_API_URL}/${chat.matched_images.to}`
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            classes={{ root: classes.listItemTextRoot }}
                            primary={chat.matched_username.to}
                            secondary={chat.msg}
                            secondaryTypographyProps={{
                              noWrap: true,
                            }}
                          />

                          <Typography className={classes.lastSeen}>
                            {chat.unreadCount}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Unblock
        getChat={getChat}
        open={blocked}
        setOpen={setBlocked}
        user_id={activeChat.userId}
      />
      <Grid
        item
        container
        alignItems='stretch'
        spacing={2}
        className={classes.chatContainer}
      >
        <Grid item className={classes.left}>
          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.inboxUserInfo}
          >
            {user && (
              <>
                <Grid item className={classes.userInfoContainer}>
                  <Grid item className={classes.avatarContainer}>
                    <Avatar
                      className={classes.avatar}
                      src={
                        user?.username.toLowerCase() === 'team intro'
                          ? image.introAdmin
                          : `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction='column'
                    alignItems='flex-start'
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography className={classes.userName}>
                        {user?.username.toLowerCase() !== 'team intro' ? (
                          <>
                            {user?.first_name} {user?.last_name}
                          </>
                        ) : (
                          'Team Intro'
                        )}
                      </Typography>
                      {user?.username.toLowerCase() !== 'team intro' && (
                        <Typography className={classes.userAge}>
                          {user?.date_of_birth.age}
                        </Typography>
                      )}
                    </div>
                    {user?.username.toLowerCase() !== 'team intro' && (
                      <>
                        <Typography className={classes.userCity}>
                          {userCity}
                          {/* {getCity(user?.location?.lat, user?.location?.lon)} */}
                        </Typography>
                        <Typography className={classes.subtitle}>
                          {calcDistance(
                            user?.location?.lat,
                            user?.location?.lon
                          )}
                          mi
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item container justifyContent='center'>
                    {user?.username.toLowerCase() !== 'team intro' && (
                      <Button
                        component={Link}
                        to={`/home/match/${user?._id}`}
                        className={classes.viewProfileButton}
                        variant='contained'
                      >
                        View Profile
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <BlockAndReport
                  getChat={getChat}
                  userId={activeChat.userId}
                  chatId={activeChat.chatId}
                  open={unMatch}
                  setOpen={setUnMatch}
                  unMatch={true}
                  userUnMatched={userUnMatched}
                  setUserUnMatched={setUserUnMatched}
                />
                <BlockAndReport
                  getChat={getChat}
                  userId={activeChat.userId}
                  open={openReportDialog}
                  setOpen={setOpenReportDialog}
                />

                <Grid item container className={classes.collapseContainer}>
                  <Grid
                    item
                    onClick={handleTwo}
                    container
                    justifyContent='space-between'
                  >
                    <Typography className={classes.childAccordionHeading}>
                      Support
                    </Typography>
                    <IconButton className={classes.collapseButton}>
                      <ExpandMore
                        ref={refTwo}
                        className={classes.childAccordionIcon}
                      />
                    </IconButton>
                  </Grid>
                  <Collapse in={two} timeout='auto' unmountOnExit>
                    <Grid
                      container
                      className={classes.collapseInner}
                      direction='column'
                    >
                      {user?.username.toLowerCase() !== 'team intro' && (
                        <>
                          {' '}
                          <Typography
                            onClick={handleUnmatchUser}
                            className={classes.accordionDetailsLink}
                          >
                            Unmatch
                          </Typography>
                          <Typography
                            onClick={handleBlockAndReport}
                            className={classes.accordionDetailsLink}
                          >
                            Block & Report
                          </Typography>
                          <Typography
                            onClick={handleDeleteChat}
                            className={classes.accordionDetailsLink}
                          >
                            Delete Chat
                          </Typography>
                        </>
                      )}

                      <Typography
                        component={Link}
                        to='/helpcenter'
                        style={{ textDecoration: 'none' }}
                        className={classes.accordionDetailsLink}
                      >
                        Help Center
                      </Typography>
                    </Grid>
                  </Collapse>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item className={classes.middle}>
          <Grid container direction='column' className={classes.messageBox}>
            <Grid item container>
              <Grid style={{ width: '60%' }} item container alignItems='center'>
                <Avatar
                  className={classes.chatTitleAvatar}
                  src={
                    user?.username.toLowerCase() === 'team intro'
                      ? image.introAdmin
                      : `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                  }
                />
                <h3 className={classes.chatTitle}>
                  {' '}
                  {user?.username.toLowerCase() !== 'team intro' ? (
                    <>{user?.username}</>
                  ) : (
                    'Team Intro'
                  )}
                </h3>
              </Grid>
              {user?.username.toLowerCase() !== 'team intro' && (
                <Grid
                  style={{ width: '40%' }}
                  item
                  container
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <IconButton
                    onClick={() => setOpenDialog(true)}
                    className={classes.callButton}
                  >
                    <img
                      src={image.blackWatch}
                      className={classes.watchIcon}
                      alt=''
                    />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={{
                      pathname: '/voicechat',
                      state: {
                        chatId: activeChat.chatId,
                        userImg: user?.profile_image,
                        username: user?.username,
                      },
                    }}
                    // target="_blank"
                    className={classes.callButton}
                  >
                    <img
                      src={image.phone}
                      className={classes.callIcon}
                      alt=''
                    />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={{
                      pathname: '/videochat',
                      state: {
                        chatId: activeChat.chatId,
                        userImg: user?.profile_image,
                        username: user?.username,
                      },
                    }}
                    className={classes.callButton}
                  >
                    <img
                      src={image.videoIcon}
                      className={classes.callIcon}
                      alt=''
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <div className={classes.containerDiv} id='main'>
              {chat.map(
                (
                  { content, idFrom, type, timestamp, status, doc, isPending },
                  index
                ) => {
                  console.log(doc.data());
                  let showProfile;
                  if (index > 0) {
                    showProfile = chat[index - 1].idFrom !== chat[index].idFrom;
                  } else {
                    showProfile = true;
                  }
                  if (type === 0) {
                    return (
                      <Grid
                        key={index}
                        alignItems='flex-start'
                        container
                        direction={
                          idFrom === activeChat.userId ? 'row' : 'row-reverse'
                        }
                        justifyContent={
                          idFrom === activeChat.userId
                            ? 'flex-start'
                            : 'flex-start'
                        }
                        className={classes.messageContainer}
                      >
                        {showProfile ? (
                          <Avatar
                            src={
                              idFrom === activeChat.userId
                                ? user?.username.toLowerCase() === 'team intro'
                                  ? image.introAdmin
                                  : `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                                : `${process.env.REACT_APP_API_URL}/${currentUser.profile_image}`
                            }
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        ) : (
                          <div
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        )}

                        <Typography
                          style={{ maxWidth: '70%', wordWrap: 'break-word' }}
                          className={
                            idFrom === activeChat.userId
                              ? classes.incomingMessage
                              : classes.outgoingMessage
                          }
                        >
                          {content}
                          <div className={classes.readRecipients}>
                            <span className='timestamp'>
                              {getLocalTime(timestamp)}
                            </span>
                            {idFrom !== activeChat.userId && (
                              <span>{getMsgCurrentStatus(status)}</span>
                            )}
                          </div>
                        </Typography>
                      </Grid>
                    );
                  } else if (type === 2) {
                    return (
                      <Grid
                        key={index}
                        alignItems='flex-start'
                        container
                        direction={
                          idFrom === activeChat.userId ? 'row' : 'row-reverse'
                        }
                        justifyContent={
                          idFrom === activeChat.userId
                            ? 'flex-start'
                            : 'flex-start'
                        }
                        className={classes.messageContainer}
                      >
                        {showProfile ? (
                          <Avatar
                            src={
                              idFrom === activeChat.userId
                                ? `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                                : `${process.env.REACT_APP_API_URL}/${currentUser.profile_image}`
                            }
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        ) : (
                          <div
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        )}
                        {content === 'pending' ? (
                          <CircularProgress
                            color={
                              idFrom === activeChat.userId
                                ? 'secondary'
                                : 'primary'
                            }
                          />
                        ) : (
                          <Audio
                            src={content}
                            fromMe={idFrom === activeChat.userId ? false : true}
                          >
                            <div className={classes.readRecipients}>
                              <span className='timestamp'>
                                {getLocalTime(timestamp)}
                              </span>
                              {idFrom !== activeChat.userId && (
                                <>
                                  {isPending && (
                                    <span>
                                      <AccessTime
                                        style={{ fontSize: '15px' }}
                                      />
                                    </span>
                                  )}
                                  {!isPending && (
                                    <span>{getMsgCurrentStatus(status)}</span>
                                  )}
                                </>
                              )}
                            </div>
                          </Audio>
                        )}
                      </Grid>
                    );
                  } else if (type === 1) {
                    return (
                      <Grid
                        key={index}
                        alignItems='flex-start'
                        container
                        direction={
                          idFrom === activeChat.userId ? 'row' : 'row-reverse'
                        }
                        justifyContent={
                          idFrom === activeChat.userId
                            ? 'flex-start'
                            : 'flex-start'
                        }
                        className={classes.messageContainer}
                      >
                        {showProfile ? (
                          <Avatar
                            src={
                              idFrom === activeChat.userId
                                ? `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                                : `${process.env.REACT_APP_API_URL}/${currentUser.profile_image}`
                            }
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        ) : (
                          <div
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        )}
                        {content === 'pending' ? (
                          <CircularProgress
                            color={
                              idFrom === activeChat.userId
                                ? 'secondary'
                                : 'primary'
                            }
                          />
                        ) : (
                          <Video
                            src={content}
                            fromMe={idFrom === activeChat.userId ? false : true}
                          />
                        )}
                      </Grid>
                    );
                  } else if (type === 3) {
                    return (
                      <Grid
                        key={index}
                        alignItems='flex-start'
                        container
                        direction={
                          idFrom === activeChat.userId ? 'row' : 'row-reverse'
                        }
                        justifyContent={
                          idFrom === activeChat.userId
                            ? 'flex-start'
                            : 'flex-start'
                        }
                        className={classes.messageContainer}
                      >
                        {showProfile ? (
                          <Avatar
                            src={
                              idFrom === activeChat.userId
                                ? `${process.env.REACT_APP_API_URL}/${user?.profile_image}`
                                : `${process.env.REACT_APP_API_URL}/${currentUser.profile_image}`
                            }
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        ) : (
                          <div
                            className={
                              idFrom === activeChat.userId
                                ? classes.messageAvatar
                                : classes.messageAvatar2
                            }
                          />
                        )}
                        <GifImage
                          src={content}
                          fromMe={idFrom === activeChat.userId ? false : true}
                        />
                      </Grid>
                    );
                  }
                }
              )}
              <div ref={scroll} />
            </div>
            {user?.username.toLowerCase() !== 'team intro' && (
              <Grid
                item
                container
                alignItems='center'
                justifyContent='space-between'
                className={classes.messageInputBox}
                wrap='nowrap'
              >
                {activeChat.chatId && (
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                    style={{ gap: '5px' }}
                  >
                    {allow ? (
                      <>
                        <Grid
                          item
                          style={{ flexGrow: 1, position: 'relative' }}
                        >
                          <Grid
                            container
                            alignItems='center'
                            justifyContent='space-between'
                            className={classes.chatInput}
                          >
                            <Grid item>
                              <IconButton
                                onClick={() => {
                                  setShowEmoji(!showEmoji);
                                  setShowGif(false);
                                  setShowRecorder(false);
                                }}
                                className={classes.iconButton}
                              >
                                <Emoji className={classes.emojiIcon} />
                              </IconButton>
                            </Grid>
                            <InputBase
                              maxRows={4}
                              multiline
                              value={message}
                              onKeyDown={handleKeyDown}
                              onKeyUp={e => {
                                if (e.key === 13) {
                                  setMessage('');
                                }
                              }}
                              onChange={handleMessage}
                              className={classes.inputBase}
                              placeholder='Write a message...'
                              inputProps={{ className: classes.inputProps }}
                              onFocus={() => {
                                setShowEmoji(false);
                                setShowGif(false);
                                setShowRecorder(false);
                              }}
                            />

                            <Grid item>
                              <Grid
                                item
                                container
                                alignItems='center'
                                wrap='nowrap'
                              >
                                <IconButton
                                  onClick={() => {
                                    setShowGif(!showGif);
                                    setShowEmoji(false);
                                    setShowRecorder(false);
                                  }}
                                  className={classes.iconButton}
                                >
                                  <GifSharp className={classes.gifIcon} />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setShowRecorder(!showRecorder);
                                    setShowEmoji(false);
                                    setShowGif(false);
                                  }}
                                  style={{
                                    marginRight: lgScreen ? '3px' : '10px',
                                    marginLeft: lgScreen ? '0px' : '5px',
                                  }}
                                  className={classes.iconButton}
                                >
                                  <Mic className={classes.icons} />
                                </IconButton>
                                <IconButton
                                  onClick={handleOpenVideoDialog}
                                  style={{ marginRight: '15px' }}
                                  className={classes.iconButton}
                                >
                                  <img
                                    src={image.recordDot}
                                    style={{
                                      width: lgScreen ? '2rem' : undefined,
                                    }}
                                    alt=''
                                  />
                                </IconButton>
                                <Dialog
                                  open={openVideoDialog}
                                  className={classes.dialog}
                                >
                                  <Grid
                                    item
                                    container
                                    direction='column'
                                    className={classes.videoRecorderContainer}
                                  >
                                    <div className={classes.videoDiv}>
                                      <VideoRecorder
                                        onRecordingComplete={videoBlob => {
                                          setRecordedVideo(videoBlob);
                                          console.log('videoBlob', videoBlob);
                                        }}
                                      />
                                    </div>
                                    <Button
                                      disabled={
                                        recordedVideo === null ? true : false
                                      }
                                      variant='contained'
                                      color='primary'
                                      onClick={handleVideoUpload}
                                      className={classes.uploadButton}
                                    >
                                      Upload video
                                    </Button>
                                  </Grid>
                                </Dialog>
                              </Grid>
                            </Grid>
                          </Grid>
                          {showGif && (
                            <div className={classes.gifPicker}>
                              <GifPicker onSelected={handleGif} />
                            </div>
                          )}
                          {showRecorder && (
                            <div className={classes.recorder}>
                              <Recorder
                                record={true}
                                title={'New recording'}
                                audioURL={audioDetails.url}
                                showUIAudio
                                handleAudioStop={data => handleAudioStop(data)}
                                handleAudioUpload={data =>
                                  handleAudioUpload(data)
                                }
                                handleCountDown={data => handleCountDown(data)}
                                handleReset={() => handleReset()}
                                mimeTypeToUseWhenRecording={`audio/webm`}
                              />
                            </div>
                          )}

                          {showEmoji && (
                            <div className={classes.emojiContainer}>
                              <Picker
                                pickerStyle={{ width: '100%', height: '250px' }}
                                onEmojiClick={onEmojiClick}
                                disableAutoFocus={true}
                                skinTone={SKIN_TONE_MEDIUM_DARK}
                                groupNames={{ smileys_people: 'PEOPLE' }}
                                native
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={handleSendMessage}
                            className={classes.sendButton}
                          >
                            <img
                              src={image.send}
                              className={classes.sendButtonIcon}
                              alt=''
                            />
                          </IconButton>
                        </Grid>
                      </>
                    ) : (
                      <Grid container justifyContent='center'>
                        <Button
                          variant='contained'
                          color='primary'
                          className={classes.quickMessageButton}
                          onClick={() => setQuickMessage(true)}
                        >
                          Send Quick Message
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <QuickMessageDialog
            open={quickMessage}
            setOpen={setQuickMessage}
            setQuickMessage={setQuickMessageValue}
            username={user?.username}
            sendMessage={handleSendQuickMessage}
          />
          <DateScheduler
            userId={activeChat.userId}
            username={user?.username}
            open={openDialog}
            setOpen={setOpenDialog}
          />
        </Grid>
        <Grid item className={classes.right}>
          <Grid container direction='column' className={classes.chats}>
            <Typography className={classes.chatsTitle}>Chats</Typography>
            <div className={classes.scrollDiv}>
              <List classes={{ root: classes.list }}>
                {chats.map((chat, index) => {
                  if (index === 0) {
                    firstChat.current.chatId = chat.chatId;
                    firstChat.current.userId = chat.matched_ids.to;
                  }
                  return (
                    <ListItem
                      selected={active === index}
                      key={chat.chatId}
                      disableGutters
                      dense
                      alignItems='center'
                      classes={{ root: classes.listItemRoot }}
                      onClick={() =>
                        handleChatClick(index, chat.chatId, chat.matched_ids.to)
                      }
                    >
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar
                          className={classes.listImage}
                          src={
                            chat.matched_username.to.toLowerCase() ===
                            'team intro'
                              ? image.introAdmin
                              : `${process.env.REACT_APP_API_URL}/${chat.matched_images.to}`
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ root: classes.listItemTextRoot }}
                        primary={chat.matched_username.to}
                        secondary={chat.msg}
                        secondaryTypographyProps={{
                          noWrap: true,
                        }}
                      />

                      <Typography className={classes.lastSeen}>
                        {chat.unreadCount}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAlert(false)}
        open={alert}
        autoHideDuration={5000}
      >
        <Alert style={{ fontSize: '16px' }} severity='error'>
          You can only send one quick message.
        </Alert>
      </Snackbar>
    </Grid>
  );
};
