const navIcons = {
  home: require('./home.svg').default,
  homeActive: require('./home-active.svg').default,
  explore: require('./explore.svg').default,
  exploreActive: require('./explore-active.svg').default,
  meetme: require('./meetme.svg').default,
  meetmeActive: require('./meetme-active.svg').default,
  inbox: require('./inbox.svg').default,
  inboxActive: require('./inbox-active.svg').default,
  activity: require('./activity.svg').default,
  activityActive: require('./activity-active.svg').default,
  online: require('./online.svg').default,
  onlineActive: require('./online-active.svg').default,
  live: require('./live.svg').default,
};

export default navIcons;
