import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Dialog,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import image from '../../assets';
import { useDispatch } from 'react-redux';

import { countryList } from '../../app.config';
import {
  setFilter,
  setFilterUpdated,
  setIsAnyOpen,
} from '../../store/Reducers/filters';

import { useTranslation } from 'react-i18next';

export const CountryFilter = ({
  setValue,
  open,
  onClose,
  value: filterValue = [],
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation();
  let obj = {};
  //   console.log(allCounrtry.default);
  [...countryList, { name: 'All' }].forEach(country => {
    obj[country.name] = false;
  });
  const [state, setState] = useState(obj);
  const [filterChanged, setFilterChanged] = useState(false);
  const handleChange = e => {
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
    if (name === 'All') {
      const myObj = {};
      [...countryList, { name: 'All' }].forEach(op => {
        myObj[op.name] = checked;
      });
      setState(myObj);
      console.log(myObj);
    }
  };
  const handleSet = () => {
    let filters = [];
    for (const [key, value] of Object.entries(state)) {
      if (value) filters.push(key);
    }
    dispatch(
      setFilter({ name: 'country', value: filters.filter(f => f !== 'All') })
    );
    setFilterChanged(!filterChanged);
    dispatch(setFilterUpdated());
    dispatch(setIsAnyOpen(false));
    onClose();
  };
  useEffect(() => {
    let obj = {};
    // eslint-disable-next-line
    [...countryList, { name: 'All' }].forEach(option => {
      obj[option.name] = false;
    });
    const keys = Object.keys(obj);
    let count = 0;
    keys.forEach(key => {
      if (filterValue.indexOf(key) > -1) {
        obj[key] = true;
        count++;
      }
    });

    if (count === keys.length - 1) {
      obj['All'] = true;
    }
    setState(obj);
  }, [filterChanged, filterValue]);
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      className={classes.dialog}
    >
      <Grid item container direction='column' className={classes.container}>
        <Grid item container className={classes.titleContainer}>
          <Typography className={classes.header}>
            {t('country')}{' '}
            <span>({t('you_can_select_multiple_countries')})</span>
          </Typography>
        </Grid>
        <Grid item container className={classes.optionContainer}>
          {[...countryList, { name: 'All' }].map((country, index) => (
            <Grid item container key={index} className={classes.margin}>
              <FormControlLabel
                className={classes.formControll}
                onChange={handleChange}
                name={country.name}
                label={
                  <div className={classes.div}>
                    <img src={country.image} className={classes.img} alt='' />
                    <span
                      className={
                        state[country.name] ? classes.active : classes.option
                      }
                    >
                      {t(country.name)}
                    </span>
                  </div>
                }
                control={
                  <Checkbox
                    name={country.name}
                    className={classes.checkBox}
                    color='primary'
                    checked={Boolean(state[country.name])}
                    checkedIcon={<img src={image.checkIcon} alt='' />}
                    icon={<img src={image.unCheck} alt='' />}
                  />
                }
                labelPlacement='start'
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          justifyContent='flex-end'
          className={classes.buttonsContainer}
        >
          <Button
            onClick={() => {
              onClose(false);
              setIsAnyOpen(false);
            }}
            className={classes.cancelButton}
            variant='text'
          >
            {t('cancel')}
          </Button>
          <Button
            className={classes.setButton}
            onClick={handleSet}
            variant='text'
            color='primary'
          >
            {t('set')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingBlock: '27px',
    width: '480px',
  },
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  titleContainer: {
    paddingInline: '24px',
  },
  header: {
    margin: 0,
    color: '#828282',
    fontSize: '18px',
    lineHeight: '45px',
    width: '100%',
    borderBottom: '1px solid #828282',
    '& span': {
      fontSize: '13px',
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      '& span': {
        fontSize: '10px',
      },
    },
  },
  optionContainer: {
    paddingInline: '24px',
    marginBlock: '24px',
    maxHeight: '65vh',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '55vh',
    },
  },
  formControll: {
    width: '100%',
    marginLeft: '0',
    marginBlock: '10px',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-label': {
      marginBottom: '0',
    },
  },
  option: {
    color: '#000000',
    fontSize: '18px',
    fontWeight: '300',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: '500',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  buttonsContainer: {
    paddingInline: '24px',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '10px',
    },
  },
  setButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  cancelButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    marginRight: '0.75rem',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  img: {
    width: '30px',
    marginRight: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '25px',
    },
  },
  div: {
    display: 'flex',
    alignItems: 'center',
  },
}));
