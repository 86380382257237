import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    Accept: 'application/json',
  },
});
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(response => {
  if (response.data.access_token) {
    localStorage.setItem('token', response.data.access_token);
  }
  return response;
});

// list of all the endpoints

// user apis
export const checkUsername = data => api.post('/api/verifyusername', data);
export const login = data => api.post('/api/login', data);
export const verify = data => api.post('/api/verify', data);
export const dob = data => api.patch('/api/dob', data);
export const username = data => api.patch('/api/username', data);
export const identify = data => api.patch('/api/identify', data);
export const bodyType = data => api.patch('/api/bodytype', data);
export const heightApi = data => api.patch('/api/height', data);
export const ethnicityApi = data => api.patch('/api/ethnicity', data);
export const religion = data => api.patch('/api/religion', data);
export const children = data => api.patch('/api/children', data);
export const datePreference = data => api.patch('/api/dp', data);
export const hometown = data => api.patch('/api/hometown', data);
export const education = data => api.patch('/api/education', data);
export const profession = data => api.patch('/api/profession', data);
export const vices = data => api.patch('/api/vices', data);
export const profileImage = data => api.patch('/api/profileImage', data);
export const step = data => api.patch('/api/step', data);
export const setLocation = data => api.patch('/api/location', data);

// users

export const getUser = () => api.get('/api/user');
export const getUserById = id => api.get(`/api/user/${id}`);
export const getMatchById = id => api.get(`/api/getmatchbyid/${id}`);

// matches

export const getMatchedUsers = () => api.get('/api/match');
export const unMatchUser = id => api.get(`/api/unmatch/${id}`);
export const blockAndReport = data => api.post('/api/block', data);
export const unblockUser = id => api.get(`api/unblock/${id}`);
export const addScheduler = data => api.post('api/scheduler', data);

// user_id, report_title, report_message

// profile visited

export const iVisitedProfiles = () => api.get('/api/ivisited');
// export const visitedMe = () => api.get("/api/visitedme");
export const visitedUser = id => api.post(`/api/visited/${id}`);

// liked me

export const likedMeApi = () => api.get('/api/likedMe');

// video apis

export const uploadVideo = data => api.patch('/api/prompt', data);

export const videos = () => api.get('/api/videos');
export const likeVideo = data => api.post('/api/video/like', data);
export const allVideos = (page, limit, query) =>
  api.get(`/api/allVideos?page=${page}&limit=${limit}&${query}`);
export const otherUserVideos = id => api.get(`/api/videos/${id || ''}`);
export const deleteVideo = id => api.delete(`/api/video/${id}`);
export const superLikeApi = data => api.post('/api/video/superlike', data);

// story

export const getStories = () => api.get('/api/story');

// likes API
export const likedMe = () => api.get('/api/likedme');
export const iLiked = () => api.get('/api/iliked');
export const iVisited = () => api.get('/api/ivisited');
export const visitedMe = () => api.get('/api/visitedme');

// live users

export const getLiveUsers = () => api.get('api/onlineuser');

// check match

export const checkMatch = id => api.get(`/api/checkmatch/${id}`);

// live stream
export const agoraToken = data => api.post('/api/agoratoken', data);
export const removeCoHost = data => api.post('/api/userleft', data);

export const makeGuestRequest = data => api.patch('/api/liveuser', data);
export const getGuestRequests = data => api.get(`/api/liveuser/${data}`);
export const liveStreamUsers = filter => api.get(`/api/liveuser${filter}`);
export const setLiveUser = data => api.post('/api/liveuser', data);
export const goLive = data => api.post('/api/liveuser', data);
export const deleteLiveUser = data => api.delete('/api/deleteliveuser', data);

// liveloop
export const createLiveloop = data => api.post('/api/liveloop', data);
export const queryLiveLoop = query => api.get(`/api/liveloop?${query}`);
export const liveLoopStatus = (id, data) =>
  api.patch(`/api/liveloop/${id}`, data);

// face-off
export const faceOff = query => api.get(`/api/faceoff?tag=${query}`);
// chat apis
export const allChats = () => api.get('/api/match');

// video block

export const blockVideo = data => api.post('/api/videoblock', data);

// reported or blocked Users

export const reportAndBlockedUsers = () => api.get('/api/allblock');

// Reported videos

export const reportedVideos = () => api.get('/api/allvideoblock');

export const getEmailNotification = () => api.get('/api/emailNotification');

export const setEmailNotification = data =>
  api.patch('/api/emailNotification', data);

export const generateOtp = data => api.post('/api/phone/code', data);

// help center apis

export const allCategories = () => api.get('/api/category');
export const allSubCategories = category_id =>
  api.get(`/api/sub/category/${category_id}`);

export const allQA = subCategory_id => api.get(`/api/faq/${subCategory_id}`);

export const checkEmail = email => api.post('/api/check-email', { email });

export const updateEmail = email => api.patch('/api/email', { email });

export const notInterestedVideo = video_id =>
  api.post('/api/video/not-interested', {
    video_id,
  });

export const preRegister = data => api.post('/api/pre-registration', data);

export const getAgoraToken = data => api.post('/api/agoratoken', data);
export const subscribetTopic = data =>
  api.post('/api/topic/subscription', data);

export default api;
