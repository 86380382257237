import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  distanceAway: {
    fontSize: '14px',
    marginTop: '8px',
    marginLeft: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      marginLeft: '0px',
    },
  },

  postAvatar: {
    width: '104px',
    height: '104px',
    marginLeft: '0px',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: '70px',
      height: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45px',
      height: '45px',
    },
  },
  postAvatarText: {
    fontSize: '22px',
    marginTop: '8px',
    marginLeft: '5px',
    fontWeight: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  postContainer: {
    width: '864px',
    // height: '750px',
    padding: '0rem 1rem',
    borderRadius: '54px',
    marginLeft: '2rem',
    [theme.breakpoints.down('lg')]: {
      width: '650px',
      // height: '530px',
      padding: '0.5rem 1rem',
      borderRadius: '33px',
      marginLeft: '0rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      background: 'white',
      padding: '0.5rem 0.5rem',
      boxShadow: theme.shadows[3],
    },
  },
  postAvatarContainer: {
    paddingTop: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.5rem',
    },
  },
  postTitle: {
    fontSize: '20px',
    fontFamily: 'Helvetica',
    margin: '10px 0px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      margin: '8px 0',
    },
  },
  postAsset: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  postAssetContainer: {
    padding: 0,
    paddingLeft: '0.5rem',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  imageContianer: {
    position: 'relative',
    height: '555px',
    width: '484px',
    overflow: 'hidden',
    borderRadius: '35px',
    backgroundColor: '#000000',
    '& .videoCaptionBtn': {
      position: 'absolute',
      top: '30px',
      left: '23px',
      cursor: 'pointer',
      zIndex: '10',
    },
    '& .videoCaptionOverly': {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      background: 'rgba(0,0,0,0.43)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      padding: '7rem 1rem',
    },
    '& .caption': {
      color: '#ffffff',
      fontSize: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '350px',
      height: '407.97px',
      '& .videoCaptionBtn': {
        '& img': {
          width: '20px',
        },
      },

      '& .videoCaptionOverly': {
        padding: '6rem 1rem',
      },
      '& .caption': {
        fontSize: '16px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      aspectRatio: '1/1.5',
    },
  },
  iconContainer: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 10,
    borderRadius: '16px',
    opacity: 1,
    transition: '0.6s ease',
  },
  icons: {
    height: '100%',
    padding: '0.25rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0.25rem',
    },
  },
  likeIcon: {
    color: '#fbfbfb',
    fontSize: '2.8rem',
    transition: '0.4s ease',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  muteIcon: {
    height: '40px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '28px',
    },
  },
  superIcons: {
    position: 'absolute',
    left: '0%',
    bottom: '42px',
    width: '100%',
    paddingInline: '3rem',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '1.71rem',
      bottom: '20px',
    },
  },
  superIcon: {
    height: '60px',
    width: '60px',
    marginInline: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      height: '48px',
      width: '48px',
      marginInline: '0.8rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& img': {
      [theme.breakpoints.down('lg')]: {
        width: '3.5rem',
      },
    },
  },
  superLikeIcon: {
    height: '45px',
    [theme.breakpoints.down('lg')]: {
      height: '30px',
    },
  },
  superLikeActiveIcon: {
    marginBottom: '-19px',
    height: '70px',
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      marginBottom: '-12px',
    },
  },
  menu: {
    bottom: 0,
    '& .MuiMenu-list': {
      padding: '0',
    },
    position: 'relative',
  },
  menuPaper: {
    overflow: 'visible',
    boxShadow: theme.shadows[3],
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 10,
      width: 10,
      height: 10,
      background: 'white',
      transform: 'translateY(-50%) rotate(45deg)',
      // zIndex: 0,
    },
  },
  menuItem: {
    margin: 0,
    color: '#000',
  },
  listContainer: {
    borderRadius: '5px',
    overflow: 'hidden',
  },
}));
