import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid,
  SwipeableDrawer,
  ListItem,
  ListItemText,
  List,
  Hidden,
} from '@material-ui/core';
import image from '../../assets/index';
import { CustomButton } from '../CustomButton/CustomButton';
import { useStyles } from './headerStyles';
import { MenuRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const Header = ({
  transparent,
  headerItems = [
    {
      label: 'Register',
      to: '/signin',
    },
  ],
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const TabsContainer = () => {
    return (
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.navLinks}
        >
          {headerItems.length > 0 &&
            headerItems.map((item, index) => (
              <Tab
                component={Link}
                to={{
                  pathname: item.to,
                  state: {
                    fromRegister: true,
                  },
                }}
                label={item.label}
                key={index}
                disableRipple
                className={transparent ? classes.tabLight : classes.tab}
              />
            ))}
        </Tabs>
        <CustomButton
          component={Link}
          to='/'
          variant='btnOutlineBlue'
          styleProps={{
            marginLeft: '10px',
            fontSize: `${lgScreen ? '1rem' : '25px'}`,
            width: `${lgScreen ? '150px' : '219px'}`,
            paddingInline: '0',
          }}
        >
          <Link to='/getapp' style={{ textDecoration: 'none' }}>
            Get the App
          </Link>
        </CustomButton>
      </>
    );
  };

  const DrawerContainer = () => {
    const handleCloseDrawer = () => {
      setOpenDrawer(false);
    };

    const toggleDrawer = () => {
      setOpenDrawer(!openDrawer);
    };

    return (
      <>
        {headerItems.length ? (
          <>
            <SwipeableDrawer
              anchor='left'
              open={openDrawer}
              onClose={handleCloseDrawer}
            >
              <List className={classes.list}>
                {headerItems.map((item, index) => (
                  <ListItem
                    className='list-item'
                    component={Link}
                    key={index}
                    to={item.to}
                  >
                    <ListItemText
                      className='list-item-text'
                      primary={item.label}
                    />
                  </ListItem>
                ))}
                <ListItem>
                  <CustomButton
                    component={Link}
                    to='/'
                    variant='btnOutlineBlue'
                    styleProps={{
                      fontSize: '1rem',
                      width: '120px',
                      paddingInline: '0',
                      marginInline: 'auto',
                    }}
                  >
                    <Link to='/getapp' style={{ textDecoration: 'none' }}>
                      Get the App
                    </Link>
                  </CustomButton>
                </ListItem>
              </List>
            </SwipeableDrawer>
            <IconButton
              onClick={toggleDrawer}
              disableRipple
              className={classes.drawerIconContainer}
            >
              <MenuRounded
                className={
                  transparent ? classes.drawerIconDark : classes.drawerIcon
                }
              />
            </IconButton>
          </>
        ) : (
          <CustomButton
            component={Link}
            to='/'
            variant='btnOutlineBlue'
            styleProps={{
              fontSize: '1rem',
              width: '120px',
              paddingInline: '0',
              marginLeft: 'auto',
            }}
          >
            <Link to='/getapp' style={{ textDecoration: 'none' }}>
              Get the App
            </Link>
          </CustomButton>
        )}
      </>
    );
  };

  return (
    <>
      <AppBar className={transparent ? classes.navLight : classes.nav}>
        <Toolbar className={classes.toolbar}>
          <Button className={classes.logo} disableRipple>
            <img
              src={image.introLogo}
              className={classes.logoImage}
              alt='company-logo'
            />
          </Button>
          <Hidden mdUp>
            <DrawerContainer />
          </Hidden>
          <Hidden smDown>
            <TabsContainer />
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </>
  );
};
