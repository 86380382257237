import React from 'react';

import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';

const Congratulation = ({ open, setOpen }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleClose = () => {
    history.replace('/');
    setOpen(false);
  };
  return (
    <Dialog open={open} className={classes.dialog}>
      <Grid
        container
        direction='column'
        className='main-container'
        alignItems='center'
      >
        <Typography variant='h2' style={{ margin: '0px', fontSize: '30px' }}>
          Congratulations 🎉
        </Typography>

        <Typography variant='body1'>
          You have successfully registered in Intro. Please check your email for
          further instructions.
        </Typography>

        <Grid item container className={classes.buttonRow}>
          <Button
            variant='contained'
            className='btn btn-continue'
            color='primary'
            onClick={handleClose}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Congratulation;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      background: theme.palette.common.lightPink,
      borderRadius: '12px',
      width: '100%',
      maxWidth: '450px',
    },
    '& .main-container': {
      gap: '30px',
      padding: '25px',
    },
  },
  buttonRow: {
    '& .btn': {
      width: '100%',
      borderRadius: '5px',
      textTransform: 'none',
      borderRadius: '25px',
      height: '45px',
      fontSize: '18px',
    },
  },
}));
