import React, { useEffect } from 'react';
import { useStyles } from './helpStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { Header } from '../../components/header/Header';
import { useHistory } from 'react-router-dom';
import { allCategories } from '../../http';
import { setCategories, setSelectedCategorie } from '../../store/Reducers/help';
export const HelpCenter = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.help.categories);
  const headerItems = [];

  const handleClick = category => {
    dispatch(setSelectedCategorie(category));
    history.push({
      pathname: '/helpcenter/details',
      state: category,
    });
  };

  const handleGetCategories = async () => {
    try {
      const { data } = await allCategories();
      dispatch(setCategories(data.data));
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <Header headerItems={headerItems} transparent />
      <Grid
        container
        alignItems='center'
        direction='column'
        justifyContent='center'
        className={classes.innerContainer}
      >
        <Grid item className={classes.titleContainer}>
          <p className={classes.title}>Help Center</p>
        </Grid>

        <Grid item>
          <p className={classes.subTitle1}>Categories</p>
        </Grid>
        <Grid item style={{ width: '80%', marginInline: 'auto' }}>
          <Grid container justifyContent='center' spacing={3}>
            {categories?.map(category => (
              <Grid
                key={category._id}
                item
                onClick={() => handleClick(category)}
              >
                <Card className={classes.card}>
                  <CardHeader
                    style={{ textDecoration: 'none' }}
                    className={classes.cardHeader}
                    title={category.title}
                  />
                  <CardContent className={classes.cardContent}>
                    <p>{category.description}</p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
