import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import { Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import Joi from 'joi-browser';
import { datePreference as datePreferenceApi } from '../../../http';
import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/header/Header';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { sexualityList, datePrefList } from '../../../app.config';

import image from '../../../assets/index';
export const DatingPref = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const userData = useSelector(state => state.auth.user.data);

  const [t] = useTranslation();

  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState({
    datePreference: '0',
    intent: '0',
  });
  const [show, setShow] = useState({
    datePreference: false,
    intent: false,
  });
  const [errors, setErrors] = useState({
    intent: '',
    datePreference: '',
  });
  const schema = {
    datePreference: Joi.string().min(2).required().label('Sexuality'),
    intent: Joi.string().min(2).required().label('Intent'),
  };

  const validate = () => {
    const data = {
      datePreference: formData.datePreference,
      intent: formData.intent,
    };
    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) {
      setErrors({
        datePreference: '',
        intent: '',
      });
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details)
        errorsObject[item.path[0]] = `"${
          item.path[0].charAt(0).toUpperCase() + item.path[0].slice(1)
        }" can not be empty`;
      setErrors(errorsObject);
      return true;
    }
  };

  const handleShow = e => {
    const { name, checked } = e.target;
    setShow(prev => ({ ...prev, [name]: checked }));
  };

  const handleSelect = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setShow(prev => ({ ...prev, [name]: true }));

    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, subSchema);
    const it = error
      ? setErrors({
          ...errors,
          [name]: `"${
            name.charAt(0).toUpperCase() + name.slice(1)
          }" can not be empty`,
        })
      : setErrors({ ...errors, [name]: '' });
  };

  const handleNext = async () => {
    const error = validate();
    if (!error) {
      try {
        const apiData = {
          interested_gender: formData.datePreference,
          interested_audience: formData.intent,
          visible: show.datePreference,
          step:
            userData.step === '/choose-date-characters'
              ? '/height'
              : userData.step,
        };
        const { data } = await datePreferenceApi(apiData);
        dispatch(submit(data));
        history.push('/register/height');
      } catch (err) {
        alert('something went wrong');
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    if (formData.datePreference.length >= 2 && formData.intent.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (formData.intent === '0') {
      setShow(prev => ({ ...prev, intent: false }));
    }
  }, [formData]);

  useEffect(() => {
    setFormData({
      datePreference: userData?.date_preference?.interested_gender || '0',
      intent: userData?.date_preference?.interested_audience || '0',
    });
    setShow({
      datePreference: userData?.date_preference?.visible || false,
      intent: userData?.date_preference?.visible || false,
    });
  }, [userData]);
  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.heart})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? '210px 210px'
              : '250px 250px'
            : '280px 280px'
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      {/* <Header transparent /> */}
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Typography variant='h1' className={classes.formTitle}>
          {t('dating_preferences')}
        </Typography>
        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid
            container
            direction='column'
            className={classes.formContainer}
            spacing={2}
          >
            <Grid
              item
              className='content'
              style={{ marginRight: smScreen ? '0px' : '-210px' }}
            >
              <Grid
                container
                direction='column'
                style={{
                  gap: lgScreen ? (xsScreen ? '40px' : '80px') : '124px',
                }}
              >
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('Sexuality')}
                    options={sexualityList}
                    placeholder='Choose'
                    show={show.datePreference}
                    handleShow={handleShow}
                    name='datePreference'
                    onSelect={handleSelect}
                    value={formData.datePreference}
                    error={Boolean(errors.datePreference)}
                    errorText={errors?.datePreference?.replace(
                      'DatePreference',
                      t('Sexuality')
                    )}
                    showNotVisible={true}
                  />
                </Grid>

                <Grid item>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('intent')}
                    options={datePrefList}
                    placeholder='Choose'
                    show={show.intent}
                    handleShow={handleShow}
                    name='intent'
                    onSelect={handleSelect}
                    value={formData.intent}
                    error={Boolean(errors.intent)}
                    errorText={errors.intent}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
          }}
        >
          <ButtonComp
            disabled={disabled}
            onClick={handleNext}
            label={t('continue')}
            style={{ width: '100%' }}
          />
          {/* <CustomIconButton onClick={handleNext} /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
