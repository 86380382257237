import { createSlice } from '@reduxjs/toolkit';
const filters = createSlice({
  name: 'filters',
  initialState: {
    gender: ['Male'],
    intent: [],
    age: [18, 50],
    religion: [],
    ethnicity: [],
    bodyType: [],
    education: [],
    kids: [],
    drink: [],
    smoke: [],
    weed: [],
    drugs: [],
    country: [],
    height: [36, 84],
    sexuality: [],
    maritalStatus: [],
    politics: [],
    occupation: [],
    exercise: [],
    diet: [],
    liveWith: [],
    haveChildren: [],
    wantChildren: [],
    pets: [],
    distance: 500,
    location: null,
    filterUpdated: 0,
    isAnyOpen: false,
  },
  reducers: {
    setGender(state, action) {
      state.gender = action.payload;
    },
    setIntent(state, action) {
      state.intent = action.payload;
    },
    setAge(state, action) {
      state.age = action.payload;
    },
    setReligion(state, action) {
      state.religion = action.payload;
    },
    setEthnicity(state, action) {
      state.ethnicity = action.payload;
    },
    setBodyType(state, action) {
      state.bodyType = action.payload;
    },
    setEducation(state, action) {
      state.education = action.payload;
    },
    setKids(state, action) {
      state.kids = action.payload;
    },
    setDrink(state, action) {
      state.drink = action.payload;
    },
    setSmoke(state, action) {
      state.smoke = action.payload;
    },
    setWeed(state, action) {
      state.weed = action.payload;
    },
    setDrugs(state, action) {
      state.drugs = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setHeight(state, action) {
      state.height = action.payload;
    },
    setDistance(state, action) {
      state.distance = action.payload;
    },
    setFilterUpdated(state, action) {
      if (action.payload === 0) {
        state.filterUpdated = 0;
        return;
      }
      state.filterUpdated = state.filterUpdated + 1;
    },
    setIsAnyOpen(state, action) {
      state.isAnyOpen = action.payload;
    },
    setSexuality(state, action) {
      state.sexuality = action.payload;
    },
    setMaritalStatus(state, action) {
      state.maritalStatus = action.payload;
    },
    setPolitics(state, action) {
      state.politics = action.payload;
    },
    setOccupation(state, action) {
      state.occupation = action.payload;
    },

    setFilter(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    setLocation(state, action) {
      state.location = action.payload;
    },
    resetFilters(state, payload) {
      state.gender = [];
      state.intent = [];
      state.age = [18, 50];
      state.religion = [];
      state.ethnicity = [];
      state.bodyType = [];
      state.education = [];
      state.kids = [];
      state.drink = [];
      state.smoke = [];
      state.weed = [];
      state.drugs = [];
      state.country = [];
      state.height = [36, 84];
      state.sexuality = [];
      state.maritalStatus = [];
      state.politics = [];
      state.occupation = [];
      state.exercise = [];
      state.diet = [];
      state.liveWith = [];
      state.haveChildren = [];
      state.wantChildren = [];
      state.pets = [];
      state.filterUpdated = state.filterUpdated + 1;
    },
  },
});

export const {
  setGender,
  setIntent,
  setAge,
  setReligion,
  setEthnicity,
  setBodyType,
  setEducation,
  setKids,
  setDrink,
  setSmoke,
  setWeed,
  setDrugs,
  setCountry,
  setHeight,
  setFilterUpdated,
  setIsAnyOpen,
  setFilter,
  resetFilters,
  setLocation,
  setDistance,
} = filters.actions;
export default filters.reducer;
