const icons = {
  degree: require('./degree.png').default,
  religion: require('./religion.svg').default,
  politics: require('./politics.png').default,
  drink: require('./drink.png').default,
  drungs: require('./drugs.png').default,
  smoke: require('./smoke.png').default,
  weed: require('./weed.png').default,
  haveChildren: require('./haveChildren.png').default,
  wantChildren: require('./wantChildren.png').default,
  workplace: require('./workplace.png').default,
  job: require('./job.png').default,
  ethnicity: require('./ethnicity.png').default,
  health: require('./health.svg').default,
  diet: require('./diet.svg').default,
  intent: require('./intent.svg').default,
  pets: require('./pets.svg').default,
  bodyType: require('./bodyType.svg').default,
  heart: require('./heart.svg').default,
};

export default icons;
