import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStyles } from './selectImageStyles';
import { Grid, useTheme, Typography, Dialog, Button } from '@material-ui/core';
import { Header } from '../../../components/header/Header';
import image from '../../../assets/index';

import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { profileImage, step } from '../../../http';
import { submit } from '../../../store/user';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import ImageCrop from '../../../modals/ImageCrop';
import { useTranslation } from 'react-i18next';

export const SelectImage = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const webcamRef = useRef();
  const [disabled, setDisabled] = useState(true);
  const headerItems = [];
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [openWebCam, setOpenWebCam] = useState(false);
  const [crop, setCrop] = useState(false);

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const [, setError] = useState(false);
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const handleUploadImage = e => {
    setError(false);
    setUploadImage('');
    setImageFile(e.target.files[0]);
    setCrop(true);
    if (e.target.files[0]) {
      const image = URL.createObjectURL(e.target.files[0]);
      setUploadImage(image);
    }
  };
  const takeScreenShot = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUploadImage(imageSrc);
    setOpenWebCam(false);
  }, [webcamRef]);

  const converBase64ToFile = async base64 => {
    const res = await fetch(base64);
    const blob = await res.blob();
    const file = new File([blob], 'cover', { type: 'image/png' });
    return file;
  };
  const handleSubmit = async () => {
    if (imageFile === null) return setError(true);
    try {
      let imageData = new FormData();
      const stepData = {
        step:
          userData.step === '/get-user-photos'
            ? '/get-prompt-questions'
            : userData.step,
      };
      const imageToUploaded = await converBase64ToFile(imageFile);
      imageData.append('profile_img', imageToUploaded);
      let res = await profileImage(imageData);
      res = await step(stepData);
      dispatch(submit(res.data));
      history.push('/uploads');
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (imageFile !== null) {
      setDisabled(false);
      imageFile && setUploadImage(imageFile);
    } else {
      setDisabled(true);
    }
  }, [imageFile]);
  return (
    <Grid
      container
      className={classes.container}
      justifyContent='center'
      alignItems='center'
      direction='column'
      style={{
        backgroundImage: `url(${image.cameraBg})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? '200px 200px'
              : '250px 250px'
            : '300px 300px'
          : '',
      }}
    >
      <Header headerItems={headerItems} transparent />
      <Grid
        container
        direction='column'
        className={classes.contentContainer}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Typography className={classes.title} variant='h4'>
            {t('Photo')}
          </Typography>
        </Grid>
        <Grid item>
          <label htmlFor='image-picker'>
            <div className={classes.imagePicker}>
              <img src={image.imagePicker} className='border' alt='' />
              {uploadImage ? (
                <img src={uploadImage} alt='' className='upload' />
              ) : (
                <img src={image.addImgae} className='add-sign' alt='' />
              )}
            </div>
          </label>
        </Grid>

        <ButtonComp
          label={t('continue')}
          disabled={disabled}
          onClick={handleSubmit}
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
          }}
        />
        <input
          type='file'
          id='image-picker'
          onChange={handleUploadImage}
          style={{ display: 'none' }}
        />
        <Dialog open={openWebCam} onClose={() => setOpenWebCam(false)}>
          <Webcam
            ref={webcamRef}
            mirrored='false'
            screenshotQuality='1'
            screenshotFormat='image/jpeg'
            imageSmoothing
          />
          <Button
            variant='contained'
            color='primary'
            onClick={takeScreenShot}
            className={classes.takeImageButton}
          >
            Take Photo
          </Button>
        </Dialog>
        {imageFile && crop && (
          <ImageCrop
            open={crop}
            imageToCrop={URL.createObjectURL(imageFile)}
            setCropped={val => setImageFile(val)}
            setOpen={setCrop}
          />
        )}
      </Grid>
    </Grid>
  );
};
