import React, { useState, useCallback, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Box,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import ProfileInputField from '../../components/UpdateProfile/ProfileInputField/ProfileInputField';
import ProfileSelect from '../../components/UpdateProfile/ProfileSelect/ProfileSelect';

import {
  bodyTypeList,
  countryList,
  dietList,
  fitnessList,
  genderList,
  getEthnicityList,
  haveChildList,
  homeTownList,
  maritalStatusList,
  occupationList,
  petList,
  politicsList,
  pronounsList,
  religionList,
  schoolDegreeList,
  sexualityList,
  vicesList,
  wantChildList,
} from '../../app.config';
import {
  bodyType,
  checkEmail,
  children,
  datePreference,
  education,
  ethnicityApi,
  hometown,
  identify,
  profession,
  religion,
  step,
  updateEmail,
  username,
  vices,
} from '../../http';
import axios from 'axios';
import { submit } from '../../store/user';
import { Alert } from '@material-ui/lab';

const UpdateProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user.data);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');

  const [pronouns, setPronouns] = useState([]);
  const [country, setCountry] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState();
  const [errors, setErrors] = useState({
    email: '',
    phone: '',
  });

  const handlePronouns = (name, value) => {
    setPronouns(value);
  };

  const handleCountry = (name, value) => {
    setCountry(value);
  };

  const [formData, setFormData] = useState({
    name: user.first_name || '',
    email: user.email || '',
    phone_number: user.phonenumber || '',
    gender: user.identify.gender || '',
    marital_status: user.identify.marital_status || '',
    sexuality: user.date_preference.interested_gender || '',
    body_type: user.body_type.type || '',
    diet: user.body_type.diet || '',
    exercise: user.body_type.fitness || '',
    race: user.ethnicity.race || '',
    hometown: user.home_town.home_town || '',
    live_with: user.home_town.live_with || '',
    school: user.education.school || '',
    degree: user.education.degree || '',
    workplace: user.profession.company.company || '',
    job_title: user.profession.job_title.job_title || '',
    occupation: user.profession.occupation.occupation || '',
    religion: user.religion.religion || '',
    politics: user.religion.politics || '',
    have_children: user.children.have_children || '',
    want_Children: user.children.want_children || '',
    pets: user.children.have_pet || '',
    drink: user.vices.drink.drink || '',
    smoke: user.vices.smoke.smoke || '',
    drugs: user.vices.drugs.drugs || '',
    marijuana: user.vices.weed.weed || '',
  });

  const [show, setShow] = useState({
    gender: user.identify.visible || false,
    pronouns: user.identify.pronouns_visible || false,
    marital_status: user.identify.marital_status_visible || false,
    sexuality: user.date_preference.visible || false,
    body_type: user.body_type.t_visible || false,
    diet: user.body_type.d_visible || false,
    exercise: user.body_type.f_visible || false,
    race: user.ethnicity.r_visible || false,
    countryList: user.ethnicity.c_visible || false,
    live_with: user.home_town.live_with_visible || false,
    degree: user.education.d_visible || false,
    occupation: user.profession.occupation.o_visible || false,
    religion: user.religion.r_visible || false,
    politics: user.religion.p_visible || false,
    have_children: user.children.visible || false,
    want_Children: user.children.w_visible || false,
    pets: user.children.p_visible || false,
    drink: user.vices.drink.d_visible || false,
    smoke: user.vices.smoke.s_visible || false,
    drugs: user.vices.drugs.dr_visible || false,
    marijuana: user.vices.weed.w_visible || false,
  });

  const handleChange = useCallback(e => {
    const { name, value } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSelect = useCallback((name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleShow = useCallback(e => {
    const { name, checked } = e.target;
    setShow(prev => ({ ...prev, [name]: checked }));
  }, []);

  const handleUpdateEmail = async () => {
    try {
      setInvalidEmail(false);
      if (formData.email === user.email) {
        return;
      }
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email)) {
        const { data } = await checkEmail(formData.email);

        if (data.exist) {
          setErrors({
            ...errors,
            email: 'This email already exists. Please try another one',
          });
        } else {
          await updateEmail(formData.email);
          setAlert('Email updated successfully!');
        }
      } else {
        setInvalidEmail(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const userInfo = {
        email: formData.email,
        first_name: formData.name,
        username: user.username ? user.username : formData.name,
      };

      const identifyData = {
        gender: formData.gender,
        visible: show.gender,
        pronouns: pronouns,
        pronouns_visible: show.pronouns,
        marital_status: formData.marital_status,
        marital_status_visible: show.marital_status,
      };

      const datePerfData = {
        interested_gender: formData.sexuality,
        visible: show.sexuality,
      };

      const bodyTypeData = {
        type: formData.body_type,
        t_visible: show.body_type,
        diet: formData.diet,
        d_visible: show.diet,
        fitness: formData.exercise,
        f_visible: show.exercise,
      };

      const ethnicityApiData = {
        country_list: country,
        race: formData.race,
        c_visible: show.countryList,
        r_visible: show.race,
      };
      const hometownData = {
        home_town: formData.hometown,
        visible: user.home_town.visible,
        live_with: formData.live_with,
        live_with_visible: show.live_with,
      };

      const educationData = {
        school: formData.school,
        s_visible: user.education.s_visible,
        degree: formData.degree,
        d_visible: show.degree,
      };
      const professionData = {
        company: formData.workplace,
        job_title: formData.job_title,
        occupation: formData.occupation,
        c_visible: user.profession.company.c_visible,
        j_visible: user.profession.job_title.j_visible,
        o_visible: show.occupation,
      };

      const religionData = {
        religion: formData.religion,
        r_visible: show.religion,
        politics: formData.politics,
        p_visible: show.politics,
      };

      const childData = {
        have_children: formData.have_children,
        want_children: formData.want_Children,
        visible: show.have_children,
        // w_visible: show.want_Children,
        have_pet: formData.pets,
        p_visible: show.pets,
      };

      const vicesData = {
        drink: formData.drink,
        d_visible: show.drink,
        smoke: formData.smoke,
        s_visible: show.smoke,
        weed: formData.marijuana,
        w_visible: show.marijuana,
        drugs: formData.drugs,
        dr_visible: show.drugs,
      };
      axios
        .all([
          username(userInfo),
          identify(identifyData),
          datePreference(datePerfData),
          bodyType(bodyTypeData),
          ethnicityApi(ethnicityApiData),
          hometown(hometownData),
          education(educationData),
          profession(professionData),
          religion(religionData),
          children(childData),
          vices(vicesData),
          step({ step: '/home' }),
        ])
        .then(
          axios.spread(function (
            res1,
            res2,
            res3,
            res4,
            res5,
            res6,
            res7,
            res8,
            res9,
            res10,
            res11,
            res12
          ) {
            dispatch(submit(res12.data));
            setAlert('Profile updated successfully!');
            setLoading(false);
          })
        )
        .catch(error => alert(error.message));
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    setFormData({
      name: user.first_name || '',
      email: user.email || '',
      phone_number: user.phonenumber || '',
      gender: user.identify.gender || '',
      marital_status: user.identify.marital_status || '',
      sexuality: user.date_preference.interested_gender || '',
      body_type: user.body_type.type || '',
      diet: user.body_type.diet || '',
      exercise: user.body_type.fitness || '',
      race: user.ethnicity.race || '',
      hometown: user.home_town.home_town || '',
      live_with: user.home_town.live_with || '',
      school: user.education.school || '',
      degree: user.education.degree || '',
      workplace: user.profession.company.company || '',
      job_title: user.profession.job_title.job_title || '',
      occupation: user.profession.occupation.occupation || '',
      religion: user.religion.religion || '',
      politics: user.religion.politics || '',
      have_children: user.children.have_children || '',
      want_Children: user.children.want_children || '',
      pets: user.children.have_pet || '',
      drink: user.vices.drink.drink || '',
      smoke: user.vices.smoke.smoke || '',
      drugs: user.vices.drugs.drugs || '',
      marijuana: user.vices.weed.weed || '',
    });
    setShow({
      gender: user.identify.visible || false,
      pronouns: user.identify.pronouns_visible || false,
      marital_status: user.identify.marital_status_visible || false,
      sexuality: user.date_preference.visible || false,
      body_type: user.body_type.t_visible || false,
      diet: user.body_type.d_visible || false,
      exercise: user.body_type.f_visible || false,
      race: user.ethnicity.r_visible || false,
      countryList: user.ethnicity.c_visible || false,
      live_with: user.home_town.live_with_visible || false,
      degree: user.education.d_visible || false,
      occupation: user.profession.occupation.o_visible || false,
      religion: user.religion.r_visible || false,
      politics: user.religion.p_visible || false,
      have_children: user.children.visible || false,
      want_Children: user.children.w_visible || false,
      pets: user.children.p_visible || false,
      drink: user.vices.drink.d_visible || false,
      smoke: user.vices.smoke.s_visible || false,
      drugs: user.vices.drugs.dr_visible || false,
      marijuana: user.vices.weed.w_visible || false,
    });
    setPronouns(user.identify.pronouns || []);
    setCountry(user.ethnicity.country_list || []);
  }, [user]);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        container
        className={classes.titleContainer}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography className={classes.title}>Edit Profile</Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={handleUpdate}
            variant='contained'
            color='primary'
            className={classes.btn}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color='primary' size={15} />
            ) : (
              'Update'
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Box style={{ marginTop: '0px' }} className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Basic
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Name'
                onChange={handleChange}
                name='name'
                value={formData.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Email'
                onChange={handleChange}
                name='email'
                value={formData.email}
                onBlur={handleUpdateEmail}
                helperText={errors.email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Phone number'
                onChange={handleChange}
                name='phone_number'
                value={formData.phone_number}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            How do you identify?
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Gender'
                options={genderList}
                handleSelect={handleSelect}
                value={formData.gender}
                name='gender'
                handleShow={handleShow}
                show={show.gender}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Pronouns'
                options={pronounsList}
                handleSelect={handleSelect}
                value={pronouns}
                name='pronouns'
                handleShow={handleShow}
                show={show.pronouns}
                handleSelectMultiple={handlePronouns}
                multiple
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Marital Status'
                options={maritalStatusList}
                handleSelect={handleSelect}
                value={formData.marital_status}
                name='marital_status'
                handleShow={handleShow}
                show={show.marital_status}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Sexuality'
                options={sexualityList}
                handleSelect={handleSelect}
                value={formData.sexuality}
                name='sexuality'
                handleShow={handleShow}
                show={show.sexuality}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Body
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Body Type'
                options={bodyTypeList}
                handleSelect={handleSelect}
                value={formData.body_type}
                name='body_type'
                handleShow={handleShow}
                show={show.body_type}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Diet'
                options={dietList}
                handleSelect={handleSelect}
                value={formData.diet}
                name='diet'
                handleShow={handleShow}
                show={show.diet}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Exercise'
                options={fitnessList}
                handleSelect={handleSelect}
                value={formData.exercise}
                name='exercise'
                handleShow={handleShow}
                show={show.exercise}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Origin/Home
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Race'
                options={getEthnicityList}
                handleSelect={handleSelect}
                value={formData.race}
                name='race'
                handleShow={handleShow}
                show={show.race}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Ethnic Background'
                options={countryList}
                country
                handleSelect={handleSelect}
                value={country}
                name='countryList'
                handleShow={handleShow}
                show={show.countryList}
                multiple
                handleSelectMultiple={handleCountry}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Hometown'
                onChange={handleChange}
                name='hometown'
                value={formData.hometown}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Living Situation'
                options={homeTownList}
                handleSelect={handleSelect}
                value={formData.live_with}
                name='live_with'
                handleShow={handleShow}
                show={show.live_with}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Education/Profession
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='School'
                onChange={handleChange}
                name='school'
                value={formData.school}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Degree'
                options={schoolDegreeList}
                handleSelect={handleSelect}
                value={formData.degree}
                name='degree'
                handleShow={handleShow}
                show={show.degree}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Wrokplace'
                onChange={handleChange}
                name='workplace'
                value={formData.workplace}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileInputField
                label='Job Title'
                onChange={handleChange}
                name='job_title'
                value={formData.job_title}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Occupation'
                options={occupationList}
                handleSelect={handleSelect}
                value={formData.occupation}
                name='occupation'
                handleShow={handleShow}
                show={show.occupation}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Religion/Politics
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Religion'
                options={religionList}
                handleSelect={handleSelect}
                value={formData.religion}
                name='religion'
                handleShow={handleShow}
                show={show.religion}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Politics'
                options={politicsList}
                handleSelect={handleSelect}
                value={formData.politics}
                name='politics'
                handleShow={handleShow}
                show={show.politics}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Children/Pets
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Have Children'
                options={haveChildList}
                handleSelect={handleSelect}
                value={formData.have_children}
                name='have_children'
                handleShow={handleShow}
                show={show.have_children}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Family plans'
                options={wantChildList}
                handleSelect={handleSelect}
                value={formData.want_Children}
                name='want_Children'
                handleShow={handleShow}
                show={show.want_Children}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Pets'
                options={petList}
                handleSelect={handleSelect}
                value={formData.pets}
                name='pets'
                handleShow={handleShow}
                show={show.pets}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Typography text-align='left' className='section-title'>
            Lifestyle
          </Typography>
          <Grid item container spacing={4}>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Drink'
                options={vicesList}
                handleSelect={handleSelect}
                value={formData.drink}
                name='drink'
                handleShow={handleShow}
                show={show.drink}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Smoke'
                options={vicesList}
                handleSelect={handleSelect}
                value={formData.smoke}
                name='smoke'
                handleShow={handleShow}
                show={show.smoke}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Drugs'
                options={vicesList}
                handleSelect={handleSelect}
                value={formData.drugs}
                name='drugs'
                handleShow={handleShow}
                show={show.drugs}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProfileSelect
                label='Marijuana'
                options={vicesList}
                handleSelect={handleSelect}
                value={formData.marijuana}
                name='marijuana'
                handleShow={handleShow}
                show={show.marijuana}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAlert(false)}
        open={!!alert}
        autoHideDuration={5000}
      >
        <Alert style={{ fontSize: '16px' }} severity='success'>
          {alert}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default UpdateProfile;

const useStyles = makeStyles(theme => ({
  container: {
    padding: '33px 0px',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      padding: '22px 0px',
      paddingInline: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
      paddingBottom: '20px',
    },
  },
  titleContainer: {
    marginBottom: '20px',
    paddingInline: '57px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '20px',
      paddingInline: '25px',
    },
  },
  title: {
    fontSize: '26px',
    fontWeight: '500',
    color: '#000000',
    margin: '0px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
    },
  },
  btn: {
    width: '240px',
    height: '62px',
    borderRadius: '32px',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      width: '120px',
      height: '40px',
    },
  },
  content: {
    paddingTop: '37px',
    flexGrow: '1',
    height: '0px',
    overflow: 'auto',
    paddingInline: '57px',
    paddingBottom: '30px',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '25px',
    },
  },
  section: {
    width: '100%',
    marginTop: '40px',
    '&:first-child': {
      marginTop: '0px',
    },
    '& .section-title': {
      textAlign: 'left',
      paddingLeft: '10px',
      margin: '0',
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: '700',
      marginBottom: '24px',
    },
  },
}));
