import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: theme.palette.common.lightPink,
    padding: '0 52px',
    boxShadow: theme.shadows[10],
    zIndex: 0,
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 25px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px',
    },
  },
  explorePostContainer: {
    [theme.breakpoints.down(1290)]: {
      flexDirection: 'column-reverse',
    },
  },
  posts: {
    [theme.breakpoints.down(1290)]: {
      order: 2,
      width: '100%',
    },
  },
  filters: {
    [theme.breakpoints.down(1290)]: {
      order: 1,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '3rem',
    },
  },
  exploreContainer: {
    padding: '1rem 4rem',

    [theme.breakpoints.down('lg')]: {
      padding: '1rem 2rem',
    },
  },
  hero: {
    backgroundColor: '#fff',
    borderRadius: '54px',
    boxShadow: theme.shadows[5],
    marginBottom: '5px',
    marginTop: '0',
    maxHeight: 'calc(100% - 130px)',
    minHeight: 'calc(100% - 230px)',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'calc(100vh - 90px)',
      minHeight: 'calc(100vh - 150px)',
      borderRadius: '34px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 90px)',
      minHeight: 'calc(100vh - 100px)',
      borderRadius: '34px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      maxHeight: '50%',
      height: '50%',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-button ': {
      width: '20px', //for horizontal scrollbar
      height: '20px', //for vertical scrollbar
    },
    overflowX: 'hidden',
  },
  heroTitle: {
    fontSize: '30px',
    fontFamily: 'Helvetica',
    marginBottom: '15px',
    color: '#000',
    margin: 0,
    fontWeight: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '28px',
    },
  },
  fixButton: {
    backgroundColor: '#FE858C',
    position: 'fixed',
    bottom: 20,
    right: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#Ff859C',
    },
  },
  tabsContainer: {
    padding: '3rem 0rem',
    [theme.breakpoints.down('lg')]: {
      padding: '2.5rem 0rem',
    },
  },
  tabsLogo: {
    width: '9rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('lg')]: {
      width: '7rem',
    },
  },
  tabs: {
    width: '100%',
  },

  icons: {
    fontSize: '1.5rem',
    marginRight: '0px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      color: '#000',
      fontSize: '21px',
      fontWeight: '500',
      margin: 'auto 0',
      textAlign: 'left',
      marginLeft: '15px',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
        fontSize: '15px',
        margin: 'auto 0',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        fontSize: '21px',
        margin: 'auto 0',
      },
    },
  },
  listItem: {
    height: '64px',
    paddingLeft: '4.5rem',
    boxSizing: 'content-box',
    cursor: 'pointer',
    width: '80%',
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '3rem',
      height: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3rem',
      height: '64px',
    },
  },
  listItemActive: {
    borderRadius: '0px 35px 35px 0px',
    width: '82%',
    boxSizing: 'content-box',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      width: '85%',
      borderRadius: '0px 32px 32px 0px',
    },
    '& .MuiListItemText-primary ': {
      color: '#fff',
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.darkPink,
    },
  },
  root: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    minWidth: '0',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '35px',
      },
    },
  },
  sliderContainer: {
    // paddingInline: "25px",
    marginTop: '40px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5rem',
      marginBottom: '0rem',
    },
  },
  newIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.7rem',
    },
  },

  drawer: {
    '& 	.MuiDrawer-paper': {
      maxWidth: '317px',
      minWidth: '317px',
    },
  },

  logoContainer: {
    padding: '24px 0px 0px 24px',
    marginBottom: '45px',

    '& .logo': {
      width: '80px',
    },
  },
}));
