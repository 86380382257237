import React, { useEffect } from 'react';
import { Grid, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { useStyles } from '../../TabsContainer/tabStyles';
// import { StorySlider } from '../../../components/StorySlider/StroySlider';
import { Filter } from '../../../components/Filter/Filter';
import { Post } from '../../../components/Post/Post';
import { useSelector, useDispatch } from 'react-redux';
// import { getStories } from '../../../http/index';
// import { setStories } from '../../../store/stories';
import { CircularProgress } from '@material-ui/core';
import { Slider } from '../../../components/Slider/Slider';
import { getLiveUsers } from '../../../http';
import { setOnlineUsers } from '../../../store/user';
import { useTranslation } from 'react-i18next';

export const Explore = props => {
  const {
    videos,
    addToRefs,
    lastElementRef,
    loaderSpinner,
    handleFitlerVideo,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // const stories = useSelector(state => state.stories.stories);
  const onlineUsers = useSelector(state => state.auth.user.onlineUsers);
  // const [, setAllStories] = useState(stories || []);

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [t] = useTranslation();

  // ..................calculting the locations
  let Late1 = localStorage.getItem('latitude');
  let lon1 = localStorage.getItem('Longitude');

  function calcCrow(lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - Late1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(Late1);
    var lat2 = toRad(lat2);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1.60934).toFixed(1);
  }
  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }
  //... ending the calculations here
  useEffect(() => {
    (async () => {
      const { data } = await getLiveUsers();
      dispatch(setOnlineUsers(data.data));
      // const { data } = await getStories();
      // dispatch(setStories(data.data));
      // setAllStories(data.data);
      // console.log(data.data);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Grid container className={classes.exploreContainer} direction='column'>
        <Grid
          item
          style={{ width: '100%' }}
          className={classes.sliderContainer}
        >
          {onlineUsers.length > 0 && (
            <>
              <Grid item>
                <Typography
                  className={classes.title}
                  style={{ color: 'black', marginBottom: '20px' }}
                >
                  {t('online_users')}
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Slider users={onlineUsers} num={false} />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item style={{ marginTop: '3rem', width: '100%' }}>
          <Grid
            container
            className={classes.explorePostContainer}
            justifyContent={'space-between'}
          >
            <Grid item classes={classes.posts}>
              <Grid container direction='column' alignItems='center'>
                {videos.length > 0 ? (
                  videos.map((item, index) => {
                    if (videos.length === index + 1) {
                      return (
                        <Grid
                          key={index}
                          ref={lastElementRef}
                          style={{ marginBottom: lgScreen ? '30px' : '60px' }}
                        >
                          <Post
                            check={calcCrow(
                              item.location.lat,
                              item.location.lon
                            )}
                            profile_img={`${process.env.REACT_APP_API_URL}/${item.profile_image}`}
                            video_url={`${process.env.REACT_APP_API_URL}/${item.video_url}`}
                            title={item.video_title}
                            username={item.user_name}
                            user_id={item.user_id}
                            user_status={item.user_status}
                            video_id={item._id}
                            ref={addToRefs}
                            like={item.like}
                            superLike={item.superLike}
                            handleFitlerVideo={handleFitlerVideo}
                            cover={`${process.env.REACT_APP_API_URL}/${item.cover}
                            
                          `}
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          key={index}
                          style={{ marginBottom: lgScreen ? '30px' : '60px' }}
                        >
                          <Post
                            check={calcCrow(
                              item.location.lat,
                              item.location.lon
                            )}
                            profile_img={`${process.env.REACT_APP_API_URL}/${item.profile_image}`}
                            video_url={`${process.env.REACT_APP_API_URL}/${item.video_url}`}
                            title={item.video_title}
                            username={item.user_name}
                            user_id={item.user_id}
                            user_status={item.user_status}
                            video_id={item._id}
                            ref={addToRefs}
                            like={item.like}
                            superLike={item.superLike}
                            handleFitlerVideo={handleFitlerVideo}
                            cover={`${process.env.REACT_APP_API_URL}/${item.cover}`}
                            caption={item.caption}
                          />
                        </Grid>
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                    }}
                  >
                    {loaderSpinner == true ? (
                      <CircularProgress thickness={5} disableShrink />
                    ) : (
                      <p>
                        <b>No posted reels available</b>
                      </p>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.filters}>
              <Filter />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
