import React from 'react';

import {
  makeStyles,
  Grid,
  AppBar,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';

import image from '../../assets';
const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <AppBar className={classes.navLight}>
        <Toolbar className={classes.toolbar}>
          <Button className={classes.logo} disableRipple>
            <img
              src={image.logo}
              className={classes.logoImage}
              alt="company-logo"
            />
          </Button>
          <Typography variant="h3" className={classes.privacy}>
            Privacy policy
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.innerContainer}>
        <Typography className={classes.title}>Privacy Notice</Typography>
        <p className="font-size-14 margin">
          Effective Date: {/* Effective date here*/} <br />
          Notice version: 1.0
        </p>
        <p className="font-size-20 title margin">
          Data Controller Contact Information:
        </p>

        <p className="font-size-14">
          Leeder, LLC <br />
          138 E38th street, <br />
          New York NY 10016 <br />
          waleed@rankpage.com <br />
        </p>

        <p className="font-size-14 margin">
          Our privacy notice governs our privacy practices when you are using
          our website Intro.date and our mobile application called Intro, and
          our web application(s) hereinafter and collectively referred to as
          website.
        </p>

        <p className="font-size-14 margin">
          Our privacy notice tells you what personal data and nonpersonal data
          we collect from you, how we collect them, how we protect them, how we
          share them, how you can access and change them, and how you can limit
          our sharing of them. Our privacy notice also explains certain legal
          rights that you have concerning your personal data. Any capitalized
          terms not defined herein will have the same meaning as where they are
          defined elsewhere on our website.
        </p>

        <p className="font-size-14 margin">
          Our privacy notice tells you what personal data and nonpersonal data
          we collect from you, how we collect them, how we protect them, how we
          share them, how you can access and change them, and how you can limit
          our sharing of them. Our privacy notice also explains certain legal
          rights that you have concerning your personal data. Any capitalized
          terms not defined herein will have the same meaning as where they are
          defined elsewhere on our website.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Definitions</span>
          <br />
          ‘NONPERSONAL DATA’ (NPD) means any information that is in no way
          personally identifiable.
        </p>

        <p className="font-size-14 margin">
          ‘PERSONAL DATA’ (PD) means any information relating to an identified
          or identifiable natural person (‘data subject’); an identifiable
          natural person can be identified directly or indirectly by reference
          to an identifier such as a name, an identification number, location
          data, an online identifier, or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural, or
          social identity of that natural person. PD is in many ways the same as
          Personally Identifiable Information (PII). However, PD is broader in
          scope and covers more data. ‘SENSITIVE PERSONAL DATA’ (SPD) a
          consumer’s social security, driver’s license, state identification
          card, or passport number; a consumer’s account log-in, financial
          account, debit card, or credit card number in combination with any
          required security, access code, password, or credentials allowing
          access to an account; a consumer’s exact geolocation; a consumer’s
          ethnic or racial origin, religious or philosophical beliefs, or union
          membership; the contents of a consumer’s mail, text messages, and
          email unless the business is the intended receiver of the
          communication; a consumer’s genetic data; the processing of biometric
          data for the purpose of uniquely identifying a consumer; personal
          information collected and analyzed about a consumer’s health; sex life
          or sexual orientation. Sensitive personal Information that is
          "publicly available" is considered sensitive personal Information or
          personal information.
        </p>

        <p className="font-size-16 title">
          Topics Covered in Our Privacy Notice
        </p>

        <p className="font-size-14 margin">
          YOUR RIGHTS <br />
          INFORMATION WE COLLECT AND HOW WE COLLECT IT <br />
          HOW YOUR PD IS USED AND SHAREDRETAINING AND DESTROYING YOUR PD <br />
          PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES <br />
          DO NOT TRACK SETTINGS <br />
          LINKS TO OTHER WEBSITES <br />
          PROTECTING CHILDREN’S PRIVACY <br />
          OUR EMAIL POLICYOUR SECURITY POLICY <br />
          USE OF YOUR CREDIT CARD <br />
          TRANSFERRING PD FROM OTHER COUNTRIES <br />
          CHANGES TO OUR PRIVACY NOTICE <br />
        </p>
        <p className="font-size-16 title">YOUR RIGHTS</p>
        <p className="font-size-14 margin">
          Contact us using the information at the top of this privacy notice to
          exercise any of your legal rights contained within this privacy
          notice. We will respond to your request within 30 days after receiving
          it.
        </p>
        <p className="font-size-14 margin">
          When using our website and submitting PD to us, you have certain
          rights under privacy laws in the United States including the
          California Consumer Privacy Act (CaCPA), the California Privacy Rights
          Act of 2020 (CPRA), and privacy laws of other countries including the
          European Union General Data Protection Regulation of 2016 (the “EU
          GDPR”), the UK General Data Protection Regulation (UK GDPR / the Data
          Protection Act 2018), the Data Protection Act 2018, and other global
          privacy laws. Even if not listed here, we will make reasonable efforts
          to honor data subject access requests even though we may be under no
          legal obligation to do so. However, we reserve the right to decline
          any data subject access request that we are not legally obligated to
          comply with. Your rights include but are not limited to the following:
        </p>
        <ol className="font-size-14 margin">
          <li className="font-size-14">
            The right to equal service, price, and not being discriminated
            against even if you exercise your privacy rights.
          </li>

          <li className="font-size-14">
            The right to one or more means where you can submit requests under
            this privacy notice including (at minimum) a toll-free telephone
            number, or if the business maintains an Internet website, a website
            address email address.
          </li>
          <li className="font-size-14">
            The right to know whether your PD is sold, shared, or disclosed, and
            to whom.
          </li>
          <li className="font-size-14">
            The right to request that we do not sell or share any of your PD.
          </li>
          <li className="font-size-14">
            The right to request that we disclose the following personal
            information to you: the categories of personal information we
            collected about you; the categories of sources from which your
            personal information is collected; the business or commercial
            purpose for collecting, selling, or sharing your personal
            information; the categories of third parties to whom we disclose
            your personal information; the specific pieces of personal
            information we have collected about you.
          </li>

          <li className="font-size-14">
            The right to be informed about the PD that we collect from you and
            how we process them.
          </li>
          <li className="font-size-14">
            The right to get confirmation that your PD are being processed and
            you can access your PD.
          </li>

          <li className="font-size-14">
            The right to have your PD corrected if they are inaccurate or
            incomplete.
          </li>
          <li className="font-size-14">
            The right to request the removal or deletion of your PD if there is
            no compelling reason for us to continue processing them. However,
            the right to deletion is not absolute and can be overridden to
            continue data processing in some cases where we still have a legal
            ground or overriding legitimate interest to process your data.
          </li>

          <li className="font-size-14">
            The right to ‘block’ or restrict the processing of your PD. When
            your PD are restricted, we are permitted to store your PD, but not
            to process them further.
          </li>
          <li className="font-size-14">
            The right to request the PD that you provided to us and use them for
            your own purposes. Upon express request, we will provide your data
            to you or another service or product provider within 30 days of your
            request subject to commercial and industrial secrets.
          </li>

          <li className="font-size-14">
            The right to object to us processing your PD for the following
            reasons:
            <ul className="font-size-14">
              <li className="font-size-14">
                processing was based on legitimate interests or the performance
                of a task in the public interest/exercise of official authority
                (including profiling);
              </li>
              <li className="font-size-14">
                direct marketing and targeted advertising (including profiling);
              </li>
              <li className="font-size-14">
                processing for purposes of scientific/historical research and
                statistics.
              </li>
            </ul>
          </li>
          <li className="font-size-14">
            The right not to be subject to a decision based solely on automated
            processing, including profiling, which produces legal effects
            regarding you or similarly significantly affects you.
          </li>
          <li className="font-size-14">
            The right that we limit the collection of your PD to that which is
            "adequate, relevant and reasonably necessary with the purposes for
            which the data is processed.
          </li>

          <li className="font-size-14">
            The right that we do not process your PD for purposes that are
            neither reasonably necessary nor compatible with the disclosed
            purposes for which such personal data is processed, as disclosed to
            you unless the controller obtains your consent.
          </li>
          <li className="font-size-14">
            The right to designate an authorized agent to make a request on your
            behalf. When designating an authorized agent, you must provide a
            valid power of attorney, the requester’s valid government-issued
            identification, and the authorized agent’s valid government-issued
            identification.
          </li>

          <li className="font-size-14">
            The right to file a complaint with supervisory authorities if your
            information has not been processed in compliance with your rights
            under privacy laws.
          </li>
        </ol>

        <p className="font-size-16 title margin">
          INFORMATION WE COLLECT AND HOW WE COLLECT IT
        </p>

        <p className="font-size-14 margin">
          Generally, you control the amount and type of information that you
          provide to us when using our website.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            Our Legal Basis for Collecting and Processing PD
          </span>
          <br />
          Our legal basis for collecting and processing your PD when you buy our
          products, services, or fill in any of the contact forms on our website
          is based on and the necessity for the performance of a contract or to
          take steps to enter into a contract. Our legal basis for collecting
          and processing your PD when you access our free products and
          information through our website is based on consent.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Automatic Information</span>
          <br />
          We automatically receive information from your web browser or mobile
          device. This information may include the IP address of your
          computer/the proxy server you use to access the Internet, your
          Internet service provider’s name, your web browser type, the type of
          mobile device, your computer operating system, and data about your
          browsing activity when using our website. We use all this information
          to help improve our website.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">When Entering and Using Our Website</span>
          <br />
          When you enter and use our website and agree to accept cookies, some
          of these cookies may contain your PD.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Our Use of Cookies</span>
          <br />
          Our website uses cookies. A cookie is a small piece of data or a text
          file that is downloaded to your computer or mobile device when you
          access certain websites. Cookies may contain text that can be read by
          the web server that delivered the cookie to you. The text contained in
          the cookie generally consists of a sequence of letters and numbers
          that uniquely identifies your computer or mobile device; it may
          contain other information as well.
        </p>

        <p className="font-size-14 margin">
          By agreeing to accept our use of cookies you are giving us and the
          third parties with which we partner permission to place, store, and
          access some or all the cookies described below on your computer and or
          mobile device.
        </p>

        <ul className="font-size-14 margin">
          <li className="font-size-14">
            Strictly Necessary Cookies - These cookies are necessary for the
            proper functioning of the website, such as displaying content,
            logging in, validating your session, responding to your request for
            services, and other functions.
          </li>
          <li className="font-size-14">
            Performance Cookies - These cookies collect information about the
            use of the website, such as pages visited, traffic sources, users’
            interests, content management, and other website measurements.
          </li>
          <li className="font-size-14">
            Functional Cookies - These cookies enable the website to remember
            users’ choices, such as their language, usernames, and other choices
            while using the website.
          </li>
          <li className="font-size-14">
            Media Cookies - These cookies can be used to improve a website’s
            performance and provide special features and content. They can be
            placed by us or third parties who provide services to us.
          </li>
          <li className="font-size-14">
            Advertising or Targeting Cookies - These cookies are usually placed
            and used by advertising companies to develop a profile of your
            browsing interests and serve advertisements on other websites that
            are related to your interests.
          </li>
          <li className="font-size-14">
            Session Cookies - These cookies allow websites to link the actions
            of a user during a browser session. They may be used for remembering
            what a user puts in their shopping cart as they browse a website.
            Session cookies also permit users to be recognized as they navigate
            a website so that any item or page changes they make are remembered
            from page to page. Session cookies expire after a browser session.
          </li>
          <li className="font-size-14">
            Persistent Cookies - These cookies are stored on a user’s device
            between browser sessions, which allows the user’s preferences or
            actions across a website or across different websites to be
            remembered. Persistent cookies may be used for remembering users’
            choices and preferences when using a website or target advertising
            to them.
          </li>
          <li className="font-size-14">
            We may also use cookies for: <br />
            <ul className="font-size-14">
              <li className="font-size-14">
                identifying the areas of our website that you have visited;
              </li>
              <li className="font-size-14">
                personalizing the content that you see on our website;
              </li>
              <li className="font-size-14">our website analytics;</li>
              <li className="font-size-14">
                remarketing our products or services to you;
              </li>
              <li className="font-size-14">
                remembering your preferences, settings, and login details;
              </li>
              <li className="font-size-14">
                targeted advertising and serving ads relevant to your interests;
              </li>
              <li className="font-size-14">
                allowing you to share content with social networks.
              </li>
            </ul>
          </li>
        </ul>
        <p className="font-size-12 margin">
          Most web browsers can be set to disable the use of cookies. However,
          if you disable cookies, you may not be able to access features on our
          website correctly or at all.
        </p>
        <p className="font-size-14 margin">
          <span className="bold">Web Beacons</span>
          <br />
          We may use a technology called web beacons to collect general
          information about your use of our website and your use of special
          promotions or newsletters. The information we collect by web beacons
          allows us to statistically monitor the number of people who open our
          emails.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            At User Registration or When Buying Products or Services
          </span>
          <br />
          When you register as a user or when buying our products or services,
          we may collect some or all of the following{' '}
          <span className="bold">‘mandatory’ information:</span> your name,
          username, email, phone number, Facebook or Google login, user handle,
          height, location, dating intent, marital status, gender, and gender
          preferences.
        </p>
        <p className="font-size-14 margin">
          <span className="bold">
            Collecting Information About Your Physical Location
          </span>
          <br />
          When you use our services, we may collect and process information
          about your actual physical location. We use several technologies such
          as GPS and IP tracking to determine your location. These technologies
          may also give us information about nearby cell towers, Wi-Fi access
          points, and other devices.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Google API</span>
          <br />
          By using our website, you are subject to the{' '}
          <a
            href="https://policies.google.com/privacy?hl=en"
            className={classes.anchor}
          >
            Google Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://policies.google.com/terms?hl=en-US"
            className={classes.anchor}
          >
            Terms of Service
          </a>
           . When collecting and processing user data, including PD from Google
          APIs, we will follow{' '}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            className={classes.anchor}
          >
            Google API Services User Data Policy
          </a>
          . We also require that our employees, contractors, and agents comply
          with the Google API Services User Data Policy.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Hotjar.com</span>
          <br />
          Hotjar is a service that helps us better understand our users’
          experience - how much time they spend on which pages, which links they
          click on, what they do and do not like, etc. This lets us build and
          maintain our service with user feedback. Hotjar uses cookies and other
          technologies to collect data on our users’ behavior and their devices
          (in particular the IP addresses of their devices captured and stored
          only in anonymized form); the screen size, type, unique identifiers of
          devices; browser information; geographic location (country only); and
          languages used to display our website. Hotjar stores this information
          in a pseudonymized user profile. Neither Hotjar nor we use this
          information to identify individual users or to match it with further
          data on individual users. You can opt-out of the creation of a user
          profile, Hotjar’s storing of data about your usage of our website, and
          Hotjar’s use of tracking cookies on other websites by visiting:
          <a
            className={classes.anchor}
            href="https://www.hotjar.com/legal/compliance/opt-out"
          >
            https://www.hotjar.com/legal/compliance/opt-out
          </a>
          .
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Disqus.com</span>
          <br />
          We use a service from www.Disqus.com that can track users’ activity
          across websites that use the Disqus commenting system unless the do
          not track option is enabled. Information tracked by Disqus may be
          disclosed to third parties including pseudonymous analytics data, web
          browser version, installed addons, referring pages, and exit links.
          Other users may search for, see, use, and repost any of your user
          content that you make publicly available through the service. You can
          choose to install a privacy-enhancing web browser extension that
          allows some of Disqus to be blocked.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Chat Software or Contact Forms</span>
          <br />
          Our website contains chat software or contact forms that enable
          visitors to communicate with us online or offline by email. In some
          cases, visitors can communicate with us without buying our products
          and services. When you use our chat software or contact forms, we may
          collect some or all the following information: your email address,
          first name, last name, location, and any other information you
          willingly choose to give us. You should limit the information you give
          to us to one that is necessary to answer your questions.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Google Analytics</span>
          <br />
          Our website uses Google Analytics to collect information about the use
          of our website. Google Analytics collects information from users such
          as age, gender, interests, demographics, how often they visit our
          website, what pages they visit, and what other websites they have used
          before coming to our website. We use the information we get from
          Google Analytics to analyze traffic, improve our marketing,
          advertising, and website. We do not combine the information collected
          using Google Analytics with PD. You can prevent Google Analytics from
          using your information by opting out at this link:
          <a
            className={classes.anchor}
            href="https://tools.google.com/dlpage/gaoptout"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Google Ad and Content Network</span>
          <br />
          Third-party vendors, including Google, use cookies, pixels, and other
          technologies to serve ads based on a user's past visits to our
          website. These technologies are also used to track your activities on
          our website and other websites, including the web pages that you
          visited, the ads or content that you clicked on, any items you may
          have purchased, and the search terms you used to deliver tailored
          advertising to you. Users may opt-out of the use of Google’s cookies
          for interest-based advertising by visiting
          <a className={classes.anchor} href="http://www.aboutads.info/choices">
            http://www.aboutads.info/choices/
          </a>{' '}
          For European users visit
          <a className={classes.anchor} href="http://www.youronlinechoices.eu">
            {' '}
            http://www.youronlinechoices.eu
          </a>
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Remarketing</span>
          <br />
          Our website and applications use remarketing advertising services.
          These remarketing services are provided by companies that show our ads
          on websites and devices across the Internet. With remarketing you may
          see ads for our products you have previously looked at. As an example,
          suppose you visit a website that sells computers, but you do not buy a
          computer on your first visit to that website. The website’s owner
          might like to encourage you to revisit their site and buy a computer
          by showing you their ads on other websites that you visit. We use
          remarketing for similar purposes. For this to happen remarketing
          companies will read a cookie in your browser. This can only happen if
          your browser is set to let it happen. You can opt-out of these types
          of advertising cookies by visiting{' '}
          <a
            href="http://www.networkadvertising.org/choices"
            className={classes.anchor}
          >
            http://www.networkadvertising.org/choices
          </a>
          .
        </p>
        <p className="font-size-14 margin">
          <span className="bold">
            Sharing Your PD for Lookalike or Similar Audience Marketing
          </span>
          <br />
          We may share your PD with third parties for similar audience marketing
          purposes. Similar audience marketing is also called lookalike audience
          marketing. The third parties we share your PD with for this type of
          marketing include Facebook and/or Google. Using your PD for similar
          audience marketing or lookalike audience marketing helps us find new
          audiences (users and customers) based on similar interests to yours.
          This helps us improve our marketing services. Your PD is only shared
          with Facebook and Google for this type of marketing. By using our
          website and agreeing to our privacy notice you are giving consent for
          your PD to be used for the marketing purposes described within this
          section.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            What Happens If You Don’t Give Us Your PD
          </span>
          <br />
          If you do not provide us with enough PD, we may not be able to provide
          you with all our products and services. However, you can access and
          use some parts of our website without giving us your PD.
        </p>

        <p className="font-size-16 title">HOW YOUR PD IS USED AND SHARED</p>
        <p className="font-size-14 margin">
          We use the information we receive from you to:
        </p>
        <ul className="font-size-14 margin">
          <li className="font-size-14">
            provide our products and services you have requested or purchased
            from us;
          </li>
          <li className="font-size-14">interact with other users;</li>
          <li className="font-size-14">
            personalize and customize our content;{' '}
          </li>
          <li className="font-size-14">make improvements to our website;</li>
          <li className="font-size-14">
            contact you with updates to our website, products, and services;
          </li>
          <li className="font-size-14">resolve problems and disputes;</li>
          <li className="font-size-14">
            contact you with marketing and advertising that we believe may be of
            interest to you.
          </li>
        </ul>

        <p className="font-size-14 margin">
          <span className="bold">Communications and Emails</span>
          <br />
          When we communicate with you about our website, we will use the email
          address you provided when you registered as a user or customer and or
          send you a text message. We may also send you emails with promotional
          information about our website or offers from us or our affiliates
          unless you have opted out of receiving such information. You can
          change your contact preferences at any time through your account or by
          contacting us using the information at the top of this privacy notice.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            Sharing Your Information When You Login Using Social Media Websites
          </span>
          <br />
          We may share your PD with third parties such as Facebook.com,
          Twitter.com, YouTube.com, Instagram.com, Google.com, and others. If
          you sign into our services through a third-party social networking
          service or website, your “friends” list from that service or website
          might be automatically imported to our services. We do not have any
          control over the privacy notices and business practices of other
          third-party services or websites.
        </p>

        <p className="font-size-14 margin">
          If you log into our website using social media websites, you are
          agreeing to let us use and store your profile information from those
          websites to make better use of any social media features on our
          website. This sharing of information helps us provide you with a
          better experience when using our website and provides us with
          information such as visitor traffic. If you use any of the social
          sharing icons on our website to share our information, you may also be
          sharing your personal information through social media websites.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            Text Messaging, SMS, Push Notifications, and Telephone Calls
          </span>
          <br />
          If you provide a mobile telephone number, or landline telephone number
          to us, you are giving your express consent and authorize us or a
          third-party to contact you by using any of these communication
          methods. You are not required to give us your consent to contact you
          through these communication methods. However, withholding your consent
          may interfere or prevent us from providing some or all our services to
          you. You can stop receiving text messages, push notifications, and
          telephone calls at any time by contacting us or using one of our
          opt-out methods.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Sharing Information with Third Parties</span>
          <br />
          We do not sell or rent your PD to third parties for marketing
          purposes. However, for data aggregation purposes we may use your NPD,
          which might be sold to other parties at our discretion. Any such data
          aggregation would not contain any of your PD. At times we give your PD
          to third-party service providers whom we hire to provide services to
          us. These third-party service providers may include but are not
          limited to payment processors, web analytics companies, advertising
          networks, call centers, data management services, help desk providers,
          accountants, law firms, auditors, shopping cart and email service
          providers, shipping companies, and other users of our website.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Legally Required Releases of Information</span>
          <br />
          We may disclose your PD if such disclosure is (a) required by
          subpoena, law, or other legal processes; (b) necessary to assist law
          enforcement officials or government enforcement agencies; (c)
          necessary to protect us from legal action or claims from third
          parties, including you and or other users; or (d) necessary to protect
          the legal rights, personal and or real property, or the personal
          safety of our company, users, employees, and business partners.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">Disclosures to Successors</span>
          <br />
          If our business is sold or merges in whole or in part with another
          business that would become responsible for providing the website to
          you, we retain the right to transfer your PD to the new business. The
          new business would retain the right to use your PD according to the
          terms of this privacy notice as well as to any changes to this privacy
          notice as instituted by the new business. We also retain the right to
          transfer your PD if our company files for bankruptcy and some or all
          of our assets are sold to another individual or company.
        </p>

        <p className="font-size-14 margin">
          <span className="bold">
            Community Discussion Boards, Blogs, or Other Mechanisms
          </span>
          <br />
          Our website may offer the ability for users to communicate through
          online community discussion boards, blogs, or other mechanisms. If you
          choose to post on these discussion mechanisms, you should use care
          when exposing any PD, as such information is not protected by our
          privacy notice nor are we liable if you disclose your PD through such
          postings. Also, PD which you post on our website for publication may
          be available worldwide on the Internet. We cannot prevent the use or
          misuse of such information by others.
        </p>

        <p className="font-size-16 title">RETAINING AND DESTROYING YOUR PD</p>
        <p className="font-size-14 margin">
          We will maintain your PD in a structure that identifies you only for
          as long as it serves the purpose(s) for which it was initially
          collected as stated in this privacy notice, or for legal, business, or
          tax purposes. We will attempt to permanently erase or anonymize your
          PD once it reaches the end of its retention period or if we receive a
          valid request from you to do so. However, some of your data may still
          exist within our systems, for example, if it is waiting to be
          overwritten. This data has been put away from use, meaning, while it
          still exists on our archive system, it cannot be readily accessed by
          any of our operating systems, or any of our employees or contractors.
        </p>

        <p className="font-size-16 title">
          PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES
        </p>
        <p className="font-size-14 margin">
          If you make any postings on our website that contain information about
          third parties, you agree that you have permission to include that
          information. While we are not legally liable for the actions of our
          users, we will remove any postings about which we are notified if such
          postings violate the privacy rights of others.
        </p>

        <p className="font-size-16 title">DO NOT TRACK SETTINGS</p>
        <p className="font-size-14 margin">
          Some web browsers have settings that enable you to request that our
          website not track your movement within our website. Our website does
          not obey such settings when transmitted to and detected by our
          website. You can turn off tracking features and other security
          settings in your browser by referring to your browser’s user manual.
        </p>

        <p className="font-size-16 title">LINKS TO OTHER WEBSITES</p>
        <p className="font-size-14 margin">
          Our website may contain links to other websites. These websites are
          not under our control and are not subject to our privacy notice. We
          have no responsibility for these websites, and we provide links to
          these websites solely for your convenience. You acknowledge that your
          use of and access to these websites are solely at your risk.
        </p>

        <p className="font-size-16 title">PROTECTING CHILDREN’S PRIVACY</p>
        <p className="font-size-14 margin">
          Our website is not designed for use by anyone under the age of 18. We
          do not knowingly collect PD from children under the age of 18. If you
          are a parent or guardian and believe that your child is using our
          website and they are under the age of 18, please contact us. Before we
          remove any information we may ask for proof of identification to
          prevent malicious removal of account information. If we discover that
          a child under the age of 18 is accessing our website, we will delete
          their information within a reasonable period of time. You acknowledge
          that we do not verify the age of our users nor have any liability to
          do so.
        </p>

        <p className="font-size-16 title">OUR EMAIL POLICY</p>
        <p className="font-size-14 margin">
          You can always opt-out of receiving email correspondence from us or
          our affiliates. We will not sell, rent, or trade your email address to
          any unaffiliated third-party without your permission except in the
          sale or transfer of our company, or if our company files for
          bankruptcy as described in the section{' '}
          <span className="bold">Disclosures to Successors </span> .
        </p>

        <p className="font-size-16 title">OUR SECURITY POLICY </p>
        <p className="font-size-14 margin">
          We have built our website and services using industry-standard
          security measures and authentication tools to protect the security of
          your PD. We and the third parties who provide services to us also
          maintain technical and physical safeguards to protect your PD.
          Unfortunately, we cannot guarantee the prevention of loss or misuse of
          your PD or secure data transmission over the Internet because of its
          nature. We strongly urge you to protect any password you may have for
          our website and not share it with anyone.
        </p>

        <p className="font-size-16 title">USE OF YOUR CREDIT CARD </p>
        <p className="font-size-14 margin">
          You may have to provide a credit or debit card to buy products and
          services from our website. We use third-party billing services and
          have no control over them. We use commercially reasonable efforts to
          ensure that your credit card number is kept strictly confidential by
          using only third-party billing services that use industry-standard
          encryption technology to protect your credit card number from
          unauthorized use. However, you understand and agree that we are in no
          way responsible for any misuse of your credit card number.
        </p>

        <p className="font-size-16 title">
          TRANSFERRING PD FROM OTHER COUNTRIES
        </p>
        <p className="font-size-14 margin">
          PD that we collect from you may be stored, processed, and transferred
          among any countries in which we operate. The European Union has not
          found the United States and some other countries to have an acceptable
          level of protection of PD under Article 45 of the GDPR. Our company
          relies on derogations for specific situations as defined in Article 49
          of the GDPR. If you are a European Union user, or a user from another
          country, with your consent your PD may be transferred to the United
          States or other countries when you request information from us. When
          you buy goods or services, we will use your PD for the performance of
          a contract with you. Wherever we transfer, process, or store your PD,
          we will attempt to apply reasonable safeguards to protect it. We will
          use the information we collect from you by following the practices
          described in our privacy notice. Also, we enter into data processing
          agreements and standard contractual clauses when appropriate. By using
          our website, services, or products, you agree to the transfers of your
          PD described within this section.
        </p>

        <p className="font-size-16 title">CHANGES TO OUR PRIVACY NOTICE</p>
        <p className="font-size-14 margin">
          We reserve the right to change this privacy notice at any time. If our
          company decides to change this privacy notice, we will post those
          changes on our website so that our users and customers are always
          aware of what information we collect, use, and disclose. If at any
          time we decide to disclose or use your PD in a method different from
          that specified at the time it was collected, we will provide advance
          notice by email sent to the email address on file in your account.
          Otherwise, we will use and disclose our users’ and customers’ PD in
          agreement with the privacy notice in effect when the information was
          collected. In all cases, your continued use of our website, services,
          and products after any change to this privacy notice will constitute
          your acceptance of such change. If you have questions about our
          privacy notice, please contact us through the information at the top
          of this privacy notice.
        </p>

        <p className="font-size-14" style={{ textAlign: 'center' }}>
          Copyright © - This document or any portion of it may not be copied or
          duplicated in any way.
        </p>
      </div>
    </Grid>
  );
};

export default PrivacyPolicy;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    background: theme.palette.common.lightPink,
    padding: '3rem',
    paddingTop: '7rem',
    '& .font-size-14': {
      fontSize: '14px',
    },
    '& .margin': {
      marginBottom: '2rem',
    },
    '& .font-size-16': {
      fontSize: '16px',
    },
    '& .font-size-20': {
      fontSize: '20px',
    },
    '& .title': {
      //   fontWeight: '700',
      textDecoration: 'underline',
    },
    '& .bold': {
      fontWeight: '700',
    },
    '& ul': {
      paddingLeft: '1rem',
    },
    '& ol': {
      paddingLeft: '1rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2rem',
      paddingTop: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
      paddingTop: '3.5rem',
      '& ul': {
        padding: 0,
      },
      '& ol': {
        padding: 0,
      },
    },
  },
  navLight: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '1rem 2.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  logo: {
    height: '5rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      height: '4rem',
    },
  },
  logoImage: {
    height: '100px',
    [theme.breakpoints.down('lg')]: {
      height: '80px',
    },
  },
  privacy: {
    color: '#000000',
    margin: 0,
    fontSize: '35px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '0.5rem',
    },
  },
  innerContainer: {
    background: '#ffffff',
    borderRadius: '48px',
    boxShadow: theme.shadows[3],
    maxHeight: 'calc(100vh - 10.5rem)',
    minHeight: 'calc(100vh - 10.5rem)',
    padding: '2rem 2.5rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      maxHeight: '50%',
      height: '50%',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-button ': {
      width: '20px', //for horizontal scrollbar
      height: '20px', //for vertical scrollbar
    },
    [theme.breakpoints.down('lg')]: {
      borderRadius: '25px',
      padding: '1.5rem 2rem',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '10px',
      padding: '1rem 1.5rem',
      minHeight: 'calc(100vh - 7rem)',
    },
  },
  title: {
    color: '#000',
    margin: 0,
    fontSize: '35px',
    fontWeight: 100,
    fontWeight: 700,
    marginBottom: '20px',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
}));
