import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Dialog,
} from '@material-ui/core';
import { Block } from '@material-ui/icons';
import { useStyles } from './streamStyles';
import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';
import image from '../../assets/index';
import { StreamerBox } from '../../components/ViewBox/StreamerBox';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getAgoraToken, goLive, removeCoHost } from '../../http';
import api from '../../http';
import { SocketContext } from '../../http/socket';
import { onMessageListener, messaging } from '../../firebaseInit';
import { Battle } from '../Battle/Battle';
import { wait } from '../../utils/waitFunction';

import { AgoraContext } from '../../context/AgoraContext';
import UserAddedToFaceoffQueue from '../../modals/UserAddedToFaceoffQueue';
import useCountdown from '../../hooks/useCountdown';

export const Stream = props => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lgScreen = useMediaQuery(theme.breakpoints.down(1680));
  const socket = useContext(SocketContext);

  const client = useContext(AgoraContext);

  const { audience } = props;
  const channelName = audience ? props.history.location.state.username : null;
  const streamId = audience ? props.history.location.state.id : null;
  const hostUid = audience ? props.history.location.state.hostUid : null;
  const user = useSelector(state => state.auth.user.data);
  const username = user.username;
  const liveRef = useRef();
  const guest = useRef();
  const liveStreamId = useRef(streamId);
  const coHostRef = useRef();

  const [rtcToken, setRtcToken] = useState(null);
  const [faceoffToken, setFaceoffToken] = useState(null);

  const { startTimer, time, resetTimer } = useCountdown(180);
  const [streamMessages, setStreamMessages] = useState([]);
  const [battleMessages, setBattleMessages] = useState([]);

  const [showQueueModal, setShowQueueModal] = useState(false);

  const [guestWindow, setGuestWindow] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [removeGuest, setRemoveGuest] = useState(false);
  const [exit, setExit] = useState(false);
  const [blueWindow, setBlueWindow] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [members, setMembers] = useState(0);
  const [coHostUserId, setCoHostUserId] = useState('');
  const [userUid, setUserUid] = useState(null);
  const [location, setLocation] = useState({
    lon: '',
    lat: '',
  });
  const [rtmChannel, setRtmChannel] = useState(null);
  const [faceOff, setFaceOff] = useState(false);
  const [closeStream, setCloseStream] = useState(false);
  const faceOFF = useRef(false);
  const battle = useRef({
    client: '',
    clientUid: null,
    host: '',
    hostUid: null,
    tag: '',
  });
  const [hostFirst, setHostFirst] = useState(false);
  const getGender = () => {
    const gender = user.identify.gender;
    if (gender.toLowerCase() === 'male') {
      return 1;
    } else if (gender.toLowerCase() === 'female') {
      return 0;
    } else {
      return 2;
    }
  };
  // Agora setUp
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let localTracks = useRef({
    videoTrack: null,
    audioTrack: null,
  });

  let remoteUsers = {};
  const remoteUser = useRef();
  // Agora client options

  const ref = {
    ref1: useRef(),
    ref2: useRef(),
  };
  // const rtmChannel = useRef(null);
  const faceoffChannel = useRef('');

  const options = {
    appId: process.env.REACT_APP_AGORA_APPID,
    channel: audience ? channelName : user.username,
    uid: null,
    token: null,
    accountName: null,
    role: 'host',
  };

  const rtmSetup = () => {
    RTMJoin();
  };

  const join = async () => {
    try {
      client.setClientRole(options.role);

      const mainUid = localStorage.getItem('my-battle-uid');

      const { data } = await getAgoraToken({
        isPublisher: true,
        channel: faceOFF.current ? faceoffChannel.current : options.channel,
        uid: faceOFF.current
          ? rtcToken?.rtcUid || 0
          : mainUid || rtcToken?.rtcUid || 0,
      });

      const res = await client.join(
        options.appId,
        data.rtcChannel,
        data.rtcToken,
        data.rtcUid
        // userUid ? userUid : options.uid || data.rtmUid
      );

      if (!faceOFF.current) {
        localStorage.setItem('my-battle-uid', data.rtcUid);
        setRtcToken(data);
      } else {
        setFaceoffToken(data);
      }

      console.log(res);

      setUserUid(data.rtmUid);
      if (socket && socket.connected) {
        socket.emit('join', {
          room_id: data.rtcChannel,
        });
      }

      await publishTracks();
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };

  const publishTracks = async () => {
    try {
      localTracks.current.videoTrack = await AgoraRTC.createCameraVideoTrack();
      localTracks.current.audioTrack =
        await AgoraRTC.createMicrophoneAudioTrack();
      if (faceOFF.current) {
        localTracks.current.videoTrack.play(ref.ref1.current);
      } else if (userUid === hostUid) {
        localTracks.current.videoTrack.play(liveRef.current);
      }
      await client.publish(Object.values(localTracks.current));
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleUserUnpublish = (user, mediaType) => {
    console.log('user unpublish');
  };
  // const handleClientRoleChanged = event => {
  //   console.log('client role changed');
  //   console.log(event);
  // };

  const leave = async () => {
    // console.log("user leaving");
    // console.log(localTracks.current);
    for (let trackName in localTracks.current) {
      let track = localTracks.current[trackName];
      console.log(track);
      if (track) {
        track.stop();
        track.close();
        localTracks.current[trackName] = undefined;
      }
    }
    remoteUsers = {};
    if (
      localTracks.current.audioTrack !== null &&
      localTracks.current.videoTrack !== null
    ) {
      await client
        .unpublish()
        .then(data => console.log(data, 'unpublish success'))
        .catch(err => console.log(err.message));
    } else if (
      localTracks.current.audioTrack &&
      localTracks.current.videoTrack
    ) {
      await client.unpublish(Object.values(localTracks));
    }
    await client.leave();
    // await client.unpublish(Object.values(localTracks));

    if (!faceOFF.current) {
      try {
        // eslint-disable-next-line
        const res = await api.delete('/api/deleteliveuser', {
          data: {
            username: username,
          },
        });
      } catch (err) {
        console.log(err.msg);
      }
    }
  };

  const subscribe = async (user, mediaType) => {
    try {
      const uid = user.uid;
      await client.subscribe(user, mediaType);
      const mainUid = localStorage.getItem('my-battle-uid');
      if (faceOFF.current || faceOff) {
        // alert("face-off");
        if (mediaType === 'video') {
          if (uid !== battle.current.hostUid) {
            user.videoTrack.play(ref.ref2.current);
          } else {
            setHostFirst(true);
            user.videoTrack.play(ref.ref1.current);
          }
        }
      } else {
        if (mediaType === 'video') {
          if (uid === userUid) {
            user.videoTrack.play(liveRef.current);
          } else {
            setGuestWindow(true);
            console.log('guest user added');
            user.videoTrack.play(guest.current);
            localStorage.setItem('uid', uid);
          }
        }
      }

      if (mediaType === 'audio') {
        user.audioTrack.play();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    console.log('published');
    subscribe(user, mediaType);
  };

  const handleUserJoined = user => {
    const id = user.uid;
    remoteUsers[id] = user;
    setMembers(prev => prev + 1);
  };

  const handleUserLeft = (user, reason) => {
    console.error('in user left');
    const id = user.uid;
    const uid = localStorage.getItem('uid');
    if (id == uid) {
      setGuestWindow(false);
    }
    delete remoteUsers[id];
    setMembers(prev => prev - 1);
    if (faceOFF.current) {
      goBackToMyStream();
    }
  };

  const clientRTM = AgoraRTM.createInstance(options.appId, {
    enableLogUpload: false,
  });
  const RTMJoin = async () => {
    clientRTM
      .login({
        uid: username,
      })
      .then(() => {
        console.log('AgoraRTM client login success. username : ' + username);
        console.log('267 login');
        // RTM channel join
        let channelName = options.channel;
        const channel = clientRTM.createChannel(channelName);
        setRtmChannel(channel);
        channel
          .join()
          .then(() => {
            console.log('AgoraRTM client channel join success');
            setIsLoggedIn(true);
            // get all members in RTM channel
            channel.getMembers().then(memberNames => {
              setMembers(memberNames.length);
              channel.on('AttributesUpdated', attributes => {
                console.log('======= Logging attributes =====');
                console.log(attributes);
              });
              channel.on('MemberJoined', () => {
                // get all members in RTM channel
                const data = {
                  id: liveStreamId.current,
                  count: 1,
                };
                socket.emit('livestreamcount', data);
                channel.getMembers().then(memberNames => {
                  setMembers(memberNames.length);
                });
              });
              channel.on('MemberLeft', () => {
                const data = {
                  id: liveStreamId.current,
                  count: -1,
                };
                socket.emit('livestreamcount', data);
                channel.getMembers().then(memberNames => {
                  setMembers(memberNames.length);
                });
              });
            });
          })
          .catch(err => console.log(err.message));
      })
      .catch(err => console.log(err.message));
  };

  const roleChange = async data => {
    if (data.type === '0') {
      leave();
      options.role = 'host';
      join();
    } else if (data.type === '1') {
      console.log('Notification====> changing role to audience');
      leave();
      options.role = 'audience';
      join();
    }
  };
  const handleRemoveCoHost = async () => {
    try {
      const apiData = {
        userId: coHostUserId,
        id: liveStreamId.current,
      };
      // eslint-disable-last-line
      const { data } = await removeCoHost(apiData);
      setRemoveGuest(false);
      setGuestWindow(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleStreamStarted = async () => {
    try {
      const goLiveData = {
        username: user.username,
        userId: user._id,
        image: user.profile_image,
        channelId: userUid,
        gender: getGender(),
        userStatus: user.current_status,
        rtcToken: 'this is a string',
        location: {
          coordinates: [
            location.lon || user.location.lon,
            location.lat || user.location.lat,
          ],
        },
      };
      const { data } = await goLive(goLiveData);
      console.log('livestream data', data);
      liveStreamId.current = data.id;
      console.log('Api res ==>', data.id);
      setIsStarted(true);
    } catch (err) {
      console.log('Something went wrong');
      props.history.goBack();
    }
  };
  const sendMessageToAudience = msg => {
    rtmChannel
      .sendMessage(msg)
      .then(data => console.log(data))
      .catch(err => console.log(err.message));
  };
  // eslint-disable-next-line
  const handleHostLeft = () => {
    props.history.goBack();
  };
  const RTMLeave = async () => {
    await clientRTM.logout();
    console.log('Client logged out of RTM');
  };
  const handleEndStream = () => {
    leave();
    props.history.replace('/liveloop');
  };
  // eslint-disable-next-line
  const goBackToMyStream = async () => {
    faceOFF.current = false;
    await leave();
    setFaceOff(false);
    const c = faceoffChannel.current;
    faceoffChannel.current = '';
    join();
    await wait(1000);
    socket.emit('join_faceoff', {
      room_id: c,
      type: 'stop',
      data: {
        sender: user?._id,
      },
    });
  };
  const quitStream = async () => {
    try {
      // eslint-disable-next-line
      const res = await api.delete('/api/deleteliveuser', {
        data: {
          username: username,
        },
      });
      props.history.goBack();
    } catch (err) {
      console.log(err.msg);
    }
  };

  const initializeAgora = async () => {
    client.on('user-published', handleUserPublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on('user-unpublished', handleUserUnpublish);
  };

  const startFaceOff = async data => {
    try {
      const faceoffData = data.data;
      console.log('faceoff data', faceoffData);
      faceoffChannel.current = faceoffData.channelId;
      // sendMessageToAudience({
      //   text: `introStartBattle090078601introStartBattle`,
      // });
      battle.current = {
        host: faceoffData.host,
        client: faceoffData.client,
        hostUid: faceoffData.hostUid,
        clientUid: faceoffData.clientUid,
        channelId: faceoffData.channelId,
        tag: `#${faceoffData.tag}`,
      };
      faceOFF.current = true;
      const sData = {
        room_id: rtcToken.rtcChannel,
        type: 'init',
        data: {
          sender: user?._id,
        },
      };

      console.log('Socket data', sData);

      socket.emit('join_faceoff', sData);
      await wait(1500);
      await leave();
      await join();
      socket.emit('join_faceoff', {
        room_id: rtcToken.rtcChannel,
        type: 'start',
        data: faceoffData,
      });

      socket.emit('join', { room_id: faceoffData.channelId });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFaceOfMessage = payload => {
    if (payload.room_id === faceoffChannel.current) {
      if (payload.message.isFaceOff) {
        setBattleMessages(prev => [...prev, payload.message]);
      }
    }
  };

  useEffect(() => {
    join();
    // rtmSetup();
    initializeAgora();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        setLocation({ lon: pos.coords.longitude, lat: pos.coords.latitude });
      });
    }
    return () => {
      (async () => {
        try {
          await leave();
          // await RTMLeave();
        } catch (err) {
          console.log(err.message);
        }
      })();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onMessageListener().then(data => {
      if (data.topic === `${user._id}_joinlive`) {
        // console.log(data);
        roleChange(data);
      }
      // else if (data.topic === 'delliveuser') {
      //   props.history.goBack();
      // }
    });
  });
  useEffect(() => {
    messaging.onMessage(payload => {
      try {
        const { data: d } = payload;
        const data = JSON.parse(d.data);
        if (data.topic === `${user._id}_faceoff` && data.status) {
          setFaceOff(true);
          startFaceOff(data);
          startTimer();
        }
        if (data.topic === `${user._id}_faceoff` && !data.status) {
          setShowQueueModal(true);
        }
      } catch (err) {
        console.log(err.message);
      }
    });
  });

  useEffect(() => {
    if (time === 0 && faceOff) {
      goBackToMyStream();
      resetTimer();
    }
  }, [time]);

  useEffect(() => {
    if (socket && socket.connected) {
      socket.on('room_message_ack', payload => {
        console.log('message arrived', payload);
        if (payload.room_id === options.channel) {
          if (!payload.isFaceOff) {
            setStreamMessages(prev => [...prev, payload.message]);
          }
        } else {
          handleFaceOfMessage(payload);
        }
      });
    }
  }, []);

  return (
    // "user/userID/myfans"
    <>
      {faceOff ? (
        <Battle
          ref={ref}
          goBackToMyStream={goBackToMyStream}
          battle={battle}
          hostFirst={hostFirst}
          quitStream={quitStream}
          time={time}
          messages={battleMessages}
        />
      ) : (
        <Grid
          container
          className={classes.mainContainer}
          justifyContent='space-between'
        >
          <Grid item container direction='column' className={classes.left}>
            <Grid item>
              <Typography className={classes.username} variant='h4'>
                {audience ? channelName : user.username}
              </Typography>
            </Grid>
            <Grid item container>
              <Grid item container>
                <Grid item>
                  <div className={classes.statsContainer}>
                    <img
                      src={image.gem}
                      className={classes.gemIcon}
                      alt='eye-icon'
                    />
                    <span className={classes.count}>3.4k</span>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.statsContainer}>
                    <img
                      src={image.eyeBlue}
                      className={classes.eyeIcon}
                      alt='eye-icon'
                    />
                    <span className={classes.count}>{members}</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.streamContainer} ref={liveRef}>
                <IconButton
                  onClick={() => setCloseStream(true)}
                  className={classes.endStreamButton}
                >
                  <Close className={classes.endStreamIcon} />
                </IconButton>
                <Dialog
                  className={classes.endStreamDialog}
                  open={closeStream}
                  onClose={() => setCloseStream(false)}
                >
                  <Grid
                    container
                    alignItems='center'
                    className={classes.endStreamContainer}
                    direction='column'
                    justifyContent='space-between'
                  >
                    <Grid item container direction='column'>
                      <Grid item>
                        <Typography className={classes.endTitle}>
                          Are you sure?
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.endSubtitle}>
                          This will end your stream.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container alignItems='center' direction='column'>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.endStreamButtons}
                        style={{ marginBottom: '1rem' }}
                        onClick={() => props.history.goBack()}
                      >
                        End Stream
                      </Button>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.endStreamButtons}
                        onClick={() => setCloseStream(false)}
                      >
                        Not Now
                      </Button>
                    </Grid>
                  </Grid>
                </Dialog>
                {isStarted ? undefined : (
                  <div style={{ zIndex: 1 }} className={classes.description}>
                    <TextField
                      classes={{ root: classes.fieldRoot }}
                      variant='standard'
                      className={classes.textField}
                      placeholder='Add Description'
                      inputProps={{ className: classes.input }}
                    />
                    <Button
                      className={classes.startButton}
                      variant='contained'
                      color='primary'
                      onClick={handleStreamStarted}
                      disabled={!userUid}
                    >
                      Start
                    </Button>
                  </div>
                )}

                {guestWindow ? (
                  <div className={classes.guestBox} style={{ zIndex: 3 }}>
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        zIndex: 2,
                      }}
                    >
                      <IconButton
                        onClick={() => setRemoveGuest(true)}
                        className={classes.closeButton}
                      >
                        <Close className={classes.closeIcon} />
                      </IconButton>
                    </div>
                    <div className={classes.guestVideo} ref={guest}></div>
                  </div>
                ) : undefined}
                <Dialog open={removeGuest} className={classes.guestUserDialog}>
                  <Grid
                    item
                    container
                    className={classes.guestDialogContainer}
                    alignItems='center'
                    direction='column'
                    // justifyContent="space-between"
                  >
                    <Typography className={classes.guestTitle}>
                      Are you sure?
                    </Typography>
                    <Typography className={classes.guestSubTitle}>
                      You are removing current livestream guest
                    </Typography>
                    <Grid
                      item
                      container
                      style={{ marginTop: 'auto' }}
                      alignItems='center'
                      direction='column'
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.endStreamButtons}
                        style={{ marginBottom: '0.75rem' }}
                        onClick={handleRemoveCoHost}
                      >
                        Remove
                      </Button>

                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.endStreamButtons}
                        onClick={() => setRemoveGuest(false)}
                      >
                        Not Now
                      </Button>
                    </Grid>
                  </Grid>
                </Dialog>
                <Dialog open={openDialog} className={classes.dialog}>
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    className={classes.dialogContent}
                  >
                    <Grid item>
                      <Typography className={classes.dialogTitle}>
                        Report Stream
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.dialogSubtitle}>
                        Are you sure you want to report this stream?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.reportButton}
                        variant='contained'
                        color='primary'
                        onClick={() => setOpenDialog(false)}
                      >
                        Report
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.cancelButton}
                        variant='outlined'
                        color='primary'
                        onClick={() => setOpenDialog(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Dialog>
                {/* waiting overlay */}
                {isWaiting ? (
                  <Grid
                    containaer
                    justifyContent='center'
                    alignItems='center'
                    direction='column'
                    className={classes.waitingOverly}
                  >
                    <Typography className={classes.overlyTitle}>
                      Wait Please
                    </Typography>
                    <Typography className={classes.overlySubtitle}>
                      You will be able to see the user's video once it is your
                      turn
                    </Typography>
                  </Grid>
                ) : undefined}
                {blueWindow ? (
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    className={classes.blueWindow}
                  >
                    <Grid item container>
                      <IconButton
                        onClick={() => setBlueWindow(false)}
                        className={classes.closeButton}
                      >
                        <Close className={classes.closeIcon} />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{ height: '100%' }}
                    >
                      <Typography className={classes.blueWindowText}>
                        Ask your viewers to play!
                      </Typography>
                    </Grid>
                  </Grid>
                ) : undefined}
              </div>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              className={classes.warningContainer}
            >
              {isWaiting ? (
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.waitTitle}>
                    {exit ? 'Are you sure?' : 'Wait Please'}
                  </Typography>
                  <Typography className={classes.waitSubtitle}>
                    {exit
                      ? 'Do you really want to get out of line?'
                      : 'You are in line for the next|date with silkysilk_00. Wait for	your turn to have fun with the user.'}
                  </Typography>
                  <Grid item>
                    {exit ? (
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            className={classes.exitSecondaryButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => setIsWaiting(false)}
                          >
                            Leave the line
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.exitSecondaryButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => setExit(false)}
                          >
                            Keep Waiting
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        className={classes.exitButton}
                        variant='outlined'
                        color='primary'
                        onClick={() => setExit(true)}
                      >
                        EXIT
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Block className={classes.block} />
                  <Typography className={classes.warning} variant='h4'>
                    Don’t stream nudity or obscene/violent behavior. ever stream
                    while driving or under unsafe conditions.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems={smScreen ? undefined : 'flex-end'}
            justifyContent={lgScreen ? 'center' : 'flex-start'}
            className={classes.utilityContainer}
          >
            <StreamerBox
              endStream={handleEndStream}
              channelId={userUid}
              roleChange={roleChange}
              streamId={streamId}
              coHostUserId={coHostUserId}
              setCoHostUserId={setCoHostUserId}
              setShowQueueModal={setShowQueueModal}
              messages={streamMessages}
            />
          </Grid>
        </Grid>
      )}
      {showQueueModal && (
        <UserAddedToFaceoffQueue
          open={showQueueModal}
          setOpen={setShowQueueModal}
        />
      )}
    </>
  );
};
