import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles, Grid, Avatar, Badge } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './style.css';

const useStyles = makeStyles(theme => ({
  image: {
    height: '133px',
    width: '133px',
    [theme.breakpoints.down('lg')]: {
      height: '95px',
      width: '95px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      width: '80px',
    },
  },
  root: {
    '& .splide__arrow': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.85,
      fill: '#ffffff',
      fontSize: '1.25rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.75rem',
      },
    },
    '& .splide__arrow--prev': {
      left: '-0.9rem',
      [theme.breakpoints.down('lg')]: {
        left: '-0.7rem',
      },
    },
    '& .splide__arrow--next': {
      right: '-1rem',
      [theme.breakpoints.down('lg')]: {
        right: '-0.6rem',
      },
    },
  },
  wrapper: {
    borderRadius: '50%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#26BB21',
      height: '18px',
      width: '18px',
      borderRadius: '50%',
      [theme.breakpoints.down('lg')]: {
        width: '12px',
        height: '12px',
        minWidth: '0',
      },
    },
  },

  slider: {
    '& .splide__slide': {
      width: '133px',
      [theme.breakpoints.down('lg')]: {
        width: '95px',
      },
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
  },
}));

export const Slider = ({ users }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Splide
        options={{
          perPage: 9,
          perMove: 1,
          gap: '1rem',
          pagination: false,
          breakpoints: {
            1250: {
              perPage: 7,
            },
            1000: {
              perPage: 7,
            },
            700: {
              perPage: 5,
            },
            550: {
              perPage: 3,
              gap: '0px',
            },
          },
        }}
        className={classes.slider}
      >
        {users?.map(user => (
          <SplideSlide style={{ aspectRatio: '1/1' }} key={user._id}>
            <Link to='/home/online'>
              <div className={classes.wrapper}>
                <Badge
                  className={classes.badge}
                  overlap={'circular'}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  color='secondary'
                  badgeContent=''
                >
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}/${user.profile_image}`}
                    style={{ borderRadius: '50%', objectFit: 'cover' }}
                    className={classes.image}
                    alt=''
                  />
                </Badge>
              </div>
            </Link>
          </SplideSlide>
        ))}
      </Splide>
    </Grid>
  );
};
