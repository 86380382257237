import React from 'react';
import { Header } from '../../components/header/Header';
import { Grid, Typography, Button, Checkbox } from '@material-ui/core';
import image from '../../assets/index';
import { useStyles } from '../Login/loginStyle';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ButtonComp from '../../components/ButtonComp/ButtonComp';

export const PreRegisteration = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const history = useHistory();

  const handlePreRegister = () => {
    history.push('/pre-register');
  };

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.container}
      >
        {/* <Header background='rgba(0,0,0,0.16)' /> */}

        <video
          className={classes.backgroundVideo}
          src={image.splashVideo}
          autoPlay
          loop
          muted
        ></video>
        <Grid item className={classes.mainContainer}>
          <Grid
            container
            className={classes.loginContainer}
            justifyContent='center'
            direction='column'
            spacing={1}
            alignItems='center'
          >
            <Grid item>
              <img
                src={image.introLogo}
                className={classes.logo}
                alt='company-logo'
              />
            </Grid>
            <Grid item>
              <ButtonComp
                label='Register Yourself'
                onClick={handlePreRegister}
              />
            </Grid>
            {/* <Grid item>
              <Typography className={classes.body1} variant='body1'>
                {t('or_continue_with_your')}
                <Link to='/signin' className={classes.anchor}>
                  {t('phone_number')}?
                </Link>
              </Typography>
            </Grid> */}

            <Grid item className={classes.policyContainer}>
              <Grid container item alignItems='center' gap={16}>
                <Grid item>
                  <Checkbox
                    color='primary'
                    checkedIcon={
                      <img
                        className={classes.checkboxIcon}
                        src={image.checkBoxChecked}
                      />
                    }
                    checked={true}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.body2} variant='body2'>
                    {t('By registering yourself for intro you agree to our')}
                    <Link
                      to='/terms-and-conditions'
                      target='_blank'
                      className={classes.anchor2}
                    >
                      {t('terms_of_service.')}
                    </Link>
                    {t('learn_how_we_process_your_data_in_our')}
                    <Link
                      to='/privacy-policy'
                      target='_blank'
                      className={classes.anchor2}
                    >
                      {t('privacy_policy')}
                    </Link>
                    {t('and')}
                    <Link to='#' className={classes.anchor2}>
                      {t('cookies_policy')}.
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
