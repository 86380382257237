import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Dialog, makeStyles, Grid, Button } from '@material-ui/core';

import 'react-image-crop/dist/ReactCrop.css';

const ImageCrop = ({ imageToCrop, setCropped, setOpen, open }) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({
    maxHeight: 400,
    maxWidth: 400,
    aspect: 1,
  });

  const [image, setImage] = useState(null);

  const cropImageNow = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    setCropped(base64Image);
    setOpen(false);
  };

  return (
    <Dialog open={open} className={classes.dialog}>
      <Grid className={classes.container}>
        {imageToCrop && (
          <div>
            <ReactCrop
              src={imageToCrop}
              onImageLoaded={setImage}
              maxHeight={400}
              maxWidth={400}
              crop={crop}
              onChange={setCrop}
            />
          </div>
        )}
      </Grid>
      <Grid container justifyContent='center' style={{ paddingBlock: '10px' }}>
        <Button
          variant='contained'
          color='primary'
          className={classes.btn}
          onClick={cropImageNow}
        >
          Crop
        </Button>
      </Grid>
    </Dialog>
  );
};

export default ImageCrop;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      background: theme.palette.common.lightPink,
      borderRadius: '12px',
    },
    '& .main-container': {
      gap: '20px',
      padding: '20px',
    },
  },
  btn: {
    width: '220px',
    borderRadius: '25px',
    backgroundColor: theme.palette.common.green,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.green + 'dd',
    },
  },
}));
