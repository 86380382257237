import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  btnOutlineBlue: {
    backgroundColor: '#fff',
    height: '60px',
    width: '406px',
    borderRadius: '80px',
    border: `2px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main}!important`,
    textTransform: 'none',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
      width: '270px',
      height: '40px',
    },
  },
  btnGradient: {
    borderRadius: '80px',
    border: '2px',
    color: theme.palette.common.blue,
    textTransform: 'none',
    // width: "100%",
    background: 'linear-gradient(to right,blue,green, yellow,red)',
    position: 'relative',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '61px',
    width: '406px',
    fontWeight: '500',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      width: '270px',
      height: '40px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 4px)',
      borderRadius: '80px',
      height: '1rem',
      display: 'block',
      background: '#fff',
      zIndex: -1,
      paddingTop: '20px',
      paddingBottom: '20px',
      [theme.breakpoints.down('lg')]: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
  },
  btnFacebook: {
    backgroundColor: '#4267B2',
    borderColor: 'transparent',
    height: '61px',
    width: '406px',
    borderRadius: '80px',
    color: '#ffffff',
    border: `2px solid ${theme.palette.common.blue}`,
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '21px',
    '&:hover': {
      backgroundColor: '#3b5998',
    },
    [theme.breakpoints.down('lg')]: {
      width: '270px',
      height: '40px',
      fontSize: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  btnWhite: {
    backgroundColor: '#ffffff',
    borderColor: 'transparent',
    height: '61px',
    width: '406px',
    borderRadius: '80px',
    color: '#000000',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '21px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('lg')]: {
      width: '270px',
      height: '40px',
      fontSize: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  btnBlue: {
    backgroundColor: theme.palette.common.blue,
    height: '61px',
    width: '406px',
    borderRadius: '80px',
    color: '#ffffff',
    border: '2px solid transparent',
    textTransform: 'none',
    fontWeight: '500',

    fontSize: '21px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down('lg')]: {
      width: '270px',
      height: '40px',
      fontSize: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  btnOutlineRed: {
    backgroundColor: '#ffffff',
    height: '61px',
    borderRadius: '80px',
    border: `2px solid #ff858c`,
    color: '#ff858c',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '21px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('lg')]: {
      width: '170px',
      height: '40px',
      fontSize: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  outlineButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    width: '134px',
    height: '85px',
    borderRadius: '43px',
    textTransform: 'none',
    fontSize: '28px',
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      width: '80px',
      height: '50px',
      marginTop: '10px',
      fontSize: '15px',
      marginLeft: '2rem',
    },
  },
  skipButton: {
    background: '#ffffff',
    width: '132px',
    height: '42px',
    borderRadius: '43px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: '25px',
    [theme.breakpoints.down('lg')]: {
      width: '80px',
      height: '33px',
      fontSize: '15px',
      marginRight: '60px',
    },
  },
}));
