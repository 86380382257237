import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    zIndex: -1,
  },
  logo: {
    marginTop: '7.5rem',
    marginBottom: '0rem',
    [theme.breakpoints.down('lg')]: {
      height: '15rem',
      marginTop: '2rem',
      marginBottom: '0rem',
    },
  },
  loginContainer: {
    // maxWidth: "45ch",
  },
  anchor: {
    fontSize: '20px',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.85rem',
    },
  },
  anchor2: {
    fontSize: '12px',
    color: '#fff',
    margin: '0 1ch',
    textDecoration: 'underline',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6rem',
    },
  },
  body1: {
    marginTop: '6.5rem',
    color: '#ffffff',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.85rem',
      marginTop: '4rem',
    },
  },
  body2: {
    fontSize: '12px',
    maxWidth: '65ch',
    color: '#ffffff',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6rem',
    },
  },
  policyContainer: {
    marginTop: '3.5rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0rem',
    },
  },
  facebookButton: {
    backgroundColor: '#3B5998',
    marginTop: '0.5rem',
    minWidth: '410px',
    height: '65px',
    textTransform: 'none',
    fontSize: '21px',
    fontWeight: 300,
    borderRadius: '33px',
    color: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      minWidth: '330px',
      height: '40px',
      fontSize: '15px',
      marginTop: '0.15rem',
    },
    '&:hover': {
      backgroundColor: '#4262A5',
    },
    '&:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      //   padding: '0rem',
    },
  },
  googleButton: {
    backgroundColor: '#CE4317',
    minWidth: '410px',
    height: '65px',
    textTransform: 'none',
    fontSize: '21px',
    fontWeight: 300,
    borderRadius: '33px',
    color: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      minWidth: '330px',
      height: '40px',
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#D14B21',
    },
    '&:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      //   padding: '1.25rem',
    },
  },
  googleIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
    },
  },
  fbIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
    },
  },
  checkboxIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
    },
  },
  backgroundVideo: {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
    backgroundColor: 'grey',
  },
  mainContainer: {
    zIndex: '1',
  },
}));
