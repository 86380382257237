import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Button, Typography } from '@material-ui/core';
// import ReactMapGL, { Marker } from 'react-map-gl';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
} from 'react-google-maps';

import { useDispatch, useSelector } from 'react-redux';

// import 'mapbox-gl/dist/mapbox-gl.css';

import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/header/Header';
import { setLocation } from '../../../http';
import { submit } from '../../../store/user';
// import image from '../../../assets';

const headerItems = [];

const Map = ({ handleLocationClick, initialLocation }) => {
  return (
    <GoogleMap
      defaultZoom={14}
      center={initialLocation}
      onClick={handleLocationClick}
    >
      <Marker position={initialLocation}></Marker>
    </GoogleMap>
  );
};

const MapComponent = withScriptjs(withGoogleMap(Map));
const Location = ({ history }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state.auth.user.data);
  const [initialLocation, setInitialLocation] = useState({
    lat: -34.397,
    lng: 150.644,
  });

  const handleContinue = async () => {
    try {
      setLoading(true);
      const locationData = {
        lon: String(initialLocation.lng),
        lat: String(initialLocation.lat),
        geoHash: 'geoHash',
        visible: true,
        step: userData.step === '/location' ? '/body-type' : userData.step,
      };
      console.log(locationData);
      const { data } = await setLocation(locationData);
      dispatch(submit(data));
      history.push('/register/body-type');
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        setInitialLocation({
          lng: pos.coords.longitude,
          lat: pos.coords.latitude,
        });
      });
    }
  };

  const handleLocationClick = e => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setInitialLocation({
      lat,
      lng,
    });
  };

  useEffect(() => {
    handleUserLocation();
  }, []);

  useEffect(() => {
    setInitialLocation({
      lat: userData?.location?.coordinates?.length
        ? userData?.location?.coordinates[1]
        : -34.397,
      lng: userData?.location?.coordinates?.length
        ? userData?.location?.coordinates[0]
        : 150.644,
    });
  }, [userData]);

  return (
    <Grid className={classes.container}>
      <Header transparent headerItems={headerItems} />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        className={classes.innerContainer}
      >
        <Typography varinat='h1' className={classes.title}>
          {t('location')}
        </Typography>
        <Grid item className={classes.mapContainer}>
          <MapComponent
            initialLocation={initialLocation}
            handleLocationClick={handleLocationClick}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GEOCODE_API}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>
        <Button
          onClick={handleContinue}
          variant='contained'
          className={classes.btn}
          disabled={loading}
        >
          {t('confirm_location')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Location;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: '#fff8f5',
    backgroundSize: '554.5px 520.5px, 330px 250px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '390px 390px, 240px 190px',
    },
  },
  innerContainer: {
    gap: '75px',
    minHeight: '100vh',
    paddingInline: '20px',
    [theme.breakpoints.down('lg')]: {
      gap: '40px',
    },
  },

  title: {
    color: '#000000',
    fontSize: '35px',
    fontWeight: '600',
    margin: '0',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
    },
  },

  mapContainer: {
    width: '1201px',
    height: '634px',
    backgroundColor: theme.palette.common.green,
    [theme.breakpoints.down('lg')]: {
      width: '790px',
      height: '480px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  btn: {
    width: '369px',
    height: '57px',
    margin: 0,
    fontSize: '16px',
    color: '#ffffff',
    marginTop: '1.25rem',
    textTransform: 'none',
    borderRadius: '29px',
    backgroundColor: theme.palette.common.green,
    '&:hover': {
      backgroundColor: theme.palette.common.green,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
