import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Select,
  MenuItem,
  TextField,
  useTheme,
  Dialog,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { Header } from '../../components/header/Header';
import image from '../../assets/index';
import OtpInput from 'react-otp-input';
import {
  verify,
  getUser,
  step,
  generateOtp,
  subscribetTopic,
} from '../../http';
import { submit } from '../../store/user';
import { useDispatch } from 'react-redux';
import Joi from 'joi-browser';
import { SocketContext } from '../../http/socket';
import { subscribeTokenToTopic, getToken } from '../../firebaseInit';
import axios from 'axios';
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import useCountdown from '../../hooks/useCountdown';
import { setGender, setLocation } from '../../store/Reducers/filters';
import { getGenderFilterPreference } from '../../utils';
import { useTranslation } from 'react-i18next';

export const SignInScreen = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const { time, resetTimer, startTimer } = useCountdown();
  const [isValid, setIsValid] = useState(false);
  const classes = useStyles();
  const socket = useContext(SocketContext);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');
  const dispatch = useDispatch();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState({
    otp: '',
    phonenumber: '',
  });
  const schema = {
    phonenumber: Joi.number().required().label('Phone number'),
  };
  const HandleNavigateUser = userData => {
    const step = userData.step;
    switch (step) {
      case null:
        props.history.push('/register/sign-up');
        break;
      case '/dob':
        props.history.push('/register/sign-up');
        break;
      case '/username':
        props.history.push('/register/sign-up');
        break;
      case '/gender-selection':
        props.history.push('/register/indentify');
        break;
      case '/choose-date-characters':
        props.history.push('/register/dating-preference');
        break;
      case '/height':
        props.history.push('/register/height');
        break;
      case '/location':
        props.history.push('/register/location');
        break;
      case '/body-type':
        props.history.push('/register/body-type');
        break;
      case '/ethnicity-page':
        props.history.push('/register/origin');
        break;
      case '/get-user-home-town':
        props.history.push('/register/origin');
        break;
      case '/get-user-education':
        props.history.push('/register/education');
        break;
      case '/get-user-profession':
        props.history.push('/register/education');
        break;
      case '/get-user-religion':
        props.history.push('/register/religion');
        break;
      case '/get-user-children':
        props.history.push('/register/children');
        break;
      case '/get-user-vices':
        props.history.push('/register/lifestyle');
        break;
      case '/get-user-photos':
        props.history.push('/register/profile-picture');
        break;
      case '/get-prompt-questions':
        props.history.push('/uploads');
        break;
      default:
        break;
    }
  };
  const validate = () => {
    const result = Joi.validate(
      {
        phonenumber: phoneNumber,
      },
      schema
    );
    if (!result.error) {
      setError({
        otp: '',
        phonenumber: '',
      });
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details)
        errorsObject[item.path[0]] = item.message;
      setError(errorsObject);
      return true;
    }
  };
  const handleChange = event => {
    setCountryCode(event.target.value);
  };
  const handleSubmit = async () => {
    const err = validate();
    if (!err) {
      try {
        const number = countryCode + phoneNumber;
        await generateOtp({
          phonenumber: number,
          isNew: 'false',
        });

        startTimer();
        setOpenDialog(true);
        // refer.current.focus();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleOtp = otp => {
    setOtp(otp);
    // handleKeypress(otp);
  };
  const handlePhoneNumber = e => {
    setPhoneNumber(e.target.value);
    const obj = {
      phonenumber: phoneNumber,
    };
    const subSchema = {
      phonenumber: Joi.number().required().label('Phone number'),
    };
    const { error } = Joi.validate(obj, subSchema);
    // eslint-disable-next-line
    const it = error
      ? setError({ ...error, phonenumber: error.details[0].message })
      : setError({ ...error, phonenumber: '' });
  };
  const handleVerify = async e => {
    if (e.keyCode === 13) {
      handleVerifyBtn();
    }
  };
  const handleVerifyBtn = async () => {
    try {
      const number = countryCode + phoneNumber;
      const { data } = await verify({
        phonenumber: number,
        code: otp,
      });
      socket.emit('login', data.data._id);
      const token = await getToken();
      subscribetTopic({
        topic: 'liveuser',
        deviceToken: token,
        isSubscribe: true,
      });
      subscribetTopic({
        topic: `${data.data._id}_liveloop`,
        deviceToken: token,
        isSubscribe: true,
      });
      subscribetTopic({
        topic: `${data.data._id}_joinlive`,
        deviceToken: token,
        isSubscribe: true,
      });
      subscribetTopic({
        topic: 'delliveuser',
        deviceToken: token,
        isSubscribe: true,
      });
      subscribetTopic({
        topic: `${data.data._id}_schedule`,
        deviceToken: token,
        isSubscribe: true,
      });
      subscribetTopic({
        topic: `${data.data._id}_faceoff`,
        deviceToken: token,
        isSubscribe: true,
      });
      const res = await getUser();
      dispatch(submit(res.data));
      dispatch(
        setGender(
          getGenderFilterPreference(
            res.data.data.identify.gender,
            res.data.data.date_preference.interested_gender
          )
        )
      );
      dispatch(
        setLocation({
          lat: +res.data.data.location.lat,
          lon: +res.data.data.location.lon,
        })
      );
      if (data.data.step === '/home') {
        props.history.replace('home');
      } else if (data.data.step === '/get-prompt-questions') {
        props.history.replace('uploads');
      } else {
        HandleNavigateUser(data.data);
        if (!data.data.step) {
          await step({ step: '/dob' });
        }
      }
      setOpenDialog(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message;
        setError({ ...error, otp: errorMessage });
      }
    }
  };

  const renderTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time - Math.floor(time / 60) * 60;

    return `0${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  };

  const headerItems = [];

  const validatePhoneNumber = () => {
    const phone = countryCode + phoneNumber;
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      phone
    );
  };

  useEffect(() => {
    if (validatePhoneNumber()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line
  }, [countryCode, phoneNumber]);

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <Header transparent headerItems={headerItems} />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.innerContainer}
        direction='column'
      >
        <Grid item className={classes.logoContainer}>
          <img src={image.introLogo} alt='' />
        </Grid>
        <Grid item className={classes.inputContainer}>
          <Select
            value={countryCode}
            onChange={handleChange}
            variant='outlined'
            className={classes.select}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              className: classes.menu,
            }}
          >
            <MenuItem className={classes.menuItem} value='+1'>
              +1
            </MenuItem>
            <MenuItem className={classes.menuItem} value='+92'>
              +92
            </MenuItem>
            <MenuItem className={classes.menuItem} value='+93'>
              +93
            </MenuItem>
          </Select>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              variant='outlined'
              type='number'
              classes={{ root: classes.inputRoot }}
              className={classes.numberInput}
              fullWidth
              placeholder={t('phone_number')}
              InputProps={{ className: classes.input }}
              onChange={handlePhoneNumber}
              error={Boolean(error.phonenumber)}
              helperText={error.phonenumber}
              FormHelperTextProps={{ className: classes.error }}
              onKeyDown={e => e.key === 'Enter' && handleSubmit()}
            />
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '1rem', width: '100%' }}>
          <ButtonComp
            className={classes.continueButton}
            onClick={handleSubmit}
            disabled={!isValid}
            label={t('continue')}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <p>Or</p>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Button
            startIcon={
              <img
                src={image.googleIcon}
                className={classes.googleIcon}
                alt=''
              />
            }
            endIcon={
              <img
                src={image.facebook}
                alt=''
                style={{ visibility: 'hidden', width: '0.1rem' }}
              />
            }
            className={classes.googleButton}
            variant='contained'
          >
            {t('sign_in_with_google')}
          </Button>
        </Grid>
        <Grid item style={{ marginTop: '1rem', width: '100%' }}>
          <Button
            startIcon={
              <img src={image.facebook} className={classes.fbIcon} alt='' />
            }
            endIcon={
              <img
                src={image.facebook}
                alt=''
                style={{ visibility: 'hidden', width: '0.1rem' }}
              />
            }
            className={classes.facebookButton}
            variant='contained'
          >
            {t('sign_in_with_facebook')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        className={classes.dialogRoot}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onKeyDown={handleVerify}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          className={classes.dialogContainer}
        >
          <Grid item>
            <Typography className={classes.dialogTitle}>
              {t('enter_the_verification_code')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.dialogSubtitle}>
              {t('sent_to')} {countryCode}-{phoneNumber}
            </Typography>
          </Grid>
          <Grid item className={classes.verifyContainer}>
            <OtpInput
              isInputNum
              value={otp}
              onChange={handleOtp}
              numInputs={6}
              separator={<span style={{ width: '10px' }} />}
              containerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              inputStyle={{
                borderRadius: smScreen ? '6px' : '11px',
                height: smScreen ? '35px' : '53px',
                width: smScreen ? '35px' : '67px',
                fontSize: smScreen ? '16px' : '28px',
                color: theme.palette.primary.main,
                outline: 'none',
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              shouldAutoFocus={true}
              focusStyle={{
                backgroundColor: 'transparent',
                outline: 'none',
              }}
            />
          </Grid>
          <Grid item>
            {error.otp && (
              <span className={classes.errorSpan}>{error.otp}</span>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent='center'
            alignItems='center'
            style={{ width: '100%', marginBottom: '20px' }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ margin: '0px' }}
            >
              {renderTime()}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              className={classes.verifyButton}
              color='primary'
              onClick={handleVerifyBtn}
              disabled={otp.length < 6}
            >
              {t('verify')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              className={classes.resendButton}
              color='primary'
              disabled={!!time}
              // onClick={() => setOpenDialog(false)}
              onClick={() => resetTimer()}
            >
              {t('resend_code')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fbfbfb',
    height: '100vh',
  },
  logoContainer: {
    marginBottom: '0',
    '& img': {
      width: '304.1px',
      [theme.breakpoints.down('lg')]: {
        width: '12rem',
      },
    },
  },
  select: {
    // "&.Mui-focused": {
    // 	border: "1px solid transparent",
    // },
    width: '128px',
    flexShrink: 'none',
    borderRadius: '34px',
    // border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    fontSize: '21px',
    marginBottom: '10px',
    height: '68px',
    marginBottom: '-1px',
    // color: "rgba(112,112,112,0.5)",
    color: '#000000',
    // boxShadow: theme.shadows[3],
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root ': {
      textAlign: 'center',
    },
    '& .MuiSelect-icon': {
      postion: 'absolute',
      display: 'none',
      left: '5px',
      [theme.breakpoints.down('lg')]: {
        left: '3px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '90px',
      height: '52px',
      fontSize: '15px',
      marginBottom: '-6px',

      // border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '60px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid #707070`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid #707070`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid #707070`,
    },
    '& .MuiSelect-outlined': {
      padding: 0,
    },
  },
  inputContainer: {
    marginTop: '1.7rem',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      gap: '5px',
    },
  },
  menuItem: {
    color: '#000',
    marginBottom: '5px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  input: {
    fontSize: '21px',
    backgroundColor: '#fff',
    borderRadius: '34px',
    height: '59px',
    margin: '0',
    paddingLeft: '7px',
    // width: '336px',
    [theme.breakpoints.down('lg')]: {
      // width: '250px',
      height: '40px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
      maxWidth: '100%',
    },
  },
  divider: {
    height: '26px',
    textAlign: 'center',
    marginTop: '5.31rem',
    marginBottom: '4.62rem',
    '& p': {
      textTransform: 'uppercase',
      fontSize: '36px',
      color: '#4A4A4A',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '3.31rem',
      marginBottom: '2.62rem',
      '& p': {
        fontSize: '28px',
      },
    },
  },
  dialogRoot: {
    '& .MuiDialog-paper': {
      borderRadius: '30px',
    },
  },
  dialogContainer: {
    backgroundColor: theme.palette.common.lightPink,
    padding: '1rem 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75rem 1rem',
    },
  },
  dialogTitle: {
    color: '#333333',
    margin: 0,
    fontSize: '21px',
    marginTop: '0.75rem',
    fontWeight: '500',
  },
  dialogSubtitle: {
    color: '#333333',
    margin: 0,
    fontSize: '18px',
    fontWeight: '300',
  },
  verifyContainer: {
    margin: '2rem 0',
  },
  verifyButton: {
    width: '274px',
    height: '52px',
    textTransform: 'none',
    fontSize: '17px',
    borderRadius: '26px',
    fontWeight: 700,
    backgroundColor: theme.palette.common.greenDark,
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      fontSize: '15px',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.greenDark,
      color: '#ffffff',
    },
  },
  resendButton: {
    width: '274px',
    height: '52px',
    textTransform: 'none',
    fontSize: '17px',
    borderRadius: '26px',
    marginTop: '1rem',
    backgroundColor: `${theme.palette.common.greenDark}`,
    color: '#ffffff',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      fontSize: '15px',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.greenDark,
      color: '#ffffff',
    },
  },
  facebookButton: {
    backgroundColor: '#3B5998',
    marginTop: '0.5rem',
    width: '582px',
    height: '70px',
    textTransform: 'none',
    fontSize: '28px',
    fontWeight: 300,
    borderRadius: '33px',
    color: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      width: '400px',
      fontSize: '20px',
      marginTop: '0.15rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: '#4262A5',
    },
    '&:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem',
    },
  },
  fbIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
    },
  },
  googleButton: {
    backgroundColor: '#CE4317',
    width: '582px',
    height: '70px',
    textTransform: 'none',
    fontSize: '28px',
    fontWeight: 300,
    borderRadius: '33px',
    color: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      width: '400px',
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: '#D14B21',
    },
    '&:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem',
    },
  },
  googleIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.5rem',
    },
  },
  inputRoot: {
    width: '100%!important',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: `1px solid #707070`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid #707070`,
      },
      '&:hover fieldset': {
        border: `1px solid #707070`,
      },
    },
  },
  errorSpan: {
    color: 'red',
    lineHeight: '2rem',
    fontSize: '1.3rem',
  },
  error: {
    fontSize: '1.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      maxHeight: '35vh',
    },
  },
  spin: {
    '&::-webkit-outer-spin-button': {
      margin: 0,
      webkitApperance: 'none',
    },
  },
  numberInput: {
    width: '432px',
    '& .MuiInputBase-root': {
      width: '100%',
      height: '68px',
    },

    [theme.breakpoints.down('lg')]: {
      width: '290px',
      height: '48px',
    },
  },
  innerContainer: {
    width: '582px',
    [theme.breakpoints.down('lg')]: {
      width: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingInline: '20px',
    },
  },
  continueButton: {
    width: '582px',
    height: '70px',
    fontSize: '28px',
    marginTop: '56px',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      width: '400px',
      fontSize: '20px',
      marginTop: '30px',
    },
  },
}));
