import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Hidden,
} from '@material-ui/core';
import image from '../../../assets/index';
import { Header } from '../../../components/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import { religion as religionApi, step } from '../../../http';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import axios from 'axios';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { useTranslation } from 'react-i18next';

import { religionList, politicsList } from '../../../app.config';
export const Religion = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const [show, setShow] = useState({
    religion: false,
    politics: false,
  });
  const [values, setValues] = useState({
    religion: '0',
    politics: '0',
  });
  const [errors] = useState({
    religion: '',
    politics: '',
  });

  const handleSkip = async () => {
    const stepData = {
      step:
        userData.step === '/get-user-religion'
          ? '/get-user-vices'
          : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/lifestyle');
    } catch (err) {
      console.log(err);
    }
  };
  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleNext = async () => {
    try {
      const religionData = {
        religion: values.religion === '0' ? '' : values.religion,
        r_visible: show.religion,
        politics: values.politics === '0' ? '' : values.politics,
        p_visible: show.politics,
        step:
          userData.step === '/get-user-religion'
            ? '/get-user-vices'
            : userData.step,
      };
      const { data } = await religionApi(religionData);
      dispatch(submit(data));
      history.push('/register/lifestyle');
    } catch (err) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    }
  };

  useEffect(() => {
    if (values.religion !== '0' || values.politics !== '0') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (values.religion === '0') {
      setShow(prev => ({ ...prev, religion: false }));
    }
    if (values.politics === '0') {
      setShow(prev => ({ ...prev, politics: false }));
    }
  }, [values]);

  useEffect(() => {
    setValues({
      religion: userData?.religion?.religion || '0',
      politics: userData?.religion?.politics || '0',
    });
    setShow({
      religion: userData?.religion?.r_visible || false,
      politics: userData?.religion?.p_visible || false,
    });
  }, [userData]);

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.earth})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${200}px ${200 * 1.26}px`
              : `${250}px ${250 * 1.26}px`
            : '300px 379px'
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px' }}
          >
            {t('religion')}/ {t('politics')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>

        <form
          action=''
          autoComplete='off'
          style={{ width: xsScreen ? '100%' : null }}
        >
          <Grid
            container
            direction='column'
            className={classes.formContainer}
            justifyContent='space-between'
          >
            <Grid
              item
              className='content'
              style={{ marginRight: smScreen ? '0px' : '-210px' }}
            >
              <Grid container direction='column' spacing={lgScreen ? 4 : 8}>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('religion')}
                    options={religionList}
                    placeholder='Choose Religion'
                    show={show.religion}
                    handleShow={handleShow}
                    name='religion'
                    onSelect={handleSelect}
                    value={values.religion}
                    error={Boolean(errors.religion)}
                    errorText={errors.religion}
                  />
                </Grid>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('politics')}
                    options={politicsList}
                    placeholder='Select an option'
                    show={show.politics}
                    handleShow={handleShow}
                    name='politics'
                    onSelect={handleSelect}
                    value={values.politics}
                    error={Boolean(errors.politics)}
                    errorText={errors.politics}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <ButtonComp
            label={t('continue')}
            disabled={disabled}
            onClick={handleNext}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
