const promptVideos = [
  {
    title: '',
    video: '',
  },
  {
    title: 'Let me introduce myself',
    video: '',
  },
  {
    title: 'This is my best travel video',
    video: require('./This is my best travel video.mp4').default, //
  },
  {
    title: 'This is one of my unusual skills',
    video: require('./This is one of my unusual skills.mp4').default, //
  },
  {
    title: "It's about to go down",
    video: require("./It's about to go down.mp4").default, //
  },
  {
    title: 'Take me back to',
    video: require('./Take me back to.mp4').default, //
  },
  {
    title: 'You caught me in the act',
    video: require('./You caught me in the act.mp4').default, //
  },
  {
    title: 'This is how I can win you over',
    video: require('./This is how I can win you over.mp4').default, //
  },
  {
    title: 'This is definitely my greatest strength',
    video: require('./This is definitely my greatest strength.mp4').default, //
  },
  {
    title: 'This is what I take pride in',
    video: require('./This is what I take pride in.mp4').default, //
  },
  {
    title: "I bet you can't do this",
    video: require('./I bet you can’t do this.mp4').default, //
  },
  {
    title: "This is what I'm overly competitive about",
    video: require("./This is what I'm overly competitive about.mp4").default, //
  },
  {
    title: 'Be prepared for this',
    video: require('./Be prepared for this.mp4').default, //
  },
  {
    title: 'My wild side',
    video: require('./My wild side.mp4').default, //
  },
  {
    title: 'Me typically at the party',
    video: require('./Me typically at the party.mp4').default, //
  },
  {
    title: 'My Instagram story mostly contains',
    video: require('./Instagram.mp4').default, //
  },
  {
    title: 'A Tik Tok fad I regret I participated in',
    video: require('./A Tik Tok fad I regret I participated in.mp4').default, //
  },
  {
    title: 'You caught me at a bad time',
    video: require('./You caught me at a bad time .mp4').default, //
  },
  {
    title: 'I bet I can make you laugh',
    video: require('./I bet I can make you laugh.mp4').default, //
  },
  {
    title: 'My biggest Flex',
    video: require('./My biggest Flex.mp4').default, //
  },
  {
    title: 'Guess where this video was taken',
    video: require('./Guess where this video was taken .mp4').default, //
  },
  {
    title: 'Me and my Pet',
    video: require('./Me and my Pet .mp4').default, //
  },
  {
    title: 'My typical Sunday looks like this',
    video: require('./Typical Sunday looks like this.mp4').default, //
  },
  {
    title: 'This is what dating me will look like',
    video: require('./This is what dating me will look like.mp4').default, //
  },
  {
    title: 'I can show you how to',
    video: require('./I can show you how to.mp4').default, //
  },
  {
    title: 'This took one take, I promise',
    video: require('./This took one take, I promise.mp4').default, //
  },
  {
    title: 'My funniest joke',
    video: '',
  },
  {
    title: 'I happen to be the President of',
    video: '',
  },
  {
    title: 'Move to the front of the line if',
    video: '',
  },
  {
    title: 'A secret confession',
    video: '',
  },
];

export default promptVideos;
