import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Slider } from '../../../components/Slider/Slider';
import image from '../../../assets/index';
import { useStyles } from './homeStyles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMatchedUsers,
  iVisitedProfiles,
  visitedMe as visitedMeApi,
  likedMeApi,
  getLiveUsers,
  checkMatch,
  otherUserVideos,
  iLiked,
} from '../../../http';
import {
  setMatches,
  setIvisited,
  setMyLikes,
  setVisitedMe,
  setILiked,
} from '../../../store/matches';
import { setOnlineUsers } from '../../../store/user';
import { setUserVideos } from '../../../store/userVideos';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import chipsIcon from '../../../assets/chipsIcons';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [chips, setChips] = useState(null);
  const userState = useSelector(state => state.auth.user.data);
  // const matched = useSelector(state => state.matches.matches);
  const user = useSelector(state => state.auth.user.data);
  const iVisited = useSelector(state => state.matches.iVisited);
  const visitedMe = useSelector(state => state.matches.visitedMe);
  const likedMe = useSelector(state => state.matches.myLikes);
  const onlineUsers = useSelector(state => state.auth.user.onlineUsers);
  const userReels = useSelector(state => state.userVideos.data);
  const iLikedData = useSelector(state => state.matches.iLiked);
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const sliceArray = array => {
    let newArray;
    if (array.length > 7) {
      newArray = array.slice(0, 7);
    } else {
      return array;
    }
    return newArray;
  };
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleImageClick = async id => {
    const { data } = await checkMatch(id);
    if (data.data) {
      history.push(`/home/match/${id}`);
    } else {
      history.push(`/home/unmatch/${id}`);
    }
  };

  const handleChips = () => {
    const arr = [];
    if (
      userState.date_preference.visible &&
      userState.date_preference.interested_audience &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.intent,
        imgClass: 'chip-icon sm',
        label: userState.date_preference.interested_audience,
      });
    }
    if (
      userState.education.d_visible &&
      userState.education.degree &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.degree,
        imgClass: 'chip-icon',
        label: userState.education.degree,
      });
    }
    if (
      userState.religion.r_visible &&
      userState.religion.religion &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.religion,
        imgClass: 'chip-icon',
        label: userState.religion.religion,
      });
    }
    if (
      userState.religion.p_visible &&
      userState.religion.politics &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.politics,
        imgClass: 'chip-icon',
        label: userState.religion.politics,
      });
    }
    if (
      userState.vices.drink.d_visible &&
      userState.vices.drink.drink &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.drink,
        imgClass: 'chip-icon',
        label: userState.vices.drink.drink,
      });
    }
    if (
      userState.vices.drugs.dr_visible &&
      userState.vices.drugs.drugs &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.drungs,
        imgClass: 'chip-icon',
        label: userState.vices.drugs.drugs,
      });
    }
    if (
      userState.vices.smoke.s_visible &&
      userState.vices.smoke.smoke &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.smoke,
        imgClass: 'chip-icon',
        label: userState.vices.smoke.smoke,
      });
    }
    if (
      userState.vices.weed.w_visible &&
      userState.vices.weed.weed &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.weed,
        imgClass: 'chip-icon',
        label: userState.vices.weed.weed,
      });
    }
    if (
      userState.body_type.t_visible &&
      userState.body_type.type &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.bodyType,
        imgClass: 'chip-icon sm',
        label: userState.body_type.type,
      });
    }
    if (
      userState.body_type.d_visible &&
      userState.body_type.diet &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.diet,
        imgClass: 'chip-icon sm',
        label: userState.body_type.diet,
      });
    }
    if (
      userState.body_type.f_visible &&
      userState.body_type.fitness &&
      arr.length !== 4
    ) {
      arr.push({
        img: chipsIcon.health,
        imgClass: 'chip-icon sm',
        label: userState.body_type.fitness,
      });
    }

    setChips(arr);
  };
  useEffect(() => {
    (async () => {
      handleChips();
      await axios
        .all([
          getMatchedUsers(),
          iVisitedProfiles(),
          visitedMeApi(),
          likedMeApi(),
          getLiveUsers(),
          iLiked(),
          otherUserVideos(user._id),
        ])
        .then(
          axios.spread(function (res1, res2, res3, res4, res5, res6, res7) {
            dispatch(setMatches(res1.data.data));
            dispatch(setIvisited(res2.data.data));
            dispatch(setVisitedMe(res3.data.data));
            dispatch(setMyLikes(res4.data.like));
            dispatch(setOnlineUsers(res5.data.data));
            dispatch(setILiked(res6.data.like));
            // dispatch(setUserVideos(res7.data.data));
          })
        )
        .catch(err => console.log(err));
    })();
    // eslint-disable-next-line
  }, [userState]);
  return (
    <Grid container direction='column' className={classes.container}>
      {onlineUsers.length > 0 && (
        <>
          <Grid item>
            <Typography className={classes.title}>
              {t('online_users')}
            </Typography>
          </Grid>
          <Grid
            item
            style={{ width: '100%' }}
            className={classes.sliderContainer}
          >
            <Slider users={onlineUsers} num={false} />
          </Grid>
        </>
      )}

      <Grid
        item
        container
        spacing={3}
        style={{ marginTop: lgScreen ? '0.5rem' : '1.5rem' }}
        className={classes.contentContainer}
      >
        <Grid item className={classes.right}>
          <Grid container direction='column'>
            <Grid item className={classes.profileCard}>
              <Typography className={classes.profileCardTitle}>
                {t('profile')}
              </Typography>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                wrap='nowrap'
              >
                <Grid item>
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}/${userState.profile_image}`}
                    component={Link}
                    to='/home/profile'
                    className={classes.profileAvatar}
                  />
                </Grid>
                <Grid item className={classes.tags}>
                  <Grid
                    container
                    alignItems='center'
                    className='tags-inner'
                    spacing={1}
                  >
                    {chips?.map((chip, index) => (
                      <div className={classes.chip} key={index}>
                        <img src={chip.img} alt='' className={chip.imgClass} />
                        <p className='chip-label'>
                          {chip.label.toLowerCase() === 'prefer not to say'
                            ? 'none'
                            : chip.label}
                        </p>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction='column'
              className={classes.reelContainer}
            >
              <Typography className={classes.reelTitle}>Reels</Typography>
              <List className={classes.reelsList}>
                {userReels.length > 0 ? (
                  userReels.map((reel, index) => (
                    <ListItem
                      key={index}
                      className={classes.reelItem}
                      dense
                      disableGutters
                      alignItems='center'
                    >
                      <ListItemAvatar className={classes.reelAvatarContainer}>
                        <Avatar
                          component={Link}
                          to='/home/profile'
                          src={`${process.env.REACT_APP_API_URL}/${reel.cover}`}
                          className={classes.reelAvatar}
                          variant='square'
                        />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ root: classes.reelItemText }}
                        primary={reel.video_title}
                      />
                    </ListItem>
                  ))
                ) : (
                  // <p>
                  // 	<b>You have'nt posted any reel yet</b>
                  // </p>
                  <Grid
                    item
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    className={classes.notFoundContainer}
                  >
                    <img
                      src={image.find}
                      className={classes.likeNotFoundImage}
                      alt=''
                    />
                    <span className={classes.notFound}>
                      You have'nt posted any reel yet
                    </span>
                  </Grid>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.middle}>
          <Grid container direction='column'>
            <Grid item className={classes.card}>
              <div className={classes.cardTitle}>
                <Typography className={classes.cardTitleOne}>
                  {t('reels_i_liked')}
                </Typography>
                <Typography
                  component={Link}
                  to='/home/mylikes'
                  className={classes.cardSubtitle}
                >
                  see more
                </Typography>
              </div>
              <div style={{ overflowX: 'auto' }} className={classes.scrollDiv}>
                <div style={{ display: 'flex' }}>
                  {iLikedData.length === 0 && (
                    <Grid
                      item
                      container
                      direction='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <img
                        src={image.find}
                        className={classes.notFoundImage}
                        alt=''
                      />
                      <span className={classes.notFound}>
                        You didn't like any reel
                      </span>
                    </Grid>
                  )}
                  {iLikedData.length &&
                    sliceArray(iLikedData)?.map((item, index) => (
                      <Avatar
                        onClick={() => handleImageClick(item.liked_to._id)}
                        key={index}
                        className={classes.cardAvatar}
                        src={`${process.env.REACT_APP_API_URL}/${item.liked_to_profile_image}`}
                      />
                    ))}
                </div>
              </div>
            </Grid>
            <Grid item className={classes.card}>
              <div className={classes.cardTitle}>
                <Typography className={classes.cardTitleOne}>
                  {t('who_viewed_my_profile')}
                </Typography>
                <Typography
                  className={classes.cardSubtitle}
                  component={Link}
                  to='/home/mylikes'
                >
                  see more
                </Typography>
              </div>
              <div style={{ overflowX: 'auto' }} className={classes.scrollDiv}>
                <div style={{ display: 'flex' }}>
                  {visitedMe.length === 0 && (
                    <Grid
                      item
                      container
                      direction='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <img
                        src={image.find}
                        className={classes.notFoundImage}
                        alt=''
                      />
                      <span className={classes.notFound}>
                        No one visited your profile
                      </span>
                    </Grid>
                  )}
                  {sliceArray(visitedMe)?.map((item, index) => (
                    <Avatar
                      // style={{ marginLeft: index === 0 ? 'auto' : '' }}
                      className={classes.cardAvatar}
                      onClick={() => handleImageClick(item?.visited_by?._id)}
                      key={index}
                      src={`${process.env.REACT_APP_API_URL}/${item.visited_by_profile_image}`}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item className={classes.card}>
              <div className={classes.cardTitle}>
                <Typography className={classes.cardTitleOne}>
                  {t('profiles_i_visited')}
                </Typography>
                <Typography
                  className={classes.cardSubtitle}
                  component={Link}
                  to='/home/mylikes'
                >
                  see more
                </Typography>
              </div>
              <div style={{ overflowX: 'auto' }} className={classes.scrollDiv}>
                <div style={{ display: 'flex' }}>
                  {iVisited.length === 0 && (
                    <Grid
                      item
                      container
                      direction='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <img
                        src={image.find}
                        className={classes.notFoundImage}
                        alt=''
                      />
                      <span className={classes.notFound}>
                        You haven't visited anyone's profile yet
                      </span>
                    </Grid>
                  )}
                  {sliceArray(iVisited)?.map((item, index) => (
                    <Avatar
                      // style={{ marginLeft: index === 0 ? 'auto' : '' }}
                      className={classes.cardAvatar}
                      // key={item.visited_to._id}
                      onClick={() => handleImageClick(item?.visited_to?._id)}
                      key={index}
                      src={`${process.env.REACT_APP_API_URL}/${item.visited_to_profile_image}`}
                    />
                  ))}
                </div>
              </div>
            </Grid>

            <Grid item container justifyContent='space-between'>
              <Grid item className={classes.itemOne} style={{ flexGrow: 1 }}>
                <Typography className={classes.imageTitle}>
                  Premium subscription
                </Typography>
                <Typography className={classes.imageText}>
                  Unlock more features - Tokens - Add more videos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.left}>
          <Grid container>
            <Grid item className={classes.listContainer}>
              <Typography className={classes.likes}>Likes</Typography>
              {likedMe.length === 0 && (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  className={classes.notFoundContainer}
                >
                  <img
                    src={image.find}
                    className={classes.likeNotFoundImage}
                    alt=''
                  />
                  <span className={classes.notFound}>
                    No one liked your videos yet
                  </span>
                </Grid>
              )}
              <List className={classes.likeList}>
                {sliceArray(likedMe)?.map((item, index) => (
                  <ListItem
                    onClick={() => handleImageClick(item?.liked_by?._id)}
                    key={index}
                    dense
                    classes={{ root: classes.listItemRoot }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.listItemAvatar}
                        src={`${process.env.REACT_APP_API_URL}/${item.liked_by_profile_image}`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.liked_by_name}
                    />
                    <ListItemSecondaryAction>
                      <img
                        className={classes.thumbIcon}
                        src={image.thumb}
                        alt=''
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Typography
                component={Link}
                to='/home/mylikes'
                className={classes.seeMore}
              >
                see more
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
