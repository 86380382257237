import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './helpStyles';
import { allQA } from '../../http';
import HelpQA from '../../components/HelpQA/HelpQA';

const SubCategories = ({ subCategory }) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);

  const handleGetQuestionAnswers = async () => {
    try {
      const { data } = await allQA(subCategory._id);
      setQuestions(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleGetQuestionAnswers();
  }, []);
  return (
    <Grid className={classes.col} item>
      <div className={classes.colInnerContainer}>
        <Typography className={classes.colTitle} variant='h2'>
          {subCategory.title}
        </Typography>
        {questions.map(q => (
          <HelpQA question={q.question} answer={q.answer} />
        ))}
      </div>
    </Grid>
  );
};

export default SubCategories;
