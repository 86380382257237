import React, { useMemo, useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Dialog,
  Checkbox,
  Button,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import { blockVideo } from '../../http/index';
import { useDispatch } from 'react-redux';
import { setFilterUpdated } from '../../store/Reducers/filters';
import { useTranslation } from 'react-i18next';

const ReportVideoDialog = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, setOpen, video_id } = props;
  const [values, setValues] = useState([]);
  const [t] = useTranslation();

  const resons = useMemo(
    () => [
      t('no_reason'),
      t('profile_is_fake,_scam_or_scammer'),
      t('inappropriate_content'),
      t('underage_or_minor'),
      t('nudity/obscene_gesture'),
      t('someone_is_in_danger'),
      t('Other'),
    ],
    []
  );

  const user = useSelector(state => state.auth.user.data);

  const handleSetValue = val => {
    let list = [];
    list.push(val);
    setValues([...list]);
  };

  const handleContinue = async () => {
    const data = {
      report_by_id: user._id,
      report_title: values[0],
      report_message: 'lol',
      video_id: video_id,
    };
    // console.log(data);
    const res = await blockVideo(data);
    dispatch(setFilterUpdated());
    setOpen(false);
    // console.log(res.data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={classes.dialog}
    >
      <Grid item container direction='column' className={classes.container}>
        <Grid item container justifyContent='center'>
          <Typography className={classes.title}>{t('report_video')}</Typography>
        </Grid>
        <Grid item container direction='column'>
          {resons.map((reason, index) => (
            <Grid
              item
              container
              alignItems='center'
              justifyContent='space-between'
              className={classes.reasonContainer}
              key={index}
              onClick={() => handleSetValue(reason)}
            >
              <Typography className={classes.reason}>{reason}</Typography>
              <Checkbox
                color='primary'
                checked={Boolean(values.indexOf(reason) > -1)}
              />
            </Grid>
          ))}
          <Grid item container justifyContent='center'>
            <Button
              variant='outlined'
              color='primary'
              className={classes.button}
              onClick={handleContinue}
            >
              {t('continue')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ReportVideoDialog;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '30px',
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  container: {
    paddingInline: '2rem',
    paddingBlock: '2rem',
    width: '500px',
  },
  title: {
    margin: 0,
    color: theme.palette.primary.main,
    fontSize: '32px',
    fontWeight: '700',
  },
  reason: {
    margin: 0,
    color: '#828282',
  },
  reasonContainer: {
    paddingBlock: '15px',
    borderBottom: '1px solid #828282',
  },
  button: {
    borderRadius: '25px',
    height: '50px',
    width: '180px',
    textTransform: 'none',
    fontSize: '20px',
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    marginTop: '1.25rem',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));
