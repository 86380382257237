import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Grid,
  Tabs,
  Tab,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import TabPanel from '../settings/TabPanel';
import {
  reportAndBlockedUsers,
  reportedVideos as reportedVideosApi,
} from '../../http';
import assets from '../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMute } from '../../store/videoSound';
import axios from 'axios';

const Reported = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const mutedState = useSelector(state => state.video.muted);
  const [muted, setMuted] = useState(mutedState);
  const [reportedUsers, setReportedUsers] = useState(null);
  const [reportedVideos, setReportedVideos] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const handleTabs = (e, tab) => {
    setTab(tab);
  };

  const videosRef = useRef([]);
  videosRef.current = [];

  const handleToggleMute = () => {
    setMuted(!muted);
    dispatch(toggleMute());
  };

  const handleScroll = () => {
    const boundry = {
      top: {
        upper: lgScreen ? -147 : -370,
        lower: lgScreen ? 382 : 530,
      },
      bottom: {
        upper: lgScreen ? 385 : 540,
        lower: lgScreen ? 912 : 1441,
      },
    };
    // eslint-disable-next-line
    videosRef.current.map(item => {
      const top = item.getBoundingClientRect().top;
      const bottom = item.getBoundingClientRect().bottom;
      if (
        top > boundry.top.upper &&
        top < boundry.top.lower &&
        bottom > boundry.bottom.upper &&
        bottom < boundry.bottom.lower
      ) {
        if (item.readyState >= 2) {
          item.play();
        }
        // console.log(video.readyState);
      } else {
        item.pause();
      }
    });
  };

  const addToRef = e => {
    if (e && !videosRef.current.includes(e) && !null) videosRef.current.push(e);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .all([reportAndBlockedUsers(), reportedVideosApi()])
      .then(
        axios.spread(function (res1, res2) {
          setReportedUsers(res1.data.data);
          setReportedVideos(res2.data.data);
          setLoading(false);
        })
      )
      .catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (tab === 1 && videosRef.current.length > 0) {
      videosRef.current[0].play();
    }
  }, [tab]);
  return (
    <Grid container direction='column' className={classes.container}>
      {!reportedUsers?.length && tab === 0 && !loading && (
        <div className={classes.notFound}>
          <img src={assets.find} alt='' />
          <p>No reported users found</p>
        </div>
      )}
      {!reportedVideos?.length && tab === 1 && !loading && (
        <div className={classes.notFound}>
          <img src={assets.find} alt='' />
          <p>No reported videos found</p>
        </div>
      )}
      {loading && (
        <div className={classes.notFound}>
          <CircularProgress color='primary' />
        </div>
      )}
      <Grid
        item
        container
        justifyContent='center'
        className={classes.tabsContainer}
      >
        <Tabs className={classes.tabs} value={tab} onChange={handleTabs}>
          <Tab className={classes.tab} label='Users' disableRipple />
          <Tab className={classes.tab} label='Videos' disableRipple />
        </Tabs>
      </Grid>
      <Grid
        item
        container
        direction='column'
        className={classes.contentContainer}
        onScroll={tab === 1 ? handleScroll : null}
      >
        <TabPanel index={0} tab={tab}>
          <Grid container direction='column' className={classes.allVideos}>
            <List className={classes.list}>
              {reportedUsers?.map((user, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.img}
                      src={`${process.env.REACT_APP_API_URL}/${user?.profile_image}`}
                      alt=''
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.text}
                    primary={user?.user_first_name}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </TabPanel>
        <TabPanel index={1} tab={tab}>
          <Grid
            container
            direction='column'
            className={classes.allVideos}
            alignItems='center'
            onScroll={handleScroll}
          >
            {reportedVideos?.map((video, index) => (
              <Grid item className={classes.videoContainer} key={index}>
                <Grid item className='title'>
                  {video?.data?.video_title}
                </Grid>
                <Grid item className='video'>
                  <video
                    autoPlay
                    loop
                    muted={muted}
                    src={`${process.env.REACT_APP_API_URL}/${video?.data?.video_url}`}
                    ref={addToRef}
                    poster={`${process.env.REACT_APP_API_URL}/${video?.data?.cover}`}
                  />
                  <IconButton
                    className={classes.speakerIcon}
                    onClick={handleToggleMute}
                  >
                    <img src={muted ? assets.mute : assets.unMute} alt='' />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Reported;

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    paddingBlock: '45px 27px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '0px 15px',
    },
  },
  tabsContainer: {
    paddingRight: '80px',
    marginBottom: '15px',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '40px',
      marginBottom: '5px',
    },
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.crimson,
      height: '6px',
      borderRadius: '2px',
      [theme.breakpoints.down('lg')]: {
        height: '4px',
      },
    },
  },
  tab: {
    minWidth: 8,
    fontSize: '25px',
    opacity: 1,
    textTransform: 'none',
    marginInline: '95px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      marginInline: '45px',
      fontSize: '20px',
    },
  },
  contentContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
    height: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '50px',
    },
  },
  list: {
    width: '70%',
    marginTop: '28px',
    marginLeft: '84px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '16px',
      marginLeft: '0px',
    },
  },
  listItem: {
    width: '100%',
    paddingBlock: '15px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '10px',
    },
  },
  text: {
    '& .MuiListItemText-primary': {
      margin: '0',
      fontSize: '16px',
      color: theme.palette.primary.main,
      textAlign: 'left',
      marginLeft: '25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        marginLeft: '15px',
      },
    },
  },
  img: {
    height: '65px',
    width: '65px',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      width: '50px',
    },
  },
  speakerIcon: {
    position: 'absolute',
    bottom: '18px',
    left: '25px',
    '& img': {
      width: '38px',
      [theme.breakpoints.down('lg')]: {
        width: '25px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      bottom: '5px',
      left: '10px',
    },
  },
  videoContainer: {
    width: '338px',
    marginTop: '45px',
    marginRight: '80px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      marginTop: '35px',
      width: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      width: '250px',
      marginInline: 'auto',
    },
    '& .title': {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '19px',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '10px',
        fontSize: '14px',
      },
    },
    '& video': {
      height: '596px',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '16px',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        height: '380px',
      },
    },
  },
  notFound: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gridGap: '14px',
    [theme.breakpoints.down('lg')]: {
      gridGap: '8px',
    },
    '& img': {
      width: '308px',
      aspectRatio: '1/1',
      [theme.breakpoints.down('lg')]: {
        width: '200px',
      },
    },
    '& p': {
      color: '#929292',
      fontSize: '25px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },
}));
