import React, { useState, useContext, useRef, useEffect } from 'react';
import { useStyles } from './postStyles';
import {
  Grid,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';
import image from '../../assets/index';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { likeVideo, checkMatch, superLikeApi } from '../../http';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMute } from '../../store/videoSound';
import { useHistory } from 'react-router-dom';
import { db } from '../../firebaseInit';
import { SocketContext } from '../../http/socket';
import QuickMessageDialog from '../quickMessageDialog/QuickMessageDialog';
import DateScheduler from '../dateSchedular/DateScheduler';
import { onMessage } from '../../utils/firestoreFunctions';
import MatchDialog from '../MatchDialog/MatchDialog';
import InstantSpark from '../InstantSpark/InstantSpark';
import ReportVideoDialog from '../ReportVideoDialog/ReportVideoDialog';
import { useTranslation } from 'react-i18next';

export const Post = React.forwardRef(
  (
    {
      username,
      profile_img,
      video_url,
      video_id,
      title,
      like,
      user_id,
      cover,
      superLike,
      check,
      caption,
    },
    ref
  ) => {
    const classes = useStyles();
    const history = useHistory();
    const socket = useContext(SocketContext);
    const videoRef = useRef(null);
    const [t] = useTranslation();
    const [quickMessage, setQuickMessage] = useState(false);
    const [showCaption, setShowCaption] = useState(false);
    const [matchData, setMatchData] = useState({
      liked_by: '',
      liked_by_name: '',
      liked_by_profile_image: '',
      liked_to: '',
      liked_to_name: '',
      liked_to_profile_image: '',
    });
    const [quickMessageValue, setQuickMessageValue] = useState('');
    const isMuted = useSelector(state => state.video.muted);
    const currentUser = useSelector(state => state.auth.user.data);
    const dispatch = useDispatch();
    const [isLiked, setIsLiked] = useState(like);
    const [superLiked, setSuperLiked] = useState(superLike);
    const [chatId, setChatId] = useState('');
    const [otherUser, setOtherUser] = useState('');
    const [openSuperDialog, setOpenSuperDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [date, setDate] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);
    const [reportDialog, setReportDialog] = useState(false);

    const handleQuickMessage = () => {
      setOpenDialog(false);
      setQuickMessage(true);
    };
    const handleDate = () => {
      setOpenDialog(false);
      setDate(true);
    };

    const handleOpenMenu = e => {
      setAnchorElement(e.target);
      setOpenMenu(true);
    };
    const handleCloseMenu = () => {
      setAnchorElement(null);
      setOpenMenu(false);
    };

    const handlePlayPauseVideo = () => {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    };

    const handleLike = async () => {
      setIsLiked(!isLiked);
      const { data } = await likeVideo({ video_id });
      setMatchData(data.data);
      if (data.matched) {
        setOpenDialog(data.matched);
        setChatId(data.chatId);
        setOtherUser(data.data.liked_to);
      }
    };

    const handleProfileClick = async id => {
      const { data } = await checkMatch(id);
      if (data.data) {
        history.push(`/home/match/${id}`);
      } else {
        history.push(`/home/unmatch/${id}`);
      }
    };

    const handleSuperLike = async () => {
      setSuperLiked(!superLiked);
      const { data } = await superLikeApi({ video_id });
      setMatchData(data.data);
      if (data.matched) {
        setOpenDialog(data.matched);
        setChatId(data.chatId);
        setOtherUser(data.data.liked_to);
      }
    };

    const handleSendQuickMessage = () => {
      if (quickMessageValue === '') {
        return;
      } else {
        const doc = {
          content: quickMessageValue,
          filename: '',
          idFrom: currentUser._id,
          idTo: otherUser,
          thumb: 0,
          type: 0,
        };
        setQuickMessage(false);
        let messages = [];
        db.collection('messages')
          .doc(chatId)
          .collection(chatId)
          .onSnapshot(snapshot => {
            snapshot.docs.forEach(doc => messages.push(doc.data()));
          });
        onMessage(
          doc,
          chatId,
          socket,
          currentUser._id,
          messages.length === 0 ? currentUser._id : otherUser
        );
      }
    };
    const handleReportVideo = () => {
      // alert("working!");
      setReportDialog(true);
      handleCloseMenu();
    };

    const theme = useTheme();
    const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    return (
      <Grid
        item
        container
        alignItems='flex-start'
        className={classes.postContainer}
        ref={ref}
      >
        <Grid
          item
          container
          className={classes.postAvatarContainer}
          sm={3}
          xs={12}
        >
          <Grid item container style={{ padding: xsScreen ? '0px' : '0.5rem' }}>
            <Grid item>
              <Hidden xsDown>
                <Grid item container direction='column' alignItems='flex-start'>
                  <Avatar
                    onClick={() => handleProfileClick(user_id)}
                    src={profile_img}
                    className={classes.postAvatar}
                  />
                  <p className={classes.postAvatarText}>@{username}</p>
                  <p className={classes.distanceAway}>{check + 'mi'}</p>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sm={9}
          xs={12}
          container
          justifyContent='flex-start'
          alignItems='center'
          item
          direction='column'
          className={classes.postAssetContainer}
        >
          <Grid
            item
            container
            style={{
              maxWidth: xsScreen ? '100%' : '72%',
              marginBottom: xsScreen ? '1rem' : '0rem',
            }}
          >
            <Grid
              item
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'
            >
              <Hidden smUp>
                <Grid item>
                  <Grid container direction='column'>
                    <Avatar
                      onClick={() => handleProfileClick(user_id)}
                      src={profile_img}
                      className={classes.postAvatar}
                    />
                    <p className={classes.postAvatarText}>@{username}</p>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item style={{ flexGrow: 1 }}>
                <h2 className={classes.postTitle}>{t(title)}</h2>
              </Grid>
              <Grid item>
                <IconButton
                  style={{ paddingBottom: lgScreen ? 10 : 14 }}
                  onClick={handleOpenMenu}
                >
                  <img src={image.threeDots} alt='' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Menu
            open={openMenu}
            onClose={handleCloseMenu}
            className={classes.menu}
            anchorEl={anchorElement}
            PaperProps={{
              className: classes.menuPaper,
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid item className={classes.listContainer}>
              <Grid item container direction='column'></Grid>
              <MenuItem
                className={classes.menuItem}
                onClick={handleReportVideo}
              >
                {t('report_video')}
              </MenuItem>
            </Grid>
          </Menu>
          <Grid item>
            <div
              className={classes.imageContianer}
              style={{
                overflow: 'hidden',
              }}
            >
              <video
                playsInline
                autoPlay={false}
                loop
                muted={isMuted}
                src={video_url}
                className={classes.postAsset}
                poster={cover}
                ref={videoRef}
                onClick={handlePlayPauseVideo}
              ></video>
              {caption && (
                <>
                  <div
                    className='videoCaptionBtn'
                    onClick={() => setShowCaption(!showCaption)}
                  >
                    <img src={image.videoCaption} alt='' />
                  </div>
                  {showCaption && (
                    <div className='videoCaptionOverly'>
                      <h2
                        className='caption'
                        onClick={() => setShowCaption(false)}
                      >
                        {caption}
                      </h2>
                    </div>
                  )}
                </>
              )}

              <div className={classes.iconContainer}>
                <Grid
                  container
                  className={classes.icons}
                  justifyContent='space-between'
                  alignItems='flex-end'
                >
                  <Grid item>
                    <IconButton
                      style={{ zIndex: 2 }}
                      onClick={() => dispatch(toggleMute())}
                    >
                      <img
                        src={isMuted ? image.mute : image.unMute}
                        className={classes.muteIcon}
                        alt=''
                      />
                    </IconButton>
                  </Grid>
                  {(superLiked || !isLiked) && (
                    <Grid item>
                      <IconButton onClick={handleSuperLike}>
                        <img
                          src={
                            superLiked ? image.superLikeFilled : image.superlike
                          }
                          className={
                            superLiked
                              ? classes.superLikeActiveIcon
                              : classes.superLikeIcon
                          }
                          alt=''
                        />
                      </IconButton>
                    </Grid>
                  )}
                  {(isLiked || !superLiked) && (
                    <Grid item>
                      <IconButton onClick={handleLike}>
                        {isLiked ? (
                          <Favorite
                            style={{ color: '#FE858C' }}
                            className={classes.likeIcon}
                          />
                        ) : (
                          <FavoriteBorder className={classes.likeIcon} />
                        )}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <MatchDialog
          handleDate={handleDate}
          handleQuickMessage={handleQuickMessage}
          fromImg={matchData.liked_by_profile_image}
          toImg={matchData.liked_to_profile_image}
          open={openDialog}
          setOpen={setOpenDialog}
        />
        <QuickMessageDialog
          open={quickMessage}
          setOpen={setQuickMessage}
          username={username}
          setQuickMessage={setQuickMessageValue}
          sendMessage={handleSendQuickMessage}
        />
        <DateScheduler
          userId={user_id}
          username={username}
          open={date}
          setOpen={setDate}
        />
        <InstantSpark open={openSuperDialog} setOpen={setOpenSuperDialog} />
        <ReportVideoDialog
          open={reportDialog}
          setOpen={setReportDialog}
          video_id={video_id}
        />
      </Grid>
    );
  }
);
