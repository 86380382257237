import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from './styles';
import { useTransition, animated } from 'react-spring';
import { Grid, IconButton, useMediaQuery } from '@material-ui/core';
import image from '../../assets/index';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMute } from '../../store/videoSound';
import InstantSpark from '../InstantSpark/InstantSpark';
import MatchDialog from '../MatchDialog/MatchDialog';
import QuickMessageDialog from '../quickMessageDialog/QuickMessageDialog';
import DateScheduler from '../dateSchedular/DateScheduler';
import { onMessage } from '../../utils/firestoreFunctions';
import { SocketContext } from '../../http/socket';
import { db } from '../../firebaseInit';
import ApplicationContext from '../../utils/contextApi';

export const MeetPost = ({ allVideos, page, setPage, totalPage }) => {
  const {
    index,
    setIndex,
    animate,
    setAnimate,
    username,
    chatId,
    openMatch,
    setOpenMatch,
    matchData,
  } = useContext(ApplicationContext);
  const classes = useStyles();
  const socket = useContext(SocketContext);
  const [openSuperDialog, setOpenSuperDialog] = useState(false);
  const isMuted = useSelector(state => state.video.muted);
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(totalPage);
  const [date, setDate] = useState(false);
  const [quickMessage, setQuickMessage] = useState(false);
  const [quickMessageValue, setQuickMessageValue] = useState('');
  // eslint-disable-next-line
  const [videos, setVideos] = useState(allVideos);
  const transition = useTransition(animate, {
    from: { x: 50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
  });
  const handleQuickMessage = () => {
    setOpenMatch(false);
    setQuickMessage(true);
  };
  const handleDate = () => {
    setOpenMatch(false);
    setDate(true);
  };
  const handleContinue = () => {
    if (index === videos.length - 1 && page < totalPages) {
      setPage(page + 1);
      setAnimate(!animate);
      setIndex(0);
    } else if (index === videos.length - 1 && page === totalPages) {
      return;
    } else {
      setAnimate(!animate);
      setIndex(index + 1);
    }
  };
  const handleSendMessage = () => {
    if (quickMessageValue === '') {
      return;
    } else {
      const doc = {
        content: quickMessageValue,
        filename: '',
        idFrom: matchData.liked_by,
        idTo: matchData.liked_to,
        thumb: 0,
        type: 0,
      };
      setQuickMessage(false);
      let messages = [];
      db.collection('messages')
        .doc(chatId)
        .collection(chatId)
        .onSnapshot(snapshot => {
          snapshot.docs.forEach(doc => messages.push(doc.data()));
        });
      onMessage(
        doc,
        chatId,
        socket,
        matchData.liked_by,
        messages.length === 0 ? matchData.liked_by : matchData.liked_to
      );
      handleContinue();
    }
  };

  useEffect(() => {
    if (videos.length !== 0) setAnimate(true);
  }, [index]);
  return (
    <>
      {transition(
        (style, item) =>
          item && (
            <animated.div style={style}>
              <Grid
                item
                container
                alignItems='flex-start'
                className={classes.postContainer}
              >
                <Grid item container direction='column'>
                  <Grid item>
                    <h2 className={classes.postTitle}>
                      {videos.length ? videos[index]?.video_title : ''}
                    </h2>
                  </Grid>
                  <Grid item className={classes.postAssetContainer}>
                    <video
                      playsInline
                      autoPlay
                      loop
                      poster={
                        `${process.env.REACT_APP_API_URL}/${videos[index]?.cover}`
                        // videos.length !== 0
                        //   ? `${process.env.REACT_APP_API_URL}/${videos[index].cover}`
                        //   : ''
                      }
                      muted={isMuted}
                      src={
                        videos.length !== 0
                          ? `${process.env.REACT_APP_API_URL}/${videos[index].video_url}`
                          : ''
                      }
                      className={classes.postAsset}
                    ></video>
                    <div className={classes.iconContainer}>
                      <Grid
                        container
                        className={classes.icons}
                        justifyContent='space-between'
                        alignItems='flex-end'
                      >
                        <Grid item>
                          <IconButton
                            style={{ zIndex: 2 }}
                            onClick={() => dispatch(toggleMute())}
                          >
                            <img
                              src={isMuted ? image.mute : image.unMute}
                              className={classes.muteIcon}
                              alt=''
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </animated.div>
          )
      )}
      <MatchDialog
        open={openMatch}
        setOpen={setOpenMatch}
        handleDate={handleDate}
        handleQuickMessage={handleQuickMessage}
        fromImg={matchData.liked_by_profile_image}
        toImg={matchData.liked_to_profile_image}
        onContinue={handleContinue}
      />
      <QuickMessageDialog
        open={quickMessage}
        setOpen={setQuickMessage}
        setQuickMessage={setQuickMessageValue}
        username={username}
        sendMessage={handleSendMessage}
      />
      <DateScheduler
        username={matchData.liked_to_name}
        userId={matchData.liked_to}
        open={date}
        setOpen={setDate}
        onContinue={handleContinue}
      />
      <InstantSpark
        open={openSuperDialog}
        setOpen={setOpenSuperDialog}
        onContinue={handleContinue}
      />
    </>
  );
};
