import React, { useState, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import TabPanel from './TabPanel';
import BlockedUsers from '../settingsTabs/BlockedUsers';
import Reported from '../settingsTabs/Reported';
import Languages from '../settingsTabs/Languages';
import EmailNotification from '../settingsTabs/EmailNotification';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import UpdateProfile from '../settingsTabs/UpdateProfile';
import Confirmation from '../../modals/Confirmation';
import { SocketContext } from '../../http/socket';

const Settings = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const socket = useContext(SocketContext);

  const [logout, setLogout] = useState(false);

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabs = (e, tab) => {
    setTab(tab);
  };

  const handleLogout = () => {
    console.log(socket);
    socket.emit('disconnect');
    localStorage.clear();
    history.replace('/');
    window.location.reload();
  };

  return (
    <Grid container style={{ height: '100%', overflow: 'hidden' }}>
      <Grid item sm={12} md={3} className={classes.left}>
        <Grid container direction='column'>
          <Typography variant='h2' className={classes.title}>
            Account Settings
          </Typography>
          <Tabs
            orientation={smScreen ? 'horizontal' : 'vertical'}
            value={tab}
            onChange={handleTabs}
            className={classes.tabs}
            variant='scrollable'
            classes={{
              scrollable: classes.scrollable,
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Edit Profile</p>}
            />
            <Tab
              disabled
              disableRipple
              className={classes.tab}
              label={<p>Your Subscriptions</p>}
            />
            <Tab
              disableRipple
              disabled
              className={classes.tab}
              label={<p>Tokens</p>}
            />

            <Tab
              disableRipple
              disabled
              className={classes.tab}
              label={<p>Upgrade</p>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Languages</p>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Blocked Users</p>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Reported</p>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Email Notifications</p>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Help Center</p>}
              component={Link}
              to='/helpcenter'
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<p>Logout</p>}
              onClick={() => {
                setLogout(true);
                setTab(tab);
              }}
            />
            <Tab
              disableRipple
              className={classes.tab}
              disabled
              label={<p>Deactivate Account</p>}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={9} className={classes.right}>
        <Grid
          container
          direction='column'
          style={{
            height: '100%',
            flexGrow: '1',
            overflow: smScreen ? 'none' : 'auto',
          }}
        >
          <TabPanel index={0} tab={tab}>
            <UpdateProfile />
          </TabPanel>
          <TabPanel index={4} tab={tab}>
            <Languages />
          </TabPanel>
          <TabPanel index={5} tab={tab}>
            <BlockedUsers />
          </TabPanel>
          <TabPanel index={6} tab={tab}>
            <Reported />
          </TabPanel>
          <TabPanel index={7} tab={tab}>
            <EmailNotification />
          </TabPanel>
        </Grid>
      </Grid>
      <Confirmation
        title='Log out'
        description='Are you sure you want to logout?'
        open={logout}
        setOpen={setLogout}
        action={handleLogout}
      />
    </Grid>
  );
};

export default Settings;

const useStyles = makeStyles(theme => ({
  left: {
    paddingBlock: '32px',
    paddingInline: '40px 10px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '20px',
      paddingInline: '25px 10px',
    },
  },
  title: {
    fontSize: '39px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '27px',
    },
  },
  tabs: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      '&.MuiTabs-scrollable': {
        width: 'calc(100vw - 80px)',
      },
    },
  },
  scrollable: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      width: 'calc(100vw - 80px)',
    },
  },
  tab: {
    padding: '0px',
    marginTop: '25px',
    fontSize: '28px',
    fontWeight: '400',
    minWidth: '100%',
    textTransform: 'none',
    opacity: '1',
    '& p': {
      textAlign: 'left',
      width: '100%',
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '0px',
      marginRight: '15px',
    },
  },
  right: {
    height: '100%',
    position: 'relative',
    background: '#FBFBFB',
    boxShadow: `inset ${theme.shadows[3]}`,
    borderRadius: '0px 54px 54px 0px',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '0px 34px 34px 0px',
      paddingBlock: '25px 15px',
      // paddingInline: '40px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      overflow: 'auto',
      paddingBottom: '100px',
      paddingBlock: '0px',
      boxShadow: 'none',
      background: '#ffffff',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      maxHeight: '50%',
      height: '50%',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-button ': {
      height: '30px', //for vertical scrollbar
    },
  },
}));
