import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction as Action,
  ListItemAvatar,
  Avatar,
  Grid,
  Button,
  List,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { submit } from '../../store/user';
import assets from '../../assets';

import { reportAndBlockedUsers, unblockUser, getUser } from '../../http';

const BlockedUsers = () => {
  const classes = useStyles();

  const [blockedUsers, setBlockedUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUnblock = async id => {
    try {
      await unblockUser(id);
      const _blockedUsers = blockedUsers.filter(user => user.block_id !== id);
      setBlockedUsers([..._blockedUsers]);
      const { data } = await getUser();
      dispatch(submit(data));
    } catch (err) {
      console.log(err.message);
      alert('something went wrong!');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await reportAndBlockedUsers();
        setBlockedUsers(data.data);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container direction='column' className={classes.mainContainer}>
      {!blockedUsers?.length && !loading && (
        <div className={classes.notFound}>
          <img src={assets.find} alt='' />
          <p>No blocked users found</p>
        </div>
      )}
      {loading && (
        <div className={classes.notFound}>
          <CircularProgress color='primary' />
        </div>
      )}
      <List className={classes.list}>
        {blockedUsers?.map((user, index) => (
          <ListItem className={classes.listItem} key={index}>
            <ListItemAvatar>
              <Avatar
                className={classes.img}
                src={`${process.env.REACT_APP_API_URL}/${user?.profile_image}`}
              />
            </ListItemAvatar>
            <ListItemText
              className={classes.text}
              primary={user?.user_first_name}
            />
            <Action>
              <Button
                className={classes.button}
                onClick={() => handleUnblock(user.block_id)}
                variant='contained'
              >
                Unblock
              </Button>
            </Action>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default BlockedUsers;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingInline: '82px 0px',
    paddingBlock: '45px 27px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '25px 15px',
      paddingInline: '40px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '0px 15px',
      paddingInline: '20px 0px',
    },
  },
  list: {
    width: '70%',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      marginTop: '10px',
      paddingBottom: '50px',
    },
  },
  listItem: {
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
    paddingBlock: '15px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '10px',
    },
  },
  text: {
    '& .MuiListItemText-primary': {
      margin: '0',
      fontSize: '16px',
      color: theme.palette.primary.main,
      textAlign: 'left',
      marginLeft: '25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        marginLeft: '15px',
      },
    },
  },
  img: {
    height: '65px',
    width: '65px',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      width: '50px',
    },
  },
  button: {
    background: '#FE858C',
    height: '52px',
    width: '125px',
    borderRadius: '26px',
    color: '#FFF2EB',
    textTransform: 'none',
    fontSize: '14px',
    '&:hover': {
      background: '#FE858C',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      width: '95px',
      fontSize: '12px',
    },
  },
  notFound: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gridGap: '14px',
    [theme.breakpoints.down('lg')]: {
      gridGap: '8px',
    },
    '& img': {
      width: '308px',
      aspectRatio: '1/1',
      [theme.breakpoints.down('lg')]: {
        width: '200px',
      },
    },
    '& p': {
      color: '#929292',
      fontSize: '25px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },
}));
