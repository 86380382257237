import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import { Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import Joi from 'joi-browser';
import { heightApi } from '../../../http';

import { Header } from '../../../components/header/Header';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { heightList } from '../../../app.config';
import { useTranslation } from 'react-i18next';

import image from '../../../assets/index';
export const Height = ({ history }) => {
  const headerItems = [];
  const [t] = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const userData = useSelector(state => state.auth.user.data);

  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState({
    height: '0',
  });
  const [show] = useState({
    height: true,
  });
  const [errors, setErrors] = useState({
    height: '',
  });
  const schema = {
    height: Joi.string().min(2).required().label('Height'),
  };

  const toFeet = cm => {
    const realFeets = (cm * 0.3937) / 12;
    let feets = Math.floor(realFeets);
    let inches = Math.round((realFeets - feets) * 12);
    if (inches === 12) {
      feets = feets + 1;
      inches = 0;
    }
    return `${feets}'${inches}" ( ${cm}cm )`;
  };

  // const newHeight = heightList.map(e => toFeet(e));

  const validate = () => {
    const data = {
      height: formData.height,
    };
    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) {
      setErrors({
        height: '',
      });
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details)
        errorsObject[item.path[0]] = `"${
          item.path[0].charAt(0).toUpperCase() + item.path[0].slice(1)
        }" can not be empty`;
      setErrors(errorsObject);
      return true;
    }
  };

  const handleSelect = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, subSchema);
    const it = error
      ? setErrors({
          ...errors,
          [name]: `"${
            name.charAt(0).toUpperCase() + name.slice(1)
          }" can not be empty`,
        })
      : setErrors({ ...errors, [name]: '' });
  };

  const handleNext = async () => {
    const error = validate();
    if (!error) {
      try {
        const heightInCM = formData.height;
        const heightData = {
          height: heightInCM,
          visible: show.height,
          step: userData.step === '/height' ? '/location' : userData.step,
        };
        const { data } = await heightApi(heightData);
        dispatch(submit(data));
        history.push('/register/location');
      } catch (err) {
        alert('something went wrong');
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    if (formData.height.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formData]);

  useEffect(() => {
    setFormData({
      height: userData?.height?.height || '0',
    });
  }, [userData]);
  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.comb})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${180}px ${180 * 1.18}px`
              : `${200}px ${200 * 1.18}px`
            : '250px 295px'
          : '',
      }}
    >
      <Header transparent headerItems={headerItems} />
      {/* <Header transparent /> */}
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Typography
          variant='h1'
          className={classes.formTitle}
          style={{ margin: '0px' }}
        >
          {t('height')}
        </Typography>
        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid
            container
            direction='column'
            className={classes.formContainer}
            spacing={2}
          >
            <Grid item className='content'>
              <Grid
                container
                direction='column'
                style={{ gap: lgScreen ? '80px' : '124px' }}
              >
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('height')}
                    height={true}
                    options={heightList}
                    placeholder={`6'2" (188cm)`}
                    name='height'
                    onSelect={handleSelect}
                    value={formData.height}
                    error={Boolean(errors.height)}
                    errorText={errors.height}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
        >
          <ButtonComp
            disabled={disabled}
            onClick={handleNext}
            label={t('continue')}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
