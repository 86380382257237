import React, { useState, useContext } from 'react';
import { makeStyles, Grid, Typography, IconButton } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMute } from '../../store/videoSound';
import image from '../../assets';
import { likeVideo, superLikeApi } from '../../http';
import { db } from '../../firebaseInit';
import { SocketContext } from '../../http/socket';
import MatchDialog from '../MatchDialog/MatchDialog';
import QuickMessageDialog from '../quickMessageDialog/QuickMessageDialog';
import DateScheduler from '../dateSchedular/DateScheduler';
import { onMessage } from '../../utils/firestoreFunctions';

export const Video = React.forwardRef(
  (
    {
      video_title,
      video_url,
      like,
      video_id,
      match,
      poster,
      superLike,
      username,
      user_id,
    },
    ref
  ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const mutedState = useSelector(state => state.video.muted);
    const currentUser = useSelector(state => state.auth.user.data);
    const [openDialog, setOpenDialog] = useState(false);
    const [quickMessage, setQuickMessage] = useState(false);
    const [date, setDate] = useState(false);
    const [isLiked, setIsLiked] = useState(like);
    const [chatId, setChatId] = useState('');
    const [matchData, setMatchData] = useState({
      liked_by: '',
      liked_by_name: '',
      liked_by_profile_image: '',
      liked_to: '',
      liked_to_name: '',
      liked_to_profile_image: '',
    });
    const [superLiked, setSuperLiked] = useState(superLike);
    const [quickMessageValue, setQuickMessageValue] = useState('');

    const handleSuperLike = async () => {
      await superLikeApi({ video_id });
      setSuperLiked(!superLiked);
    };
    const handleLike = async () => {
      setIsLiked(!isLiked);
      const { data } = await likeVideo({ video_id });
      setMatchData(data.data);
      if (data.matched) {
        setChatId(data.chatId);
        setOpenDialog(data.matched);
      }
    };

    const handleQuickMessage = () => {
      setOpenDialog(false);
      setQuickMessage(true);
    };
    const handleDate = () => {
      setOpenDialog(false);
      setDate(true);
    };
    const handleSendQuickMessage = () => {
      if (quickMessageValue === '') {
        return;
      } else {
        const doc = {
          content: quickMessageValue,
          filename: '',
          idFrom: currentUser._id,
          idTo: user_id,
          thumb: 0,
          type: 0,
        };
        setQuickMessage(false);
        let messages = [];
        db.collection('messages')
          .doc(chatId)
          .collection(chatId)
          .onSnapshot(snapshot => {
            snapshot.docs.forEach(doc => messages.push(doc.data()));
          });
        onMessage(
          doc,
          chatId,
          socket,
          currentUser._id,
          messages.length === 0 ? currentUser._id : user_id
        );
      }
    };
    return (
      <>
        <Grid
          item
          container
          className={classes.post}
          direction='column'
          alignItems='center'
        >
          <Grid item>
            <Typography
              style={{ textAlign: 'center' }}
              className={classes.postTitle}
            >
              {video_title}
            </Typography>
            <div className={classes.postContainer}>
              <video
                ref={ref}
                playsInline
                loop
                muted={mutedState}
                className={classes.video}
                poster={`${process.env.REACT_APP_API_URL}/${poster}`}
                src={`${process.env.REACT_APP_API_URL}/${video_url}`}
              ></video>
              <div className={classes.iconContainer}>
                <Grid
                  container
                  className={classes.icons}
                  justifyContent='space-between'
                  alignItems='flex-end'
                >
                  <Grid item>
                    <IconButton onClick={() => dispatch(toggleMute())}>
                      <img
                        src={mutedState ? image.mute : image.unMute}
                        className={classes.muteIcon}
                        alt=''
                      />
                    </IconButton>
                  </Grid>
                  {/* {match ? ( */}
                  <Grid item>
                    <IconButton onClick={handleSuperLike}>
                      <img
                        className={classes.superlike}
                        src={superLiked ? image.sparkActive : image.superlike}
                        alt=''
                      />
                    </IconButton>
                  </Grid>
                  {/* ) : undefined} */}
                  <Grid item>
                    <IconButton onClick={handleLike}>
                      <Favorite
                        style={{ transition: '0.5s ease' }}
                        className={
                          isLiked ? classes.likeFilled : classes.likeIcon
                        }
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <MatchDialog
          handleDate={handleDate}
          handleQuickMessage={handleQuickMessage}
          fromImg={matchData.liked_by_profile_image}
          toImg={matchData.liked_to_profile_image}
          open={openDialog}
          setOpen={setOpenDialog}
        />
        <QuickMessageDialog
          open={quickMessage}
          setOpen={setQuickMessage}
          username={username}
          setQuickMessage={setQuickMessageValue}
          sendMessage={handleSendQuickMessage}
        />
        <DateScheduler open={date} setOpen={setDate} />
      </>
    );
  }
);
const useStyles = makeStyles(theme => ({
  postTitle: {
    color: '#000',
    margin: '0',
    fontSize: '20px',
    textAlign: 'left',
    width: '100%',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  postContainer: {
    width: '429px',
    height: '503px',
    borderRadius: '44px',
    position: 'relative',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('lg')]: {
      width: '330px',
      height: '380px',
      borderRadius: '33px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '450px',
      borderRadius: '33px',
    },
  },
  iconContainer: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 15,
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      bottom: 5,
    },
  },
  icons: {
    height: '80px',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '0.25rem',
      padding: '0',
      height: '50px',
    },
  },
  likeIcon: {
    color: '#fbfbfb',
    fontSize: '2.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.8rem',
    },
  },
  muteIcon: {
    maxWidth: '30px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '20px',
    },
  },
  post: {
    marginTop: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
    },
  },
  likeFilled: {
    fontSize: '2.3rem',
    color: 'red',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  },
  superlike: {
    width: '1.3rem',
    transition: '0.6s ease',
    [theme.breakpoints.down('lg')]: {
      width: '1rem',
    },
  },
}));
