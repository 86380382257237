export const homeTownList = [
  'Alone',
  'Student Accommodation',
  'Traveling',
  'With Roommates',
  'With Family',
  'Prefer Not To Say',
];

export const schoolDegreeList = [
  'No Degree',
  'High School',
  'Trade/Specialty School',
  'Associates',
  "Bachelor's",
  'Graduate',
  'Masters',
  'Doctorate',
  'Prefer Not To Say',
];

export const religionList = [
  'Agnostic',
  'Atheist',
  'Buddhist',
  'Catholic',
  'Christian',
  'Hindu',
  'Jewish',
  'Muslim',
  'Sikh',
  'Spiritual',
  'Non-Religious',
  'Other',
  'Prefer Not To Say',
];

export const politicsList = [
  'Not Political',
  'Liberal',
  'Conservative',
  'Moderate',
  'Other',
  'Prefer Not To Say',
];

export const dietList = [
  'Carnivore',
  'Everything',
  'Gluten Free',
  'Healthy',
  'Islamic',
  'Kosher',
  'Lactose Free',
  'Muscle Building',
  'Pescatarian',
  'Vegan',
  'Vegetarian',
  'Prefer Not To Say',
];

export const fitnessList = [
  'Never',
  'Once a week',
  '2-4 times a week',
  '5+ times a week',
  'Prefer Not To Say',
];

export const bodyTypeList = [
  'Average',
  'Athletic',
  'Muscular',
  'Thin',
  'Curvy',
  'Big & Bold',
  'Prefer Not To Say',
];

export const petList = [
  'Pet-free',
  'Dog',
  'Cat',
  'Bird',
  'Fish',
  'Rabbit',
  'Hamster',
  'Turtle',
  'Horse',
  'Snake',
  'Other',
  'Multiple',
  'Prefer Not To Say',
];

export const maritalStatusList = [
  'Single',
  'Divorced',
  'Separated',
  'Married',
  'Living together',
  'Widowed',
  'Not Looking',
];

export const vicesList = ['Often', 'Socially', 'No', 'Prefer Not To Say'];

export const haveChildList = ['Yes', 'No', 'Prefer Not To Say'];

export const wantChildList = ['Yes', 'No', 'Open', 'Prefer Not To Say'];

export const pronounsList = [
  'She',
  'Her',
  'Hers',
  'He',
  'Him',
  'His',
  'They',
  'Them',
  'Theirs',
  'Ve',
  'Ver',
  'Vis',
  'Ze',
  'Hir',
  'Hirs',
  'Prefer Not To Say',
];

export const occupationList = [
  'Architecture and Construction',
  'Arts, Design, Entertainment, Sports, and Media',
  'Building and Grounds Cleaning and Maintenance',
  'Business and Finance',
  'Community and Social Services',
  'Science, Technology, Engineering\n and Mathematics',
  'Construction and Extraction',
  'Education, Training, and Library,\n',
  'Farming, Fishing and Forestry',
  'Food Preparation and Serving Related',
  'Healthcare Practitioners and Technical',
  'Healthcare Support',
  'Installation, Maintenance and Repair',
  'Legal',
  'Life, Physical and Social Science',
  'Business Management and Administration',
  'Military',
  'Office and Administrative Support',
  'Government and Public Administration',
  'Transportation. Distribution and Logistics',
  'Personal Care and Services',
  'Protective and Security Service',
  'Marketing, Sales, and Service',
];

export const heightList = [
  91.44, 94.488, 97.536, 99.568, 100.584, 103.632, 106.68, 109.728, 112.776,
  114.3, 115.824, 118.872, 121.92, 124.968, 127.0, 129.5, 132.1, 134.6, 137.2,
  139.7, 142.2, 145.0, 147.0, 150.0, 152.4, 155.0, 158.0, 160.0, 163.0, 165.0,
  168.0, 170.0, 173.0, 175.0, 178.0, 180.0, 183.0, 185.0, 188.0, 191.0, 193.0,
  195.0, 198.0, 201.0, 203.0, 205.74, 208.28, 210.82, 213.36,
];

export const datePrefList = [
  'Anything',
  'Meet Someone New',
  'Friends',
  'Something Casual',
  'Relationship',
  'Marriage',
];

export const sexualityList = [
  'Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Allosexual',
  'Androsexual',
  'Asexual',
  'Autosexual',
  'Bicurious',
  'Demisexual',
  'Fluid',
  'Graysexual',
  'Gynesexual',
  'Monosexual',
  'Omnisexual',
  'Pansexual',
  'Polysexual',
  'Queer',
  'Skoliosexual',
  'Spectrasexual',
  'Prefer not to say',
];

export const getEthnicityList = [
  'Asian',
  'Black/African',
  'Hispanic/Latin',
  'Indigenous/Aboriginal',
  'Indian',
  'Middle Eastern',
  'Pacific Islander',
  'Two or More Races',
  'White/Caucasian',
  'Other',
  'Prefer Not To Say',
];

export const genderList = [
  'Male',
  'Female',
  'Agender',
  'Androgyne',
  'Androgynous',
  'Bigender',
  'Cis',
  'Cisgender',
  'Cis Female',
  'Cis Male',
  'Cis Man',
  'Cis Woman',
  'Cisgender Female',
  'Cisgender Male',
  'Cisgender Man',
  'Cisgender Woman',
  'Female to Male',
  'FTM',
  'Gender Fluid',
  'Gender Nonconforming',
  'Gender Questioning',
  'Gender Variant',
  'Genderqueer',
  'Intersex',
  'Male to Female',
  'MTF',
  'Neither',
  'Neutrois',
  'Non-binary',
  'Pangender',
  'Trans',
  'Trans*',
  'Trans Female',
  'Trans* Female',
  'Trans Male',
  'Trans* Male',
  'Trans Man',
  'Trans* Man',
  'Trans Person',
  'Trans* Person',
  'Trans Woman',
  'Trans* Woman',
  'Trans feminine',
  'Transgender',
  'Transgender Female',
  'Transgender Male',
  'Transgender Man',
  'Transgender Person',
  'Transgender Woman',
  'Trans masculine',
  'Transsexual',
  'Transsexual Female',
  'Transsexual Male',
  'Transsexual Man',
  'Transsexual Person',
  'Transsexual Woman',
  'Two-Spirit',
  'Other',
];

export const countryList = [
  { image: require('./assets/flags/af.png').default, name: 'Afghanistan' },
  { image: require('./assets/flags/ax.png').default, name: 'Aland Islands' },
  { image: require('./assets/flags/al.png').default, name: 'Albania' },
  { image: require('./assets/flags/dz.png').default, name: 'Algeria' },
  { image: require('./assets/flags/as.png').default, name: 'AmericanSamoa' },
  { image: require('./assets/flags/ad.png').default, name: 'Andorra' },
  { image: require('./assets/flags/ao.png').default, name: 'Angola' },
  { image: require('./assets/flags/ai.png').default, name: 'Anguilla' },
  { image: require('./assets/flags/aq.png').default, name: 'Antarctica' },
  {
    image: require('./assets/flags/ag.png').default,
    name: 'Antigua and Barbuda',
  },
  { image: require('./assets/flags/ar.png').default, name: 'Argentina' },
  { image: require('./assets/flags/am.png').default, name: 'Armenia' },
  { image: require('./assets/flags/aw.png').default, name: 'Aruba' },
  { image: require('./assets/flags/au.png').default, name: 'Australia' },
  { image: require('./assets/flags/at.png').default, name: 'Austria' },
  { image: require('./assets/flags/az.png').default, name: 'Azerbaijan' },
  { image: require('./assets/flags/bs.png').default, name: 'Bahamas' },
  { image: require('./assets/flags/bh.png').default, name: 'Bahrain' },
  { image: require('./assets/flags/bd.png').default, name: 'Bangladesh' },
  { image: require('./assets/flags/bb.png').default, name: 'Barbados' },
  { image: require('./assets/flags/by.png').default, name: 'Belarus' },
  { image: require('./assets/flags/be.png').default, name: 'Belgium' },
  { image: require('./assets/flags/bz.png').default, name: 'Belize' },
  { image: require('./assets/flags/bj.png').default, name: 'Benin' },
  { image: require('./assets/flags/bm.png').default, name: 'Bermuda' },
  { image: require('./assets/flags/bt.png').default, name: 'Bhutan' },
  {
    image: require('./assets/flags/bo.png').default,
    name: 'Bolivia, Plurinational State of',
  },
  {
    image: require('./assets/flags/ba.png').default,
    name: 'Bosnia and Herzegovina',
  },
  { image: require('./assets/flags/bw.png').default, name: 'Botswana' },
  { image: require('./assets/flags/br.png').default, name: 'Brazil' },
  {
    image: require('./assets/flags/io.png').default,
    name: 'British Indian Ocean Territory',
  },
  {
    image: require('./assets/flags/bn.png').default,
    name: 'Brunei Darussalam',
  },
  { image: require('./assets/flags/bg.png').default, name: 'Bulgaria' },
  { image: require('./assets/flags/bf.png').default, name: 'Burkina Faso' },
  { image: require('./assets/flags/bi.png').default, name: 'Burundi' },
  { image: require('./assets/flags/kh.png').default, name: 'Cambodia' },
  { image: require('./assets/flags/cm.png').default, name: 'Cameroon' },
  { image: require('./assets/flags/ca.png').default, name: 'Canada' },
  { image: require('./assets/flags/cv.png').default, name: 'Cape Verde' },
  { image: require('./assets/flags/ky.png').default, name: 'Cayman Islands' },
  {
    image: require('./assets/flags/cf.png').default,
    name: 'Central African Republic',
  },
  { image: require('./assets/flags/td.png').default, name: 'Chad' },
  { image: require('./assets/flags/cl.png').default, name: 'Chile' },
  { image: require('./assets/flags/cn.png').default, name: 'China' },
  { image: require('./assets/flags/cx.png').default, name: 'Christmas Island' },
  {
    image: require('./assets/flags/cc.png').default,
    name: 'Cocos {Keeling} Islands',
  },
  { image: require('./assets/flags/co.png').default, name: 'Colombia' },
  { image: require('./assets/flags/km.png').default, name: 'Comoros' },
  { image: require('./assets/flags/cg.png').default, name: 'Congo' },
  {
    image: require('./assets/flags/cd.png').default,
    name: 'Congo',
  },
  { image: require('./assets/flags/ck.png').default, name: 'Cook Islands' },
  { image: require('./assets/flags/cr.png').default, name: 'Costa Rica' },
  { image: require('./assets/flags/ci.png').default, name: "Cote d'Ivoire" },
  { image: require('./assets/flags/hr.png').default, name: 'Croatia' },
  { image: require('./assets/flags/cu.png').default, name: 'Cuba' },
  { image: require('./assets/flags/cy.png').default, name: 'Cyprus' },
  { image: require('./assets/flags/cz.png').default, name: 'Czech Republic' },
  { image: require('./assets/flags/dk.png').default, name: 'Denmark' },
  { image: require('./assets/flags/dj.png').default, name: 'Djibouti' },
  { image: require('./assets/flags/dm.png').default, name: 'Dominica' },
  {
    image: require('./assets/flags/do.png').default,
    name: 'Dominican Republic',
  },
  { image: require('./assets/flags/ec.png').default, name: 'Ecuador' },
  { image: require('./assets/flags/eg.png').default, name: 'Egypt' },
  { image: require('./assets/flags/sv.png').default, name: 'El Salvador' },
  {
    image: require('./assets/flags/gq.png').default,
    name: 'Equatorial Guinea',
  },
  { image: require('./assets/flags/er.png').default, name: 'Eritrea' },
  { image: require('./assets/flags/ee.png').default, name: 'Estonia' },
  { image: require('./assets/flags/et.png').default, name: 'Ethiopia' },
  {
    image: require('./assets/flags/fk.png').default,
    name: 'Falkland Islands {Malvinas}',
  },
  { image: require('./assets/flags/fo.png').default, name: 'Faroe Islands' },
  { image: require('./assets/flags/fj.png').default, name: 'Fiji' },
  { image: require('./assets/flags/fi.png').default, name: 'Finland' },
  { image: require('./assets/flags/fr.png').default, name: 'France' },
  { image: require('./assets/flags/gf.png').default, name: 'French Guiana' },
  { image: require('./assets/flags/pf.png').default, name: 'French Polynesia' },
  { image: require('./assets/flags/ga.png').default, name: 'Gabon' },
  { image: require('./assets/flags/gm.png').default, name: 'Gambia' },
  { image: require('./assets/flags/ge.png').default, name: 'Georgia' },
  { image: require('./assets/flags/de.png').default, name: 'Germany' },
  { image: require('./assets/flags/gh.png').default, name: 'Ghana' },
  { image: require('./assets/flags/gi.png').default, name: 'Gibraltar' },
  { image: require('./assets/flags/gr.png').default, name: 'Greece' },
  { image: require('./assets/flags/gl.png').default, name: 'Greenland' },
  { image: require('./assets/flags/gd.png').default, name: 'Grenada' },
  { image: require('./assets/flags/gp.png').default, name: 'Guadeloupe' },
  { image: require('./assets/flags/gu.png').default, name: 'Guam' },
  { image: require('./assets/flags/gt.png').default, name: 'Guatemala' },
  { image: require('./assets/flags/gg.png').default, name: 'Guernsey' },
  { image: require('./assets/flags/gn.png').default, name: 'Guinea' },
  { image: require('./assets/flags/gw.png').default, name: 'Guinea-Bissau' },
  { image: require('./assets/flags/gy.png').default, name: 'Guyana' },
  { image: require('./assets/flags/ht.png').default, name: 'Haiti' },
  {
    image: require('./assets/flags/va.png').default,
    name: 'Holy See {Vatican City State}',
  },
  { image: require('./assets/flags/hn.png').default, name: 'Honduras' },
  { image: require('./assets/flags/hk.png').default, name: 'Hong Kong' },
  { image: require('./assets/flags/hu.png').default, name: 'Hungary' },
  { image: require('./assets/flags/is.png').default, name: 'Iceland' },
  { image: require('./assets/flags/in.png').default, name: 'India' },
  { image: require('./assets/flags/id.png').default, name: 'Indonesia' },
  {
    image: require('./assets/flags/ir.png').default,
    name: 'Iran',
  },
  { image: require('./assets/flags/iq.png').default, name: 'Iraq' },
  { image: require('./assets/flags/ie.png').default, name: 'Ireland' },
  { image: require('./assets/flags/im.png').default, name: 'Isle of Man' },
  { image: require('./assets/flags/il.png').default, name: 'Israel' },
  { image: require('./assets/flags/it.png').default, name: 'Italy' },
  { image: require('./assets/flags/jm.png').default, name: 'Jamaica' },
  { image: require('./assets/flags/jp.png').default, name: 'Japan' },
  { image: require('./assets/flags/je.png').default, name: 'Jersey' },
  { image: require('./assets/flags/jo.png').default, name: 'Jordan' },
  { image: require('./assets/flags/kz.png').default, name: 'Kazakhstan' },
  { image: require('./assets/flags/ke.png').default, name: 'Kenya' },
  { image: require('./assets/flags/ki.png').default, name: 'Kiribati' },
  {
    image: require('./assets/flags/kp.png').default,
    name: 'Korea',
  },
  {
    image: require('./assets/flags/kr.png').default,
    name: 'South Korea',
  },
  { image: require('./assets/flags/kw.png').default, name: 'Kuwait' },
  { image: require('./assets/flags/kg.png').default, name: 'Kyrgyzstan' },
  { image: require('./assets/flags/la.png').default, name: 'Laos' },
  { image: require('./assets/flags/lv.png').default, name: 'Latvia' },
  { image: require('./assets/flags/lb.png').default, name: 'Lebanon' },
  { image: require('./assets/flags/ls.png').default, name: 'Lesotho' },
  { image: require('./assets/flags/lr.png').default, name: 'Liberia' },
  {
    image: require('./assets/flags/ly.png').default,
    name: 'Libyan Arab Jamahiriya',
  },
  { image: require('./assets/flags/li.png').default, name: 'Liechtenstein' },
  { image: require('./assets/flags/lt.png').default, name: 'Lithuania' },
  { image: require('./assets/flags/lu.png').default, name: 'Luxembourg' },
  { image: require('./assets/flags/mo.png').default, name: 'Macao' },
  { image: require('./assets/flags/mk.png').default, name: 'Macedonia' },
  { image: require('./assets/flags/mg.png').default, name: 'Madagascar' },
  { image: require('./assets/flags/mw.png').default, name: 'Malawi' },
  { image: require('./assets/flags/my.png').default, name: 'Malaysia' },
  { image: require('./assets/flags/mv.png').default, name: 'Maldives' },
  { image: require('./assets/flags/ml.png').default, name: 'Mali' },
  { image: require('./assets/flags/mt.png').default, name: 'Malta' },
  { image: require('./assets/flags/mh.png').default, name: 'Marshall Islands' },
  { image: require('./assets/flags/mq.png').default, name: 'Martinique' },
  { image: require('./assets/flags/mr.png').default, name: 'Mauritania' },
  { image: require('./assets/flags/mu.png').default, name: 'Mauritius' },
  { image: require('./assets/flags/yt.png').default, name: 'Mayotte' },
  { image: require('./assets/flags/mx.png').default, name: 'Mexico' },
  {
    image: require('./assets/flags/fm.png').default,
    name: 'Micronesia',
  },
  { image: require('./assets/flags/md.png').default, name: 'Moldova' },
  { image: require('./assets/flags/mc.png').default, name: 'Monaco' },
  { image: require('./assets/flags/mn.png').default, name: 'Mongolia' },
  { image: require('./assets/flags/me.png').default, name: 'Montenegro' },
  { image: require('./assets/flags/ms.png').default, name: 'Montserrat' },
  { image: require('./assets/flags/ma.png').default, name: 'Morocco' },
  { image: require('./assets/flags/mz.png').default, name: 'Mozambique' },
  { image: require('./assets/flags/mm.png').default, name: 'Myanmar' },
  { image: require('./assets/flags/na.png').default, name: 'Namibia' },
  { image: require('./assets/flags/nr.png').default, name: 'Nauru' },
  { image: require('./assets/flags/np.png').default, name: 'Nepal' },
  { image: require('./assets/flags/nl.png').default, name: 'Netherlands' },
  {
    image: require('./assets/flags/an.png').default,
    name: 'Netherlands Antilles',
  },
  { image: require('./assets/flags/nc.png').default, name: 'New Caledonia' },
  { image: require('./assets/flags/nz.png').default, name: 'New Zealand' },
  { image: require('./assets/flags/ni.png').default, name: 'Nicaragua' },
  { image: require('./assets/flags/ne.png').default, name: 'Niger' },
  { image: require('./assets/flags/ng.png').default, name: 'Nigeria' },
  { image: require('./assets/flags/nu.png').default, name: 'Niue' },
  { image: require('./assets/flags/nf.png').default, name: 'Norfolk Island' },
  {
    image: require('./assets/flags/mp.png').default,
    name: 'Northern Mariana Islands',
  },
  { image: require('./assets/flags/no.png').default, name: 'Norway' },
  { image: require('./assets/flags/om.png').default, name: 'Oman' },
  { image: require('./assets/flags/pk.png').default, name: 'Pakistan' },
  { image: require('./assets/flags/pw.png').default, name: 'Palau' },
  {
    image: require('./assets/flags/ps.png').default,
    name: 'Palestine/ Tanzania',
  },
  { image: require('./assets/flags/pa.png').default, name: 'Panama' },
  { image: require('./assets/flags/pg.png').default, name: 'Papua New Guinea' },
  { image: require('./assets/flags/py.png').default, name: 'Paraguay' },
  { image: require('./assets/flags/pe.png').default, name: 'Peru' },
  { image: require('./assets/flags/ph.png').default, name: 'Philippines' },
  { image: require('./assets/flags/pn.png').default, name: 'Pitcairn' },
  { image: require('./assets/flags/pl.png').default, name: 'Poland' },
  { image: require('./assets/flags/pt.png').default, name: 'Portugal' },
  { image: require('./assets/flags/pr.png').default, name: 'Puerto Rico' },
  { image: require('./assets/flags/qa.png').default, name: 'Qatar' },
  { image: require('./assets/flags/ro.png').default, name: 'Romania' },
  { image: require('./assets/flags/ru.png').default, name: 'Russia' },
  { image: require('./assets/flags/rw.png').default, name: 'Rwanda' },
  { image: require('./assets/flags/re.png').default, name: 'Reunion' },
  { image: require('./assets/flags/bl.png').default, name: 'Saint Barthelemy' },
  {
    image: require('./assets/flags/sh.png').default,
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  {
    image: require('./assets/flags/kn.png').default,
    name: 'Saint Kitts and Nevis',
  },
  { image: require('./assets/flags/lc.png').default, name: 'Saint Lucia' },
  { image: require('./assets/flags/mf.png').default, name: 'Saint Martin' },
  {
    image: require('./assets/flags/pm.png').default,
    name: 'Saint Pierre and Miquelon',
  },
  {
    image: require('./assets/flags/vc.png').default,
    name: 'Saint Vincent and the Grenadines',
  },
  { image: require('./assets/flags/ws.png').default, name: 'Samoa' },
  { image: require('./assets/flags/sm.png').default, name: 'San Marino' },
  {
    image: require('./assets/flags/st.png').default,
    name: 'Sao Tome and Principe',
  },
  { image: require('./assets/flags/sa.png').default, name: 'Saudi Arabia' },
  { image: require('./assets/flags/sn.png').default, name: 'Senegal' },
  { image: require('./assets/flags/rs.png').default, name: 'Serbia' },
  { image: require('./assets/flags/sc.png').default, name: 'Seychelles' },
  { image: require('./assets/flags/sl.png').default, name: 'Sierra Leone' },
  { image: require('./assets/flags/sg.png').default, name: 'Singapore' },
  { image: require('./assets/flags/sk.png').default, name: 'Slovakia' },
  { image: require('./assets/flags/si.png').default, name: 'Slovenia' },
  { image: require('./assets/flags/sb.png').default, name: 'Solomon Islands' },
  { image: require('./assets/flags/so.png').default, name: 'Somalia' },
  { image: require('./assets/flags/za.png').default, name: 'South Africa' },
  { image: require('./assets/flags/ss.png').default, name: 'South Sudan' },
  {
    image: require('./assets/flags/gs.png').default,
    name: 'South Georgia and the South Sandwich Islands',
  },
  { image: require('./assets/flags/es.png').default, name: 'Spain' },
  { image: require('./assets/flags/lk.png').default, name: 'Sri Lanka' },
  { image: require('./assets/flags/sd.png').default, name: 'Sudan' },
  { image: require('./assets/flags/sr.png').default, name: 'Suriname' },
  {
    image: require('./assets/flags/sj.png').default,
    name: 'Svalbard and Jan Mayen',
  },
  { image: require('./assets/flags/sz.png').default, name: 'Swaziland' },
  { image: require('./assets/flags/se.png').default, name: 'Sweden' },
  { image: require('./assets/flags/ch.png').default, name: 'Switzerland' },
  {
    image: require('./assets/flags/sy.png').default,
    name: 'Syrian Arab Republic',
  },
  { image: require('./assets/flags/tw.png').default, name: 'Taiwan' },
  { image: require('./assets/flags/tj.png').default, name: 'Tajikistan' },
  {
    image: require('./assets/flags/tz.png').default,
    name: 'Tanzania',
  },
  { image: require('./assets/flags/th.png').default, name: 'Thailand' },
  { image: require('./assets/flags/tl.png').default, name: 'Timor-Leste' },
  { image: require('./assets/flags/tg.png').default, name: 'Togo' },
  { image: require('./assets/flags/tk.png').default, name: 'Tokelau' },
  { image: require('./assets/flags/to.png').default, name: 'Tonga' },
  {
    image: require('./assets/flags/tt.png').default,
    name: 'Trinidad and Tobago',
  },
  { image: require('./assets/flags/tn.png').default, name: 'Tunisia' },
  { image: require('./assets/flags/tr.png').default, name: 'Turkey' },
  { image: require('./assets/flags/tm.png').default, name: 'Turkmenistan' },
  {
    image: require('./assets/flags/tc.png').default,
    name: 'Turks and Caicos Islands',
  },
  { image: require('./assets/flags/tv.png').default, name: 'Tuvalu' },
  { image: require('./assets/flags/ug.png').default, name: 'Uganda' },
  { image: require('./assets/flags/ua.png').default, name: 'Ukraine' },
  {
    image: require('./assets/flags/ae.png').default,
    name: 'United Arab Emirates',
  },
  { image: require('./assets/flags/gb.png').default, name: 'United Kingdom' },
  { image: require('./assets/flags/us.png').default, name: 'United States' },
  { image: require('./assets/flags/uy.png').default, name: 'Uruguay' },
  { image: require('./assets/flags/uz.png').default, name: 'Uzbekistan' },
  { image: require('./assets/flags/vu.png').default, name: 'Vanuatu' },
  {
    image: require('./assets/flags/ve.png').default,
    name: 'Venezuela',
  },
  { image: require('./assets/flags/vn.png').default, name: 'Vietnam' },
  {
    image: require('./assets/flags/vg.png').default,
    name: 'British Virgin Islands',
  },
  {
    image: require('./assets/flags/vi.png').default,
    name: 'Virgin Islands, U.S.',
  },
  {
    image: require('./assets/flags/wf.png').default,
    name: 'Wallis and Futuna',
  },
  { image: require('./assets/flags/ye.png').default, name: 'Yemen' },
  { image: require('./assets/flags/zm.png').default, name: 'Zambia' },
  { image: require('./assets/flags/zw.png').default, name: 'Zimbabwe' },
];
