import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';
import { useStyles } from './tabStyles';
import { TopBar } from '../../components/TopBar/TopBar';
import { Explore } from '../Tabs/Explore/Explore';
import { Online } from '../Tabs/Online/Online';
import { MyLikes } from '../Tabs/MyLikes/MyLikes';
import { Home } from '../Tabs/Home/Home';
import { Inbox } from '../Tabs/Inbox/Inbox';
import Settings from '../settings/Settings';
import image from '../../assets/index';
import { UserProfile } from '../Tabs/UserProfile/UserProfile';
import { MeetMe } from '../Tabs/MeetMe/MeetMe';
import { NavLink } from 'react-router-dom';
import { ProfileMatched } from '../Tabs/ProfileMatched/ProfileMatched';
import { UnMatch } from '../Tabs/UnMatch/UnMatch';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import { SocketContext } from '../../http/socket';
import { useSelector, useDispatch } from 'react-redux';
import { allVideos as getAllVideos, allChats } from '../../http/index';
import { setChatState, setChatVisit } from '../../store/inbox';

import { setFilterUpdated } from '../../store/Reducers/filters';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import navIcons from '../../assets/nav-icons';
import { debounce, shuffle } from '../../utils';

export const AllTabs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const currentUser = useSelector(state => state.auth.user.data);
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [videos, setVideos] = useState([]);
  const [loader, setLoader] = useState(false);
  const allVideos = useRef([]);
  const pageNumber = useRef(1);
  const [changing, setChanging] = useState(1);
  const [t] = useTranslation();
  const totalPages = useRef(0);
  allVideos.current = [];
  // filters

  const handleFitlerVideo = id => {
    setVideos(videos.filter(v => v._id !== id));
  };

  const tabItems = useMemo(
    () => [
      {
        label: t('home'),
        icon: navIcons.home,
        activeIcon: navIcons.homeActive,
        to: '/home',
      },
      {
        label: t('explore'),
        icon: navIcons.explore,
        activeIcon: navIcons.exploreActive,
        to: '/home/explore',
      },
      {
        label: t('meet_me'),
        icon: navIcons.meetme,
        activeIcon: navIcons.meetmeActive,
        to: '/home/meetme',
      },
      {
        label: 'Inbox',
        icon: navIcons.inbox,
        activeIcon: navIcons.inboxActive,
        to: '/home/inbox',
      },
      {
        label: t('activity'),
        icon: navIcons.activity,
        activeIcon: navIcons.activityActive,
        to: '/home/mylikes',
      },
      {
        label: 'Online',
        icon: navIcons.online,
        activeIcon: navIcons.onlineActive,
        to: '/home/online',
      },
      {
        label: t('live!'),
        icon: navIcons.live,
        activeIcon: navIcons.live,
        to: '/live',
      },
    ],
    []
  );

  const {
    gender,
    age,
    intent,
    religion,
    ethnicity,
    bodyType,
    education,
    kids,
    drink,
    smoke,
    weed,
    drugs,
    country,
    height,
    filterUpdated,
    isAnyOpen,
    maritalStatus,
    sexuality,
    politics,
    occupation,
    exercise,
    diet,
    liveWith,
    haveChildren,
    wantChildren,
    pets,
  } = useSelector(state => state.filters);

  const locationFilter = useSelector(state => state.filters.location);

  const location = useLocation();
  const currentPath = location.pathname;

  const [open, setOpen] = useState(false);
  const [meetMeVideos, setMeetMeVideos] = useState([]);
  const [meetMeTotalPage, setMeetMeTotalPage] = useState(0);
  const [meetMePageNumber, setMeetMePageNumber] = useState(1);
  const [chats, setChats] = useState([]);
  const [meetMeLoading, setMeetMeLoading] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  // filters end
  const [userUnMatched, setUserUnMatched] = useState(false);
  const observer = useRef();
  const lastElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (
        entries[0].isIntersecting &&
        pageNumber.current <= totalPages.current
      ) {
        pageNumber.current = pageNumber.current + 1;
        setChanging(prev => prev + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const addToRefs = e => {
    if (e && !allVideos.current.includes(e) && !null) allVideos.current.push(e);
  };
  const handleScroll = e => {
    const boundry = {
      top: {
        upper: lgScreen ? -147 : -370,
        lower: lgScreen ? 382 : 530,
      },
      bottom: {
        upper: lgScreen ? 385 : 540,
        lower: lgScreen ? 912 : 1441,
      },
    };
    // eslint-disable-next-line
    allVideos.current.map(item => {
      const video = item.lastChild.lastChild.lastChild.firstChild;
      const top = item.getBoundingClientRect().top;
      const bottom = item.getBoundingClientRect().bottom;
      if (
        top > boundry.top.upper &&
        top < boundry.top.lower &&
        bottom > boundry.bottom.upper &&
        bottom < boundry.bottom.lower
      ) {
        if (video.readyState >= 2) {
          video.play();
        }
      } else {
        video.pause();
      }
    });
  };

  useEffect(() => {
    if (isAnyOpen) {
      allVideos.current.map(item =>
        item.lastChild.lastChild.lastChild.firstChild.pause()
      );
    }
  }, [isAnyOpen]);

  useEffect(() => {
    if (allVideos.current.length > 0 && isAnyOpen === false) {
      const video =
        allVideos.current[0].lastChild.lastChild.lastChild.firstChild;
      video.play();
    }
  });

  const url = window.location.hash;

  useEffect(() => {
    if (url === '#/home/explore') {
      if (filterUpdated > 0) {
        pageNumber.current = 1;
        setVideos([]);
        dispatch(setFilterUpdated(0));
      }
      (async function () {
        setLoader(true);
        const query = `${
          gender.length > 0 ? `gender=${JSON.stringify(gender)}` : null
        }&${age.length > 0 ? `age=${JSON.stringify(age)}` : null}&${
          intent.length > 0
            ? `interested_audience=${JSON.stringify(intent)}`
            : null
        }&${
          religion.length > 0 ? `religion=${JSON.stringify(religion)}` : null
        }&${
          ethnicity.length > 0 ? `ethnicity=${JSON.stringify(ethnicity)}` : null
        }&${bodyType.length > 0 ? `type=${JSON.stringify(bodyType)}` : null}&${
          education.length > 0 ? `eduction=${JSON.stringify(education)}` : null
        }&${kids.length > 0 ? `children=${JSON.stringify(kids)}` : null}&${
          drink.length > 0 ? `drink=${JSON.stringify(drink)}` : null
        }&${smoke.length > 0 ? `smoke=${JSON.stringify(smoke)}` : null}&${
          weed.length > 0 ? `weed=${JSON.stringify(weed)}` : null
        }&${drugs.length > 0 ? `drugs=${JSON.stringify(drugs)}` : null}&${
          country.length > 0 ? `country=${JSON.stringify(country)}` : null
        }&${
          height.length > 0
            ? `height=${JSON.stringify([height[0] * 2.54, height[1] * 2.54])}`
            : null
        }&${
          maritalStatus.length > 0
            ? `marital_status=${JSON.stringify(maritalStatus)}`
            : null
        }&${
          sexuality.length > 0 ? `sexuality=${JSON.stringify(sexuality)}` : null
        }&${
          politics.length > 0 ? `politics=${JSON.stringify(politics)}` : null
        }&${
          occupation.length > 0
            ? `occupation=${JSON.stringify(occupation)}`
            : null
        }&${
          exercise.length > 0 ? `exercise=${JSON.stringify(exercise)}` : null
        }&${diet.length > 0 ? `diet=${JSON.stringify(diet)}` : null}&${
          liveWith.length > 0 ? `live_with=${JSON.stringify(liveWith)}` : null
        }&${
          haveChildren.length > 0
            ? `have_child=${JSON.stringify(haveChildren)}`
            : null
        }&${
          wantChildren.length > 0
            ? `want_child=${JSON.stringify(wantChildren)}`
            : null
        }&${pets.length > 0 ? `pet=${JSON.stringify(pets)}` : null}&${
          locationFilter?.lon ? `long=${locationFilter?.lon}` : null
        }&${
          locationFilter?.lat ? `lat=${locationFilter?.lat}` : null
        }&distance=500`;

        const { data } = await getAllVideos(pageNumber.current, 10, query);

        const v = data.data.filter(d => !d.not_interested);

        setVideos([...videos, ...v.reverse()]);
        totalPages.current = data.totalPages;
        setLoader(false);
      })();
    } else {
      setVideos([]);
      pageNumber.current = 1;
      setLoader(false);
    }

    // eslint-disable-next-line
  }, [changing, url, filterUpdated]);

  useEffect(() => {
    (async () => {
      if (meetMePageNumber === 1) {
        setMeetMeLoading(true);
      }
      const { data } = await getAllVideos(meetMePageNumber, 10, '');
      let videos = data.data.filter(e => !e.like && !e.superLike);
      videos = shuffle(videos);
      videos = videos.filter(
        video =>
          video.video_title.toLowerCase() === 'let me introduce myself' ||
          video.video_title.toLowerCase() === ''
      );
      setMeetMeVideos(videos);
      setMeetMeTotalPage(data.totalPages);
      setMeetMeLoading(false);
    })();
    // }
  }, [meetMePageNumber, url]);

  const handleGetChat = async () => {
    const { data } = await allChats();
    setChats(data.data);
    const index = localStorage.getItem('index');

    if (index) {
      dispatch(setChatVisit(1));
      const payload = {
        chatId: data?.data[index]?.chatId,
        userId: data?.data[index]?.matched_ids.to,
        activeIndex: index || 0,
      };
      dispatch(setChatState(payload));
    }
  };

  useEffect(() => {
    const url = window.location.pathname;
    handleGetChat();
    socket.on(`chatUpdate_${currentUser._id}`, data => {
      // console.log(data);
      // console.log('data =>', data);
      // const _data = JSON.parse(data?.data);
      // // console.log(JSON.parse(data?.data));
      // if (data?.type === 'lastmessage') {
      //   (async () => {
      //     const { data } = await allChats();
      //     setChats(data.data);
      //   })();
      // }
      // if (_data.matched_ids.to !== currentUser._id) {
      //   const ref = db
      //     .collection('messages')
      //     .doc(_data.chatId)
      //     .collection(_data.chatId)
      //     .doc(_data.docId);
      //   ref.update({ status: 'delivered' });
      // }
    });
    setMeetMePageNumber(1);
  }, [url, userUnMatched]);

  return (
    <Grid container direction='row'>
      <Hidden smDown>
        <Grid item style={{ width: '17%' }}>
          <Grid
            container
            alignItems='center'
            className={classes.tabsContainer}
            direction='column'
          >
            <Grid item>
              <img src={image.introLogo} className={classes.tabsLogo} alt='' />
            </Grid>
            <Grid item className={classes.tabs}>
              <List className={classes.root}>
                {tabItems.map((item, index) => (
                  <ListItem
                    className={classes.listItem}
                    alignItems='center'
                    key={index}
                    dense
                    disableGutters
                    component={NavLink}
                    to={item.to}
                    activeClassName={classes.listItemActive}
                    exact
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <img
                        src={
                          currentPath === item.to ? item.activeIcon : item.icon
                        }
                        className={classes.newIcon}
                        alt='icon'
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.label}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item style={{ width: smScreen ? '100%' : '83%' }}>
        <Grid container direction='column' className={classes.mainContainer}>
          <TopBar />
          <Grid
            item
            container
            className={classes.hero}
            alignItems='center'
            direction='column'
            onScroll={debounce(handleScroll, 300)}
          >
            <ProtectedRoute exact path='/home' component={Home} />
            <ProtectedRoute
              exact
              path='/home/explore'
              addToRefs={addToRefs}
              lastElementRef={lastElementRef}
              videos={videos}
              component={Explore}
              loaderSpinner={loader}
              handleFitlerVideo={handleFitlerVideo}
            />
            <ProtectedRoute
              exact
              path='/home/meetme'
              allVideos={meetMeVideos}
              setPage={setMeetMePageNumber}
              page={meetMePageNumber}
              totalPage={meetMeTotalPage}
              component={MeetMe}
              loading={meetMeLoading}
            />
            <ProtectedRoute
              exact
              path='/home/inbox'
              getChat={handleGetChat}
              chats={chats}
              component={Inbox}
              userUnMatched={userUnMatched}
              setUserUnMatched={setUserUnMatched}
            />
            <ProtectedRoute exact path='/home/mylikes' component={MyLikes} />
            <ProtectedRoute exact path='/home/online' component={Online} />
            <ProtectedRoute
              exact
              path='/home/profile'
              component={UserProfile}
            />
            <ProtectedRoute
              exact
              path='/home/match/:id'
              component={ProfileMatched}
            />
            <ProtectedRoute
              exact
              path='/home/unmatch/:id'
              component={UnMatch}
            />
            <ProtectedRoute exact path='/home/settings' component={Settings} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
