import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { likedMe, iLiked, iVisited, visitedMe } from '../../../http';
import GeoCode from 'react-geocode';

import { useDispatch, useSelector } from 'react-redux';

import ActivitySlider from '../../../components/activitySlider/ActivitySlider';
import axios from 'axios';
import {
  setILiked,
  setIvisited,
  setLikedMe,
  setVisitedMe,
} from '../../../store/matches';
import { shuffle } from '../../../utils';

export const MyLikes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector(state => state.matches);

  const [iLikedData, setILikedData] = useState([]);
  const [iVistedData, setIVisitedData] = useState([]);
  const [visitedMeData, setVisitedMeData] = useState([]);
  const [likedMeData, setLikedMeData] = useState([]);

  GeoCode.setApiKey(process.env.REACT_APP_GEOCODE_API);

  const getCity = () => {
    GeoCode.fromLatLng('48.8583701', '2.2922926').then(
      response => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'country':
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        console.log(city, state, country);
        console.log(address);
      },
      error => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    (async () => {
      axios
        .all([likedMe(), iLiked(), iVisited(), visitedMe()])
        .then(
          axios.spread(function (res1, res2, res3, res4) {
            dispatch(setLikedMe(res1.data));
            dispatch(setILiked(res2.data));
            dispatch(setIvisited(res3.data.data));
            dispatch(setVisitedMe(res4.data.data));
          })
        )
        .catch(err => console.log(err.message));
    })();
    getCity();
  }, []);

  // For making data for each slider

  useEffect(() => {
    // console.log(state.likedMe);

    const _iLikedData = [];
    state.iLiked.like?.forEach(user => {
      const obj = {
        id: user.liked_to._id,
        name: user.liked_to_name,
        profile: user.liked_to_profile_image,
        age: user.liked_to?.date_of_birth?.age,
        location: {
          lat: user.liked_to?.location?.lat,
          lng: user.liked_to?.location?.lon,
        },
        isSuperLike: false,
      };
      _iLikedData.push(obj);
    });
    state.iLiked.superLike?.forEach(user => {
      const obj = {
        id: user?.liked_to?._id,
        name: user?.liked_to_name,
        profile: user?.liked_to_profile_image,
        age: user?.liked_to?.date_of_birth?.age,
        location: {
          lat: user?.liked_to?.location?.lat,
          lng: user?.liked_to?.location?.lon,
        },
        isSuperLike: true,
      };
      _iLikedData.push(obj);
    });

    setILikedData(shuffle(_iLikedData));
    const _iVisitedData = state.iVisited.map(user => ({
      id: user?.visited_to?._id,
      name: user?.visited_to_name,
      profile: user?.visited_to_profile_image,
      age: user?.visited_to?.date_of_birth?.age,
      location: {
        lat: user?.visited_to?.location?.lat,
        lng: user?.visited_to?.location?.lon,
      },
    }));
    setIVisitedData(_iVisitedData);

    const _visitedMeData = state.visitedMe.map(user => ({
      id: user?.visited_by?._id,
      name: user?.visited_by_name,
      profile: user?.visited_by_profile_image,
      age: user?.visited_by?.date_of_birth?.age,
      location: {
        lat: user?.visited_by?.location?.lat,
        lng: user?.visited_by?.location?.lon,
      },
    }));
    setVisitedMeData(_visitedMeData);

    const likedMeList = [];
    state.likedMe?.like?.forEach(user => {
      const obj = {
        id: user?.liked_by?._id,
        name: user?.liked_by_name,
        profile: user?.liked_by_profile_image,
        age: user?.liked_by?.date_of_birth?.age,
        location: {
          lat: user?.liked_by?.location?.lat,
          lng: user?.liked_by?.location?.lon,
        },
        isSuperLike: false,
      };
      likedMeList.push(obj);
    });
    state.likedMe?.superLike?.forEach(user => {
      const obj = {
        id: user.liked_by._id,
        name: user.liked_by_name,
        profile: user.liked_by_profile_image,
        age: user.liked_by?.date_of_birth?.age,
        location: {
          lat: user.liked_by?.location?.lat,
          lng: user.liked_by?.location?.lon,
        },
        isSuperLike: true,
      };
      likedMeList.push(obj);
    });
    setLikedMeData(shuffle(likedMeList));
  }, [state]);

  return (
    <>
      <Grid item className={classes.container}>
        <h2 className={classes.title}>Who liked my videos</h2>
        <Grid item className={classes.sliderContainer}>
          <ActivitySlider
            data={likedMeData}
            message='Nobody liked your videos yet'
          />
        </Grid>

        <div className='divider'></div>

        <h2 className={classes.title}>Videos I liked</h2>
        <Grid item className={classes.sliderContainer}>
          <ActivitySlider
            data={iLikedData}
            message="You didn't like any video"
          />
        </Grid>

        <h2 className={classes.title}>Who viewed my profile</h2>
        <Grid item className={classes.sliderContainer}>
          <ActivitySlider
            data={visitedMeData}
            message='Nobody viewed your profile yet'
          />
        </Grid>

        <h2 className={classes.title}>Profiles I’ve visited</h2>
        <Grid item className={classes.sliderContainer}>
          <ActivitySlider
            data={iVistedData}
            message="You didn't visit any profile"
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '95%',
    paddingTop: '52px',
    '& .divider': {
      borderTop: '1px solid #7E7E7E',
      marginBlock: '36px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '20px',
      '& .divider': {
        borderTop: '1px solid #7E7E7E',
        marginBlock: '25px',
      },
    },
  },
  sliderContainer: {
    width: '91%',
    marginLeft: '40px',
    marginBlock: '16px 51px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '30px',
      marginBlock: '10px 30px',
    },
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#443F3D',
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px',
    },
  },
}));
