import { makeStyles } from '@material-ui/core';
import item1 from '../../../assets/item1.png';
import item2 from '../../../assets/item2.png';
export const useStyles = makeStyles(theme => ({
  container: {
    padding: '1rem 5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 2rem',
      paddingBottom: '0rem',
    },
  },

  contentContainer: {
    [theme.breakpoints.down(756)]: {
      alignItems: 'stretch',
    },
  },

  left: {
    width: '20%',
    [theme.breakpoints.down(756)]: {
      width: 'calc(50%)',
      order: 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
    },
  },
  middle: {
    width: '50%',
    [theme.breakpoints.down(756)]: {
      width: '100%',
      order: 3,
    },
  },
  right: {
    width: '30%',
    [theme.breakpoints.down(756)]: {
      width: 'calc(50%)',
      order: 2,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 2,
    },
  },
  title: {
    color: '#000',
    marginBottom: '20px',
    fontSize: '28px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '10px',
    },
  },
  likes: {
    color: '#000',
    textAlign: 'left',
    margin: 0,
    padding: '1rem',
    fontSize: '23px',
    fontWeight: 700,
    margin: '0px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      padding: '0rem',
      paddingLeft: '0.5rem',
    },
    // [theme.breakpoints.down(756)]: {
    //   height: '100%',
    // },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      color: '#000',
      fontSize: '14px',
      textAlign: 'left',
      marginLeft: '10px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '10px',
        marginLeft: '0px',
      },
    },
  },
  listItemRoot: {
    marginBottom: '1rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0.25rem',
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '35px',
    },
  },
  listItemAvatar: {
    height: '46px',
    width: '46px',
    [theme.breakpoints.down('lg')]: {
      height: '30px',
      width: '30px',
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '15px',
    },
  },
  thumbIcon: {
    height: '25px',
    width: '25px',
    [theme.breakpoints.down('lg')]: {
      height: '15px',
      width: '15px',
    },
  },
  listContainer: {
    backgroundColor: '#fbfbfb',
    width: '100%',
    height: '585px',
    borderRadius: '19px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    boxShadow: theme.shadows[3],
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '376px',
    },
    [theme.breakpoints.down(756)]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      height: '376px',
    },
  },
  card: {
    backgroundColor: '#fbfbfb',
    height: '133px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '19px',
    boxShadow: theme.shadows[3],
    marginBottom: '0.9rem',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '86px',
      marginBottom: '0.65rem',
    },
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0px',
    },
  },
  cardTitleOne: {
    color: '#000',
    fontSize: '21px',
    fontWeight: 300,
    margin: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  cardSubtitle: {
    color: '#000',
    fontSize: '13px',
    fontWeight: 300,
    textDecoration: 'underline',
    margin: 'auto 0',
    [theme.breakpoints.down('lg')]: {
      fontSize: '8px',
    },
  },
  cardAvatar: {
    height: '65px',
    width: '65px',
    marginLeft: '10px',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '40px',
    },
  },
  itemOne: {
    width: '49%',
    height: '146px',
    borderRadius: '18px',
    boxShadow: theme.shadows[3],
    boxSizing: 'border-box',
    padding: '1rem',
    backgroundImage: `url(${item1})`,
    backgroundSize: 'cover',
    backgroungPosition: 'center',
    backgroundColor: '#000000',
    [theme.breakpoints.down('lg')]: {
      width: '49%',
      height: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemTwo: {
    width: '49%',
    height: '146px',
    borderRadius: '18px',
    boxShadow: theme.shadows[3],
    boxSizing: 'border-box',
    padding: '1rem',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${item2})`,
    backgroundSize: 'cover',
    backgroungPosition: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '49%',
      height: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  reelContainer: {
    width: '100%',
    height: '402px',
    backgroundColor: '#fbfbfb',
    borderRadius: '13px',
    boxShadow: theme.shadows[3],
    boxSizing: 'border-box',
    padding: '1rem 0rem',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      padding: '1rem',
      height: '260px',
    },
  },
  reelTitle: {
    color: '#000',
    textAlign: 'center',
    width: '100%',
    margin: '0',
    fontSize: '20px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  reelAvatar: {
    width: '88px',
    height: '88px',
    borderRadius: '23px',
    [theme.breakpoints.down('lg')]: {
      width: '55px',
      height: '55px',
      borderRadius: '15px',
    },
  },
  reelItemText: {
    marginLeft: '15px',
    '& .MuiListItemText-primary': {
      color: '#000',
      fontSize: '15px',
      textAlign: 'left',
      [theme.breakpoints.down('lg')]: {
        fontSize: '11px',
      },
    },
    '& .MuiListItemText-secondary': {
      color: '#000',
      fontSize: '12px',
      textAlign: 'left',
      [theme.breakpoints.down('lg')]: {
        fontSize: '8px',
      },
    },
  },
  reelItem: {
    marginBottom: '0.9rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0.5rem',
    },
  },
  profileCard: {
    width: '100%',
    height: '162px',
    backgroundColor: '#fbfbfb',
    boxSizing: 'border-box',
    borderRadius: '22px',
    boxShadow: theme.shadows[3],
    marginBottom: '1.3rem',
    padding: '1rem 1rem',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      padding: '0.75rem 0.5rem',
      height: '118px',
      marginBottom: '0.6rem',
    },
  },
  profileCardTitle: {
    color: '#000',
    fontSize: '23px',
    fontWeight: '700',
    margin: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  profileAvatar: {
    height: '102px',
    width: '101px',
    marginTop: '-5px',
    [theme.breakpoints.down('lg')]: {
      height: '65px',
      width: '65px',
    },
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    width: '85px',
    height: '34px',
    color: '#fff',
    borderRadius: '17px',
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '34px',
    [theme.breakpoints.down('lg')]: {
      width: '60px',
      height: '20px',
      lineHeight: '20px',
      fontSize: '9px',
    },
  },
  imageTitle: {
    margin: 0,
    color: '#fff',
    textAlign: 'left',
    fontSize: '17px',
    marginTop: '50px',
    marginBottom: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      marginTop: '25px',
    },
  },
  imageText: {
    margin: 0,
    color: '#fff',
    textAlign: 'left',
    fontSize: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
  },
  tags: {
    margin: 'auto 0',
    paddingLeft: '12px',
    '& .tags-inner': {
      gridGap: '7.5px',
    },
    [theme.breakpoints.down('lg')]: {
      '& .tags-inner': {
        gridGap: '5.5px',
      },
    },
  },
  reelAvatarContainer: {
    minWidth: '0px',
  },
  seeMore: {
    color: '#000',
    fontSize: '13px',
    fontWeight: 300,
    textDecoration: 'underline',
    margin: '0',
    display: 'block',
    marginTop: 'auto',
    position: 'absolute',
    bottom: '5px',
    left: 0,
    right: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
  },
  scrollDiv: {
    scrollbarWidth: 'thin',
    paddingBottom: '6px',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar': {
      height: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '10px',
    },
  },
  notFound: {
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
  likeList: {
    maxHeight: '480px',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      maxHeight: '50%',
      height: '50%',
    },
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: '320px',
    },
  },
  notFoundImage: {
    height: '3rem',
    [theme.breakpoints.down('lg')]: {
      height: '2rem',
    },
  },
  likeNotFoundImage: {
    height: '5rem',
    [theme.breakpoints.down('lg')]: {
      height: '3rem',
    },
  },
  notFoundContainer: {
    height: 'calc(100% - 160px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 80px)',
    },
    [theme.breakpoints.down(756)]: {
      flexGrow: '1',
    },
  },
  reelsList: {
    width: '100%',
    paddingInline: '2rem',
    height: '340px',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '10px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingInline: '1rem',
      height: '205px',
    },
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    height: '30px',
    paddingInline: '11px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    gridGap: '5px',
    [theme.breakpoints.down('lg')]: {
      height: '25px',
      paddingInline: '9px',
    },
    '& .chip-label': {
      color: '#ffffff',
      fontSize: '11px',
      margin: 0,
      maxWidth: '20ch',
      [theme.breakpoints.down('lg')]: {
        fontSize: '9px',
      },
    },
    '& .chip-icon': {
      width: '12px',
      '&.sm': {
        width: '22px',
        marginLeft: '-5px',
        marginRight: '-5px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '10px',
        '&.sm': {
          width: '18px',
          marginLeft: '-3px',
          marginRight: '-3px',
        },
      },
    },
  },
}));
