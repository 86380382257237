import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Button,
  IconButton,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Grid,
  Collapse,
  Slider,
} from '@material-ui/core';
import { FilterList, ExpandLess, ChevronRight } from '@material-ui/icons';
import { useStyles } from './filterStyles';
import { FilterDialog } from '../FilterDialog/FilterDialog';
import { CountryFilter } from '../CountryFilter/CountryFilter';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
  genderList,
  religionList,
  getEthnicityList,
  bodyTypeList,
  schoolDegreeList,
  wantChildList,
  maritalStatusList,
  politicsList,
  occupationList,
  fitnessList,
  dietList,
  vicesList,
  homeTownList,
  haveChildList,
  petList,
  sexualityList,
  datePrefList as intentList,
  countryList,
} from '../../app.config';

import { getCity, getGenderFilterPreference, inchesToFeets } from '../../utils';

import {
  setFilter,
  setFilterUpdated,
  setIsAnyOpen,
  // resetFilters,
  setGender,
  setDistance,
  resetFilters,
} from '../../store/Reducers/filters';
import LocationFilter from '../../modals/LocationFilter';

export const Filter = ({}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [openLocationFilter, setOpenLocationFilter] = useState(false);
  const user = useSelector(state => state.auth.user.data);
  const [city, setCity] = useState('');
  const {
    location,
    gender,
    age,
    intent,
    religion,
    ethnicity,
    bodyType,
    education,
    drink,
    smoke,
    weed,
    drugs,
    country,
    height,
    sexuality,
    maritalStatus,
    politics,
    occupation,
    exercise,
    diet,
    liveWith,
    haveChildren,
    wantChildren,
    pets,
  } = useSelector(state => state.filters);

  const [expanded, setExpanded] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [withinCollapse, setWithinCollapse] = useState(false);
  const [heightCollapse, setHeightCollapse] = useState(false);
  const [sliderValue, setSliderValue] = useState([22, 33]);
  const [heightSlider, setHeightSlider] = useState(height);
  const [radius, setRadius] = useState(500);
  const imgRef = useRef();
  const heightImgRef = useRef();
  const withinRef = useRef();
  // filter dialog state
  const [dialogs, setDialogs] = useState(null);

  const handleOpenDialog = name => {
    setDialogs(name);
  };

  const handleCloseDialog = () => {
    setDialogs(null);
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
    imgRef.current.classList.toggle(`${classes.rotate}`);
  };
  const handleSliderAge = (event, val) => {
    setSliderValue(val);
  };
  const handleHeightSlider = (event, val) => {
    setHeightSlider(val);
  };
  const valueLabelFormat = val => {
    let feets = Math.floor(val / 12);
    let inches = val - feets * 12;
    feets = Math.floor(feets);
    inches = Math.floor(inches);

    return `${feets}'${inches}''`;
  };
  const handleSetAge = () => {
    dispatch(setFilter({ name: 'age', value: sliderValue }));
    dispatch(setFilterUpdated());
    setCollapse(!collapse);
    imgRef.current.classList.toggle(`${classes.rotate}`);
  };
  const handleSetHeight = () => {
    dispatch(setFilter({ name: 'height', value: heightSlider }));
    dispatch(setFilterUpdated());
    setHeightCollapse(!heightCollapse);
    heightImgRef.current.classList.toggle(`${classes.rotate}`);
  };
  const handleSetRadius = () => {
    dispatch(setDistance(radius));
    dispatch(setFilterUpdated());
    setWithinCollapse(!withinCollapse);
    withinRef.current.classList.toggle(`${classes.rotate}`);
  };
  useEffect(() => {
    if (location) {
      getCity(location.lat, location.lon, setCity);
    }
  }, [location]);
  return (
    <Accordion
      expanded={expanded}
      square
      classes={{ root: classes.accordionRoot }}
    >
      <AccordionSummary
        classes={{ root: classes.summary }}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div className='row'>
          <div>
            <IconButton
              className={classes.filterIconContainer}
              onClick={() => setExpanded(!expanded)}
            >
              <FilterList className={classes.filterIcon} />
            </IconButton>
            <Typography className={classes.heading}>{t('filter')}</Typography>
          </div>
          <Button
            onClick={() => {
              dispatch(resetFilters());
              dispatch(
                setGender(
                  getGenderFilterPreference(
                    user.identify.gender,
                    user.date_preference.interested_gender
                  )
                )
              );
            }}
            className={classes.resetButton}
            variant='text'
          >
            {t('reset')}
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <Grid item container direction='column'>
          <Grid item container>
            <Typography variant='h4' className={classes.sectionTitle}>
              {t('basic')}
            </Typography>
          </Grid>

          {/* Gender */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('gender');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('gender')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {gender.length === 0 && 'Select'}
                {gender.length === genderList.length
                  ? 'All'
                  : gender.map((item, index) => {
                      if (index === gender.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('gender')}
            options={['All', ...genderList]}
            setValue='gender'
            value={gender}
            open={dialogs === 'gender'}
            onClose={handleCloseDialog}
          />
          {/* Location */}
          <Grid
            item
            container
            onClick={() => {
              setOpenLocationFilter(true);
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('location')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {t(city)}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          {/* <FilterDialog
            title='Gender'
            options={genderList}
            setValue='gender'
            value={gender}
            open={dialogs === 'location'}
            onClose={handleCloseDialog}
          /> */}
          {/* Within */}
          <Grid
            item
            container
            direction='column'
            className={classes.ageFilterContainer}
          >
            <Grid
              item
              container
              className={classes.ageFilter}
              alignItems='center'
              onClick={() => setWithinCollapse(!withinCollapse)}
            >
              <Grid
                item
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: '4.5%',
                }}
              >
                <Typography className={classes.title}>{t('within')}</Typography>
                <Typography noWrap className={classes.subtitle}>
                  {radius}
                </Typography>
              </Grid>
              <Grid item>
                <ChevronRight ref={withinRef} className={classes.arrowIcon} />
              </Grid>
            </Grid>
            <Collapse in={withinCollapse} timeout='auto' unmountOnExit>
              <Slider
                onChange={(e, val) => setRadius(val)}
                value={radius}
                defaultValue={0}
                min={10}
                max={500}
                color='secondary'
                valueLabelDisplay='on'
                classes={{ root: `${classes.sliderRoot} within` }}
              />
              <Grid item container justifyContent='flex-end'>
                <Button
                  className={classes.cancelButton}
                  style={{ textTransform: 'none' }}
                  variant='text'
                >
                  {t('cancel')}
                </Button>
                <Button
                  className={classes.setButton}
                  variant='text'
                  color='primary'
                  onClick={handleSetRadius}
                  style={{ textTransform: 'none' }}
                >
                  {t('set')}
                </Button>
              </Grid>
            </Collapse>
          </Grid>
          {/* age */}
          <Grid
            item
            container
            direction='column'
            className={classes.ageFilterContainer}
          >
            <Grid
              item
              container
              className={classes.ageFilter}
              alignItems='center'
              onClick={handleCollapse}
            >
              <Grid
                item
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: '4.5%',
                }}
              >
                <Typography className={classes.title}>{t('age')}</Typography>
                <Typography noWrap className={classes.subtitle}>
                  {age.length === 0 ? 'Select' : `${age[0]}-${age[1]}`}
                </Typography>
              </Grid>
              <Grid item>
                <ChevronRight ref={imgRef} className={classes.arrowIcon} />
              </Grid>
            </Grid>
            <Collapse in={collapse} timeout='auto' unmountOnExit>
              <Grid item container justifyContent='center'>
                <Slider
                  onChange={handleSliderAge}
                  value={sliderValue}
                  defaultValue={[18, 50]}
                  min={18}
                  max={99}
                  color='secondary'
                  valueLabelDisplay='on'
                  classes={{ root: classes.sliderRoot }}
                />
              </Grid>
              <Grid item container justifyContent='flex-end'>
                <Button
                  className={classes.cancelButton}
                  style={{ textTransform: 'none' }}
                  variant='text'
                >
                  {t('cancel')}
                </Button>
                <Button
                  className={classes.setButton}
                  variant='text'
                  color='primary'
                  onClick={handleSetAge}
                  style={{ textTransform: 'none' }}
                >
                  {t('set')}
                </Button>
              </Grid>
            </Collapse>
          </Grid>
          {/* Intent */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('intent');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('intent')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {intent.length === 0 && t('Select')}
                {intent.length === intentList.length
                  ? 'All'
                  : intent.map((item, index) => {
                      if (index === intent.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('intent')}
            options={[...intentList, 'All']}
            setValue='intent'
            value={intent}
            open={dialogs === 'intent'}
            onClose={handleCloseDialog}
          />
          <Grid item container>
            <Typography variant='h4' className={classes.sectionTitle}>
              Looks
            </Typography>
          </Grid>
          {/* Height */}
          <Grid
            item
            container
            direction='column'
            className={classes.ageFilterContainer}
          >
            <Grid
              item
              container
              className={classes.ageFilter}
              alignItems='center'
              onClick={() => {
                setHeightCollapse(!heightCollapse);
                heightImgRef.current.classList.toggle(`${classes.rotate}`);
              }}
            >
              <Grid
                item
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: '4.5%',
                }}
              >
                <Typography className={classes.title}>{t('height')}</Typography>
                <Typography noWrap className={classes.subtitle}>
                  {height.length === 0
                    ? 'Select'
                    : `${inchesToFeets(height[0])}-${inchesToFeets(height[1])}`}
                </Typography>
              </Grid>
              <Grid item>
                <ChevronRight
                  ref={heightImgRef}
                  className={classes.arrowIcon}
                />
              </Grid>
            </Grid>
            <Collapse in={heightCollapse} timeout='auto' unmountOnExit>
              <Slider
                onChange={handleHeightSlider}
                value={heightSlider}
                min={35.8}
                max={84}
                step={1}
                valueLabelFormat={valueLabelFormat}
                color='secondary'
                valueLabelDisplay='on'
                classes={{ root: classes.sliderRoot }}
              />
              <Grid item container justifyContent='flex-end'>
                <Button className={classes.cancelButton} variant='text'>
                  {t('cancel')}
                </Button>
                <Button
                  className={classes.setButton}
                  variant='text'
                  color='primary'
                  onClick={handleSetHeight}
                >
                  {t('set')}
                </Button>
              </Grid>
            </Collapse>
          </Grid>
          {/* Body type */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('bodyType');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('body_type')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {bodyType.length === 0 && t('Select')}
                {bodyType.length === bodyTypeList.length - 1
                  ? 'All'
                  : bodyType.map((item, index) => {
                      if (index === bodyType.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('body_type')}
            options={[...bodyTypeList.slice(0, bodyTypeList.length - 1), 'All']}
            setValue='bodyType'
            value={bodyType}
            open={dialogs === 'bodyType'}
            onClose={handleCloseDialog}
          />
          <Grid item container>
            <Typography variant='h4' className={classes.sectionTitle}>
              Background & Identity
            </Typography>
          </Grid>
          {/* Sexuality */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('sexuality');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('Sexuality')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {sexuality.length === 0 && t('Select')}
                {sexuality.length === sexualityList.length - 1
                  ? 'All'
                  : sexuality.map((item, index) => {
                      if (index === sexuality.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('Sexuality')}
            options={[
              ...sexualityList.slice(0, sexualityList.length - 1),
              'All',
            ]}
            setValue='sexuality'
            value={sexuality}
            open={dialogs === 'sexuality'}
            onClose={handleCloseDialog}
          />
          {/* marital status */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('maritalStatus');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('marital_status')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {maritalStatus.length === 0 && t('Select')}
                {maritalStatus.length === maritalStatusList.length
                  ? 'All'
                  : maritalStatus.map((item, index) => {
                      if (index === maritalStatus.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('marital_status')}
            options={[...maritalStatusList, 'All']}
            setValue='maritalStatus'
            value={maritalStatus}
            open={dialogs === 'maritalStatus'}
            onClose={handleCloseDialog}
          />
          {/* Religion */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('religion');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('religion')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {religion.length === 0 && t('Select')}
                {religion.length === religionList.length - 1
                  ? 'All'
                  : religion.map((item, index) => {
                      if (index === religion.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('religion')}
            options={[...religionList.slice(0, religionList.length - 1), 'All']}
            setValue='religion'
            value={religion}
            open={dialogs === 'religion'}
            onClose={handleCloseDialog}
          />
          {/* Politics */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('politics');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('politics')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {politics.length === 0 && t('Select')}
                {politics.length === politicsList.length - 1
                  ? 'All'
                  : politics.map((item, index) => {
                      if (index === politics.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('politics')}
            options={[...politicsList.slice(0, politicsList.length - 1), 'All']}
            setValue='politics'
            value={politics}
            open={dialogs === 'politics'}
            onClose={handleCloseDialog}
          />
          {/* Ethnicity */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('ethnicity');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('ethnicity')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {ethnicity.length === 0 && t('Select')}
                {ethnicity.length === getEthnicityList.length - 1
                  ? 'All'
                  : ethnicity.map((item, index) => {
                      if (index === ethnicity.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />{' '}
            </Grid>
          </Grid>
          <FilterDialog
            title={t('ethnicity')}
            options={[
              ...getEthnicityList.slice(0, getEthnicityList.length - 1),
              'All',
            ]}
            setValue='ethnicity'
            value={ethnicity}
            open={dialogs === 'ethnicity'}
            onClose={handleCloseDialog}
          />
          {/* Country */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('country');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('country')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {country.length === 0 && t('Select')}
                {country.length === countryList.length - 1
                  ? 'All'
                  : country.map((item, index) => {
                      if (index === country.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <CountryFilter
            setValue={t('country')}
            value={country}
            open={dialogs === 'country'}
            onClose={handleCloseDialog}
          />
          {/* Education */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('education');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('education')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {education.length === 0 && t('Select')}
                {education.length === schoolDegreeList.length - 1
                  ? 'All'
                  : education.map((item, index) => {
                      if (index === education.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('education')}
            options={[
              ...schoolDegreeList.slice(0, schoolDegreeList.length - 1),
              'All',
            ]}
            setValue='education'
            value={education}
            open={dialogs === 'education'}
            onClose={handleCloseDialog}
          />
          {/* Occupation */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('occupation');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('occupation')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {occupation.length === 0 && t('Select')}
                {occupation.length === occupationList.length
                  ? 'All'
                  : occupation.map((item, index) => {
                      if (index === occupation.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('occupation')}
            options={[...occupationList, 'All']}
            setValue='occupation'
            value={occupation}
            open={dialogs === 'occupation'}
            onClose={handleCloseDialog}
          />

          <Grid item container>
            <Typography variant='h4' className={classes.sectionTitle}>
              {t('lifestyle')}
            </Typography>
          </Grid>
          {/* Exercise */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('exercise');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('exercise')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {exercise.length === 0 && t('Select')}
                {exercise.length === fitnessList.length - 1
                  ? 'All'
                  : exercise.map((item, index) => {
                      if (index === exercise.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('exercise')}
            options={[...fitnessList.slice(0, fitnessList.length - 1), 'All']}
            setValue='exercise'
            value={exercise}
            open={dialogs === 'exercise'}
            onClose={handleCloseDialog}
          />
          {/* Diet */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('diet');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('diet')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {diet.length === 0 && t('Select')}
                {diet.length === dietList.length - 1
                  ? 'All'
                  : diet.map((item, index) => {
                      if (index === diet.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('diet')}
            options={[...dietList.slice(0, dietList.length - 1), 'All']}
            setValue='diet'
            value={diet}
            open={dialogs === 'diet'}
            onClose={handleCloseDialog}
          />
          {/* Drink */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('drink');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('drink')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {drink.length === 0 && t('Select')}
                {drink.length === vicesList.length - 1
                  ? 'All'
                  : drink.map((item, index) => {
                      if (index === drink.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('drink')}
            options={[...vicesList.slice(0, vicesList.length - 1), 'All']}
            setValue='drink'
            value={drink}
            open={dialogs === 'drink'}
            onClose={handleCloseDialog}
          />
          {/* Smoke */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('smoke');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('smoke')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {smoke.length === 0 && t('Select')}
                {smoke.length === vicesList.length - 1
                  ? 'All'
                  : smoke.map((item, index) => {
                      if (index === smoke.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('smoke')}
            options={[...vicesList.slice(0, vicesList.length - 1), 'All']}
            setValue='smoke'
            value={smoke}
            open={dialogs === 'smoke'}
            onClose={handleCloseDialog}
          />
          {/* Marijuana */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('marijuana');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>
                {t('marijuana')}
              </Typography>
              <Typography noWrap className={classes.subtitle}>
                {weed.length === 0 && t('Select')}
                {weed.length === vicesList.length - 1
                  ? 'All'
                  : weed.map((item, index) => {
                      if (index === weed.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('marijuana')}
            options={[...vicesList.slice(0, vicesList.length - 1), 'All']}
            setValue='weed'
            value={weed}
            open={dialogs === 'marijuana'}
            onClose={handleCloseDialog}
          />
          {/* Drugs */}
          <Grid
            item
            container
            onClick={() => {
              handleOpenDialog('drugs');
              dispatch(setIsAnyOpen(true));
            }}
            alignItems='center'
            className={classes.filter}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '4.5%',
              }}
            >
              <Typography className={classes.title}>{t('drugs')}</Typography>
              <Typography noWrap className={classes.subtitle}>
                {drugs.length === 0 && t('Select')}
                {drugs.length === vicesList.length - 1
                  ? 'All'
                  : drugs.map((item, index) => {
                      if (index === drugs.length - 1) {
                        return `${t(item)}`;
                      } else {
                        return `${t(item)}, `;
                      }
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight className={classes.arrowIcon} />
            </Grid>
          </Grid>
          <FilterDialog
            title={t('drugs')}
            options={[...vicesList.slice(0, vicesList.length - 1), 'All']}
            setValue='drugs'
            value={drugs}
            open={dialogs === 'drugs'}
            onClose={handleCloseDialog}
          />
        </Grid>
        <Grid item container>
          <Typography variant='h4' className={classes.sectionTitle}>
            {t('family')}
          </Typography>
        </Grid>
        {/*  Who do you live with */}
        <Grid
          item
          container
          onClick={() => {
            handleOpenDialog('liveWith');
            dispatch(setIsAnyOpen(true));
          }}
          alignItems='center'
          className={classes.filter}
        >
          <Grid
            item
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '4.5%',
            }}
          >
            <Typography className={classes.title}>
              {t('living_situation')}
            </Typography>
            <Typography noWrap className={classes.subtitle}>
              {liveWith.length === 0 && t('Select')}
              {liveWith.length === homeTownList.length - 1
                ? 'All'
                : liveWith.map((item, index) => {
                    if (index === liveWith.length - 1) {
                      return `${t(item)}`;
                    } else {
                      return `${toString(item)}, `;
                    }
                  })}
            </Typography>
          </Grid>
          <Grid item>
            <ChevronRight className={classes.arrowIcon} />
          </Grid>
        </Grid>

        <FilterDialog
          title={t('living_situation')}
          options={[...homeTownList.slice(0, homeTownList.length - 1), 'All']}
          setValue='liveWith'
          value={liveWith}
          open={dialogs === 'liveWith'}
          onClose={handleCloseDialog}
        />
        {/* Have children */}

        <Grid
          item
          container
          onClick={() => {
            handleOpenDialog('haveChildren');
            dispatch(setIsAnyOpen(true));
          }}
          alignItems='center'
          className={classes.filter}
        >
          <Grid
            item
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '4.5%',
            }}
          >
            <Typography className={classes.title}>
              {t('have_children')}
            </Typography>
            <Typography noWrap className={classes.subtitle}>
              {haveChildren.length === 0 && t('Select')}
              {haveChildren.length === haveChildList.length - 1
                ? 'All'
                : haveChildren.map((item, index) => {
                    if (index === haveChildren.length - 1) {
                      return `${t(item)}`;
                    } else {
                      return `${t(item)}, `;
                    }
                  })}
            </Typography>
          </Grid>
          <Grid item>
            <ChevronRight className={classes.arrowIcon} />
          </Grid>
        </Grid>

        <FilterDialog
          title={t('have_children')}
          options={[...haveChildList.slice(0, haveChildList.length - 1), 'All']}
          setValue='haveChildren'
          value={haveChildren}
          open={dialogs === 'haveChildren'}
          onClose={handleCloseDialog}
        />
        {/* Want Children */}
        <Grid
          item
          container
          onClick={() => {
            handleOpenDialog('wantChildren');
            dispatch(setIsAnyOpen(true));
          }}
          alignItems='center'
          className={classes.filter}
        >
          <Grid
            item
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '4.5%',
            }}
          >
            <Typography className={classes.title}>
              {t('family_plans')}
            </Typography>
            <Typography noWrap className={classes.subtitle}>
              {wantChildren.length === 0 && t('Select')}
              {wantChildren.length === wantChildList.length - 1
                ? 'All'
                : wantChildren.map((item, index) => {
                    if (index === wantChildren.length - 1) {
                      return `${t(item)}`;
                    } else {
                      return `${t(item)}, `;
                    }
                  })}
            </Typography>
          </Grid>
          <Grid item>
            <ChevronRight className={classes.arrowIcon} />
          </Grid>
        </Grid>

        <FilterDialog
          title={t('family_plans')}
          options={[...wantChildList.slice(0, wantChildList.length - 1), 'All']}
          setValue='wantChildren'
          value={wantChildren}
          open={dialogs === 'wantChildren'}
          onClose={handleCloseDialog}
        />
        {/* Pets */}
        <Grid
          item
          container
          onClick={() => {
            handleOpenDialog('pets');
            dispatch(setIsAnyOpen(true));
          }}
          alignItems='center'
          className={classes.filter}
        >
          <Grid
            item
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '4.5%',
            }}
          >
            <Typography className={classes.title}>{t('Pets')}</Typography>
            <Typography noWrap className={classes.subtitle}>
              {pets.length === 0 && t('Select')}
              {pets.length === petList.length - 1
                ? 'All'
                : pets.map((item, index) => {
                    if (index === pets.length - 1) {
                      return `${t(item)}`;
                    } else {
                      return `${t(item)}, `;
                    }
                  })}
            </Typography>
          </Grid>
          <Grid item>
            <ChevronRight className={classes.arrowIcon} />
          </Grid>
        </Grid>

        <FilterDialog
          title={t('Pets')}
          options={[...petList.slice(0, petList.length - 1), 'All']}
          setValue='pets'
          value={pets}
          open={dialogs === 'pets'}
          onClose={handleCloseDialog}
        />
        <Button
          disableRipple
          variant='text'
          onClick={() => {
            setExpanded(false);
            // setIsAnyOpen(true);
          }}
          className={classes.expandLess}
        >
          <ExpandLess className={classes.expandLessIcon} />
        </Button>
      </AccordionDetails>
      {openLocationFilter && <LocationFilter setOpen={setOpenLocationFilter} />}
    </Accordion>
  );
};
