import React from 'react';
import { useStyles } from './inputStyle';
import { TextField, Grid, Typography } from '@material-ui/core';
import image from '../../assets';

export const Input = React.forwardRef(
  ({ label, onFocus, type, arrow, ...rest }, ref) => {
    const classes = useStyles();
    return (
      <>
        <Grid item container direction='column' className={classes.container}>
          <Grid item style={{ width: '100%' }}>
            <Typography
              style={{ width: '100%' }}
              className={classes.inputLabel}
              variant='h3'
            >
              {label}
            </Typography>
          </Grid>
          <Grid item className={classes.fieldContainer}>
            <TextField
              inputRef={ref}
              {...rest}
              type={type}
              variant='outlined'
              margin='none'
              className={classes.inputRoot}
              InputProps={{
                className: classes.input,
                onFocus: onFocus,
              }}
              fullWidth
              FormHelperTextProps={{ className: classes.error }}
            />
            {arrow && (
              <div className='icon'>
                <img src={image.downArrowBlack} alt='' />
              </div>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
);
