import React, { useEffect, useMemo, useState } from 'react';

import { Dialog, makeStyles, Grid, Button } from '@material-ui/core';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
} from 'react-google-maps';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation } from '../store/Reducers/filters';
import { setFilterUpdated } from '../store/Reducers/filters';
import { useTranslation } from 'react-i18next';

const Map = ({ handleLocationClick, initialLocation }) => {
  return (
    <GoogleMap
      defaultZoom={14}
      center={initialLocation}
      onClick={handleLocationClick}
    >
      <Marker position={initialLocation}></Marker>
    </GoogleMap>
  );
};

const MapComponent = withScriptjs(withGoogleMap(Map));

const LocationFilter = ({ setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const user = useSelector(state => state.auth.user.data);
  const [initialLocation, setInitialLocation] = useState({
    lat: +user.location.lat || -34.397,
    lng: +user.location.lon || 150.644,
  });

  const handleLocationClick = e => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setInitialLocation({
      lat,
      lng,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSet = () => {
    dispatch(
      setLocation({
        lat: initialLocation.lat,
        lon: initialLocation.lng,
      })
    );
    dispatch(setFilterUpdated());
    handleClose();
  };

  useEffect(() => {
    if (user) {
      setInitialLocation({
        lat: +user.location.lat,
        lng: +user.location.lon,
      });
    }
  }, [user]);

  return (
    <Dialog open={true} className={classes.dialog}>
      <Grid className={classes.main}>
        <MapComponent
          initialLocation={initialLocation}
          handleLocationClick={handleLocationClick}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GEOCODE_API}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Grid>
      <Grid container justifyContent='flex-end' style={{ paddingTop: '10px' }}>
        <Button
          onClick={handleClose}
          className={classes.cancelButton}
          variant='text'
        >
          {t('cancel')}
        </Button>
        <Button
          className={classes.setButton}
          onClick={handleSet}
          variant='text'
          color='primary'
        >
          {t('set')}
        </Button>
      </Grid>
    </Dialog>
  );
};

export default LocationFilter;

const useStyles = makeStyles(theme => ({
  main: {
    width: '500px',
    height: '400px',
  },
  setButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  cancelButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    marginRight: '0.75rem',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
}));
