import React, { useEffect, useState } from 'react';
import { useStyles } from '../Styles/registerStyles';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import image from '../../../assets/index';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import { Header } from '../../../components/header/Header';
import { Input } from '../../../components/Textfield/Input';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { profession, education, step } from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import axios from 'axios';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { schoolDegreeList, occupationList } from '../../../app.config';
import { useTranslation } from 'react-i18next';

export const Education = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [t] = useTranslation();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const userData = useSelector(state => state.auth.user.data);

  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState({
    degree: '0',
    occupation: '0',
  });
  const [details, setDetails] = useState({
    school: '',
    work: '',
    job: '',
  });
  const [show, setShow] = useState({
    school: false,
    degree: false,
    work: false,
    job: false,
    occupation: false,
  });

  const [errors] = useState({
    school: '',
    degree: '',
    occupation: '',
  });

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleDetails = e => {
    const { value, name } = e.target;
    setDetails({ ...details, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleNext = async () => {
    const educationData = {
      school: details.school,
      s_visible: show.school,
      degree: values.degree === '0' ? '' : values.degree,
      d_visible: show.degree,
      step:
        userData.step === '/get-user-education'
          ? '/get-user-profession'
          : userData.step,
    };

    const professionData = {
      company: details.work,
      job_title: details.job,
      occupation: values.occupation === '0' ? '' : values.occupation,
      c_visible: show.work,
      j_visible: show.job,
      o_visible: show.occupation,
      step:
        userData.step === '/get-user-education'
          ? '/get-user-religion'
          : userData.step,
    };
    await axios
      .all([education(educationData), profession(professionData)])
      .then(
        axios.spread(function (res1, res2) {
          dispatch(submit(res2.data));
          history.push('/register/religion');
        })
      )
      .catch(err => {
        console.log(err.message);
      });
  };
  const handleSkip = async () => {
    const stepData = {
      step:
        userData.step === '/get-user-education'
          ? '/get-user-religion'
          : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/religion');
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (
      details.school !== '' ||
      values.degree !== '0' ||
      values.occupation !== '0'
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (details.school === '') {
      setShow(prev => ({ ...prev, school: false }));
    }
    if (details.job === '') {
      setShow(prev => ({ ...prev, job: false }));
    }
    if (details.work === '') {
      setShow(prev => ({ ...prev, work: false }));
    }
    if (values.degree === '0') {
      setShow(prev => ({ ...prev, degree: false }));
    }
    if (values.occupation === '0') {
      setShow(prev => ({ ...prev, occupation: false }));
    }
  }, [details, values]);

  useEffect(() => {
    setValues({
      degree: userData?.education?.degree || '0',
      occupation: userData?.profession?.occupation?.occupation || '0',
    });
    setDetails({
      school: userData?.education?.school || '',
      work: userData?.profession?.company?.company || '',
      job: userData?.profession?.job_title?.job_title || '',
    });
    setShow({
      school: userData?.education?.s_visible || false,
      degree: userData?.education?.d_visible || false,
      work: userData?.profession?.company?.c_visible || false,
      job: userData?.profession?.job_title?.j_visible || false,
      occupation: userData?.profession?.occupation?.o_visible || false,
    });
  }, [userData]);
  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.bag})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${200}px ${200 * 1.15}px`
              : `${250}px ${250 * 1.15}px`
            : '300px 344px'
          : '',
      }}
    >
      <Header transparent headerItems />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px' }}
          >
            {t('education')}/ {t('profession')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>
        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid
            container
            direction='column'
            alignItems='flex-end'
            className={classes.formContainer}
          >
            <Grid
              item
              className='content'
              style={{
                marginRight: smScreen ? '0px' : '-210px',
                width: xsScreen ? '100%' : null,
              }}
            >
              <Grid item container sm={12} className={classes.inputContainer}>
                <Grid item style={{ flexGrow: '1' }}>
                  <Input
                    label={t('school')}
                    type='text'
                    placeholder='(Optional)'
                    value={details.school}
                    onChange={handleDetails}
                    name='school'
                    error={Boolean(errors.school)}
                    helperText={errors.school}
                  />
                </Grid>
                <Grid
                  item
                  container
                  style={{ marginTop: '10px' }}
                  alignItems='center'
                  className={classes.switchContainer}
                >
                  <span className={classes.showProfileText}>
                    {t('show_on_profile')}
                  </span>
                  <Checkbox
                    variant='switch'
                    name='school'
                    show={show.school}
                    handleShow={handleShow}
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.inputContainer} sm={12}>
                <SelectOption
                  checkboxVaraint='switch'
                  label={t('degree')}
                  options={schoolDegreeList}
                  placeholder='Select an option'
                  show={show.degree}
                  handleShow={handleShow}
                  name='degree'
                  onSelect={handleSelect}
                  value={values.degree}
                  error={Boolean(errors.degree)}
                  errorText={errors.degree}
                />
              </Grid>
              <Grid item container className={classes.inputContainer} sm={12}>
                <Grid item style={{ flexGrow: '1' }}>
                  <Input
                    label={t('workplace')}
                    type='text'
                    placeholder='(Optional)'
                    value={details.work}
                    onChange={handleDetails}
                    name='work'
                    error={Boolean(errors.work)}
                    helperText={errors.work}
                  />
                </Grid>
                <Grid
                  item
                  container
                  style={{ marginTop: '10px' }}
                  alignItems='center'
                  className={classes.switchContainer}
                >
                  <span className={classes.showProfileText}>
                    {t('show_on_profile')}
                  </span>
                  <Checkbox
                    variant='switch'
                    name='work'
                    show={show.work}
                    handleShow={handleShow}
                  />
                </Grid>
              </Grid>
              <Grid item container className={classes.inputContainer} sm={12}>
                <Grid item style={{ flexGrow: '1' }}>
                  <Input
                    label={t('job_title')}
                    type='text'
                    placeholder='(Optional)'
                    value={details.job}
                    onChange={handleDetails}
                    name='job'
                    error={Boolean(errors.job)}
                    helperText={errors.job}
                  />
                </Grid>
                <Grid
                  item
                  container
                  style={{ marginTop: '10px' }}
                  alignItems='center'
                  className={classes.switchContainer}
                >
                  <span className={classes.showProfileText}>
                    {t('show_on_profile')}
                  </span>
                  <Checkbox
                    variant='switch'
                    name='job'
                    show={show.job}
                    handleShow={handleShow}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12}>
                <SelectOption
                  checkboxVaraint='switch'
                  label={t('occupation')}
                  options={occupationList}
                  placeholder='Select an option'
                  show={show.occupation}
                  handleShow={handleShow}
                  name='occupation'
                  onSelect={handleSelect}
                  value={values.occupation}
                  error={Boolean(errors.occupation)}
                  errorText={errors.occupation}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <ButtonComp
            label={t('continue')}
            onClick={handleNext}
            disabled={disabled}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
