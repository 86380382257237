import { createSlice } from '@reduxjs/toolkit';

const help = createSlice({
  name: 'help',
  initialState: {
    categories: [],
    selectedCategorie: null,
  },
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setSelectedCategorie(state, action) {
      state.selectedCategorie = action.payload;
    },
  },
});

export const { setCategories, setSelectedCategorie } = help.actions;
export default help.reducer;
