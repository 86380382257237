import { createSlice } from '@reduxjs/toolkit';

const matchSlice = createSlice({
  name: 'video',
  initialState: {
    matches: [],
    iVisited: [],
    visitedMe: [],
    myLikes: [],
    likedMe: [],
    iLiked: [],
  },
  reducers: {
    setMatches: (state, action) => {
      state.matches = action.payload;
    },
    setIvisited: (state, action) => {
      state.iVisited = action.payload;
    },
    setVisitedMe: (state, action) => {
      state.visitedMe = action.payload;
    },
    setMyLikes: (state, action) => {
      state.myLikes = action.payload;
    },
    setLikedMe: (state, action) => {
      state.likedMe = action.payload;
    },
    setILiked: (state, action) => {
      state.iLiked = action.payload;
    },
  },
});

export const {
  setMatches,
  setIvisited,
  setVisitedMe,
  setMyLikes,
  setLikedMe,
  setILiked,
} = matchSlice.actions;
export default matchSlice.reducer;
