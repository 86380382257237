import React from 'react';

import { Box, Typography, makeStyles, TextField } from '@material-ui/core';

const ProfileInputField = ({
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box style={{ width: '100%' }}>
      <Typography variant='h3' className={classes.label}>
        {label}
      </Typography>
      <TextField
        value={value}
        name={name}
        onChange={onChange}
        className={classes.inputRoot}
        InputProps={{
          className: classes.input,
        }}
        fullWidth
        variant='outlined'
        error={error}
        helperText={helperText}
        FormHelperTextProps={{ className: classes.error }}
        {...rest}
      />
    </Box>
  );
};

export default ProfileInputField;

const useStyles = makeStyles(theme => ({
  label: {
    margin: 0,
    color: '#000000',
    fontWeight: '400',
    marginBottom: '15px',
    paddingLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
  },
  input: {
    fontSize: '22px',
    backgroundColor: '#ffffff',
    borderRadius: '34px',
    height: '62px',
    paddingLeft: '7px',
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      fontSize: '15px',
    },
  },
  error: {
    fontSize: '1.3rem',
    marginTop: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      marginTop: '5px',
    },
  },

  inputRoot: {
    '& .MuiOutlinedInput-root': {
      margin: '0px',
      boxShadow: theme.shadows[2],
      '& fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
      '&:hover fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
      '&.Mui-focused fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));
