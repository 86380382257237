import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import image from '../../../assets/index';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import { Header } from '../../../components/header/Header';
import { vices, step } from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { vicesList } from '../../../app.config';
import { useTranslation } from 'react-i18next';

export const LifeStyle = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState({
    drink: false,
    smoke: false,
    weed: false,
    drugs: false,
  });
  const [values, setValues] = useState({
    drink: '0',
    smoke: '0',
    weed: '0',
    drugs: '0',
  });

  const [errors] = useState({
    drink: '',
    smoke: '',
    weed: '',
    drugs: '',
  });

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleNext = async () => {
    try {
      const apiData = {
        drink: values.drink === '0' ? '' : values.drink,
        d_visible: show.drink,
        smoke: values.smoke === '0' ? '' : values.smoke,
        s_visible: show.smoke,
        weed: values.weed === '0' ? '' : values.weed,
        w_visible: show.weed,
        drugs: values.drugs === '0' ? '' : values.drugs,
        dr_visible: show.drugs,
        step:
          userData.step === '/get-user-vices'
            ? '/get-user-children'
            : userData.step,
      };
      const { data } = await vices(apiData);
      dispatch(submit(data));
      history.push('/register/children');
    } catch (err) {
      console.log(err);
    }
  };
  const handleSkip = async () => {
    const stepData = {
      step:
        userData.step === '/get-user-vices'
          ? '/get-user-children'
          : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/children');
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (
      values.drink !== '0' ||
      values.drugs !== '0' ||
      values.smoke !== '0' ||
      values.weed !== '0'
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (values.drink === '0') {
      setShow(prev => ({ ...prev, drink: false }));
    }
    if (values.drugs === '0') {
      setShow(prev => ({ ...prev, drugs: false }));
    }
    if (values.smoke === '0') {
      setShow(prev => ({ ...prev, smoke: false }));
    }
    if (values.weed === '0') {
      setShow(prev => ({ ...prev, weed: false }));
    }
  }, [values]);

  useEffect(() => {
    setValues({
      drink: userData?.vices?.drink?.drink || '0',
      smoke: userData?.vices?.smoke?.smoke || '0',
      weed: userData?.vices?.weed?.weed || '0',
      drugs: userData?.vices?.drugs?.drugs || '0',
    });
    setShow({
      drink: userData?.vices?.drink?.d_visible || false,
      smoke: userData?.vices?.smoke?.s_visible || false,
      weed: userData?.vices?.weed?.w_visible || false,
      drugs: userData?.vices?.drugs?.dr_visible || false,
    });
  }, [userData]);

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.drink})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${200}px ${200 * 1.13}px`
              : `${250}px ${250 * 1.13}px`
            : '300px 339px'
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px' }}
          >
            {t('lifestyle')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>

        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid
            container
            direction='column'
            alignItems='flex-end'
            className={classes.formContainer}
            spacing={lgScreen ? 2 : 2}
          >
            <Grid
              item
              className='content'
              style={{
                marginRight: smScreen ? '0px' : '-210px',
                width: xsScreen ? '100%' : null,
              }}
            >
              <Grid container direction='column'>
                <Grid item className={classes.inputContainer} sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('drink')}
                    options={vicesList}
                    placeholder='Select an option'
                    show={show.drink}
                    handleShow={handleShow}
                    name='drink'
                    onSelect={handleSelect}
                    value={values.drink}
                    error={Boolean(errors.drink)}
                    errorText={errors.drink}
                  />
                </Grid>
                <Grid item className={classes.inputContainer} sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('smoke')}
                    options={vicesList}
                    placeholder='Select an option'
                    show={show.smoke}
                    handleShow={handleShow}
                    name='smoke'
                    onSelect={handleSelect}
                    value={values.smoke}
                    error={Boolean(errors.smoke)}
                    errorText={errors.smoke}
                  />
                </Grid>
                <Grid item className={classes.inputContainer} sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('drugs')}
                    options={vicesList}
                    placeholder='Select an option'
                    show={show.drugs}
                    handleShow={handleShow}
                    name='drugs'
                    onSelect={handleSelect}
                    value={values.drugs}
                    error={Boolean(errors.drugs)}
                    errorText={errors.drugs}
                  />
                </Grid>
                <Grid item className={classes.inputContainer} sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('marijuana')}
                    options={vicesList}
                    placeholder='Select an option'
                    show={show.weed}
                    handleShow={handleShow}
                    name='weed'
                    onSelect={handleSelect}
                    value={values.weed}
                    error={Boolean(errors.weed)}
                    errorText={errors.weed}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <ButtonComp
            label={t('continue')}
            disabled={disabled}
            onClick={handleNext}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
