const icons = {
  aries: require('./aries.png').default,
  virgo: require('./virgo.png').default,
  taurus: require('./taurus.png').default,
  gemini: require('./gemini.png').default,
  leo: require('./leo.png').default,
  libra: require('./libra.png').default,
  scorpio: require('./scorpio.png').default,
  aquarius: require('./aquarius.png').default,
  sagittarius: require('./saggitarius.png').default,
  pisces: require('./pisces.png').default,
  cancer: require('./cancer.png').default,
  capricorn: require('./capricorn.png').default,
};

export default icons;
