import React, { useState, useEffect } from 'react';

import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  MenuItem,
  Select,
  InputBase,
  Button,
  makeStyles,
  Checkbox as MuiCheckbox,
  ListItemText,
  Hidden,
  Chip,
} from '@material-ui/core';
// import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import image from '../../../assets/index';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import { Header } from '../../../components/header/Header';
import { hometown as hometownApi, ethnicityApi, step } from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import { Input } from '../../../components/Textfield/Input';
import { Close } from '@material-ui/icons';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import {
  countryList,
  homeTownList,
  getEthnicityList,
  languagesSpoke,
} from '../../../app.config';
import { useTranslation } from 'react-i18next';

const CountryChip = ({ label = 'Pakistan', img, array, setArray }) => {
  const classes = useStyles();

  const deletItem = val => {
    let newArray = array.filter(item => item.name != val);
    setArray(newArray);
  };
  return (
    <Grid item>
      <Grid
        item
        container
        alignItems='center'
        className={classes.chipContainer}
      >
        <img src={img} alt='' className={classes.chipImg} />
        <span className={classes.countryName}>{label}</span>
        <div onClick={() => deletItem(label)}>
          <Close className={classes.chipIcon} />
        </div>
      </Grid>
    </Grid>
  );
};

export const Origin = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const [ethinic, setEthinic] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState({
    origin: false,
    ethnic: false,
    hometown: false,
    home: false,
    languages: false,
  });
  const [values, setValues] = useState({
    origin: '0',
    hometown: '0',
  });
  const [home, setHome] = useState('');
  const [errors] = useState({
    origin: '',
    hometown: '',
    ethinic: '',
    languages: '',
  });
  const handleChange = e => {
    setHome(e.target.value);
    setShow(prev => ({ ...prev, home: true }));
  };

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };

  const handleEthinic = e => {
    const val = e.target.value;

    val.map((e, index) => {
      if (val[index]) {
        return e.name;
      }
    });
    setEthinic(e.target.value);
    setShow(prev => ({ ...prev, ethnic: true }));
  };

  const handleLanguages = e => {
    const val = e.target.value;
    console.log(val);
    setLanguages(e.target.value);
    setShow(prev => ({ ...prev, languages: true }));
  };

  const handleDeselectLanguage = val => {
    let newArray = languages.filter(item => item != val);
    setLanguages(newArray);
  };

  const handleNext = async () => {
    const ethnicityApiData = {
      country_list: ethinic.map(e => e.name),
      race: values.origin === '0' ? '' : values.origin,
      c_visible: show.ethnic,
      r_visible: show.origin,
      step:
        userData.step === '/ethnicity-page'
          ? '/get-user-home-town'
          : userData.step,
    };
    const hometownData = {
      home_town: home,
      visible: show.home,
      live_with: values.hometown === '0' ? '' : values.hometown,
      live_with_visible: show.hometown,
      step:
        userData.step === '/ethnicity-page'
          ? '/get-user-education'
          : userData.step,
    };
    await axios
      .all([ethnicityApi(ethnicityApiData), hometownApi(hometownData)])
      .then(
        axios.spread(function (res1, res2) {
          dispatch(submit(res2.data));
          history.push('/register/education');
        })
      )
      .catch(err => console.log(err.message));
  };

  const handleSkip = async () => {
    const stepData = {
      step:
        userData.step === '/ethnicity-page'
          ? '/get-user-education'
          : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/education');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      ethinic.length >= 1 ||
      values.origin !== '0' ||
      values.hometown !== '0'
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (ethinic.length === 0) {
      setShow(prev => ({ ...prev, ethnic: false }));
    }
    if (values.origin === '0') {
      setShow(prev => ({ ...prev, origin: false }));
    }
    if (values.hometown === '0') {
      setShow(prev => ({ ...prev, hometown: false }));
    }
    if (home.length === 0) {
      setShow(prev => ({ ...prev, home: false }));
    }
  }, [ethinic, values, home]);

  useEffect(() => {
    setValues({
      origin: userData?.ethnicity?.race || '0',
      hometown: userData?.home_town?.live_with || '0',
    });
    setHome(userData?.home_town?.home_town || '');
    const countries = [];
    const _countryList = userData?.ethnicity?.country_list || [];
    _countryList.forEach(c => {
      countryList.find(n => {
        if (n.name === c) {
          countries.push(n);
        }
      });
    });
    setEthinic(countries);
    setShow({
      origin: userData?.ethnicity?.r_visible || false,
      ethnic: userData?.ethnicity?.c_visible || false,
      hometown: userData?.home_town?.visible || false,
      home: userData?.home_town?.live_with_visible || false,
    });
  }, [userData]);
  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.bgHome})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? '200px 200px'
              : '250px 250px'
            : '300px 300px'
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        style={{ marginBottom: '0rem' }}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px' }}
          >
            {t('hometown')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>
        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.formContainer}
            spacing={lgScreen ? 0 : 2}
          >
            <Grid
              item
              className='content'
              style={{
                marginRight: smScreen ? '0px' : '-210px',
                width: xsScreen ? '100%' : null,
              }}
            >
              <Grid container direction='column' spacing={lgScreen ? 4 : 8}>
                <Grid item>
                  {/* start */}
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('race')}
                    options={getEthnicityList}
                    placeholder='Select an option'
                    show={show.origin}
                    handleShow={handleShow}
                    name='origin'
                    onSelect={handleSelect}
                    value={values.origin}
                    error={Boolean(errors.origin)}
                    errorText={errors.origin}
                  />
                </Grid>
                <Grid item>
                  {/* start */}
                  <Grid container spacing={2} direction='column'>
                    <Grid item style={{ paddingBottom: '0' }}>
                      <Typography className={classes.inputLabel} variant='h3'>
                        {t('ethnic_background')}
                        <p className={classes.ethinicSmallText}>
                          {t('you_can_select_multiple_countries')}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{ position: 'relative' }}
                      alignItems='center'
                    >
                      <Grid
                        item
                        style={{ position: 'relative' }}
                        className={classes.selectContainer}
                      >
                        <Select
                          value={ethinic}
                          onChange={handleEthinic}
                          // variant="outlined"
                          multiple
                          error={true}
                          className={classes.select1}
                          color='primary'
                          name='ethinic'
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            className: classes.menu,
                          }}
                          classes={{ root: classes.selectRoot }}
                          inputProps={{
                            classes: {
                              root: classes.border,
                            },
                            placeholder: 'Select an option',
                          }}
                          IconComponent={() => (
                            <img
                              src={image.downArrowBlue}
                              className={classes.dropdownIcon}
                              alt=''
                            />
                          )}
                          input={
                            <InputBase
                              style={{
                                border: errors.ethinic
                                  ? `2px solid ${theme.palette.error.main}`
                                  : `1px solid transparent`,
                                boxShadow: theme.shadows[2],
                              }}
                              id='select-multiple-chip'
                            />
                          }
                          renderValue={() => (
                            <span className={classes.customPlaceHolder}>
                              <span>Choose Country</span>
                            </span>
                          )}
                        >
                          {/* <MenuItem dense className={classes.menuItem} value={[]}>
													<span className={classes.none}>Choose country</span>
												</MenuItem> */}
                          {countryList.map((option, index) => (
                            <MenuItem
                              key={option.unicode}
                              className={classes.menuItem}
                              value={option}
                            >
                              <MuiCheckbox
                                color='primary'
                                style={{
                                  transform: lgScreen
                                    ? 'scale(0.8)'
                                    : 'scale(1.3)',
                                  marginRight: lgScreen ? '10px' : '0px',
                                  width: lgScreen ? '31px' : '24px',
                                  height: lgScreen ? '31px' : '24px',
                                  minWidth: '0px',
                                  minHeight: '0px',
                                }}
                                checked={ethinic.indexOf(option) > -1}
                                // checked={() => console.log("working ")}
                              />
                              <img
                                src={option.image}
                                width={lgScreen ? '30px' : '40px'}
                                alt=''
                              />
                              <ListItemText
                                className={classes.listItemText}
                                primary={t(option.name)}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        {ethinic.length === 0 ? (
                          <span className={classes.customPlaceHolder}>
                            <span>Select an option</span>
                          </span>
                        ) : null}

                        {Boolean(errors.ethinic) && (
                          <span className={classes.error}>
                            {errors.ethinic}
                          </span>
                        )}
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems='center'
                        justifyContent='flex-start'
                        className={`${classes.switchContainer} checkbox`}
                      >
                        <span>{t('show_on_profile')}</span>
                        <Checkbox
                          variant='switch'
                          name='ethnic'
                          show={show.ethnic}
                          handleShow={handleShow}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={3}
                      className={classes.chipParentContainer}
                    >
                      {ethinic[0] != null &&
                        ethinic.map((value, index) => (
                          <React.Fragment key={index}>
                            {value.name ? (
                              <CountryChip
                                key={value.unicode}
                                label={value.name}
                                img={value.image}
                                array={ethinic}
                                setArray={val => setEthinic(val)}
                              />
                            ) : null}
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {/* start */}
                  <Grid container spacing={2} direction='column'>
                    <Grid item style={{ paddingBottom: '0' }}>
                      <Typography className={classes.inputLabel} variant='h3'>
                        {t('Languages Spoken')}
                        <p className={classes.ethinicSmallText}>
                          {t('You can select multiple languages')}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{ position: 'relative' }}
                      alignItems='center'
                    >
                      <Grid
                        item
                        style={{ position: 'relative' }}
                        className={classes.selectContainer}
                      >
                        <Select
                          value={languages}
                          onChange={handleLanguages}
                          // variant="outlined"
                          multiple
                          error={true}
                          className={classes.select1}
                          color='primary'
                          name='ethinic'
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            className: classes.menu,
                          }}
                          classes={{ root: classes.selectRoot }}
                          inputProps={{
                            classes: {
                              root: classes.border,
                            },
                            placeholder: 'Select an option',
                          }}
                          IconComponent={() => (
                            <img
                              src={image.downArrowBlue}
                              className={classes.dropdownIcon}
                              alt=''
                            />
                          )}
                          input={
                            <InputBase
                              style={{
                                border: errors.ethinic
                                  ? `2px solid ${theme.palette.error.main}`
                                  : `1px solid transparent`,
                                boxShadow: theme.shadows[2],
                              }}
                              id='select-multiple-chip'
                            />
                          }
                          renderValue={() => (
                            <span className={classes.customPlaceHolder}>
                              <span>Select an option</span>
                            </span>
                          )}
                        >
                          {languagesSpoke.map((option, index) => (
                            <MenuItem
                              key={option}
                              className={classes.menuItem}
                              value={option}
                            >
                              <MuiCheckbox
                                color='primary'
                                style={{
                                  transform: lgScreen
                                    ? 'scale(0.8)'
                                    : 'scale(1.3)',
                                  marginRight: lgScreen ? '10px' : '0px',
                                  width: lgScreen ? '31px' : '24px',
                                  height: lgScreen ? '31px' : '24px',
                                  minWidth: '0px',
                                  minHeight: '0px',
                                }}
                                checked={languages.indexOf(option) > -1}
                                // checked={() => console.log("working ")}
                              />
                              <ListItemText
                                className={classes.listItemText}
                                primary={option}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        {languages.length === 0 ? (
                          <span className={classes.customPlaceHolder}>
                            <span>Select an option</span>
                          </span>
                        ) : null}

                        {Boolean(errors.languages) && (
                          <span className={classes.error}>
                            {errors.languages}
                          </span>
                        )}
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems='center'
                        justifyContent='flex-start'
                        className={`${classes.switchContainer} checkbox`}
                      >
                        <span>{t('show_on_profile')}</span>
                        <Checkbox
                          variant='switch'
                          name='languages'
                          show={show.languages}
                          handleShow={handleShow}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={3}
                      className={classes.chipParentContainer}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '10px',
                          paddingInline: '12px',
                          marginTop: '5px',
                        }}
                      >
                        {languages.map(p => (
                          <Chip
                            key={p}
                            className={classes.chipContainer}
                            size='medium'
                            label={t(p)}
                            clickable
                            onDelete={() => handleDeselectLanguage(p)}
                          />
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('living_situation')}
                    options={homeTownList}
                    placeholder='Select an option'
                    show={show.hometown}
                    handleShow={handleShow}
                    name='hometown'
                    onSelect={handleSelect}
                    value={values.hometown}
                    error={Boolean(errors.hometown)}
                    errorText={errors.hometown}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    style={{ position: 'relative' }}
                    justifyContent='flex-end'
                  >
                    <Grid item className={classes.inputContainer}>
                      <Input
                        label={t('your_hometown')}
                        type='text'
                        placeholder='(Optional)'
                        value={home}
                        onChange={handleChange}
                        name='home'
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      style={{ marginBottom: lgScreen ? '-30px' : '-60px' }}
                      alignItems='center'
                      className={classes.switchContainer}
                    >
                      <span className={classes.showProfileText}>
                        {t('show_on_profile')}
                      </span>
                      <Checkbox
                        variant='switch'
                        name='work'
                        show={show.home}
                        handleShow={handleShow}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              Skip
            </Button>
          </Hidden>
          <ButtonComp
            disabled={disabled}
            onClick={handleNext}
            label={t('continue')}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  ethinicSmallText: {
    fontSize: '16px',
    color: '#828282',
    marginTop: '13px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      marginTop: '8px',
    },
  },

  container: {
    minHeight: '100vh',
    backgroundColor: '#F2F0EF',
    backgroundRepeat: 'no-repeat',
    paddingBlock: '130px 79px',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '390px 390px',
      paddingBlock: '70px 40px',
    },
  },
  form: {
    padding: '0 8rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
  },
  formContainer: {
    // width: "860px",
    padding: '10px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
      // padding: "5px 10px",
      // width: "680px",
    },
    zIndex: 1,
  },
  checkbox: {
    color: theme.palette.primary.main,
    borderRadius: '4px',
    transform: 'scale(1.6)',
    [theme.breakpoints.down('lg')]: {
      transform: 'scale(1.3)',
    },
  },

  p: {
    textAlign: 'left',
    color: '#000',
    marginTop: '2rem',
    fontSize: '23px',
    fontWeight: '100',
    '& a': {
      color: '#000    ',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      marginTop: '1rem',
    },
  },

  title: {
    marginLeft: '34px',
    marginBottom: '0.8rem',
  },
  checkboxWithText: {
    backgroundColor: '#fff',
    borderRadius: '34px',
    border: `2px solid  ${theme.palette.primary.main}`,
    width: '210px',
    height: '62px',
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '100%',
    },
  },
  label: {
    marginLeft: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      width: '88%',
    },
  },
  showProfileText: {
    fontSize: '15px',
    marginRight: '10px',
    marginLeft: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  checkboxContainer: {
    marginBottom: '1rem',
    paddingLeft: '16px',
    width: '880px',
    [theme.breakpoints.down('lg')]: {
      width: '700px',
    },
  },
  t2: {
    fontSize: '18px',
    fontWeight: '100',
  },
  inputContainer: {
    width: '589px',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formControlLabel: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: '264px',
    height: '62px',
    borderRadius: '34px',
    backgroundColor: 'white',
    padding: '0.5rem',
    margin: '0',
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '100%',
    },
    '&.MuiFormControlLabel-root': {
      justifyContent: 'space-between',
    },
    '& .MuiFormControlLabel-label': {
      color: '#000000',
      margin: '0',
      marginLeft: '5px',
      fontSize: '18px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
    },
  },
  chipParentContainer: {
    maxWidth: '685px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '465px',
    },
  },
  chipContainer: {
    backgroundColor: '#fff',
    padding: '15px 13px',
    borderRadius: '23px',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('lg')]: {
      padding: '8px 10px',
    },
  },
  chipImg: {
    width: '20px',
  },
  chip: {
    margin: '2px',
    [theme.breakpoints.down('lg')]: {
      height: '23px',
      margin: '1px',
      fontSize: '11px',
    },
  },

  chipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: '22px',
    cursor: 'pointer',
  },

  countryName: {
    fontSize: '14px',
    marginInline: '11px',
  },

  // select styles
  selectContainer: {
    width: '589px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  select: {
    width: '589px',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    marginBottom: '10px',
    height: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      width: '450px',
      height: '45px',
      fontSize: '15px',
      marginBottom: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    boxShadow: theme.shadows[2],
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  formTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      marginBottom: '0rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    },
  },
  marginBottom: {
    marginBottom: '0rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0rem',
    },
  },
  select1: {
    width: '589px',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    marginBottom: '10px',
    minHeight: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      borderColor: 'transparent',
    },
    '&:after': {
      borderColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      width: '450px',
      minHeight: '45px',
      fontSize: '15px',
      marginBottom: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  menuOrigin: {
    visibility: 'hidden',
    '& .MuiMenu-paper': {
      visibility: 'hidden',
    },
  },
  inputLabel: {
    marginLeft: '25px',
    padding: 'none',
    fontWeight: '300',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      marginLeft: '14px',
    },
  },
  switchRoot: {},
  switchContainer: {
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
    },
    width: '210px',
    '& span': {
      fontSize: '15px',
      marginRight: '20px',
      marginLeft: '15px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      width: '140px',
      margin: 0,
      '&.checkbox': {
        top: '-20px',
      },
    },
  },
  dropdownIcon: {
    fontSize: '2rem',
    marginRight: '15px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
  },
  none: {
    color: 'rgba(112,112,112,0.5)',
  },

  //   dialog styles
  dialog: {
    '& .MuiDialog-paper': {
      border: '1px solid #707070',
      borderRadius: '30px',
      [theme.breakpoints.down('xs')]: {
        margin: '10px',
      },
    },
  },
  dialogContainer: {
    backgroundColor: theme.palette.common.lightPink,
    // padding: "36px 20px",
    paddingBlock: '36px 25px',
    paddingInline: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingBlock: '16px 12px',
      paddingInline: '15px',
    },
  },
  dialogTitle: {
    margin: 0,
    color: '#000000',
    textAlign: 'left',
    fontSize: '22px',
    fontWeight: '500',
  },
  dialogSubtitle: {
    margin: 0,
    color: '#828282',
    textAlign: 'left',
    fontSize: '18px',
  },
  chipsContainer: {
    marginTop: '35px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  dialogButton: {
    width: '369px',
    height: '57px',
    margin: 0,
    fontSize: '16px',
    color: '#ffffff',
    marginTop: '1.25rem',
    textTransform: 'none',
    borderRadius: '29px',
  },
  active: {
    border: '2px solid red',
  },
  menuItem: {
    color: '#000',
    marginBottom: '0px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  underline: {
    // visibility: "hidden",
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: 'transparent',
    },
  },
  chips: { display: 'flex', flexWrap: 'wrap' },
  chip: {
    margin: '2px',
    [theme.breakpoints.down('lg')]: {
      height: '23px',
      margin: '1px',
      fontSize: '11px',
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '1.3rem',
    marginLeft: '10px',
    marginTop: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      margin: 0,
      color: '#000000',
      fontSize: '22px',
      textAlign: 'left',
      marginLeft: '10px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '15px',
      },
    },
  },
  menu: {
    position: 'absolute',
    '& .MuiMenu-paper': {
      maxHeight: '35vh',
    },
  },
  customPlaceHolder: {
    color: '#000000',
    opacity: '0.4',
    position: 'absolute',
    fontSize: '22px',
    top: '14px',
    left: '20px',
    zIndex: 0,
    pointerEvents: 'none',
    [theme.breakpoints.down('lg')]: {
      top: '12px',
      left: '20px',
      fontSize: '15px',
    },
  },
  dropdownIcon: {
    fontSize: '2rem',
    marginRight: '15px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      width: '16px',
    },
  },
  skipButton: {
    height: '42px',
    width: '134px',
    fontSize: '25px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    borderRadius: '43px',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      height: '35px',
      width: '120px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '45px',
      marginBottom: '20px',
    },
  },
}));
