import React, { useEffect, useState } from 'react';
import { useStyles } from './helpStyles';
import { Grid } from '@material-ui/core';
import { Header } from '../../components/header/Header';
import { useLocation } from 'react-router-dom';
import SubCategories from './SubCategories';
import { allSubCategories } from '../../http';

export const CategoryDetails = () => {
  const classes = useStyles();
  const category = useLocation().state;

  const [subCategories, setSubCategories] = useState([]);

  const handleGetSubCategories = async () => {
    try {
      const { data } = await allSubCategories(category._id);
      setSubCategories(data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetSubCategories();
  }, []);

  return (
    <Grid
      container
      alignItems='center'
      direction='column'
      className={classes.container}
    >
      <Header transparent headerItems={[]} />
      <Grid
        item
        container
        className={classes.colorTxt}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item className={classes.textContainer}>
          <h1>{category?.title}</h1>
          <h2>{category?.description}</h2>
        </Grid>
      </Grid>
      <Grid item>
        <p className={classes.subtitle2}>
          Intro &gt; Help Center &gt; {category?.title}
        </p>
      </Grid>
      <Grid item container className={classes.columnContainer}>
        {subCategories.map(sub => (
          <SubCategories key={sub._id} subCategory={sub} />
        ))}
      </Grid>
    </Grid>
  );
};
