import React, { useMemo, useState } from 'react';
import {
  Badge,
  Grid,
  Avatar,
  Hidden,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import image from '../../assets/index';
import { useStyles } from './topBarStyle';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import navIcons from '../../assets/nav-icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

export const TopBar = ({ live }) => {
  const classes = useStyles();
  // const history = useHistory();
  const userState = useSelector(state => state.auth.user.data);
  const location = useLocation();
  const currentPath = location.pathname;

  const [t] = useTranslation();

  const tabItems = useMemo(
    () => [
      {
        label: t('home'),
        icon: navIcons.home,
        activeIcon: navIcons.homeActive,
        to: '/home',
      },
      {
        label: t('explore'),
        icon: navIcons.explore,
        activeIcon: navIcons.exploreActive,
        to: '/home/explore',
      },
      {
        label: t('meet_me'),
        icon: navIcons.meetme,
        activeIcon: navIcons.meetmeActive,
        to: '/home/meetme',
      },
      {
        label: 'Inbox',
        icon: navIcons.inbox,
        activeIcon: navIcons.inboxActive,
        to: '/home/inbox',
      },
      {
        label: t('activity'),
        icon: navIcons.activity,
        activeIcon: navIcons.activityActive,
        to: '/home/mylikes',
      },
      {
        label: 'Online',
        icon: navIcons.online,
        activeIcon: navIcons.onlineActive,
        to: '/home/online',
      },
      {
        label: t('live!'),
        icon: navIcons.live,
        activeIcon: navIcons.live,
        to: '/live',
      },
    ],
    []
  );

  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Grid
      container
      justifyContent='center'
      // direction="column"
      className={classes.nav}
    >
      <Grid item xs={12}>
        <Grid
          container
          className={classes.nav}
          justifyContent='space-between'
          alignItems='center'
          style={{ position: 'relative' }}
        >
          {live ? (
            <>
              <Grid item>
                <Grid container alignItems='center'>
                  <Hidden mdUp>
                    <Grid
                      item
                      style={{ cursor: 'pointer' }}
                      onClick={handleToggleDrawer}
                    >
                      <img src={image.bars} alt='' />
                    </Grid>
                  </Hidden>
                  <Grid item>
                    <img
                      src={image.introLogo}
                      className={classes.logo}
                      alt=''
                    />
                  </Grid>
                  <Hidden xsDown>
                    <Grid item>
                      <Grid container alignItems='center'>
                        <Grid item>
                          <Avatar
                            className={classes.avatar}
                            src={`${process.env.REACT_APP_API_URL}/${userState.profile_image}`}
                            alt='jhon doe'
                            component={Link}
                            to='/home/profile'
                          />
                        </Grid>
                        <Grid item>
                          <span className={classes.avatarTitle}>
                            {userState.username || userState.first_name}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Grid container alignItems='center'>
                <Hidden mdUp>
                  <Grid
                    item
                    style={{ cursor: 'pointer' }}
                    onClick={handleToggleDrawer}
                  >
                    <img src={image.bars} alt='' />
                  </Grid>
                </Hidden>
                <Grid item>
                  <Avatar
                    className={classes.avatar}
                    src={`${process.env.REACT_APP_API_URL}/${userState.profile_image}`}
                    alt='jhon doe'
                    component={Link}
                    to='/home/profile'
                  />
                </Grid>
                <Grid item>
                  <span className={classes.avatarTitle}>
                    {userState.username}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item style={{ zIndex: 1 }}>
            <Grid item>
              <Grid
                container
                className={classes.rightContainer}
                alignItems='center'
                style={{
                  marginLeft: live ? 0 : '',
                }}
              >
                <Grid item>
                  {live ? (
                    <Link to='/home/explore' className={classes.live}>
                      <div>
                        <img src={image.pinkHeart} alt='' />
                        <p style={{ textTransform: 'uppercase' }}>
                          {t('Date')}
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <Link to='/live' className={classes.live}>
                      <div>
                        <img src={image.live} alt='' />
                        <p style={{ textTransform: 'uppercase' }}>
                          {t('live')}
                        </p>
                      </div>
                    </Link>
                  )}
                </Grid>

                <Grid item>
                  <Badge
                    component={Link}
                    to='/home/inbox'
                    style={{ marginRight: '15px' }}
                    color='primary'
                  >
                    <img src={image.mail} className={classes.icons} alt='' />
                  </Badge>
                  <Badge color='primary' component={Link} to='/home/settings'>
                    <img src={image.setting} className={classes.icons} alt='' />
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <SwipeableDrawer
          onClose={handleToggleDrawer}
          className={classes.drawer}
          anchor='left'
          open={open}
        >
          <Grid container direction='column'>
            <Grid item className={classes.logoContainer}>
              <img src={image.logo} className='logo' alt='' />
            </Grid>
            <Grid item className={classes.tabs}>
              <List className={classes.root}>
                {tabItems.map((item, index) => (
                  <ListItem
                    className={classes.listItem}
                    alignItems='center'
                    key={index}
                    dense
                    disableGutters
                    component={NavLink}
                    to={item.to}
                    activeClassName={classes.listItemActive}
                    exact
                    onClick={handleToggleDrawer}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <img
                        src={
                          currentPath === item.to ? item.activeIcon : item.icon
                        }
                        className={classes.newIcon}
                        alt='icon'
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.label}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Hidden>
    </Grid>
  );
};
