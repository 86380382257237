import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Hidden,
} from '@material-ui/core';
import image from '../../../assets/index';
import { Header } from '../../../components/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import { children, step } from '../../../http';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import axios from 'axios';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { useTranslation } from 'react-i18next';

import { wantChildList, haveChildList, petList } from '../../../app.config';

export const Children = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const [show, setShow] = useState({
    haveChildren: false,
    wantChildren: false,
    pets: false,
  });
  const [values, setValues] = useState({
    haveChildren: '0',
    wantChildren: '0',
    pets: '0',
  });
  const [errors] = useState({
    haveChildren: '',
    wantChildren: '',
    pets: '',
  });

  const handleSkip = async () => {
    const stepData = {
      step:
        userData.step === '/get-user-children'
          ? '/get-user-photos'
          : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/profile-picture');
    } catch (err) {
      console.log(err);
    }
  };

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleNext = async () => {
    try {
      const childData = {
        have_children: values.haveChildren === '0' ? '' : values.haveChildren,
        want_children: values.wantChildren === '0' ? '' : values.wantChildren,
        visible: show.haveChildren,
        have_pet: values.pets === '0' ? '' : values.pets,
        p_visible: show.pets,
        w_children: show.wantChildren,
        step:
          userData.step === '/get-user-children'
            ? '/get-user-photos'
            : userData.step,
      };

      const { data } = await children(childData);
      dispatch(submit(data));
      history.push('/register/profile-picture');
    } catch (err) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        console.log(err.request?.data.message);
      }
    }
  };

  useEffect(() => {
    if (
      values.haveChildren !== '0' ||
      values.wantChildren !== '0' ||
      values.pets !== '0'
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (values.haveChildren === '0') {
      setShow(prev => ({ ...prev, haveChildren: false }));
    }
    if (values.wantChildren === '0') {
      setShow(prev => ({ ...prev, wantChildren: false }));
    }
    if (values.pets === '0') {
      setShow(prev => ({ ...prev, pets: false }));
    }
  }, [values]);

  useEffect(() => {
    setValues({
      haveChildren: userData?.children?.have_children || '0',
      wantChildren: userData?.children?.want_children || '0',
      pets: userData?.children?.have_pet || '0',
    });
    setShow({
      haveChildren: userData?.children?.visible || false,
      wantChildren: userData?.children?.w_children || false,
      pets: userData?.children?.p_visible || false,
    });
  }, [userData]);

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.baby})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? '200px 200px'
              : '250px 250px'
            : '300px 300px'
          : '',
      }}
    >
      <Header headerItems={[]} transparent />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px', flex: '1', textAlign: 'center' }}
          >
            {t('children')}/ {t('Pets')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>

        <form
          action=''
          autoComplete='off'
          style={{ width: xsScreen ? '100%' : null }}
        >
          <Grid container direction='column' className={classes.formContainer}>
            <Grid
              item
              className='content'
              style={{ marginRight: smScreen ? '0px' : '-210px' }}
            >
              <Grid container direction='column' spacing={lgScreen ? 4 : 8}>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('have_children')}
                    options={haveChildList}
                    placeholder='Select an option'
                    show={show.haveChildren}
                    handleShow={handleShow}
                    name='haveChildren'
                    onSelect={handleSelect}
                    value={values.haveChildren}
                    error={Boolean(errors.haveChildren)}
                    errorText={errors.haveChildren}
                  />
                </Grid>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('family_plans')}
                    options={wantChildList}
                    placeholder='Select an option'
                    show={show.wantChildren}
                    handleShow={handleShow}
                    name='wantChildren'
                    onSelect={handleSelect}
                    value={values.wantChildren}
                    error={Boolean(errors.wantChildren)}
                    errorText={errors.wantChildren}
                  />
                </Grid>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('Pets')}
                    options={petList}
                    placeholder='Select an option'
                    show={show.pets}
                    handleShow={handleShow}
                    name='pets'
                    onSelect={handleSelect}
                    value={values.pets}
                    error={Boolean(errors.pets)}
                    errorText={errors.pets}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
          justifyContent='center'
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <ButtonComp
            label={t('continue')}
            disabled={disabled}
            onClick={handleNext}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
