import React, { useEffect, useState } from 'react';

import {
  Dialog,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction as Action,
  Checkbox as MUICheckbox,
  makeStyles,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';

import { Checkbox } from '../../Checkbox/Checkbox';

import classNames from 'classnames';

import { CloseRounded } from '@material-ui/icons';

const ProfileSelectMenu = ({
  open,
  handleClose,
  options,
  label,
  handleSelect,
  name,
  value,
  handleShow,
  show,
  handleSelectMultiple,
  multiple,
  country,
}) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState('');
  const [values, setValues] = useState([]);

  const handleClick = val => {
    if (multiple && typeof handleSelectMultiple === 'function') {
      if (values.find(v => v === val)) {
        console.log(values.filter(f => f !== val));
        setValues(values.filter(f => f !== val));
      } else {
        console.log([...values, val]);
        setValues([...values, val]);
      }
    } else {
      setSelectedValue(val);
    }
  };

  const handleCloseLocal = () => {
    if (multiple && typeof handleSelectMultiple === 'function') {
      setValues(values);
    } else {
      setSelectedValue(value);
    }
    handleClose();
  };

  const handleSetValues = () => {
    if (multiple && typeof handleSelectMultiple === 'function') {
      handleSelectMultiple(name, values);
    } else {
      handleSelect(name, selectedValue);
    }
    handleClose();
  };

  useEffect(() => {
    if (value) {
      if (multiple && typeof handleSelectMultiple === 'function') {
        setValues(value);
      } else {
        setSelectedValue(value);
      }
    }
  }, [value]);

  return (
    <Dialog className={classes.dialog} onClose={handleClose} open={open}>
      <Box className={classes.box}>
        <Typography variant='h2' className={classes.title}>
          {label}
        </Typography>
        <IconButton
          onClick={handleCloseLocal}
          disableFocusRipple
          disableRipple
          className={classes.closeButton}
        >
          <CloseRounded className='icon' />
        </IconButton>
        <List className={classes.list}>
          {options?.map((option, index) => (
            <ListItem
              onClick={() => handleClick(country ? option.name : option)}
              className={classes.listItem}
              key={index}
            >
              <ListItemText
                primary={country ? option.name : option}
                className={classes.label}
              />
              <Action>
                <MUICheckbox
                  onClick={() => handleClick(country ? option.name : option)}
                  color='primary'
                  className={classes.checkbox}
                  size='medium'
                  checked={
                    values.includes(country ? option.name : option) ||
                    selectedValue === option
                  }
                />
              </Action>
            </ListItem>
          ))}
        </List>
        <Grid
          item
          container
          alignItems='center'
          className={classes.switchBox}
          justifyContent='space-between'
        >
          <Typography className='text'>Show on profile</Typography>
          <Checkbox
            variant='switch'
            name={name}
            show={show}
            handleShow={handleShow}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent='flex-end'
          className={classes.buttonsContainer}
        >
          <Button
            className={classes.setButton}
            onClick={handleSetValues}
            variant='text'
            color='primary'
          >
            Set
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ProfileSelectMenu;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '25px',
      backgroundColor: theme.palette.common.lightPink,
      overflow: 'hidden',
    },
  },
  box: {
    width: '540px',
    paddingBlock: '41px 67px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
      paddingBlock: '20px 25px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '32px',
    right: '32px',
    padding: '0px',
    '& .icon': {
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#3E3E3E',
    },

    [theme.breakpoints.down('lg')]: {
      top: '20px',
      right: '20px',
      '& .icon': {
        fontSize: '20px',
      },
    },
  },
  list: {
    maxHeight: '50vh',
    overflow: 'auto',
    paddingInline: '20px',
  },
  listItem: {
    cursor: 'pointer',
    paddingBlock: '25px',
    borderBottom: '1px solid #E0E0E0',
    '&.last': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '15px',
    },
  },
  title: {
    margin: '0px',
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
      marginBottom: '15px',
    },
  },
  label: {
    '& .MuiListItemText-primary': {
      margin: 0,
      color: '#000000',
      textAlign: 'left',
      fontSize: '22px',
      fontWeight: '500',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },
  switchBox: {
    paddingInline: '33px 47px',
    marginBlock: '35px 67px',
    '& .text': {
      margin: 0,
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      marginBlock: '25px 35px',
    },
  },
  buttonsContainer: {
    paddingInline: '22px',
  },
  setButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  cancelButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    marginRight: '0.75rem',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
}));
