import ApplicationContext from "./contextApi";
import { useState } from "react";

export const MyProvider = ({ children }) => {
	const [index, setIndex] = useState(0);
	const [animate, setAnimate] = useState(false);
	const [username, setUsername] = useState("");
	const [chatId, setChatId] = useState("");
	const [openMatch, setOpenMatch] = useState(false);
	const [matchData, setMatchData] = useState({
		liked_by: "",
		liked_by_name: "",
		liked_by_profile_image: "",
		liked_to: "",
		liked_to_name: "",
		liked_to_profile_image: "",
	});

	return (
		<ApplicationContext.Provider
			value={{
				index,
				setIndex,
				animate,
				setAnimate,
				username,
				setUsername,
				chatId,
				setChatId,
				openMatch,
				setOpenMatch,
				matchData,
				setMatchData,
			}}
		>
			{children}
		</ApplicationContext.Provider>
	);
};
