import React, { useState } from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import ProfileSelectMenu from '../ProfileSelectMenu.js/ProfileSelectMenu';

const ProfileSelect = ({
  label,
  options,
  name,
  handleSelect,
  value,
  handleShow,
  show,
  handleSelectMultiple,
  multiple,
  country,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box className={classes.container} onClick={handleOpenDialog}>
        <Typography variant='h3' className={classes.label}>
          {label}
        </Typography>
        <Grid
          item
          container
          className={classes.field}
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            className='value'
            style={{
              maxWidth: '85%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {multiple
              ? value.map((val, index) => {
                  if (index === value.length - 1) {
                    return val;
                  } else {
                    return `${val}, `;
                  }
                })
              : value}
          </Typography>
          <Typography className='show'>{show ? 'Visible' : 'Hide'}</Typography>
        </Grid>
      </Box>
      <ProfileSelectMenu
        handleSelect={handleSelect}
        name={name}
        options={options}
        open={openDialog}
        label={label}
        handleClose={handleClose}
        value={value}
        handleShow={handleShow}
        show={show}
        handleSelectMultiple={handleSelectMultiple}
        multiple
        country={country}
      />
    </>
  );
};

export default ProfileSelect;

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  label: {
    margin: '0px',
    marginBottom: '15px',
    fontWeight: '400',
    marginLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
  },
  field: {
    fontSize: '22px',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    borderRadius: '34px',
    height: '62px',
    paddingLeft: '7px',
    boxShadow: theme.shadows[2],
    paddingInline: '30px',
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      fontSize: '15px',
    },

    '& .value': {
      fontSize: '20px',
      fontWeight: '400',
      margin: 0,
      color: '#000000',
      [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
      },
    },
    '& .show': {
      fontSize: '18px',
      fontWeight: '300',
      margin: 0,
      color: '#000000',
      [theme.breakpoints.down('lg')]: {
        fontSize: '15px',
      },
    },
  },
}));
