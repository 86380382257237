import React, { useEffect, useState } from 'react';

import { Grid, MenuItem, Select, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';
import { Input } from '../../components/Textfield/Input';
import image from '../../assets';
import { SelectOption } from '../../components/SelectOption/SelectOption';
import { genderList } from '../../app.config';
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import Joi from 'joi-browser';
import Congratulation from '../../modals/Congratulations';
import { preRegister } from '../../http';
import axios from 'axios';

const schema = {
  full_name: Joi.string().required().label('Full name'),
  email: Joi.string().email().required().label('Email'),
  gender: Joi.string().optional().label('Gender'),
};

const validatePhoneNumber = (code, p) => {
  const phone = code + p;
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
    phone
  );
};

const PreRegisterForm = () => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [values, setValues] = useState({
    full_name: '',
    email: '',
    gender: '0',
    phone: '',
    country_code: '+1',
  });

  const [errors, setErrors] = useState({
    full_name: '',
    email: '',
    gender: '',
    phone: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    if (name === 'phone' || name === 'country_code') {
      return null;
    } else {
      const obj = { [name]: value };
      const subSchema = { [name]: schema[name] };
      const { error } = Joi.validate(obj, subSchema);

      const it = error
        ? setErrors({ ...errors, [name]: error.details[0].message })
        : setErrors({ ...errors, [name]: '' });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      let hasError = false;
      if (validate()) {
        hasError = true;
      }
      if (!validatePhoneNumber(values.country_code, values.phone)) {
        setErrors(prev => ({ ...prev, phone: 'Invalid phone number' }));
        hasError = true;
      }
      if (!hasError) {
        const apiData = {
          first_name: values.full_name,
          email: values.email,
          phone_number: values.country_code + values.phone,
          ...(values.gender !== '0' && { gender: values.gender }),
        };
        await preRegister(apiData);
        setShowModal(true);
      }
    } catch (err) {
      console.log(err.message);
      if (axios.isAxiosError(err)) {
        const msg = err.response.data.message;
        if (msg.includes('Phone')) {
          setErrors(prev => ({ ...prev, phone: msg }));
        } else {
          setErrors(prev => ({ ...prev, email: msg }));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    // console.log(location);
    const { country_code, phone, ...data } = values;

    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) {
      setErrors({});
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details) {
        if (item.path[0] !== 'undefined')
          errorsObject[item.path[0]] = item.message;
      }
      setErrors(errorsObject);
      return true;
    }
  };

  const checkValidations = () => {
    if (values.full_name !== '' && values.email !== '' && values.phone !== '') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!checkValidations()) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);
  return (
    <Grid className={classes.container}>
      <form action='' onSubmit={e => e.preventDefault()}>
        <Grid
          container
          direction='column'
          spacing={2}
          className={classes.formContainer}
        >
          <Grid
            item
            container
            justifyContent='center'
            style={{ width: '100%' }}
          >
            <img src={image.introLogo} width={200} alt='' />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Input
              type='text'
              placeholder='Full Name'
              name='full_name'
              value={values.full_name}
              onChange={handleChange}
              error={Boolean(errors.full_name)}
              helperText={errors.full_name}
            />
          </Grid>

          <Grid item style={{ width: '100%' }}>
            <Input
              type='text'
              value={values.email}
              placeholder='Email'
              name='email'
              onChange={handleChange}
              // value={user.firstname}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <SelectOption
              value={values.gender}
              placeholder='Gender (Optional)'
              options={genderList}
              showNotVisible
              name='gender'
              onSelect={handleChange}
            />
          </Grid>
          <Grid>
            <Grid
              item
              container
              wrap='nowrap'
              className={classes.inputContainer}
            >
              <Select
                value={values.country_code}
                onChange={handleChange}
                name='country_code'
                variant='outlined'
                className={classes.select}
                error={Boolean(errors.phone)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  className: classes.menu,
                }}
              >
                <MenuItem className={classes.menuItem} value='+1'>
                  +1
                </MenuItem>
                <MenuItem className={classes.menuItem} value='+92'>
                  +92
                </MenuItem>
                <MenuItem className={classes.menuItem} value='+93'>
                  +93
                </MenuItem>
              </Select>
              <Grid item style={{ flexGrow: 1 }}>
                <Input
                  type='number'
                  placeholder='Phone Number'
                  name='phone'
                  onChange={handleChange}
                  error={Boolean(errors.phone)}
                />
              </Grid>
            </Grid>
            {errors.phone && (
              <div style={{ paddingTop: '5px' }}>
                <Typography
                  variant='caption'
                  color='error'
                  style={{
                    fontSize: '14px',
                    paddingInline: '10px',
                  }}
                >
                  {errors.phone}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item>
            <ButtonComp
              label='Register'
              className={classes.button}
              disabled={disable || loading}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </form>
      <Congratulation open={showModal} setOpen={setShowModal} />
    </Grid>
  );
};

export default PreRegisterForm;

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F0EF',
  },
  formContainer: {
    width: '100%',
    maxWidth: '450px',
  },
  select: {
    // "&.Mui-focused": {
    // 	border: "1px solid transparent",
    // },
    width: '128px',
    flexShrink: 'none',
    borderRadius: '34px',
    // border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    fontSize: '21px',
    height: '45px',
    // color: "rgba(112,112,112,0.5)",
    color: '#000000',
    // boxShadow: theme.shadows[3],
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root ': {
      textAlign: 'center',
    },
    '& .MuiSelect-icon': {
      postion: 'absolute',
      display: 'none',
      left: '5px',
      [theme.breakpoints.down('lg')]: {
        left: '3px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '90px',
      height: '45px',
      fontSize: '15px',
      marginBottom: '-13px',

      // border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '60px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: theme.shadows[2],
      border: `1px solid transparent`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid transparent`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid transparent`,
    },
    '& .MuiSelect-outlined': {
      padding: 0,
    },
  },
  inputContainer: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px',
    marginTop: '-13px',
    [theme.breakpoints.down('lg')]: {
      gap: '10px',
    },
  },
  menuItem: {
    color: '#000',
    marginBottom: '5px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  input: {
    fontSize: '21px',
    backgroundColor: '#fff',
    borderRadius: '34px',
    height: '59px',
    margin: '0',
    paddingLeft: '7px',
    // width: '336px',
    [theme.breakpoints.down('lg')]: {
      // width: '250px',
      height: '40px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
      maxWidth: '100%',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      maxHeight: '35vh',
    },
  },
  button: {
    width: '100%',
    marginTop: '20px',
  },
}));
