import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/index';
import { ThemeProvider } from '@material-ui/core';
import theme from './components/Theme/theme';
import { HashRouter as Router, Route } from 'react-router-dom';
import { SocketContext, socket } from './http/socket';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ar from './locales/ar.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import hi from './locales/hi.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';
import zh from './locales/zh.json';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
      hi: {
        translation: hi,
      },
      it: {
        translation: it,
      },
      ja: {
        translation: ja,
      },
      ko: {
        translation: ko,
      },
      pt: {
        translation: pt,
      },
      ru: {
        translation: ru,
      },
      zh: {
        translation: zh,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <Router>
          <Route path='/' component={App} />
        </Router>
      </Provider>
    </SocketContext.Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
