import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: '#F2F0EF',
    // backgroundSize: "554.5px 520.5px, 330px 250px",
    backgroundRepeat: 'no-repeat',
    // backgroundSize: "390px 390px, 240px 190px"
    paddingBlock: '130px 79px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '75px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBlock: '75px 20px',
    },
  },

  form: {
    padding: '0 8rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
  },
  formContainer: {
    // width: "860px",
    // backgroundColor: "red",
    // marginTop: "2rem",
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
      // padding: "5px 10px",
      // width: "680px",
    },
    zIndex: 1,
  },
  checkbox: {
    color: theme.palette.primary.main,
    borderRadius: '4px',
    transform: 'scale(1.6)',
    [theme.breakpoints.down('lg')]: {
      transform: 'scale(1.3)',
    },
  },
  // skipButton: {
  //   backgroundColor: "white",
  // },
  p: {
    textAlign: 'left',
    color: '#000',
    marginTop: '2rem',
    fontSize: '23px',
    fontWeight: '100',
    '& a': {
      color: '#000    ',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      marginTop: '1rem',
    },
  },
  formTitle: {
    marginBottom: '1.9rem',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      marginBottom: '0rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
  },
  // skipButton: {
  //   backgroundColor: theme.palette.primary.main,
  //   width: '134px',
  //   height: '85px',
  //   borderRadius: '43px',
  //   marginTop: '20px',
  //   marginLeft: '3rem',
  //   fontSize: '28px',
  //   color: '#ffff',
  //   fontWeight: '700',
  //   textTransform: 'capitalzie',
  //   '&:hover': {
  //     backgroundColor: theme.palette.primary.dark,
  //   },
  //   display: 'inline-block',
  //   [theme.breakpoints.down('lg')]: {
  //     width: '80px',
  //     height: '50px',
  //     fontSize: '18px',
  //     marginTop: '10px',
  //   },
  // },
  title: {
    marginLeft: '34px',
    marginBottom: '0.8rem',
    fontSize: '22px',
    color: '#000',
    fontWeight: '400',
  },
  showText: {
    fontSize: '15px',
    marginRight: '11px',
  },
  checkboxWithText: {
    backgroundColor: '#fff',
    borderRadius: '34px',
    border: `2px solid  ${theme.palette.primary.main}`,
    width: '264px',
    height: '62px',
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '100%',
    },
  },
  intentContainer: {
    marginTop: '40px',
  },
  label: {
    marginLeft: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      width: '88%',
    },
  },
  showProfileText: {
    fontSize: '15px',
    marginRight: '10px',
    marginLeft: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  checkboxContainer: {
    marginTop: '0.8rem',
    marginBottom: '1rem',
    paddingLeft: '16px',
    width: '880px',
    [theme.breakpoints.down('lg')]: {
      width: '700px',
    },
  },
  t2: {
    fontSize: '18px',
    fontWeight: '100',
  },
  inputContainer: {
    width: '456px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  formControlLabel: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: '264px',
    height: '62px',
    borderRadius: '34px',
    backgroundColor: 'white',
    padding: '0.5rem',
    margin: '0',
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '100%',
    },
    '&.MuiFormControlLabel-root': {
      justifyContent: 'space-between',
    },
    '& .MuiFormControlLabel-label': {
      color: '#000000',
      margin: '0',
      marginLeft: '5px',
      fontSize: '18px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '1.3rem',
    marginLeft: '20px',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      marginTop: '7px',
    },
  },

  // select styles
  selectContainer: {
    width: '456px',
    [theme.breakpoints.down('lg')]: {
      width: '300px',
    },
  },
  select: {
    width: '456px',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    marginBottom: '15px',
    height: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      height: '40px',
      fontSize: '15px',
      marginBottom: '0px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  select1: {
    width: '456px',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    marginBottom: '10px',
    minHeight: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      borderColor: 'transparent',
    },
    '&:after': {
      borderColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      minHeight: '40px',
      fontSize: '15px',
      marginBottom: '0px',
    },
  },
  menuOrigin: {
    visibility: 'hidden',
    '& .MuiMenu-paper': {
      visibility: 'hidden',
    },
  },
  inputLabel: {
    marginLeft: '25px',
    padding: 'none',
    fontWeight: '300',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      marginLeft: '14px',
    },
  },
  switchRoot: {},
  switchContainer: {
    marginTop: '-10px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
    },
    width: '210px',
    '& span': {
      fontSize: '15px',
      marginRight: '20px',
      marginLeft: '22px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        marginLeft: '14px',
        marginRight: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      margin: '0px',
      width: '140px',
    },
  },
  dropdownIcon: {
    fontSize: '2rem',
    marginRight: '5px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
  },
  none: {
    color: 'rgba(112,112,112,0.5)',
  },

  //   dialog styles
  dialog: {
    '& .MuiDialog-paper': {
      border: '1px solid #707070',
      borderRadius: '0px',
    },
  },
  dialogContainer: {
    backgroundColor: theme.palette.common.lightPink,
    // padding: "36px 20px",
    paddingBlock: '36px 25px',
    paddingInline: '20px',
  },
  dialogTitle: {
    margin: 0,
    color: '#000000',
    textAlign: 'left',
    fontSize: '22px',
    fontWeight: 'bold',
  },
  dialogSubtitle: {
    margin: 0,
    color: '#828282',
    textAlign: 'left',
    fontSize: '18px',
  },
  chipsContainer: {
    marginTop: '35px',
  },
  dialogButton: {
    width: '369px',
    height: '57px',
    margin: 0,
    fontSize: '16px',
    color: '#ffffff',
    marginTop: '1.25rem',
    textTransform: 'none',
    borderRadius: '29px',
  },
  active: {
    border: '2px solid red',
  },
  menuItem: {
    color: '#000',
    marginBottom: '5px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  underline: {
    // visibility: "hidden",
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: 'transparent',
    },
  },
  chips: { display: 'flex', flexWrap: 'wrap' },
  chip: {
    margin: '2px',
    [theme.breakpoints.down('lg')]: {
      height: '23px',
      margin: '1px',
      fontSize: '11px',
    },
  },
  inputContainer: {
    marginBottom: '30px',
    position: 'relative',
    padding: '0px',

    '& .chips-container': {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      '& .chip': {
        backgroundColor: 'white',
        height: '40px',
        borderRadius: '20px',
        boxShadow: theme.shadows[1],
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  skipButton: {
    height: '42px',
    width: '134px',
    fontSize: '25px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    borderRadius: '43px',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      height: '35px',
      width: '120px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '45px',
      marginBottom: '20px',
    },
  },
  select1: {
    width: '589px',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    marginBottom: '10px',
    minHeight: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      borderColor: 'transparent',
    },
    '&:after': {
      borderColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      width: '450px',
      minHeight: '45px',
      fontSize: '15px',
      marginBottom: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  menuItem: {
    color: '#000',
    marginBottom: '0px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      margin: 0,
      color: '#000000',
      fontSize: '22px',
      textAlign: 'left',
      marginLeft: '10px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '15px',
        marginLeft: '0px',
      },
    },
  },
  customPlaceHolder: {
    color: '#000000',
    opacity: '0.4',
    position: 'absolute',
    fontSize: '22px',
    top: '14px',
    left: '20px',
    zIndex: 0,
    pointerEvents: 'none',
    [theme.breakpoints.down('lg')]: {
      top: '12px',
      left: '20px',
      fontSize: '15px',
    },
  },
  selectContainer: {
    width: '589px',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dropdownIcon: {
    marginRight: '15px',
    width: '16px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
  },
  menu: {
    position: 'absolute',
    '& .MuiMenu-paper': {
      maxHeight: '35vh',
    },
  },
  chipParentContainer: {
    maxWidth: '685px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '465px',
    },
  },
}));
