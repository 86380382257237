import React from 'react';
import {
  makeStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction as Action,
  Grid,
  List,
} from '@material-ui/core';

import { ArrowForwardRounded } from '@material-ui/icons';

const languages = [
  'English',
  'Arabic',
  'Chinese',
  'French',
  'Italian',
  'Japanese',
  'Korean',
  'Portugese',
  'Russian',
  'Spanish',
  'Urdu/Hindi',
];

const Languages = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.mainContainer}>
      <List className={classes.list}>
        {languages.map((lang, index) => (
          <ListItem
            className={classes.listItem}
            style={{ paddingTop: index === 0 ? '0px' : '' }}
            key={index}
          >
            <ListItemText className={classes.text} primary={lang} />
            <Action>
              <ArrowForwardRounded className={classes.arrow} />
            </Action>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default Languages;
const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingInline: '82px 0px',
    paddingBlock: '45px 27px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '25px 15px',
      paddingInline: '40px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '0px 15px',
      paddingInline: '20px 0px',
    },
  },
  list: {
    width: '60%',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
      paddingBottom: '50px',
    },
  },
  listItem: {
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
    paddingBlock: '34px',
    paddingInline: '0px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '18px',
    },
  },
  text: {
    '& .MuiListItemText-primary': {
      margin: '0',
      fontSize: '20px',
      color: theme.palette.primary.main,
      textAlign: 'left',
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },

  arrow: {
    color: theme.palette.primary.main,
    fontSize: '30px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
}));
