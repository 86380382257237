import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
  Grid,
  Avatar,
  Badge,
  // useTheme,
  // useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getLiveUsers, checkMatch } from '../../../http';
import { setOnlineUsers } from '../../../store/user';
import { useHistory } from 'react-router-dom';
import image from '../../../assets';
import { toRad } from '../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2rem 2rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1.5rem 2rem',
    },
  },
  title: {
    fontSize: '39px',
    fontWeight: '700',
    marginTop: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
      marginBottom: '1rem',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#26BB21',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
      minWidth: '0',
      [theme.breakpoints.down('lg')]: {
        width: '12px',
        height: '12px',
      },
    },
  },
  badge2: {
    '& .MuiBadge-badge': {
      backgroundColor: '#f5ce42',
      color: '#26BB21',
      height: '39px',
      width: '39px',
      borderRadius: '50%',
      border: '2px solid #ffffff',
      [theme.breakpoints.down('lg')]: {
        width: '16px',
        height: '16px',
        minWidth: '0',
      },
    },
  },
  avatar: {
    height: '91px',
    width: '91px',
    [theme.breakpoints.down('lg')]: {
      height: '70px',
      width: '70px',
    },
  },
  avatarContainer: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarTxt: {
    fontSize: '21px',
    marginTop: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  fixButton: {
    backgroundColor: '#FE858C',
    position: 'fixed',
    bottom: 20,
    right: 20,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#Ff859C',
    },
  },
  innerContainer: {
    gap: '31px 110px',
    // flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
      gap: '20px 50px',
    },
  },
  item: {
    flexWrap: 'nowrap',
    marginBottom: '2rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '1rem',
    },
  },
  notFoundContainer: {
    marginTop: '10rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '6rem',
    },
  },
  infoBox: {
    marginLeft: '13px',
  },
  info: {
    textAlign: 'left',
    margin: '0px',
    color: '#000000',
    fontSize: '19px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
}));

export const Online = () => {
  const classes = useStyles();
  // const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  // const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const onlineUsers = useSelector(state => state.auth.user.onlineUsers);
  let lat = localStorage.getItem('latitude');
  let lon = localStorage.getItem('Longitude');

  const calcDistance = (lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat);
    var dLon = toRad(lon2 - lon);
    var lat1 = toRad(lat);
    var lat2 = toRad(lat);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1.60934).toFixed(1);
  };
  const handleClick = async id => {
    const { data } = await checkMatch(id);
    if (data.data) {
      history.push(`/home/match/${id}`);
    } else {
      history.push(`/home/unmatch/${id}`);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getLiveUsers();
        dispatch(setOnlineUsers(data.data));
      } catch (err) {
        console.log(err.message);
      }
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.container} style={{ width: '100%' }}>
      <h1 className={classes.title}>Online</h1>
      <Grid
        container
        className={classes.innerContainer}
        style={{
          justifyContent: onlineUsers.length === 0 ? 'center' : 'unset',
        }}
      >
        {onlineUsers.length === 0 && (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
            className={classes.notFoundContainer}
            sm={2}
          >
            <img src={image.find} className={classes.notFoundImage} alt='' />
            <span className={classes.notFound}>
              No user is online right now
            </span>
          </Grid>
        )}
        {onlineUsers.map(user => (
          <Grid key={user._id} item className={classes.item}>
            <Grid item container alignItems='center'>
              <Grid container alignItems='center'>
                <Badge
                  className={classes.badge}
                  overlap={'circular'}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  color='secondary'
                  badgeContent=''
                >
                  <Avatar
                    className={classes.avatar}
                    src={`${process.env.REACT_APP_API_URL}/${user.profile_image}`}
                    onClick={() => handleClick(user._id)}
                  />
                </Badge>
                <Grid item className={classes.infoBox}>
                  <Grid item container direction='column'>
                    <Grid item>
                      <Typography className={classes.info}>
                        {user?.date_of_birth?.age}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.info}>
                        {calcDistance(
                          user.location.coordinates[1],
                          user.location.coordinates[0]
                        ) + ' mi'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <span className={classes.avatarTxt}>@{user.username}</span>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
