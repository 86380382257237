import { makeStyles } from '@material-ui/core';
import image from '../../../assets/index';

export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: '#F2F0EF',
    backgroundImage: `url(${image.bgUser})`,
    backgroundSize: '644.5px 644.5px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 100%',
    paddingBlock: '94px 79px',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '390px 390px',
      paddingBlock: '75px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '300px 300px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '220px 220px',
      paddingBlock: '75px 20px',
    },
  },
  formTitle: {
    marginBottom: '56px',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      marginBottom: '0rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formContainer: {
    width: '593px',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
      marginTop: '20px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    borderRadius: '4px',
    transform: 'scale(1.6)',
    [theme.breakpoints.down('lg')]: {
      transform: 'scale(1.2)',
    },
  },

  p: {
    textAlign: 'left',
    color: '#000',
    margin: '0',
    marginTop: '13px',
    fontSize: '23px',
    fontWeight: '100',
    '& .link': {
      color: '#000',
    },
    link: {
      color: '#000',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  typographyS1: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      marginLeft: '10px',
    },
  },
  inputContainer: {
    marginBottom: '39px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '25px',
    },
  },
  main: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 20px',
    },
  },
}));
