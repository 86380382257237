import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Typography,
  Avatar,
  Grid,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { getUserById, visitedUser, otherUserVideos } from '../../../http';
import axios from 'axios';
import { Video } from '../../../components/video/Video';
import image from '../../../assets';
import clsx from 'clsx';
import chipsIcon from '../../../assets/chipsIcons';
import { calculateZodiacSign } from '../../../utils/zodiacSign';
import zodiacSigns from '../../../assets/zodiacSigns';
import { countryList } from '../../../app.config';
import GeoCode from 'react-geocode';
import { getCity } from '../../../utils';

GeoCode.setApiKey(process.env.REACT_APP_GEOCODE_API);

export const UnMatch = props => {
  const id = props.match.params.id;
  const classes = useStyles();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [zodiac, setZodiac] = useState('');
  const [user, setUser] = useState({
    phonenumber: '',
    socialMedia_id: '',
    step: '/dob',
    date_of_birth: { dob: '', age: 0 },
    email: '',
    first_name: '',
    last_name: '',
    username: '',
    identify: { gender: '', visible: false },
    date_preference: {
      interested_gender: '',
      interested_audience: '',
      visible: false,
    },
    height: { height: 0.0, visible: false },
    body_type: { type: '', visible: false },
    ethnicity: {
      country_list: [],
      c_visible: false,
      race: '',
      r_visible: false,
    },
    location: { lat: '', lon: '', geoHash: '', visible: false },
    home_town: {
      live_with: '',
      home_town: '',
      live_with_visible: false,
      visible: false,
    },
    education: {
      school: '',
      s_visible: false,
      degree: '',
      d_visible: false,
    },
    profession: {
      company: { company: '', c_visible: false },
      job_title: { job_title: '', j_visible: false },
      occupation: { occupation: '', o_visible: false },
    },
    religion: { religion: '', visible: false },
    vices: {
      drink: { drink: '', d_visible: false },
      smoke: { smoke: '', s_visible: false },
      weed: { weed: '', w_visible: false },
      drugs: { drugs: '', dr_visible: false },
    },
    profile_image: '',
    children: { have_children: '', want_children: '', visible: false },
    prompt: [{ question: '', url: '' }],
  });

  const [city, setCity] = useState('');
  const [videos, setVideos] = useState([]);

  const videosRef = useRef([]);
  videosRef.current = [];
  const toFeet = cm => {
    const realFeets = (cm * 0.3937) / 12;
    let feets = Math.floor(realFeets);
    let inches = Math.round((realFeets - feets) * 12);
    if (inches === 12) {
      feets = feets + 1;
      inches = 0;
    }
    return `${feets}'${inches}"`;
  };

  const handleScroll = () => {
    const boundry = {
      top: {
        upper: lgScreen ? -147 : -370,
        lower: lgScreen ? 382 : 530,
      },
      bottom: {
        upper: lgScreen ? 385 : 540,
        lower: lgScreen ? 912 : 1441,
      },
    };
    // eslint-disable-next-line
    videosRef.current.map(item => {
      const top = item.getBoundingClientRect().top;
      const bottom = item.getBoundingClientRect().bottom;
      if (
        top > boundry.top.upper &&
        top < boundry.top.lower &&
        bottom > boundry.bottom.upper &&
        bottom < boundry.bottom.lower
      ) {
        if (item.readyState >= 2) {
          item.play();
        }
        // console.log(video.readyState);
      } else {
        item.pause();
      }
    });
  };
  const addToRef = e => {
    if (e && !videosRef.current.includes(e) && !null) videosRef.current.push(e);
  };
  const HandleGetCountryFlags = countryArray => {
    // console.log(countryArray);
    const countries = [];

    countryArray?.forEach(country => {
      countryList.forEach(c => {
        if (c.name === country) {
          countries.push(c);
        }
      });
    });

    return countries;
  };
  useEffect(() => {
    (async function () {
      await axios
        .all([getUserById(id), visitedUser(id), otherUserVideos(id)])
        .then(
          axios.spread((res1, res2, res3) => {
            setUser(res1.data.data);
            setVideos(res3.data.data);
            getCity(res1.data.data.location.coordinates);
            let birthday = res1.data.data.date_of_birth.dob;
            birthday = birthday.split('/');
            const sign = calculateZodiacSign(
              Number(birthday[1]),
              Number(birthday[0])
            );
            setZodiac(sign);
            getCity(
              res1.data.data.location.lat,
              res1.data.data.location.lon,
              setCity
            );
          })
        )
        .catch(err => {
          console.log(err.message);
        });
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (videosRef.current.length > 0) {
      videosRef.current[0].play();
    }
  });
  return (
    <Grid container className={classes.container}>
      <Grid
        item
        container
        alignItems='center'
        direction='column'
        className={classes.left}
      >
        <Grid item className={classes.userInfo}>
          <Grid container direction='column' spacing={2}>
            <Grid item className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={`${process.env.REACT_APP_API_URL}/${user.profile_image}`}
              />
            </Grid>
            <Grid item container direction='column' alignItems='center'>
              <Typography
                style={{ textTransform: 'capitalize' }}
                className={classes.userName}
              >
                {`${user.first_name}`}, {user.date_of_birth.age}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='column'>
          <List className={classes.list}>
            {user.identify.visible && user.identify.gender && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pGender}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={user.identify.gender}
                />
              </ListItem>
            )}

            {user.location.visible && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pLocation}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={city} />
              </ListItem>
            )}

            {user.height.visible && user.height.height && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pHeight}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={toFeet(user.height.height)}
                />
              </ListItem>
            )}

            {user.profession.company.c_visible &&
              user.profession.company.company && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img
                      src={image.pCompany}
                      alt=''
                      className={classes.listIcon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={user.profession.company.company}
                  />
                </ListItem>
              )}

            {user.profession.job_title.j_visible &&
              user.profession.job_title.job_title && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={image.pJob} alt='' className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={user.profession.job_title.job_title}
                  />
                </ListItem>
              )}

            {user.education.school.s_visible && user.education.school && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pSchool}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={user.education.school}
                />
              </ListItem>
            )}
          </List>
          <Grid item>
            <Typography className={classes.backgroundTitle}>
              <span style={{ textTransform: 'capitalize' }}>
                {user.first_name}
              </span>
              's Background
            </Typography>
          </Grid>
          <Grid item container>
            {HandleGetCountryFlags(user.ethnicity.country_list).map(
              (country, index) => (
                <Grid
                  item
                  container
                  key={index}
                  xs={6}
                  className={classes.flagContainer}
                >
                  <div className={classes.flexInner}>
                    <img src={country.image} alt='' />
                    <Typography className={classes.countryNames}>
                      {country.name}
                    </Typography>
                  </div>
                </Grid>
              )
            )}
          </Grid>
          {/* Basic info */}
          <Grid item>
            <Typography className={clsx(classes.backgroundTitle, 'basic-info')}>
              Basic info
            </Typography>
          </Grid>
          <Grid item container className={classes.chipsContainer}>
            {zodiac && (
              <div className='chip'>
                <img src={zodiacSigns[zodiac]} alt='' className='chip-icon' />
                <p
                  className='chip-label'
                  style={{ textTransform: 'capitalize' }}
                >
                  {zodiac}
                </p>
              </div>
            )}
            {user.date_preference.visible &&
              user.date_preference.interested_audience &&
              user.date_preference.interested_audience.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.intent} alt='' className='chip-icon sm' />
                  <p className='chip-label'>
                    {user.date_preference.interested_audience}
                  </p>
                </div>
              )}

            {user.education.d_visible &&
              user.education.degree &&
              user.education.degree.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.degree} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.education.degree}</p>
                </div>
              )}

            {user.religion.r_visible &&
              user.religion.religion &&
              user.religion.religion.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.religion}
                    alt=''
                    className='chip-icon sm'
                  />
                  <p className='chip-label'>{user.religion.religion}</p>
                </div>
              )}

            {user.religion.p_visible &&
              user.religion.politics &&
              user.religion.politics.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.politics} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.religion.politics}</p>
                </div>
              )}

            {user.identify.marital_status_visible &&
              user.identify.marital_status &&
              user.identify.marital_status.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.heart} alt='' className='chip-icon ' />
                  <p className='chip-label'>{user.identify.marital_status}</p>
                </div>
              )}

            {user.vices.drink.d_visible &&
              user.vices.drink.drink &&
              user.vices.drink.drink.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.drink} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.drink.drink}</p>
                </div>
              )}

            {user.vices.drugs.dr_visible &&
              user.vices.drugs.drugs &&
              user.vices.drugs.drugs.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.drungs} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.drugs.drugs}</p>
                </div>
              )}

            {user.vices.smoke.s_visible &&
              user.vices.smoke.smoke &&
              user.vices.smoke.smoke.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.smoke} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.smoke.smoke}</p>
                </div>
              )}
            {user.vices.weed.w_visible &&
              user.vices.weed.weed &&
              user.vices.weed.weed.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.weed} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.weed.weed}</p>
                </div>
              )}

            {user.children.have_children &&
              user.children.visible &&
              user.children.have_children.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.haveChildren}
                    alt=''
                    className='chip-icon'
                  />
                  <p className='chip-label'>{user.children.have_children}</p>
                </div>
              )}
            {user.children.want_children &&
              user.children.visible &&
              user.children.want_children.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.wantChildren}
                    alt=''
                    className='chip-icon'
                  />
                  <p className='chip-label'>{user.children.want_children}</p>
                </div>
              )}

            {user.body_type.t_visible &&
              user.body_type.type &&
              user.body_type.type.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.bodyType}
                    alt=''
                    className='chip-icon sm'
                  />
                  <p className='chip-label'>{user.body_type.type}</p>
                </div>
              )}

            {user.body_type.d_visible &&
              user.body_type.diet &&
              user.body_type.diet.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.diet} alt='' className='chip-icon sm' />
                  <p className='chip-label'>{user.body_type.diet}</p>
                </div>
              )}
            {user.body_type.f_visible &&
              user.body_type.fitness &&
              user.body_type.fitness.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.health} alt='' className='chip-icon sm' />
                  <p className='chip-label'>{user.body_type.fitness}</p>
                </div>
              )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          onScroll={handleScroll}
          className={clsx(classes.scrollDiv, 'noMainVideos')}
        >
          {videos.map((video, index) => (
            <Video
              key={index}
              video_url={video.video_url}
              like={video.like}
              video_title={video.video_title}
              ref={addToRef}
              poster={video.cover}
              video_id={video._id}
              username={video.user_name}
              user_id={video.user_id}
            />
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        className={classes.middle}
      >
        <Grid
          item
          container
          onScroll={handleScroll}
          className={clsx(classes.scrollDiv, 'mainVideos')}
        >
          {videos.map((video, index) => (
            <Video
              key={index}
              video_url={video.video_url}
              like={video.like}
              video_title={video.video_title}
              ref={addToRef}
              poster={video.cover}
              video_id={video._id}
              username={video.user_name}
              user_id={video.user_id}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item container direction='column' className={classes.right}></Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  container: {
    // padding: "0rem 3rem",
    height: '100%',
  },
  left: {
    flex: 1,
    boxSizing: 'border-box',
    paddingTop: '4rem',
    paddingInline: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '1.5rem',
      paddingTop: '1.5rem',
    },
    [theme.breakpoints.down(800)]: {
      flex: 1,
    },
    [theme.breakpoints.down(620)]: {
      height: 'unset',
      display: 'block',
    },
  },
  middle: {
    flex: 1.5,
    backgroundColor: '#fbfbfb',
    boxSizing: 'border-box',
    border: '1px solid rgba(112, 112, 112, 0.17)',
    height: '100%',
    [theme.breakpoints.down(800)]: {
      flex: 1.3,
      height: 'auto',
    },
    [theme.breakpoints.down(620)]: {
      flex: 1,
      height: 'auto',
      display: 'none',
    },
  },
  right: {
    flex: 0.7,
    [theme.breakpoints.down(800)]: {
      flex: 0,
      display: 'none',
    },
  },
  userName: {
    fontSize: '29px',
    fontWeight: 700,
    marginBottom: 0,
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  userCity: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: 0,
    margin: 0,
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  userAge: {
    fontSize: '29px',
    marginBottom: '0',
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  subtitle: {
    fontSize: '20px',
    marginBottom: 0,
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  avatar: {
    height: '202px',
    width: '202px',
    marginInline: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: '150px',
      width: '150px',
    },
    [theme.breakpoints.down('md')]: {
      height: '120px',
      width: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
    },
  },
  anchor: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    margin: 0,
    fontWeight: 700,
    marginTop: '1rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5rem',
      fontSize: '15px',
    },
  },
  userInfo: {
    width: '100%',
    padding: '0 2.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0 1.5rem',
    },
  },

  scrollDiv: {
    padding: '2rem',
    overflowY: 'auto',
    paddingBottom: '10rem',
    '&.noMainVideos': {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '1rem',
      paddingBottom: '10rem',
    },
    [theme.breakpoints.down(620)]: {
      padding: '1rem',
      height: '100%',
      overflow: 'none',
      '&.noMainVideos': {
        display: 'block',
        height: 'unset',
      },
    },
  },

  list: {
    marginTop: '45px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      margin: 0,
      color: '#000',
      textAlign: 'left',
      marginLeft: '10px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
    },
  },
  listItemIcon: {
    minWidth: '20px',
    '& img': {
      marginInline: 'auto',
    },
  },
  listItem: {
    padding: 0,
    marginBottom: '0px',
  },
  backgroundTitle: {
    margin: 0,
    color: '#7E7E7E',
    fontSize: '20px',
    textAlign: 'left',
    marginBottom: '11.5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      marginBottom: '8px',
    },
    '&.basic-info': {
      marginTop: '17px',
      [theme.breakpoints.down('lg')]: {
        marginTop: '10px',
      },
    },
  },
  countryNames: {
    margin: 0,
    color: '#505050',
    fontSize: '14px',
    textAlign: 'left',
  },

  flexInner: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '14px',
    '& img': {
      width: '22px',
    },
  },
  chipsContainer: {
    paddingBlock: '11px',
    gridGap: '9px',
    [theme.breakpoints.down('lg')]: {
      gridGap: '6px',
    },
    '& .chip': {
      backgroundColor: theme.palette.primary.main,
      height: '40px',
      paddingInline: '11px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '20px',
      gridGap: '5px',
      [theme.breakpoints.down('lg')]: {
        height: '35px',
        paddingInline: '9px',
      },
      '& .chip-label': {
        color: '#ffffff',
        fontSize: '15px',
        margin: 0,
        maxWidth: '20ch',
        [theme.breakpoints.down('lg')]: {
          fontSize: '12px',
        },
      },
      '& .chip-icon': {
        width: '14px',
        '&.sm': {
          width: '25px',
          marginLeft: '-5px',
          marginRight: '-5px',
        },
      },
    },
  },
}));
