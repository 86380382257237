import React, { useState } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Chip,
  Select,
  InputBase,
  ListItemText,
  MenuItem,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import { useStyles } from '../Styles/registerStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../../components/header/Header';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import { identify } from '../../../http/index';
import axios from 'axios';
import Joi from 'joi-browser';
import { useEffect } from 'react';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

import { submit } from '../../../store/user';

import {
  pronounsList,
  maritalStatusList,
  genderList,
} from '../../../app.config';
import image from '../../../assets';

import { useTranslation } from 'react-i18next';

export const Identify = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const userData = useSelector(state => state.auth.user.data);
  const [user, setUser] = useState({
    gender: '0',
    maritalStatus: '0',
  });

  const [show, setShow] = useState({
    gender: false,
    pronuouns: false,
    maritalStatus: false,
  });

  const [errors, setErrors] = useState({
    gender: '',
    maritalStatus: '',
  });

  const [pronounsOptions, setPronoundsOptions] = useState(pronounsList);

  const [pronouns, setPronouns] = useState([]);

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };

  const handleDeSelectPronouns = val => {
    setPronouns(pronouns.filter(p => p !== val));
  };

  const handleSelect = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, subSchema);
    const it = error
      ? setErrors({
          ...errors,
          [name]: `"${
            name.charAt(0).toUpperCase() + name.slice(1)
          }" can not be empty`,
        })
      : setErrors({ ...errors, [name]: '' });
  };

  const schema = {
    gender: Joi.string().min(2).required().label('Gender'),
    pronuouns: Joi.array().required().label('Pronuouns'),
    maritalStatus: Joi.string().min(2).required().label('Marital Status'),
  };

  const validate = () => {
    const data = {
      gender: user.gender,
      pronuouns: pronouns,
      maritalStatus: user.maritalStatus,
    };
    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) {
      setErrors({
        gender: '',
        pronuouns: '',
        maritalStatus: '',
      });
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details)
        errorsObject[item.path[0]] = item.message;

      console.log(errorsObject);
      if (user.gender.length <= 1) {
        errorsObject.gender = 'gender can not be empty!';
      }
      setErrors(errorsObject);
      return true;
    }
  };

  const handleSubmit = async () => {
    const error = validate();
    try {
      if (!error) {
        const genderData = {
          gender: user.gender,
          visible: show.gender,
          pronouns: [...pronouns],
          pronouns_visible: show.pronuouns,
          marital_status: user.maritalStatus,
          marital_status_visible: show.maritalStatus,
          step:
            userData.step === '/gender-selection'
              ? '/choose-date-characters'
              : userData.step,
        };
        const { data } = await identify(genderData);
        dispatch(submit(data));
        history.push('/register/dating-preference');
      }
    } catch (err) {
      console.log(err.message);
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    }
  };

  const checkValidation = () => {
    if (user.gender.length >= 2 && user.maritalStatus.length >= 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (user.gender === '0') {
      setShow(prev => ({ ...prev, gender: false }));
    }
    if (user.maritalStatus === '0') {
      setShow(prev => ({ ...prev, maritalStatus: false }));
    }
    if (pronouns.length === 0) {
      setShow(prev => ({ ...prev, pronuouns: false }));
    }
  };

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handlePronouns = e => {
    const val = e.target.value.filter(c => c);
    setPronouns(val);
    setShow(prev => ({ ...prev, pronuouns: true }));
  };

  useEffect(() => {
    checkValidation();
  }, [user, pronouns]);

  useEffect(() => {
    setUser({
      gender: userData?.identify?.gender || '0',
      maritalStatus: userData?.identify?.marital_status || '0',
    });
    setShow({
      gender: userData?.identify?.visible || false,
      pronuouns: userData?.identify?.pronouns_visible || false,
      maritalStatus: userData?.identify?.marital_status_visible || false,
    });
    setPronouns(userData?.identify?.pronouns || []);
  }, [userData]);

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.genderBg})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${180}px ${180 * 1.11}px`
              : `${220}px ${220 * 1.11}px`
            : `${300}px ${300 * 1.11}px`
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Typography
          variant='h1'
          style={{ margin: '0' }}
          className={classes.formTitle}
        >
          How do you Identify?
        </Typography>
        <form autoComplete='off' style={{ width: xsScreen ? '100%' : null }}>
          <Grid container className={classes.formContainer} direction='column'>
            <Grid
              item
              className='content'
              style={{ marginRight: smScreen ? '0px' : '-210px' }}
            >
              <Grid
                container
                direction='column'
                spacing={lgScreen ? (smScreen ? 2 : 4) : 8}
              >
                <Grid className={classes.inputContainer} item>
                  <SelectOption
                    label={t('gender')}
                    options={genderList}
                    placeholder='Select an option'
                    name='gender'
                    value={user.gender}
                    onSelect={handleSelect}
                    errorText={errors?.gender}
                    checkboxVaraint='switch'
                    show={show.gender}
                    handleShow={handleShow}
                  />
                </Grid>
                <Grid
                  item
                  className={pronouns.length > 1 ? '' : classes.inputContainer}
                >
                  <Grid container spacing={2} direction='column'>
                    <Grid item style={{ paddingBottom: '0' }}>
                      <Typography className={classes.inputLabel} variant='h3'>
                        {t('pronouns')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{ position: 'relative' }}
                      alignItems='center'
                    >
                      <Grid
                        item
                        style={{ position: 'relative' }}
                        className={classes.selectContainer}
                      >
                        <Select
                          value={pronouns}
                          onChange={handlePronouns}
                          // variant="outlined"
                          multiple
                          error={true}
                          className={classes.select1}
                          color='primary'
                          name='ethinic'
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            className: classes.menu,
                          }}
                          classes={{ root: classes.selectRoot }}
                          inputProps={{
                            classes: {
                              root: classes.border,
                            },
                            placeholder: 'Select an option',
                          }}
                          IconComponent={() => (
                            <img
                              src={image.downArrowBlue}
                              className={classes.dropdownIcon}
                              alt=''
                            />
                          )}
                          input={
                            <InputBase
                              style={{
                                border: errors.ethinic
                                  ? `2px solid ${theme.palette.error.main}`
                                  : `1px solid transparent`,
                                boxShadow: theme.shadows[2],
                              }}
                              id='select-multiple-chip'
                            />
                          }
                          renderValue={() => (
                            <span className={classes.customPlaceHolder}>
                              <span> Choose pronouns</span>
                            </span>
                          )}
                        >
                          {pronounsOptions.map((option, index) => (
                            <MenuItem
                              key={index}
                              className={classes.menuItem}
                              value={option}
                            >
                              <MuiCheckbox
                                color='primary'
                                style={{
                                  transform: lgScreen
                                    ? 'scale(0.8)'
                                    : 'scale(1.3)',
                                  marginRight: lgScreen ? '10px' : '0px',
                                  width: lgScreen ? '31px' : '24px',
                                  height: lgScreen ? '31px' : '24px',
                                  minWidth: '0px',
                                  minHeight: '0px',
                                }}
                                checked={pronouns.indexOf(option) > -1}
                                // checked={() => console.log("working ")}
                              />
                              <ListItemText
                                className={classes.listItemText}
                                primary={t(option)}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        {pronouns.length === 0 ? (
                          <span className={classes.customPlaceHolder}>
                            <span> Select an option</span>
                          </span>
                        ) : null}

                        {Boolean(errors) && (
                          <span className={classes.error}>
                            {errors?.pronouns}
                          </span>
                        )}
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems='center'
                        justifyContent='flex-start'
                        className={`${classes.switchContainer} checkbox`}
                      >
                        <span>{t('show_on_profile')}</span>
                        <Checkbox
                          variant='switch'
                          name='pronuouns'
                          show={show.pronuouns}
                          handleShow={handleShow}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {pronouns.length > 0 && (
                  <Grid
                    className={classes.inputContainer}
                    style={{ paddingBlock: '0px' }}
                    item
                  >
                    <div className='chips-container'>
                      {pronouns.map(p => (
                        <Chip
                          key={p}
                          className='chip'
                          size='medium'
                          label={t(p)}
                          clickable
                          onDelete={() => handleDeSelectPronouns(p)}
                        />
                      ))}
                    </div>
                  </Grid>
                )}
                <Grid className={classes.inputContainer} item>
                  <SelectOption
                    label={t('marital_status')}
                    options={maritalStatusList}
                    placeholder='Select an option'
                    name='maritalStatus'
                    value={user.maritalStatus}
                    onSelect={handleSelect}
                    errorText={errors?.maritalStatus?.replace(
                      'MaritalStatus',
                      'Marital status'
                    )}
                    checkboxVaraint='switch'
                    show={show.maritalStatus}
                    handleShow={handleShow}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          className={classes.formContainer}
          justifyContent='center'
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
          }}
        >
          <ButtonComp
            label={t('continue')}
            onClick={handleSubmit}
            disabled={disabled}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
