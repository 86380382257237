import React, { useState, useEffect } from 'react';
import { useStyles } from '../Styles/registerStyles';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import image from '../../../assets/index';
import { SelectOption } from '../../../components/SelectOption/SelectOption';
import { Header } from '../../../components/header/Header';
import { bodyType, step } from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import { bodyTypeList, dietList, fitnessList } from '../../../app.config';
import axios from 'axios';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';

import { useTranslation } from 'react-i18next';

export const Body = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation();

  const userData = useSelector(state => state.auth.user.data);

  const bodyTypeOptions = bodyTypeList;
  const [disabled, setDisabled] = useState(true);

  const [show, setShow] = useState({
    bodyType: false,
    diet: false,
    fitness: false,
  });
  const [values, setValues] = useState({
    bodyType: '0',
    diet: '0',
    fitness: '0',
  });
  const [errors] = useState({
    bodyType: '',
    diet: '',
    fitness: '',
  });

  const handleShow = e => {
    const { checked, name } = e.target;
    setShow({ ...show, [name]: checked });
  };
  const handleSelect = e => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    setShow(prev => ({ ...prev, [name]: true }));
  };
  const handleNext = async () => {
    // const error = validate();
    // if (!error) {
    const bodyTypeData = {
      type: values.bodyType === '0' ? '' : values.bodyType,
      t_visible: show.bodyType,
      diet: values.diet === '0' ? '' : values.diet,
      d_visible: show.diet,
      fitness: values.fitness === '0' ? '' : values.fitness,
      f_visible: show.fitness,
      step: userData.step === '/body-type' ? '/ethnicity-page' : userData.step,
    };
    // console.log(bodyTypeData);
    await axios
      .all([bodyType(bodyTypeData)])
      .then(
        axios.spread(function (res1) {
          // console.log(res1.data);
          dispatch(submit(res1.data));
          history.push('/register/origin');
        })
      )
      .catch(err => {
        console.log(err.message);
      });
    // }
  };

  const handleSkip = async () => {
    const stepData = {
      step: userData.step === '/body-type' ? '/ethnicity-page' : userData.step,
    };
    try {
      const { data } = await step(stepData);
      dispatch(submit(data));
      history.push('/register/origin');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      values.bodyType !== '0' ||
      values.diet !== '0' ||
      values.fitness !== '0'
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (values.bodyType === '0') {
      setShow(prev => ({ ...prev, bodyType: false }));
    }
    if (values.diet === '0') {
      setShow(prev => ({ ...prev, diet: false }));
    }
    if (values.fitness === '0') {
      setShow(prev => ({ ...prev, fitness: false }));
    }
  }, [values]);

  useEffect(() => {
    setValues({
      bodyType: userData?.body_type?.type || '0',
      diet: userData?.body_type?.diet || '0',
      fitness: userData?.body_type?.fitness || '0',
    });
    setShow({
      bodyType: userData?.body_type?.t_visible || false,
      diet: userData?.body_type?.d_visible || false,
      fitness: userData?.body_type?.f_visible || false,
    });
  }, [userData]);

  return (
    <Grid
      container
      className={classes.container}
      style={{
        backgroundImage: `url(${image.bodyBg})`,
        backgroundPosition: '100% 100%',
        backgroundSize: lgScreen
          ? smScreen
            ? xsScreen
              ? `${180 * 1.444}px ${180}px`
              : `${220 * 1.444}px ${220}px`
            : '361px 250px'
          : '',
      }}
    >
      <Header transparent headerItems={[]} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
        justifyContent='space-between'
      >
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '520px'
              : '799px',
            marginRight: smScreen ? '0px' : '-210px',
          }}
          justifyContent='space-between'
        >
          <div />
          <Typography
            variant='h1'
            className={classes.formTitle}
            style={{ margin: '0px' }}
          >
            {t('body')}
          </Typography>
          <Hidden smDown>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <div />
          </Hidden>
        </Grid>
        <form action='' style={{ width: xsScreen ? '100%' : null }}>
          <Grid container direction='column' className={classes.formContainer}>
            <Grid
              item
              className='content'
              style={{ marginRight: smScreen ? '0px' : '-210px' }}
            >
              <Grid container direction='column' spacing={lgScreen ? 4 : 8}>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('body_type')}
                    options={bodyTypeOptions}
                    placeholder='Select an option'
                    show={show.bodyType}
                    handleShow={handleShow}
                    name='bodyType'
                    onSelect={handleSelect}
                    value={values.bodyType}
                    error={Boolean(errors.bodyType)}
                    errorText={errors.bodyType}
                  />
                </Grid>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('diet')}
                    options={dietList}
                    placeholder='Select an option'
                    show={show.diet}
                    handleShow={handleShow}
                    name='diet'
                    onSelect={handleSelect}
                    value={values.diet}
                    error={Boolean(errors.diet)}
                    errorText={errors.diet}
                  />
                </Grid>
                <Grid item sm={12}>
                  <SelectOption
                    checkboxVaraint='switch'
                    label={t('exercise')}
                    options={fitnessList}
                    placeholder='Select an option'
                    show={show.fitness}
                    handleShow={handleShow}
                    name='fitness'
                    onSelect={handleSelect}
                    value={values.fitness}
                    error={Boolean(errors.fitness)}
                    errorText={errors.fitness}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          item
          container
          style={{
            width: lgScreen
              ? smScreen
                ? xsScreen
                  ? '100%'
                  : '350px'
                : '450px'
              : '589px',
            marginTop: '20px',
          }}
          justifyContent='center'
        >
          <Hidden mdUp>
            <Button
              variant='contained'
              className={classes.skipButton}
              onClick={handleSkip}
            >
              {t('skip')}
            </Button>
          </Hidden>
          <ButtonComp
            onClick={handleNext}
            disabled={disabled}
            label={t('continue')}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
