import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  input: {
    fontSize: '22px',
    backgroundColor: '#ffffff',
    borderRadius: '34px',
    height: '62px',
    paddingLeft: '7px',
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      fontSize: '15px',
    },
  },
  inputDate: {
    fontSize: '22px',
    backgroundColor: '#fff',
    borderRadius: '34px',
    height: '62px',
    border: `2px solid ${theme.palette.primary.light}`,
    margin: '0',
    paddingLeft: '7px',
    '&::-webkit-calendar-picker-indicator': {
      backgroundColor: 'red',
      color: 'rgba(0,0,0,0)',
      display: 'block',
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      backgroundColor: 'red',
      fontSize: '15px',
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  inputLabel: {
    marginLeft: '25px',
    marginBottom: '22px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      marginLeft: '14px',
      marginBottom: '15px',
    },
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      boxShadow: theme.shadows[2],
      '& fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
      '&:hover fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
      '&.Mui-focused fieldset': {
        border: '1px solid transparent',
        // border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
  error: {
    fontSize: '1.3rem',
    marginTop: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      marginTop: '5px',
    },
  },
  fieldContainer: {
    position: 'relative',
    '& .MuiInputBase-root': {
      margin: 0,
    },
    '& .icon': {
      position: 'absolute',
      right: '15px',
      top: '26px',
      backgroundColor: '#ffffff',
      pointerEvents: 'none',
      [theme.breakpoints.down('lg')]: {
        top: '13px',
        '& img': {
          width: '20px',
        },
      },
    },
  },
}));
