import React from 'react';
// import { useStyles } from "./selectOptionStyle";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Checkbox } from '../Checkbox/Checkbox';
import image from '../../assets/index';
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  menuItem: {
    color: '#000',
    marginBottom: '0px',
    fontSize: '22px',
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  selectContainer: {
    width: '589px',
    [theme.breakpoints.down('lg')]: {
      width: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  select: {
    width: '100%',
    borderRadius: '34px',
    backgroundColor: '#fff',
    fontSize: '22px',
    margin: '0',
    height: '62px',
    textAlign: 'left',
    paddingLeft: '0.4rem',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45px',
      fontSize: '15px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: theme.shadows[2],
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      // border: `2px solid ${theme.palette.primary.main}`,
    },
    // border: "1px solid",
  },

  inputLabel: {
    marginLeft: '25px',
    padding: 'none',
    fontWeight: '300',
    marginBottom: '22px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      marginLeft: '14px',
      marginBottom: '15px',
    },
  },
  switchRoot: {},
  switchContainer: {
    width: '210px',
    '& span': {
      fontSize: '15px',
      marginRight: '20px',
      marginLeft: '15px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '140px',
    },
  },
  dropdownIcon: {
    fontSize: '2rem',
    marginRight: '15px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      width: '16px',
    },
  },
  none: {
    color: 'rgba(112,112,112,0.5)',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '1.3rem',
    marginLeft: '10px',
    marginTop: '5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  menu: {
    position: 'absolute',
    '& .MuiMenu-paper': {
      maxHeight: '35vh',
    },
  },
  mainContainer: {
    // position: 'relative',
  },
}));
const toFeet = cm => {
  const realFeets = (cm * 0.3937) / 12;
  let feets = Math.floor(realFeets);
  let inches = Math.round((realFeets - feets) * 12);
  if (inches === 12) {
    feets = feets + 1;
    inches = 0;
  }
  return `${feets}'${inches}" ( ${Math.floor(cm)}cm )`;
};
export const SelectOption = ({
  options,
  label,
  checkboxVaraint,
  placeholder,
  show,
  handleShow,
  name,
  onSelect,
  value,
  errorText,
  identify,
  noShow,
  height,
  hideArrow,
  showNotVisible,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [t] = useTranslation();

  return (
    <Grid
      container
      style={{
        paddingInline: identify ? '1rem 0.8rem' : '',
        marginBottom: identify ? (lgScreen ? '15px' : '30px') : '',
        position: 'relative',
      }}
      direction='column'
    >
      <Grid item style={{ paddingBottom: '0' }}>
        <Typography className={classes.inputLabel} variant='h3'>
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems='center'
        className={classes.mainContainer}
      >
        <Grid
          item
          className={classes.selectContainer}
          style={{ minWidth: noShow ? '100%' : '' }}
        >
          <Select
            // autoWidth
            {...rest}
            value={`${value}`}
            onChange={onSelect}
            variant='outlined'
            className={classes.select}
            color='primary'
            name={name}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              className: classes.menu,
            }}
            classes={{ root: classes.selectRoot }}
            inputProps={{
              classes: {
                root: classes.border,
              },
            }}
            IconComponent={() => (
              <img
                className={classes.dropdownIcon}
                src={!hideArrow ? image.downArrowBlue : null}
                alt=''
              />
            )}
          >
            <MenuItem dense className={classes.menuItem} value={'0'}>
              <span className={classes.none}>{placeholder}</span>
            </MenuItem>
            {options.map(option => (
              <MenuItem
                key={option}
                className={classes.menuItem}
                value={`${option}`}
              >
                {height ? toFeet(option) : t(option)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {!noShow && !height && (
          <Grid
            item
            container
            alignItems='center'
            justifyContent='flex-start'
            className={classes.switchContainer}
            style={{
              marginLeft: identify ? 'auto' : '',
              visibility: showNotVisible ? 'hidden' : 'visible',
            }}
          >
            <span>{t('show_on_profile')}</span>
            <Checkbox
              variant={checkboxVaraint}
              name={name}
              show={show}
              handleShow={handleShow}
            />
          </Grid>
        )}
      </Grid>
      {errorText && <span className={classes.error}>{errorText}</span>}
    </Grid>
  );
};
