import React, { useState } from 'react';
import { Grid, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { useStyles } from '../Styles/registerStylesOne';
import { Input } from '../../../components/Textfield/Input';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from '../../../store/user';
import { Header } from '../../../components/header/Header';
import {
  dob,
  username,
  setLocation as setLocationApi,
  checkUsername,
  checkEmail,
} from '../../../http/index';
import axios from 'axios';
import Joi from 'joi-browser';
import { useEffect } from 'react';
import ButtonComp from '../../../components/ButtonComp/ButtonComp';
import { useTranslation } from 'react-i18next';

export const SignUp = ({ onNext, history }) => {
  const classes = useStyles();
  const [, setDobError] = useState(false);
  const [t] = useTranslation();

  const [location, setLocation] = useState({
    lon: '',
    lat: '',
  });
  const userData = useSelector(state => state.auth.user.data);

  const dobString = userData?.date_of_birth?.dob?.split('/');

  const [type, setType] = useState('text');
  const [disabled, setDisabled] = useState(true);
  const [user, setUser] = useState({
    email: '',
    firstname: '',
    lastname: '',
    username: '',
    dob: '',
    identify: '0',
  });

  const [errors, setErrors] = useState({
    firstname: '',
    username: '',
    dob: '',
    identify: '',
    email: '',
  });
  const schema = {
    firstname: Joi.string().required().label('Firstname'),
    username: Joi.string().required().label('Username'),
    dob: Joi.date().required().label('Date of birth'),
    email: Joi.string().email().required().label('Email'),
  };

  const showDate = () => {
    const dateSplit = user.dob.toString().split('-');
    const date = `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`;
    return date;
  };

  const validate = () => {
    // console.log(location);
    const data = {
      firstname: user.firstname,
      username: user.username,
      dob: user.dob,
      email: user.email,
    };

    const result = Joi.validate(data, schema, { abortEarly: false });
    if (!result.error) {
      setErrors({});
      return false;
    } else {
      const errorsObject = {};
      for (let item of result.error.details)
        errorsObject[item.path[0]] = item.message;

      if (user.identify.length <= 1) {
        errorsObject.identify = 'Identify can not be empty!';
      }
      setErrors(errorsObject);
      return true;
    }
  };
  const dispatch = useDispatch();
  const handleUser = e => {
    const { name, value } = e.target;
    setUser(prev => ({
      ...prev,
      [name]:
        name === 'username' && !user.username.includes('@')
          ? `@${value}`
          : value,
    }));
    if (name === 'dob') {
      console.log('he');
      setErrors(prev => ({ ...prev, dob: '' }));
    }
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, subSchema);
    // eslint-disable-next-line
    const it = error
      ? setErrors({ ...errors, [name]: error.details[0].message })
      : setErrors({ ...errors, [name]: '' });
  };

  const calculateAge = val => {
    const dob = new Date(val);
    const currentDate = Date.now();
    const diff = currentDate - dob.getTime();
    let age = new Date(diff);
    let year = age.getUTCFullYear();
    age = Math.abs(year - 1970);
    return age;
  };

  const handleSubmit = async () => {
    let hasError = false;
    const error = validate();
    if (error) {
      hasError = true;
    }
    if (calculateAge(user.dob) < 18) {
      setErrors({ ...errors, dob: 'Age should be atleast 18' });
      hasError = true;
    }

    setDobError(false);

    if (
      userData.step === '/dob' ||
      userData.step === '/username' ||
      userData.step === null
    ) {
      const { data: emailCheck } = await checkEmail(user.email);
      if (emailCheck.exist) {
        setErrors({
          ...errors,
          email: `"${user.email}" already exists.`,
        });
        hasError = true;
      }
      const { data } = await checkUsername({
        username: user.username.split('@')[1],
      });
      if (data.nameExists) {
        setErrors({
          ...errors,
          username: `"${user.username}" is already taken.`,
        });
        hasError = true;
      }
    }

    if (!hasError) {
      const dateOfBirth = {
        dob: showDate(),
        age: calculateAge(user.dob),
        step: '/username',
      };

      const userInfo = {
        email: user.email,
        first_name: user.firstname,
        last_name: user.lastname,
        username: user.username.split('@')[1],
        step: '/gender-selection',
      };
      const locationData = {
        lon: String(location.lon),
        lat: String(location.lat),
        geoHash: 'geoHash',
        visible: true,
        step: '/gender-selection',
      };
      await axios
        .all([
          dob(dateOfBirth),
          setLocationApi(locationData),
          username(userInfo),
        ])
        .then(
          axios.spread(function (res1, res2, res3) {
            dispatch(submit(res3.data));
            history.push('/register/indentify');
          })
        )
        .catch(err => console.log(err.message));
    }
  };

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        setLocation({ lon: pos.coords.longitude, lat: pos.coords.latitude });
      });
    }
  }, []);

  const checkValidation = () => {
    if (
      errors.firstname === '' &&
      user.firstname.length &&
      errors.dob === '' &&
      user.dob.length &&
      errors.username === '' &&
      user.username.length &&
      errors.email === '' &&
      user.email.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const headerItems = [
    {
      label: 'Sign in',
      to: '/signin',
    },
    {
      label: 'Help',
      to: '/helpcenter',
    },
  ];

  useEffect(() => {
    checkValidation();
  }, [user, errors]);

  useEffect(() => {
    setUser({
      email: userData?.email || '',
      firstname: userData?.first_name || '',
      lastname: '',
      username: '@' + userData?.username || '',
      dob:
        dobString.join('').length > 0
          ? `${dobString[2]}-${dobString[0]}-${dobString[1]}`
          : '',
      identify: '0',
    });
  }, [userData]);

  return (
    <Grid container className={classes.container}>
      <Header transparent headerItems={headerItems} />
      <Grid
        container
        alignItems='center'
        direction='column'
        className={classes.form}
      >
        <Typography variant='h1' className={classes.formTitle}>
          Sign Up
        </Typography>
        <Grid item className={classes.main}>
          <form autoComplete='off'>
            <Grid
              container
              className={classes.formContainer}
              direction='column'
            >
              <Grid item container className={classes.inputContainer}>
                <Grid item sm={12} style={{ width: '100%' }}>
                  <Input
                    arrow
                    type={type}
                    placeholder='Calender'
                    label={t('date_of_birth')}
                    onFocus={e => {
                      e.target.type = 'date';
                      setType('date');
                    }}
                    onBlur={e => {
                      e.target.type = 'text';
                      setType('text');
                    }}
                    name='dob'
                    onChange={handleUser}
                    value={
                      type === 'text' && user.dob !== '' ? showDate() : user.dob
                    }
                    error={Boolean(errors.dob)}
                    helperText={errors.dob}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='row'
                className={classes.inputContainer}
              >
                <Grid item xs={12}>
                  <Input
                    label={t('enter_your_name')}
                    type='text'
                    placeholder='Name'
                    name='firstname'
                    onChange={handleUser}
                    value={user.firstname}
                    error={Boolean(errors.firstname)}
                    helperText={errors.firstname}
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.inputContainer}>
                <Input
                  label={t('enter_your_email')}
                  type='email'
                  name='email'
                  placeholder='Email'
                  onChange={handleUser}
                  value={user.email}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email}
                />
              </Grid>
              <Grid item className={classes.inputContainer}>
                <Input
                  label={t('select_a_username')}
                  type='text'
                  name='username'
                  placeholder='@'
                  onChange={handleUser}
                  value={user.username}
                  error={Boolean(errors.username)}
                  helperText={errors.username}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
        <div className={classes.formContainer} style={{ marginTop: 'auto' }}>
          <Grid
            item
            container
            justifyContent='center'
            style={{ marginTop: 'auto', width: '100%', padding: '0px 20px' }}
          >
            <ButtonComp
              label={t('continue')}
              onClick={handleSubmit}
              disabled={disabled}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item container justifyContent='center'>
            <Typography
              className={classes.p}
              variant='body1'
              style={{ marginTop: lgScreen ? '20px ' : '30px' }}
            >
              Already have an account?{' '}
              <Link to='/signin' className={classes.p}>
                Sign In
              </Link>
            </Typography>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
