export const calculateZodiacSign = (_day, _month) => {
  let day = _day;
  let month = _month;

  const zodiac = [
    '',
    'capricorn',
    'aquarius',
    'pices',
    'aries',
    'taurus',
    'gemini',
    'cancer',
    'leo',
    'virgo',
    'libra',
    'scorpio',
    'sagittarius',
    'capricorn',
  ];

  const last_day = ['', 19, 18, 20, 20, 21, 21, 22, 22, 21, 22, 21, 20, 19];

  return day > last_day[month] ? zodiac[month * 1 + 1] : zodiac[month];
};
