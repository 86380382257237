import React, { useState } from 'react';

import {
  Collapse,
  makeStyles,
  Grid,
  Typography,
  ClickAwayListener,
} from '@material-ui/core';

const HelpQA = ({ question, answer }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid
        container
        direction='column'
        onClick={handleToggle}
        className={classes.container}
      >
        <Typography variant='subtitle2'>{question}</Typography>
        <Collapse
          in={open}
          timeout='auto'
          unmountOnExit
          className={classes.collapse}
        >
          {answer}
        </Collapse>
      </Grid>
    </ClickAwayListener>
  );
};

export default HelpQA;

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    gap: '10px',
    cursor: 'pointer',
  },
  collapse: {
    paddingBottom: '10px',
  },
}));
