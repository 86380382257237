import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Dialog,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import {
  setFilter,
  setIsAnyOpen,
  setFilterUpdated,
} from '../../store/Reducers/filters';

import image from '../../assets';

import { useTranslation } from 'react-i18next';

export const FilterDialog = ({
  options,
  setValue,
  open,
  onClose,
  title,
  value: filterValue = [],
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation();
  let obj = {};
  // eslint-disable-next-line
  options.forEach(option => {
    obj[option] = false;
  });
  // const [options]
  const [state, setState] = useState(obj);
  const [filterChanged, setFilterChanged] = useState(false);
  const handleChange = e => {
    const prev = { ...state };
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
    if (name === 'All') {
      const myObj = {};
      options.forEach(op => {
        myObj[op] = checked;
      });
      setState(myObj);
    }
  };
  const handleSet = () => {
    let filters = [];
    for (const [key, value] of Object.entries(state)) {
      if (value) filters.push(key);
    }

    dispatch(
      setFilter({ name: setValue, value: filters.filter(f => f !== 'All') })
    );
    setFilterChanged(!filterChanged);
    dispatch(setFilterUpdated());
    dispatch(setIsAnyOpen(false));
    onClose();
  };
  useEffect(() => {
    let obj = {};
    // eslint-disable-next-line
    options.forEach(option => {
      obj[option] = false;
    });
    const keys = Object.keys(obj);
    let count = 0;
    keys.forEach(key => {
      if (filterValue.includes(key)) {
        obj[key] = true;
        count++;
      }
    });
    if (count === keys.length - 1) {
      obj['All'] = true;
    }
    setState(obj);
    // console.log(obj);
  }, [filterChanged, filterValue]);
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      className={classes.dialog}
    >
      <Grid item container direction='column' className={classes.container}>
        <Grid
          item
          container
          justifyContent='center'
          className={classes.titleContainer}
        >
          <Typography className={classes.header}>
            {title} <span>(You can choose multiple options)</span>
          </Typography>
        </Grid>
        <Grid item container className={classes.optionContainer}>
          {options.map((option, index) => (
            <Grid item container key={index} className={classes.margin}>
              <FormControlLabel
                className={classes.formControll}
                onChange={handleChange}
                label={
                  <span
                    className={state[option] ? classes.active : classes.option}
                    style={{ textAlign: 'left' }}
                  >
                    {option === 'All' && setValue !== 'gender'
                      ? 'All of above'
                      : t(option)}
                  </span>
                }
                control={
                  <Checkbox
                    name={option}
                    className={classes.checkBox}
                    color='primary'
                    checked={Boolean(state[option])}
                    checkedIcon={<img src={image.checkIcon} alt='' />}
                    icon={<img src={image.unCheck} alt='' />}
                  />
                }
                labelPlacement='start'
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          justifyContent='flex-end'
          className={classes.buttonsContainer}
        >
          <Button
            onClick={() => {
              onClose(false);
              setIsAnyOpen(false);
            }}
            className={classes.cancelButton}
            variant='text'
          >
            {t('cancel')}
          </Button>
          <Button
            className={classes.setButton}
            onClick={handleSet}
            variant='text'
            color='primary'
          >
            {t('set')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingBlock: '27px',
    width: '380px',
  },
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  titleContainer: {
    paddingInline: '24px',
  },
  header: {
    margin: 0,
    color: '#828282',
    fontSize: '18px',
    lineHeight: '45px',
    width: '100%',
    borderBottom: '1px solid #828282',
    textAlign: 'left',
    '& span': {
      fontSize: '13px',
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      '& span': {
        fontSize: '10px',
        paddingLeft: '5px',
      },
    },
  },
  optionContainer: {
    paddingInline: '24px',
    marginBlock: '24px',
    maxHeight: '65vh',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '55vh',
    },
  },
  formControll: {
    width: '100%',
    marginLeft: '0',
    marginBlock: '10px',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-label': {
      marginBottom: '0',
    },
    [theme.breakpoints.down('lg')]: {
      marginBlock: '5px',
    },
  },
  option: {
    color: '#000000',
    fontSize: '18px',
    fontWeight: '300',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: '500',
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  buttonsContainer: {
    paddingInline: '24px',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
    },
  },
  setButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  cancelButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    marginRight: '0.75rem',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
}));
