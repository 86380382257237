import { createSlice } from '@reduxjs/toolkit';

const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    prompt: {
      title: '',
      description: '',
      type: '',
    },
    liveloop: {
      data: {},
      filters: {
        gender_identifier: '',
        age: [],
        distance: '',
        location: {
          coordinates: [],
        },
      },
      started: false,
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.liveloop.filters = action.payload;
    },
    setLiveLoop: (state, action) => {
      state.liveloop.started = action.payload;
    },
    setPromptData: (state, action) => {
      const { title, description } = action.payload;
      state.prompt.title = title;
      state.prompt.description = description;
    },
  },
});

export const { setFilters, setLiveLoop, setPromptData } = utilsSlice.actions;
export default utilsSlice.reducer;
