import React, { useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { MeetPost } from '../../../components/meetme/MeetPost';
import image from '../../../assets';
import { IconButton } from '@material-ui/core';
import ApplicationContext from '../../../utils/contextApi';
import { superLikeApi, likeVideo } from '../../../http';

export const MeetMe = ({ allVideos, setPage, page, totalPage, loading }) => {
  const {
    index,
    setIndex,
    animate,
    setAnimate,
    setUsername,
    setChatId,
    setOpenMatch,
    setMatchData,
  } = useContext(ApplicationContext);

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  // .............. handeling close video method

  const handleCloseButton = () => {
    if (allVideos.length > 0) {
      if (index === allVideos.length - 1 && page < totalPage) {
        setPage(page + 1);
        setAnimate(!animate);
        setIndex(0);
      } else if (index === allVideos.length - 1 && page === totalPage) {
        return;
      } else {
        setAnimate(!animate);
        setIndex(index + 1);
      }
    }
  };
  // super liske video
  const handleSuperLike = async id => {
    try {
      const { data } = await superLikeApi({ video_id: id });
      setMatchData(data.data);
      if (data.matched) {
        setChatId(data.chatId);
        setUsername(data.data.liked_to_name);
        setOpenMatch(true);
      } else {
        if (index === allVideos.length - 1 && page < totalPage) {
          setPage(page + 1);
          setAnimate(!animate);
          setIndex(0);
        } else if (index === allVideos.length - 1 && page === totalPage) {
          return;
        } else {
          setAnimate(!animate);
          setIndex(index + 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLikeVideo = async id => {
    try {
      const { data } = await likeVideo({ video_id: id });
      setMatchData(data.data);
      if (data.matched) {
        setChatId(data.chatId);
        setUsername(data.data.liked_to_name);
        setOpenMatch(true);
      } else {
        if (index === allVideos.length - 1 && page < totalPage) {
          setPage(page + 1);
          setAnimate(!animate);
          setIndex(0);
        } else if (index === allVideos.length - 1 && page === totalPage) {
          return;
        } else {
          setAnimate(!animate);
          setIndex(index + 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item container>
        <Typography className={classes.title}>Meet Me</Typography>
      </Grid>
      {loading ? (
        <div className={classes.notFound}>
          <CircularProgress color='primary' />
        </div>
      ) : (
        <>
          {allVideos.length > 0 ? (
            <Grid
              item
              container
              alignItems='center'
              // direction='column'
              className={classes.postContainer}
            >
              <MeetPost
                allVideos={allVideos}
                setPage={setPage}
                page={page}
                totalPage={totalPage}
              />

              <Grid
                item
                // className={classes.actionButtonContainer}
              >
                <Grid container className={classes.btns}>
                  <>
                    <Grid item>
                      <Grid container direction='column' alignItems='center'>
                        <IconButton onClick={handleCloseButton} className='btn'>
                          <img
                            src={image.noButton}
                            alt=''
                            style={{ width: lgScreen ? '60px' : '98px' }}
                          />
                        </IconButton>
                        <h4 className={classes.btnSubtitle}>No</h4>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{ marginInline: lgScreen ? '25px' : '70px' }}
                    >
                      <Grid container direction='column' alignItems='center'>
                        <IconButton
                          onClick={() => handleSuperLike(allVideos[index]._id)}
                          // onClick={() => setOpenSuperDialog(true)}
                          className='btn'
                        >
                          <img
                            src={image.supIcon}
                            alt=''
                            style={{ width: lgScreen ? '60px' : '98px' }}
                          />
                        </IconButton>
                        <h4
                          className={classes.btnSubtitle}
                          style={{ color: '#FE858C' }}
                        >
                          Instant Spark
                        </h4>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction='column' alignItems='center'>
                        <IconButton
                          onClick={() => handleLikeVideo(allVideos[index]._id)}
                          className='btn'
                        >
                          <img
                            src={image.likePng}
                            alt=''
                            style={{ width: lgScreen ? '60px' : '98px' }}
                          />
                        </IconButton>
                        <h4
                          className={classes.btnSubtitle}
                          style={{ color: '#5330AD' }}
                        >
                          Like
                        </h4>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.notFound}>
              <img src={image.find} alt='' />
              <h4>No reels found</h4>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingInline: '50px',
    paddingTop: '2rem',
    paddingBottom: '0rem',
    position: 'relative',
    minHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 2rem',
    },
  },
  title: {
    color: '#000',
    margin: 0,
    fontSize: '35px',
    fontWeight: 100,
    fontWeight: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
  postContainer: {
    marginTop: '0rem',
    paddingInline: '209px 0px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5rem',
      paddingInline: '100px 0px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0.5rem',
      paddingInline: '50px 0px',
    },
    [theme.breakpoints.down(800)]: {
      marginTop: '0.5rem',
      paddingInline: '0px 0px',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },

  actionButtonContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    paddingLeft: '40%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },

  btns: {
    paddingLeft: '64px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      '& .btn': {
        paddingInline: '0px',
      },
    },
  },
  btnSubtitle: {
    fontSize: '21px',
    fontWeight: '300',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  notFound: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gridGap: '14px',
    [theme.breakpoints.down('lg')]: {
      gridGap: '8px',
    },
    '& img': {
      width: '308px',
      aspectRatio: '1/1',
      [theme.breakpoints.down('lg')]: {
        width: '200px',
      },
    },
    '& p': {
      color: '#929292',
      fontSize: '25px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },
}));
