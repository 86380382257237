import { useEffect, useState } from 'react';

const useCountdown = () => {
  const [time, setTime] = useState(300);
  const [start, setStart] = useState(false);

  const handleTic = () => {
    if (start) {
      setTime(time - 1);
    }
  };

  const startTimer = () => {
    setStart(true);
  };

  const stopTimer = () => {
    setStart(false);
  };

  const resetTimer = () => {
    setTime(300);
    startTimer();
  };

  useEffect(() => {
    const timer = setInterval(() => handleTic(), 1000);
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (time === 0) {
      stopTimer();
    }
  }, [time]);

  return {
    time,
    resetTimer,
    startTimer,
  };
};

export default useCountdown;
