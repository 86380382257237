import React from 'react';

import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';

const Confirmation = ({ title, description, open, setOpen, action }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} className={classes.dialog}>
      <Grid
        container
        direction='column'
        className='main-container'
        alignItems='center'
      >
        <Typography variant='h2' style={{ margin: '0px', fontSize: '30px' }}>
          {title}
        </Typography>

        <Typography variant='body1'>{description}</Typography>

        <Grid item container className={classes.buttonRow}>
          <Button
            variant='outlined'
            className='btn btn-cancel'
            color='primary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            className='btn btn-continue'
            color='primary'
            onClick={action}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Confirmation;

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      background: theme.palette.common.lightPink,
      borderRadius: '12px',
    },
    '& .main-container': {
      gap: '20px',
      padding: '20px',
    },
  },
  buttonRow: {
    gap: '16px',
    '& .btn': {
      width: 'calc(50% - 8px)',
      borderRadius: '5px',
      textTransform: 'none',
    },
  },
}));
