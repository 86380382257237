import React, { useState, useRef, useEffect } from 'react';
import {
  makeStyles,
  IconButton,
  Slider,
  CircularProgress,
} from '@material-ui/core';
import { PlayArrow, Pause, Close } from '@material-ui/icons';

const Audio = ({ src, fromMe, children }) => {
  const classes = useStyles();
  const player = useRef();
  const [play, setPlay] = useState(false);
  const [value, setValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [remainingTime, setRemainingTime] = useState('00:00');
  const handlePlay = () => {
    if (player.current.paused && !isPlaying) {
      player.current.play();
      setPlay(true);
    } else if (!player.current.paused && isPlaying) {
      player.current.pause();
      setPlay(false);
    }
  };
  const handleChange = (event, newValue) => {
    player.current.currentTime = (newValue / 100) * player.current.duration;
    // player.current.currentTime = newValue;
    setValue(newValue);
  };
  const handleTimeUpdate = () => {
    const time = player.current.duration - player.current.currentTime;
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    setRemainingTime(`${minutes}:${seconds}`);
    const progress =
      (player.current.currentTime / player.current.duration) * 100;
    setValue(progress);
  };

  return (
    <>
      <div>
        <div
          className={
            fromMe
              ? canPlay
                ? classes.meContainer
                : classes.hide
              : canPlay
              ? classes.container
              : classes.hide
          }
        >
          <audio
            onPlaying={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onCanPlay={() => {
              setCanPlay(true);
              handleTimeUpdate();
            }}
            ref={player}
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => {
              setPlay(false);
              setValue(0);
            }}
          >
            <source src={src} />
          </audio>
          <div className='row'>
            <IconButton onClick={handlePlay} className={classes.icon}>
              {play ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Slider value={value} onChange={handleChange} />
          </div>
          <div className='row-2'>
            <div style={{ fontSize: '12px' }}>{remainingTime}</div>
            {children}
          </div>
        </div>
        {!canPlay && (
          <CircularProgress color={fromMe ? 'primary' : 'secondary'} />
        )}
      </div>
    </>
  );
};

export default Audio;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '200px',
    alignItems: 'center',
    background: 'rgba(254, 133, 140, 0.1)',
    paddingBlock: '5px',
    paddingInline: '10px 20px',
    borderRadius: '20px',
    flexDirection: 'column',
    '& .row': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& .row-2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  meContainer: {
    display: 'flex',
    width: '200px',
    alignItems: 'center',
    background: 'rgba(161, 125, 255, 0.13)',
    paddingBlock: '5px',
    paddingInline: '10px 20px',
    borderRadius: '20px',
  },
  icon: {
    height: '30px',
    width: '30px',
    marginRight: '10px',
  },
  hide: {
    visibility: 'hidden',
    width: '1px',
    height: '1px',
  },
}));
