import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  nav: {
    height: '120px',
    paddingInline: '10px',
    [theme.breakpoints.down('lg')]: {
      height: '85px',
    },
  },
  searchBar: {
    width: '337px',
    height: '54px',
    backgroundColor: '#ffffff',
    borderRadius: '27px',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '250px',
    },
  },

  searchIcon: {
    height: '20px',
    width: '20px',
  },
  searchInput: {
    margin: 'auto 0',
    marginLeft: '20px',
    fontSize: '15px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      marginLeft: '15px',
      width: '70%',
      fontSize: '12px',
    },
  },
  iconContainer: {
    marginRight: '20px',
    [theme.breakpoints.down('lg')]: {
      marginRight: '10px',
      height: '25px',
      width: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '5px',
      height: '25px',
      width: '25px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    '&::placeholder': {
      color: theme.palette.primary.main,
    },
  },
  live: {
    textDecoration: 'none',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      gridGap: '12px',
    },
    '& p': {
      fontSize: '30px',
      textTransform: 'none',
      textDecoration: 'none',
      fontWeight: 'bold',
      color: '#443F3D',
      [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
      },
    },
    '& img': {
      transform: 'translateY(2px)',
      [theme.breakpoints.down('lg')]: {
        width: '23px',
        transform: 'translateY(1px)',
      },
    },
  },
  avatarTitle: {
    fontSize: '20px',
    fontFamily: 'Helvetica',
    marginLeft: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  avatar: {
    height: '75px',
    width: '75px',
    marginLeft: '2rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '1rem',
      width: '50px',
      height: '50px',
    },
  },
  rightContainer: {
    marginLeft: 'auto',
    marginRight: '2rem',
    gridGap: '6rem',
    [theme.breakpoints.down('lg')]: {
      gridGap: '4rem',
      marginLeft: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      gridGap: '1rem',
      marginLeft: '0rem',
      marginRight: '0rem',
    },
  },
  menuContainer: {
    opacity: '0.9',
    // right: "100px",
    bottom: 0,
    '& .MuiMenu-list': {
      padding: '0',
    },
    position: 'relative',
  },
  icons: {
    height: '25px',
    width: '25px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '15px',
      width: '15px',
    },
  },
  menuIcon: {
    color: '#000',
    fontSize: '2rem',
  },
  menuItem: {
    margin: '0',
    height: '60px',
    padding: '5px 2rem',
    justifyContent: 'center',
  },
  menuTitle: {
    color: '#000',
    fontSize: '20px',
  },
  list: {
    padding: '0',
  },
  listItem: {
    width: '250px',
    padding: '0.5rem 0',
    '&:hover': {
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  menuAvatar: {
    marginLeft: '10px',
  },
  listItemsText: {
    '& .MuiListItemText-primary': {
      color: '#000',
      margin: 0,
      fontSize: '12px',
      width: '100%',
      textAlign: 'left',
      marginLeft: '10px',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  action: {
    '& span': {
      fontSize: '12px',
    },
  },
  logo: {
    width: '8rem',
    marginLeft: '30px',
    [theme.breakpoints.down('lg')]: {
      width: '5.5rem',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      border: '2px solid #ffffff',
    },
  },
  menuItem: {
    margin: 0,
    color: '#000000',
    fontSize: '15px',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
    // background: "red",
    '&:hover': {
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  menuPaper: {
    overflow: 'visible',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 10,
      width: 10,
      height: 10,
      background: 'white',
      transform: 'translateY(-50%) rotate(45deg)',
      // zIndex: 0,
    },
  },
  menuInner: {
    borderRadius: '3px',
    overflow: 'hidden',
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      color: '#000',
      fontSize: '21px',
      fontWeight: '500',
      margin: 'auto 0',
      textAlign: 'left',
      marginLeft: '15px',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
        fontSize: '15px',
        margin: 'auto 0',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        fontSize: '21px',
        margin: 'auto 0',
      },
    },
  },
  listItem: {
    height: '64px',
    paddingLeft: '4.5rem',
    boxSizing: 'content-box',
    cursor: 'pointer',
    width: '80%',
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '3rem',
      height: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3rem',
      height: '64px',
    },
  },
  listItemActive: {
    borderRadius: '0px 35px 35px 0px',
    width: '82%',
    boxSizing: 'content-box',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      width: '85%',
      borderRadius: '0px 32px 32px 0px',
    },
    '& .MuiListItemText-primary ': {
      color: '#fff',
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.darkPink,
    },
  },
  root: {
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    minWidth: '0',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '35px',
      },
    },
  },
  sliderContainer: {
    // paddingInline: "25px",
    marginTop: '40px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5rem',
      marginBottom: '0rem',
    },
  },
  newIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '1.7rem',
    },
  },

  drawer: {
    '& 	.MuiDrawer-paper': {
      maxWidth: '317px',
      minWidth: '317px',
    },
  },

  logoContainer: {
    padding: '24px 0px 0px 24px',
    marginBottom: '45px',

    '& .logo': {
      width: '80px',
    },
  },
}));
