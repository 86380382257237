import GeoCode from 'react-geocode';

import { genderList } from '../app.config';

const genders = [...genderList, ...'All'];
GeoCode.setApiKey(process.env.REACT_APP_GEOCODE_API);

export const inchesToFeets = val => {
  let feets = Math.floor(val / 12);
  let inches = val - feets * 12;
  feets = Math.floor(feets);
  inches = Math.floor(inches);

  return `${feets}'${inches}''`;
};

export const toRad = Value => {
  return (Value * Math.PI) / 180;
};

export const shuffle = a => {
  let array = a;
  let i = array.length;
  while (i--) {
    const ri = Math.floor(Math.random() * i);
    [array[i], array[ri]] = [array[ri], array[i]];
  }
  return array;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getGenderFilterPreference = (gender, sexuality) => {
  if (!gender || !sexuality) {
    return ['Male', 'Female'];
  }
  if (
    gender.toLowerCase() === 'male' &&
    sexuality.toLowerCase() === 'straight'
  ) {
    return ['Female'];
  } else if (
    gender.toLowerCase() === 'female' &&
    sexuality.toLowerCase() === 'straight'
  ) {
    return ['Male'];
  } else if (
    gender.toLowerCase() === 'male' &&
    sexuality.toLowerCase() !== 'straight'
  ) {
    return genders;
  } else if (
    gender.toLowerCase() === 'female' &&
    sexuality.toLowerCase() !== 'straight'
  ) {
    return genders;
  } else if (
    gender.toLowerCase() !== 'male' &&
    gender.toLowerCase() !== 'female'
  ) {
    return genders;
  }
};

export const getCity = (lat, lon, setAddress) => {
  GeoCode.fromLatLng(lat, lon).then(
    response => {
      let city, state, country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              city = response.results[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              state = response.results[0].address_components[i].long_name;
              break;
            case 'country':
              country = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      if (country.toLowerCase() === 'united states') {
        return setAddress(`${city}, ${state}`);
      } else {
        return setAddress(`${city}, ${country}`);
      }
    },
    error => {
      console.error(error);
    }
  );
};
