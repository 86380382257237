import React from 'react';

import {
  makeStyles,
  Grid,
  AppBar,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';

import image from '../../assets';
const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <AppBar className={classes.navLight}>
        <Toolbar className={classes.toolbar}>
          <Button className={classes.logo} disableRipple>
            <img
              src={image.logo}
              className={classes.logoImage}
              alt='company-logo'
            />
          </Button>
          <Typography variant='h3' className={classes.privacy}>
            Terms and conditions
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.innerContainer}>
        <Typography className={classes.title}>Terms and Conditions</Typography>
        <p className='font-size-14 margin'>
          Leeder, LLC: {/* Effective date here*/} <br />
          138 E38th street, <br />
          New York NY 10016 <br />
          waleed@rankpage.com
        </p>
        <p className='font-size-14 '>Effective Date:</p>
        <p className='font-size-14 margin' style={{ marginTop: '20px' }}>
          This web page represents a legal document and is the Terms and
          Conditions, from now on called (Agreement) for our website Intro.date,
          our mobile application called Intro, and our web application(s)
          hereinafter and collectively referred to as (Website). By using our
          Website, you agree to fully comply with and be bound by the following
          Agreement each time you use our Website.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Definitions</span>
          <br />
          The terms “us”, “we”, and “our” refer to Leeder, LLC, the owner of
          this Website. The term “Product(s)” refers to any products or services
          we sell or give away. A “Visitor” is someone who merely browses our
          Website. A “Member” is someone who has registered with our Website to
          use our Products. The term “User” is a collective identifier that
          refers to either a Visitor or a Member.
        </p>
        <p className='font-size-14 margin'>
          All text, information, graphics, design, and data offered through our
          Website or Products whether produced by our Members or by us, are
          collectively known as our “Content”. We distinguish content posted by
          our Members as “Member Content”.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>
            Eligibility and Registration for Membership
          </span>
          <br />
          To use our Website and Products, you must register to become a Member.
          Your Membership is not transferable or assignable and is void where
          prohibited. Our Website and Products are intended solely for Users who
          are at least (18) years old or older. Any registration by, use of, or
          access to our Website and Products by anyone under that age is
          unauthorized, unlicensed, and in violation of this Agreement. By using
          our Website and Products, you represent and warrant that you are (18)
          years old or older and agree to obey all the terms of this Agreement.
          Leeder, LLC has sole right and discretion to decide whether to accept
          a Member and may reject a Member’s registration with or without
          explanation.
        </p>

        <p className='font-size-14 margin'>
          When you complete the registration process, you may receive a password
          that will allow you to access our Website and Products. You agree to
          maintain the confidentiality of your password and are fully
          responsible for all liability and damages resulting from your failure
          to maintain that confidentiality as well as all activities that occur
          by using your password.
        </p>
        <p className='font-size-14 margin'>
          You agree to immediately tell us of any unauthorized use of your
          password or any other breach of security. You agree that we cannot and
          will not be liable for any loss or damage arising from your failure to
          comply with password security as discussed herein.
        </p>

        <p className='font-size-14 margin'>
          <span className='bold'>Acceptance of Agreement</span>
          <br />
          This Agreement is between you and Leeder, LLC. <br />
          <br />
          THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT
          LIMIT OUR LIABILITY TO YOU. PLEASE READ THESE TERMS AND CONDITIONS
          CAREFULLY AND IN THEIR ENTIRETY, AS USING, ACCESSING, AND/OR BROWSING
          OUR WEBSITE CONSTITUTES ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF
          YOU DO NOT AGREE TO BE BOUND TO EVERY TERM AND CONDITION SET FORTH
          HERE, PLEASE EXIT OUR WEBSITE IMMEDIATELY AND DO NOT USE ACCESS AND
          BROWSE IT FURTHER. Except as otherwise noted, this Agreement makes up
          the entire and only Agreement between you and Leeder, LLC and
          supersedes all other Agreements, representations, warranties, and
          understandings about our Website, Products, and the subject matter
          here. However, for you to use our Website and Products, you may also
          be required to agree to additional terms and conditions. Those
          additional terms and conditions will be incorporated into this
          Agreement unless otherwise stated.
        </p>

        <p className='font-size-14 margin'>
          <span className='bold'>Privacy Notice</span>
          <br />
          Our Privacy Notice is considered part of this Agreement and is
          available on this website. You must review our Privacy Notice by
          clicking on this{' '}
          <a href='https://www.intro-beta.com/#/privacy-policy' target='_blank'>
            link
          </a>
          . If you do not accept and agree to be bound by all the terms of this
          Agreement, including our https://www.intro-beta.com Privacy Notice, do
          not use this Website or our Products.
        </p>

        <p className='font-size-20 title margin'>
          Your Personal Safety and Interactions with Other Members
        </p>
        <p className='font-size-14 margin'>
          PLEASE READ CAREFULLY. IF YOU CHOOSE TO USE OUR WEBSITE AND SERVICES,
          YOU DO SO VOLUNTARILY AND AT YOUR OWN RISK. YOU ALONE ARE RESPONSIBLE
          FOR YOUR INTERACTIONS WITH OTHER MEMBERS OF OUR WEBSITE, SERVICES, OR
          ORGANIZATION. YOU UNDERSTAND THAT Leeder, LLC HAS NO OBLIGATION TO
          PERFORM CRIMINAL BACKGROUND CHECKS OR ANY SCREENING PROCEDURES ON OUR
          MEMBERS OR TO INQUIRE INTO THE BACKGROUNDS OF OUR MEMBERS. Leeder, LLC
          ALSO DOES NOT REQUEST BACKGROUND INFORMATION FROM ITS MEMBERS OR TRY
          TO CORROBORATE THE STATEMENTS OF ITS MEMBERS. Leeder, LLC MAKES NO
          WARRANTIES OR REPRESENTATIONS AS TO THE BEHAVIOR OF OUR MEMBERS OR
          THEIR COMPATIBILITY WITH OTHER MEMBERS. Leeder, LLC HOWEVER, RESERVES
          THE RIGHT TO CONDUCT A CRIMINAL BACKGROUND CHECK OR OTHER SEARCHES
          USING A SEX-OFFENDER REGISTER OR OTHER PUBLIC RECORDS, AT ANY TIME. BY
          AGREEING TO THESE TERMS AND CONDITIONS, YOU HEREBY AUTHORIZE ANY SUCH
          CHECK.
        </p>
        <p className='font-size-14 margin'>
          Leeder, LLC IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR
          OFFLINE, OF ANY MEMBER OF OUR WEBSITE, SERVICES OR ORGANIZATION. IN NO
          EVENT SHALL Leeder, LLC, ITS AFFILIATES, OR ITS PARTNERS BE LIABLE
          DIRECTLY OR INDIRECTLY, FOR ANY LOSSES OR DAMAGES WHATSOEVER, WHETHER
          DIRECT, INDIRECT, GENERAL COMPENSATORY, CONSEQUENTIAL OR INCIDENTAL,
          ARISING FROM OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN
          CONNECTION WITH THE USE OF OUR WEBSITE OR SERVICES, INCLUDING, WITHOUT
          LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, DEATH OR ANY OTHER
          DAMAGES RESULTING FROM MEETINGS OR COMMUNICATIONS WITH OTHER MEMBERS
          OR PERSONS YOU MEET THROUGH OUR SERVICES.
        </p>

        <p className='font-size-20 title margin'>Your Safety</p>

        <p className='font-size-14 margin'>
          YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL INTERACTIONS WITH
          OTHER MEMBERS OF OUR WEBSITE, SERVICES, OR ORGANIZATIONS, ESPECIALLY
          IF YOU CHOOSE TO MEET IN PERSON, OR IF YOU DECIDE TO SEND MONEY OR
          PROVIDE CREDIT CARD INFORMATION TO ANOTHER MEMBER.
        </p>
        <p className='font-size-14 margin'>
          WE STRONGLY SUGGEST: <br />
          YOU GET TO KNOW SOMEONE BEFORE YOU MEET THEM OFFLINE.
        </p>
        <ol className='font-size-14 margin'>
          <li className='font-size-14'>
            ALWAYS MEET THEM IN A PUBLIC PLACE FOR THE FIRST TIME.
          </li>
          <li className='font-size-14'>
            TELL A FAMILY MEMBER OR FRIEND WHERE YOU ARE MEETING.
          </li>
          <li className='font-size-14'>
            HAVE YOUR OWN MEANS OF TRANSPORTATION IN YOUR INITIAL MEET-UP.
          </li>
        </ol>

        <p className='font-size-14 margin'>
          <span className='bold'>Harassment by Other Users</span>
          <br />
          If you believe that any user of our Services is harassing you or is
          otherwise using personal information about you for unlawful purposes,
          we encourage you to first inform local law enforcement authorities and
          then contact us.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Arbitration</span>
          <br />
          Any legal controversy or claim arising from or relating to this
          Agreement and/or our Website and Products, excluding legal action
          taken by us to collect or recover damages for – or obtain any
          injunction relating to – website operations, intellectual property,
          and our Products, will be settled solely by binding arbitration
          following the commercial arbitration rules of the American Arbitration
          Association. Any such controversy or claim will be arbitrated on an
          individual basis and will not be consolidated in any arbitration with
          any claim or controversy of any other party. The arbitration will be
          conducted in New York City, New York, and judgment on the arbitration
          award may be entered into any court having jurisdiction thereof. You
          or we may seek any interim or preliminary relief from a court of
          competent jurisdiction in New York City, New York, necessary to
          protect our or your rights or property pending the completion of
          arbitration. Each party will bear half the arbitration fees and costs.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Choice of Law and Jurisdiction </span>
          <br />
          This Agreement will be treated as if it were executed and performed in
          New York City, New York and will be governed by and construed
          following the laws of the state of New York without regard to conflict
          of law provisions. Also, you agree to submit to the personal
          jurisdiction and venue of such courts. Any cause of action by you
          about our Website or Products must be instituted within one (1) year
          after the cause of action arose or be forever waived and barred.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Limited License </span>
          <br />
          Leeder, LLC grants you a nonexclusive, nontransferable, revocable
          license to access and use our Website and Products strictly under this
          Agreement. Your use of our Website and Products is solely for
          internal, personal, noncommercial purposes unless otherwise provided
          for in this Agreement. No printout or electronic version of any part
          of our Website or Products may be used by you in any litigation or
          arbitration matter at all under any circumstances.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Legal Compliance</span>
          <br />
          You agree to comply with all applicable domestic and international
          laws, statutes, ordinances, and regulations about your use of our
          Website, Content, and Products, and any software provided within.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Our Relationship to You</span>
          <br />
          This Agreement in no way creates any agency, partnership, joint
          venture, or employee-employer or franchisor-franchisee relationship
          between you and Leeder, LLC.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Our Intellectual Property</span>
          <br />
          Our Website may contain our service marks or trademarks as well as
          those of our affiliates or other companies in the form of words,
          graphics, and logos. Your use of our Website or Products does not
          constitute any right or license for you to use our service marks or
          trademarks without the prior written permission of Leeder, LLC.
        </p>

        <p className='font-size-14 margin'>
          Our Content, as found within our Website and Products, is protected
          under the United States and foreign copyrights. The copying,
          redistribution, use, or publication by you of any such Content is
          strictly prohibited. Your use of our Website and Products does not
          grant you any ownership rights to our Content.
        </p>

        <p className='font-size-14 margin'>
          <span className='bold'>Errors, Corrections, and Changes</span>
          <br />
          We do not represent or otherwise warrant that our Website and Products
          will be error-free or free from viruses or other harmful components.
          We do not represent or warrant the information available on or through
          our Website and Products will be correct, accurate, timely, or
          reliable. Leeder, LLC reserves the right at our sole discretion to
          change any content, software, and other items used or contained in our
          Website or Products at any time without notice.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Disclaimer</span>
          <br />
          Our Website publishes content supplied by third parties, Users,
          advertisers, merchants, and sponsors. Accordingly, Leeder, LLC has no
          editorial control over such content. Any opinions or other information
          or content expressed or made available by third parties, including
          information providers, Users, or any other User of our Website, are
          those of the respective author(s) and not of Leeder, LLC. Leeder, LLC
          does not guarantee the accuracy, completeness, merchantability, or
          fitness for any particular purpose nor the legality of any content
          provided by any of these parties.
        </p>

        <p className='font-size-14 margin'>
          You understand that we do not operate or control the products or
          services offered by third-party merchants. These merchants are
          responsible for all aspects of order processing, fulfillment, billing,
          and customer service. We are not a party to the transactions entered
          between you and merchants. You agree that use of or purchase from such
          merchants is AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY
          US. All rules, legal documents (including privacy notices), and
          operating procedures of merchants will apply to you while on any
          merchant websites.
        </p>

        <p className='font-size-14 margin'>
          <span className='bold'>Merchants</span>
          <br />
          We are not a party to the transactions entered into between you and
          merchants. You agree that use of or purchase from such merchants is AT
          YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY US. All rules,
          legal documents, refund policies (including privacy notices), and
          operating procedures of merchants will apply to you while on any
          merchant website or their physical place of business.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Refunds</span>
          <br />
          We do not have a refund policy.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Warranty Disclaimer</span>
          <br />
          Leeder, LLC is not responsible or liable in any manner for any Content
          posted on our Website or in connection with our Products, whether
          posted or caused by Members of our Website, or by Leeder, LLC.
          Although we provide rules for Member conduct and postings, we do not
          control and are not responsible for what Members post, transmit, or
          share on our Website or Products, and are not responsible for any
          offensive, inappropriate, obscene, unlawful, or otherwise
          objectionable content you may encounter using our Website or Products.
          Leeder, LLC is not responsible for the online or offline conduct of
          any User of our Website or Products. Our Website or Products may be
          temporarily unavailable from time to time for maintenance or other
          reasons. Leeder, LLC assumes no responsibility for any error,
          omission, interruption, deletion, defect, delay in operation or
          transmission, communications line failure, theft or destruction,
          unauthorized access to, or alteration of Member communications.
          Leeder, LLC is not responsible for any technical problem or other
          problems of any telephone network or service, computer system, server
          or provider, computer or mobile phone equipment, or software, or any
          failure of email because of technical problems or traffic congestion
          on the Internet, or any combination–including injury or damage to
          Members’ or any other person’s computer, mobile phone, or other
          hardware or software – related to or resulting from the use or
          downloading of materials in connection with our Website or Products,
          including, without limitation, any software provided through our
          Website or Products. Under no circumstances will Leeder, LLC be
          responsible for any loss or damage, including any loss or damage,
          personal injury, or death resulting from anyone’s use of our Website
          or Products, or any interactions between Users of our Website or
          Products, whether online or offline. Reference to any products,
          services, processes, or other information by trade name, trademark,
          manufacturer, supplier, does not constitute or imply endorsement,
          sponsorship, recommendation, or any affiliation with our Website by
          third parties or by any of the equipment or programming associated
          with or used by our Products.
        </p>

        <p className='font-size-14 margin'>
          THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH OUR WEBSITE
          ARE PROVIDED ‘AS-IS’, ‘AS AVAILABLE’, WITH ‘ALL FAULTS’, AND ALL
          EXPRESS OR IMPLIED WARRANTIES ARE DISCLAIMED (INCLUDING, BUT NOT
          LIMITED TO, THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE). OUR WEBSITE AND
          PRODUCTS MAY CONTAIN BUGS, ERRORS, PROBLEMS, OR OTHER LIMITATIONS.
        </p>
        <p className='font-size-14 margin'>
          Leeder, LLC, INCLUDING ALL OUR AFFILIATES, HAS NO LIABILITY AT ALL FOR
          YOUR USE OF OUR WEBSITE OR PRODUCTS. Leeder, LLC CANNOT GUARANTEE AND
          DOES NOT PROMISE ANY SPECIFIC RESULTS FROM THE USE OF OUR WEBSITE OR
          PRODUCTS, INCLUDING, BUT NOT LIMITED TO, RELATED SOFTWARE. Leeder, LLC
          DOES NOT REPRESENT OR WARRANT THAT OUR CONTENT, PRODUCTS, OR ANY
          SOFTWARE FOUND WITHIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
          ERROR-FREE, OR FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.
          THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF
          ANY SUCH CONTENT OR SOFTWARE AND USE INDUSTRY-RECOGNIZED SOFTWARE TO
          DETECT AND REMOVE VIRUSES. ALL RESPONSIBILITY OR LIABILITY FOR ANY
          DAMAGES CAUSED BY VIRUSES SOMEHOW ATTRIBUTED TO OUR CONTENT, PRODUCTS,
          AND RELATED SOFTWARE IS DISCLAIMED.
        </p>
        <p className='font-size-14 margin'>
          WITHOUT LIMITATION, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR
          OTHERWISE OBTAIN CONTENT AND RELATED SOFTWARE FROM OR THROUGH OUR
          WEBSITE OR PRODUCTS AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY
          RESPONSIBLE FOR YOUR USE OF IT AND ANY DAMAGES TO YOUR MOBILE DEVICE
          OR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM OF ANY KIND THAT MAY
          RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR
          LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER
          BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
          NEGLIGENCE), PRODUCT LIABILITY, EVEN IF ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE
          ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND
          Leeder, LLC.
        </p>
        <p className='font-size-14 margin'>
          OUR WEBSITE AND PRODUCTS WOULD NOT BE PROVIDED WITHOUT SUCH
          LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
          OBTAINED BY YOU FROM US THROUGH OUR WEBSITE OR PRODUCTS WILL CREATE
          ANY WARRANTY, REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN
          THIS AGREEMENT.
        </p>

        <p className='font-size-14 margin'>
          <span className='bold'>Limitation of Liability</span>
          <br />
          IN NO EVENT WILL Leeder, LLC OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE
          LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY
          LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE,
          CONTENT, PRODUCTS, OR ANY RELATED SOFTWARE ACCESSED THROUGH OR
          DOWNLOADED FROM OUR WEBSITE OR PRODUCTS, EVEN IF Leeder, LLC IS AWARE
          OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. DESPITE
          ANYTHING TO THE CONTRARY HERE, Leeder, LLC’s LIABILITY TO YOU FOR ANY
          CAUSE AT ALL, AND REGARDLESS OF THE FORM OF THE ACTION, WILL ALWAYS BE
          LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR WEBSITE AND OR
          PRODUCTS ACCESSED DURING THE PREVIOUS MONTH OF YOUR MEMBERSHIP BEFORE
          THE EVENT RESULTING IN LIABILITY.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Member Conduct</span>
          <br />
          Members may post their content to our Website through our products
          (Member Content). Members and Visitors understand that by using our
          Website or Products, they may be exposed to content that is offensive,
          indecent, or objectionable. We have no control over Member Content and
          do not in any way guarantee its quality, accuracy, or integrity.
          Leeder, LLC is not responsible for the monitoring or filtering of any
          Member Content. Should any Member Content be found illegal, Leeder,
          LLC will submit all necessary information to relevant authorities.{' '}
          <br />
          If any Member Content is reported to Leeder, LLC as being offensive or
          inappropriate, we may ask the Member to retract or modify the
          questionable content within 24 hours of being notified by Leeder, LLC.
          If the Member fails to meet such a request, Leeder, LLC has full
          authority to restrict the Member’s ability to post Member Content or
          to immediately terminate the Member’s use of our Website and Products
          without further notice to the Member. <br />
          We have sole discretion to remove any Member Content that violates
          this Agreement or that is otherwise objectionable in our sole
          discretion. Members are responsible for complying with all applicable
          federal and state laws for their content, including copyright and
          trademark laws. Members will respect copyright and trademark laws.{' '}
          <br />
          You warrant that you will not use our Website or Products to infringe
          on the intellectual property rights of others in any way. Following
          the Digital Millennium Copyright Act (DMCA) and other applicable law,
          we have adopted a policy of terminating Members whom we judge, in our
          sole discretion, to be infringers of others’ intellectual property
          rights.
        </p>

        <p className='font-size-14'>
          <i>
            <span className='bold'>
              As a User, you agree not to use our Website or Products to do any
              of the following:
            </span>
          </i>
        </p>

        <u>
          <p className='font-size-14' style={{ marginBottom: '10px' }}>
            Upload, post, or transmit any Member Content that:
          </p>
        </u>
        <ol className='font-size-14 margin'>
          <li className='font-size-14'>
            Violates any local, state, federal, or international laws
          </li>
          <li className='font-size-14'>
            Infringes on any patent, trademark, trade secret, copyright, or
            other proprietary rights of any party
          </li>
          <li className='font-size-14'>
            Harms, threatens, defames, promotes violence or illegal activities,
            or is otherwise vulgar, obscene, abusive, harassing, tortuous,
            libelous, invasive of another’s privacy, hateful, or racially,
            ethically, or otherwise objectionable
          </li>
          <li className='font-size-14'>
            Links directly or indirectly to any materials to which you do not
            have a right to link
          </li>
          <li className='font-size-14'>
            Contains any private information of any third-party, including,
            without limitation, addresses, phone numbers, email addresses,
            Social Security numbers, and credit card numbers
          </li>
          <li className='font-size-14'>
            Contains software viruses or any other computer code, files, or
            programs designed to interrupt, destroy, or limit the functionality
            of any computer software or hardware or telecommunications
            equipment, or to extract information from our Website or Products
          </li>
          <li className='font-size-14'>
            Contains any unsolicited or unauthorized advertising, solicitations,
            promotional materials, junk mail, spam, chain letters, pyramid
            schemes, or any other form of solicitation
          </li>
          <li className='font-size-14'>
            In the sole judgment of Leeder, LLC is objectionable or restricts or
            inhibits any other person from using or enjoying our Website or
            Products, or which may expose Leeder, LLC, our affiliates, or our
            Users to any harm or liability of any type
          </li>
        </ol>

        <u>
          <p className='font-size-14' style={{ marginBottom: '10px' }}>
            Use our Content to:
          </p>
        </u>
        <ol className='font-size-14 margin'>
          <li className='font-size-14'>Develop a competing website</li>
          <li className='font-size-14'>
            Create compilations or derivative works as defined under United
            States copyright laws
          </li>
          <li className='font-size-14'>
            Redistribute it in any manner, including, but not limited to, sale,
            license, lease, rental, subscription, or any other distribution
            mechanism
          </li>
          <li className='font-size-14'>
            Decompile, disassemble, or reverse engineer our Website, Products,
            and any related software
          </li>
          <li className='font-size-14'>
            Use our Website or Products in any manner that violates this
            Agreement or any local, state, federal, or international laws
          </li>
        </ol>

        <p className='font-size-14 margin'>
          <span className='bold'>Use of Information</span>
          <br />
          You grant Leeder, LLC a license to use the information and materials
          you post to our Website. By posting, displaying, transmitting,
          performing, or distributing information or other content (“Member
          Content”) to our Website, you are granting Leeder, LLC, its officers,
          directors, employees, agents, consultants, representatives, and
          affiliates, a license to use the Member Content about the operation of
          the business of Leeder, LLC, its directors, employees, officers,
          affiliates, representatives, consultants, and agents, including,
          without limitation, a right to distribute, copy, transmit, publicly
          display, reproduce, translate, edit, and reformat Member Content. You
          understand and agree that you will not be compensated for any Member
          Content. By posting Member Content on our Website or Products, you
          warrant and represent that you own the rights to the Member Content or
          are authorized to post, display, distribute, perform, or transmit
          Member Content.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Unlawful Activity</span>
          <br />
          We reserve the right to investigate complaints or reported violations
          of this Agreement and to take any action we judge appropriate,
          including, but not limited to, reporting any suspected unlawful
          activity to law enforcement officials, regulators, or other third
          parties and disclosing any information necessary or appropriate to
          such persons or entities relating to your profile, email addresses,
          usage history, posted materials, IP addresses, and traffic
          information.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Linking to Our Website</span>
          <br />
          You may provide links to our Website if (a) you do not remove or
          obscure any portion of our Website by framing, (b) your website does
          not engage in illegal or pornographic activities, and (c) you stop
          providing links to our Website immediately on our request.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Links to Other Websites</span>
          <br />
          Our Website may from time to time contain links to third-party
          websites. Inclusion of links for any website on our Website does not
          mean that we endorse, guarantee, warrant, or recommend the services,
          information, content, and or data of such third-party websites.
          Leeder, LLC has no control over the legal documents and privacy
          practices of third-party websites; you access any third-party websites
          at your own risk. We recommend that you review the privacy notice and
          terms and conditions of those websites to fully understand what
          information is collected and how it is used.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Payments</span>
          <br />
          You represent and warrant that if you are buying something from us,
          (i) any payment information you supply is true and complete, (ii)
          charges incurred by you will be honored by your bank or credit card
          company, (iii) you will pay the charges incurred by you at the posted
          prices, including any applicable taxes, and (iv) if your initial
          payment method is dishonored, you will still pay the incurred charges,
          including any surcharge we may incur because of the dishonored
          payment.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Refund and Return Policy</span>
          <br />
          We have no refund policy.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>
            Termination of Membership or User Privileges
          </span>
          <br />
          Your membership or User privileges with us are effective until
          terminated by you or us. Your rights under this Agreement will
          terminate without our notice if you fail to comply with any term of
          this Agreement. On termination, you will stop representing yourself as
          a Member or User. You must delete or destroy any information or
          content (including all copies) obtained from our Website. Certain
          provisions of this Agreement, including, but not limited to,
          copyrights, indemnity, trademarks, limitation of liability, warranty,
          and jurisdictional issues will survive the termination of this
          Agreement.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Indemnification</span>
          <br />
          You agree to indemnify, defend, and hold us and our partners, agents,
          officers, directors, employees, subcontractors, successors, assigns,
          third-party suppliers of information and documents, attorneys,
          advertisers, product and service providers, and affiliates free from
          any liability, loss, claim, and expense, including reasonable
          attorney’s fees, related to your violation of this Agreement or use of
          our Website or Products.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Severability and Survival</span>
          <br />
          Should any part of this Agreement be held invalid or unenforceable,
          that portion will be construed consistent with applicable law and the
          remaining portions will remain in full force and effect. To the extent
          that any Content is in conflict or inconsistent with this Agreement,
          this Agreement will take precedence. Our failure to enforce any
          provision of this Agreement will not be deemed a waiver of such a
          provision, nor of the right to enforce such a provision. Our rights
          under this Agreement will survive any termination of this Agreement.
        </p>
        <p className='font-size-14 margin'>
          <span className='bold'>Changes to Our Agreement</span>
          <br />
          We reserve the right to change this Agreement at any time by giving
          you advance notice of the changes by email or in writing. We will also
          post these changes on our Website. These changes will become effective
          30 days after receipt of the notice. To avoid doubt, no unilateral
          amendment will retroactively change agreed dispute-resolution
          provisions of these Terms and Conditions, if any, including, for
          example, arbitration provisions for then-pending disputes unless the
          parties expressly agree otherwise. Your continued use of our Website
          and Products after any change to this Agreement and our telling you
          will constitute your acceptance of such change. If you do not agree
          with the changes to this Agreement, you can choose to discontinue the
          use of our Website and Products.
        </p>

        <p className='font-size-14' style={{ textAlign: 'center' }}>
          Copyright © - This document or any portion of it may not be copied or
          duplicated in any way.
        </p>
      </div>
    </Grid>
  );
};

export default TermsAndConditions;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    background: theme.palette.common.lightPink,
    padding: '3rem',
    paddingTop: '7rem',
    '& .font-size-14': {
      fontSize: '14px',
    },
    '& .margin': {
      marginBottom: '2rem',
    },
    '& .font-size-16': {
      fontSize: '16px',
    },
    '& .font-size-20': {
      fontSize: '20px',
    },
    '& .title': {
      //   fontWeight: '700',
      textDecoration: 'underline',
    },
    '& .bold': {
      fontWeight: '700',
    },
    '& ul': {
      paddingLeft: '1rem',
    },
    '& ol': {
      paddingLeft: '1rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2rem',
      paddingTop: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
      paddingTop: '3.5rem',
      '& ul': {
        padding: 0,
      },
      '& ol': {
        padding: 0,
      },
    },
  },
  navLight: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '1rem 2.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  logo: {
    height: '5rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('lg')]: {
      height: '4rem',
    },
  },
  logoImage: {
    height: '100px',
    [theme.breakpoints.down('lg')]: {
      height: '80px',
    },
  },
  privacy: {
    color: '#000000',
    margin: 0,
    fontSize: '35px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '0.5rem',
    },
  },
  innerContainer: {
    background: '#ffffff',
    borderRadius: '48px',
    boxShadow: theme.shadows[3],
    maxHeight: 'calc(100vh - 10.5rem)',
    minHeight: 'calc(100vh - 10.5rem)',
    padding: '2rem 2.5rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      maxHeight: '50%',
      height: '50%',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.darkPink,
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-button ': {
      width: '20px', //for horizontal scrollbar
      height: '20px', //for vertical scrollbar
    },
    [theme.breakpoints.down('lg')]: {
      borderRadius: '25px',
      padding: '1.5rem 2rem',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '10px',
      padding: '1rem 1.5rem',
      minHeight: 'calc(100vh - 7rem)',
    },
  },
  title: {
    color: '#000',
    margin: 0,
    fontSize: '35px',
    fontWeight: 100,
    fontWeight: 700,
    marginBottom: '20px',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
}));
