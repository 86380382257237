import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  heading: {
    color: '#000',
    margin: '0',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
  },
  filterIconContainer: {
    height: '60px',
    width: '60px',
    paddingInline: '10px',
    backgroundColor: '#FE858C',
    '&:hover': {
      backgroundColor: '#FE858C',
    },
    [theme.breakpoints.down('lg')]: {
      height: '40px',
      width: '40px',
    },
  },
  filterIcon: {
    color: '#fff',
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  },
  accordionRoot: {
    backgroundColor: theme.palette.common.lightPink,
    borderRadius: '25px',
    width: '392px',
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      borderRadius: '18px',
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
  summary: {
    backgroundColor: '#fbfbfb',
    padding: '0.5rem 1rem',
    borderRadius: '25px',
    height: '85px',
    [theme.breakpoints.down('lg')]: {
      height: '55px',
      borderRadius: '18px',
    },
    '& .row': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      },
    },
  },
  details: {
    display: 'block',
    padding: '5px',
    paddingInline: '16px',
  },
  // child accordion
  filter: {
    height: '54px',
    background: '#ffffff',
    borderRadius: '12px',
    boxSizing: 'border-box',
    paddingInline: '14px 14px',
    boxShadow: theme.shadows[1],
    marginBlock: '5px',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
      paddingInline: '8px 8px',
    },
  },
  ageFilterContainer: {
    background: '#ffffff',
    borderRadius: '12px',
    boxShadow: theme.shadows[1],
    marginBlock: '2px',
    paddingInline: '21px 14px',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '14px 8px',
    },
  },
  ageFilter: {
    height: '54px',
    [theme.breakpoints.down('lg')]: {
      height: '50px',
    },
  },
  sliderRoot: {
    marginTop: '10px',
    width: '90%',
    color: theme.palette.primary.main,
    height: '2px',
    '&.within': {
      '& .MuiSlider-thumb': {
        height: '20px',
        width: '20px',
        backgroundColor: '#fff',
        boxShadow: theme.shadows[3],
        marginTop: '-7px',
        marginLeft: '-7px',
        '&:nth-child(even)': {
          '& span': {
            '& span': {
              '& span': {
                paddingRight: '0px',
              },
            },
          },
        },
        '&:nth-child(odd)': {
          '& span': {
            '& span': {
              '& span': {
                paddingLeft: '0px',
              },
            },
          },
        },
      },
    },
    '& .MuiSlider-thumb': {
      height: '20px',
      width: '20px',
      backgroundColor: '#fff',
      boxShadow: theme.shadows[3],
      marginTop: '-7px',
      marginLeft: '-7px',
      '&:nth-child(even)': {
        '& span': {
          '& span': {
            '& span': {
              paddingRight: '20px',
            },
          },
        },
      },
      '&:nth-child(odd)': {
        '& span': {
          '& span': {
            '& span': {
              paddingLeft: '20px',
            },
          },
        },
      },
    },
    '& 	.MuiSlider-valueLabel': {
      left: '-50%',
      top: '-18px',
      '& *': {
        background: 'transparent',
        color: '#000',
        fontSize: '16px',
      },
    },
    '& .MuiSlider-rail': {
      height: '6px',
      backgroundColor: theme.palette.common.darkPink,
    },
    '& .MuiSlider-track': {
      height: '6px',
      backgroundColor: '#FE858C',
    },
  },
  title: {
    margin: 0,
    color: '#7E7E7E',
    fontSize: '15px',
    fontWeight: 300,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
    },
  },
  subtitle: {
    margin: 0,
    color: theme.palette.primary.main,
    fontSize: '17px',
    fontWeight: 500,
    maxWidth: '20ch',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  arrowIcon: {
    marginTop: '7px',
    color: '#BDBDBD',
    fontSize: '35px',
    marginLeft: '-10px',
    transition: '0.4s ease',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
  expandLess: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0',
    margin: '0',
  },
  expandLessIcon: {
    color: theme.palette.primary.main,
    fontSize: '3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
    },
  },
  setButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  cancelButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 500,
    marginRight: '0.75rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  resetButton: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 300,
    marginRight: '0.75rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: '700',
    paddingInline: '23px',
    marginBlock: '15px 12px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      paddingInline: '15px',
    },
  },
}));
