import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction as Action,
  Grid,
  List,
} from '@material-ui/core';

import { getEmailNotification, setEmailNotification } from '../../http';

import { Checkbox } from '../../components/Checkbox/Checkbox';

const EmailNotification = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    isMatch: false,
    isMessage: false,
    isInstantLike: false,
    isLike: false,
    isScheduler: false,
  });

  const handleChange = async e => {
    const { name, checked } = e.target;
    setState(prev => ({ ...prev, [name]: checked }));
    const apiData = { type: name, val: checked };
    await setEmailNotification(apiData);
  };

  useEffect(() => {
    (async () => {
      const { data } = await getEmailNotification();
      setState(data.data);
    })();
  }, []);

  return (
    <Grid container direction='column' className={classes.mainContainer}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.text} primary='Match' />
          <Action>
            <Checkbox
              variant='switch'
              name='isMatch'
              handleShow={handleChange}
              show={state.isMatch}
            />
          </Action>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.text} primary='Messages' />
          <Action>
            <Checkbox
              variant='switch'
              name='isMessage'
              handleShow={handleChange}
              show={state.isMessage}
            />
          </Action>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.text} primary='Instant Spark' />
          <Action>
            <Checkbox
              variant='switch'
              name='isInstantLike'
              handleShow={handleChange}
              show={state.isInstantLike}
            />
          </Action>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.text} primary='Likes' />
          <Action>
            <Checkbox
              variant='switch'
              show={state.isLike}
              name='isLike'
              handleShow={handleChange}
            />
          </Action>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText className={classes.text} primary='Scheduler' />
          <Action>
            <Checkbox
              show={state.isScheduler}
              variant='switch'
              name='isScheduler'
              handleShow={handleChange}
            />
          </Action>
        </ListItem>
      </List>
    </Grid>
  );
};

export default EmailNotification;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingInline: '82px 0px',
    paddingBlock: '45px 27px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '25px 15px',
      paddingInline: '40px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '0px 15px',
      paddingInline: '20px 0px',
    },
  },
  list: {
    width: '60%',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      width: '100%',
    },
  },
  listItem: {
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
    paddingBlock: '34px',
    paddingInline: '0px',
    [theme.breakpoints.down('lg')]: {
      paddingBlock: '18px',
    },
  },
  text: {
    '& .MuiListItemText-primary': {
      margin: '0',
      fontSize: '20px',
      color: theme.palette.primary.main,
      textAlign: 'left',
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
    },
  },

  arrow: {
    color: theme.palette.primary.main,
    fontSize: '30px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
}));
