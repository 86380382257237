import React from 'react';
import {
  Grid,
  makeStyles,
  Avatar,
  Typography,
  Hidden,
  // Badge
} from '@material-ui/core';
// import { useSelector } from 'react-redux';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { ChevronRightRounded } from '@material-ui/icons';
import { checkMatch } from '../../http';
import { useHistory } from 'react-router-dom';
import image from '../../assets';
import GeoCode from 'react-geocode';

GeoCode.setApiKey(process.env.REACT_APP_GEOCODE_API);

const ActivitySlider = ({ data, message }) => {
  const classes = useStyles();
  const history = useHistory();
  let lat = localStorage.getItem('latitude');
  let lon = localStorage.getItem('Longitude');

  const calcDistance = (lat2, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat);
    var dLon = toRad(lon2 - lon);
    var lat1 = toRad(lat);
    var lat2 = toRad(lat);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d / 1.60934).toFixed(1);
  };

  const getCity = () => {
    GeoCode.fromLatLng('48.8583701', '2.2922926').then(
      response => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'country':
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // console.log(city, state, country);
        // console.log(address);
        return city;
      },
      error => {
        console.error(error);
      }
    );
  };

  const handleClick = async id => {
    const { data } = await checkMatch(id);
    if (data.data) {
      history.push(`/home/match/${id}`);
    } else {
      history.push(`/home/unmatch/${id}`);
    }
  };

  // Converts numeric degrees to radians
  const toRad = Value => {
    return (Value * Math.PI) / 180;
  };

  return (
    <>
      {data.length > 0 && (
        <Splide
          className={classes.slider}
          options={{
            perPage: 6,
            perMove: 1,
            pagination: false,
            gap: '0.5rem',
            breakpoints: {
              1410: {
                perPage: 5,
              },
              1250: {
                perPage: 4,
              },
              1164: {
                perPage: 6,
              },
              800: {
                perPage: 4,
              },
              600: {
                perPage: 3,
                gap: '0.25rem',
              },
              410: {
                perPage: 1,
              },
            },
          }}
          renderControls={() => (
            <div className={`${classes.splideArrows} splide__arrows`}>
              <div className=' splide__arrow--prev ' role='button'>
                <ChevronRightRounded className='btn' />
              </div>
              <div className='splide__arrow--next' role='button'>
                <ChevronRightRounded className='btn' />
              </div>
            </div>
          )}
        >
          {data.map((user, index) => (
            <SplideSlide key={index}>
              <Grid item>
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                ></Grid>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item>
                    <Grid item container direction='column' alignItems='center'>
                      <Grid item className={classes.avatar_container}>
                        <Avatar
                          src={`${process.env.REACT_APP_API_URL}/${user.profile}`}
                          className={classes.avatar}
                          alt=''
                          onClick={() => handleClick(user.id)}
                        />

                        {user.isSuperLike && (
                          <img className='super' src={image.superLikeFilled} />
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container direction='column'>
                          <Typography className={classes.name}>
                            @{user.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {/* <Hidden smDown> */}
                    <Grid
                      container
                      direction='column'
                      className={classes.infoBox}
                    >
                      <Grid item>
                        <Typography className={classes.info}>
                          {user?.age}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.info}>
                          {calcDistance(
                            user?.location?.lat,
                            user?.location?.lng
                          ) + ' mi'}
                        </Typography>
                      </Grid>
                      {/* <Typography className={classes.name}>
                        {user.name}
                      </Typography>
                      <p className={classes.text}>{getCity()}</p>
                      <p className={classes.text}>{user.age}</p>
                      <p className={classes.text}>
                        {calcDistance(user?.location?.lat, user?.location?.lng)}
                        mi
                      </p> */}
                    </Grid>
                    {/* </Hidden> */}
                  </Grid>
                </Grid>
              </Grid>
            </SplideSlide>
          ))}
        </Splide>
      )}

      {!data.length && (
        <Grid container justifyContent='center'>
          <h2 className={classes.message}>{message}</h2>
        </Grid>
      )}
    </>
  );
};

export default ActivitySlider;

const useStyles = makeStyles(theme => ({
  slider: {
    '& .splide__arrow ': {
      display: 'none',
    },
  },
  avatar: {
    height: '91px',
    width: '91px',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: '70px',
      width: '70px',
    },
  },
  splideArrows: {
    background: 'red',
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '0',
    '& .splide__arrow--prev': {
      marginLeft: '-65px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '-55px',
      },
    },
    '& .splide__arrow--next': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      marginRight: '-65px',
      [theme.breakpoints.down('lg')]: {
        marginRight: '-55px',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '-25px',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px',
      },
    },
    '& .btn': {
      fontSize: '50px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('lg')]: {
        fontSize: '40px',
      },
    },
  },
  name: {
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#000000',
    margin: '0',
    maxWidth: '10ch',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  text: {
    fontSize: '19px',
    marginTop: '2px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      marginTop: '1px',
    },
  },
  message: {
    fontSize: '24px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
  },
  infoBox: {
    marginLeft: '0px',
  },
  info: {
    textAlign: 'left',
    margin: '0px',
    color: '#000000',
    fontSize: '19px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#26BB21',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
      minWidth: '0',
      [theme.breakpoints.down('lg')]: {
        width: '12px',
        height: '12px',
      },
    },
  },
  avatar_container: {
    position: 'relative',
    '& .super': {
      position: 'absolute',
      left: '50%',
      bottom: '0%',
      transform: 'translate(-50%, 30%)',
      [theme.breakpoints.down('lg')]: {
        width: '25px',
      },
    },
  },
}));
