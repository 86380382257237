import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  makeStyles,
  Typography,
  Avatar,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  Dialog,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab,
} from '@material-ui/core';
import clsx from 'clsx';
import chipsIcon from '../../../assets/chipsIcons';
import image from '../../../assets/index';
import { FavoriteBorder, Close } from '@material-ui/icons';
import { otherUserVideos, deleteVideo } from '../../../http';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMute, setMute } from '../../../store/videoSound';
import { setUserVideos } from '../../../store/userVideos';
import { calculateZodiacSign } from '../../../utils/zodiacSign';
import zodiacSigns from '../../../assets/zodiacSigns';
import { useHistory } from 'react-router-dom';
import { countryList } from '../../../app.config';
import { getCity } from '../../../utils';

const MyVideo = props => {
  const classes = useStyles();
  const { index, video, handleDialogOpen, muted, handleToggleMute } = props;
  const [, setObserver] = useState(null);
  const videoRef = useRef(null);

  const handleChangeView = useCallback(entries => {
    for (let entry of entries) {
      if (entry.intersectionRatio > 0.8) {
        if (videoRef.current) {
          document.querySelectorAll('video').forEach(video => {
            video.pause();
          });
          if (videoRef.current.readyState >= 2) {
            videoRef.current.play();
          }
        } else {
          videoRef.current.pause();
        }
      }
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleChangeView, {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    });
    setObserver(observer);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [handleChangeView]);

  useEffect(() => {
    setTimeout(() => {
      if (index === 0) {
        videoRef.current?.play();
      }
    }, 500);
    // eslint-disable-next-line
  }, []);
  return (
    <Grid
      item
      container
      className={classes.post}
      direction='column'
      alignItems='center'
      key={index}
    >
      <Grid item>
        <Typography
          style={{ textAlign: 'center' }}
          className={classes.postTitle}
        >
          {video.video_title}
        </Typography>
        <div className={classes.postContainer}>
          <video
            ref={videoRef}
            playsInline
            loop
            muted={muted}
            className={classes.video}
            poster={`${process.env.REACT_APP_API_URL}/${video.cover}`}
            src={`${process.env.REACT_APP_API_URL}/${video.video_url}`}
          ></video>
          <IconButton
            onClick={() => handleDialogOpen(video._id)}
            className={classes.closeButton}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <div className={classes.iconContainer}>
            <Grid
              container
              className={classes.icons}
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item>
                <IconButton onClick={handleToggleMute}>
                  <img
                    src={muted ? image.mute : image.unMute}
                    className={classes.muteIcon}
                    alt=''
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid container alignItems='center'>
                  <Grid item>
                    <IconButton>
                      <span className={classes.likeCount}>{video.likes}</span>
                      <img
                        src={image.superLikeFilled}
                        alt=''
                        className={`${classes.likeIcon} super`}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <span className={classes.likeCount}>{video.likes}</span>
                      <FavoriteBorder className={classes.likeIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const UserProfile = props => {
  const history = useHistory();
  const user = useSelector(state => state.auth.user.data);
  const id = user._id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down(620));
  const [city, setCity] = useState('');

  const [zodiac, setZodiac] = useState('');
  const [videos, setVideos] = useState([]);
  const mutedState = useSelector(state => state.video.muted);
  const [muted, setMuted] = useState(mutedState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const muteRef = useRef();
  const [deleteId, setDeleteId] = useState(null);
  const [uploadFirst, setUploadFirst] = useState(false);

  const toFeet = cm => {
    const realFeets = (cm * 0.3937) / 12;
    const feets = Math.floor(realFeets);
    const inches = Math.round((realFeets - feets) * 12);
    return `${feets}'${inches}"`;
  };
  const handleToggleMute = () => {
    setMuted(!muted);
    dispatch(toggleMute());
  };
  const handleDeleteVideo = async () => {
    try {
      await deleteVideo(deleteId);
      const videosArray = videos.filter(video => video._id !== deleteId);
      setVideos(videosArray);
      setIsDialogOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleDialogOpen = id => {
    if (videos.length === 1) {
      setUploadFirst(true);
    }
    setDeleteId(id);
    muteRef.current = mutedState;
    dispatch(setMute(true));
    setMuted(true);
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDeleteId(null);
    dispatch(setMute(muteRef.current));
    setIsDialogOpen(false);
    setMuted(muteRef.current);
  };

  const HandleGetCountryFlags = countryArray => {
    // console.log(countryArray);
    const countries = [];

    countryArray?.forEach(country => {
      countryList.forEach(c => {
        if (c.name === country) {
          countries.push(c);
        }
      });
    });

    return countries;
  };
  useEffect(() => {
    (async function () {
      try {
        const { data } = await otherUserVideos(id);
        setVideos(data.data);
        let birthday = user.date_of_birth.dob;
        birthday = birthday.split('/');
        const sign = calculateZodiacSign(
          Number(birthday[1]),
          Number(birthday[0])
        );
        setZodiac(sign);
        dispatch(setUserVideos(data.data));
        // console.log(data.data);
      } catch (err) {
        console.log(err.message);
      }
    })();
    if (user) {
      getCity(user.location.lat, user.location.lon, setCity);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (isDialogOpen) {
      // eslint-disable-next-line
      videosRef?.current?.map(video => {
        video.pause();
      });
    }
    // eslint-disable-next-line
  });
  return (
    <Grid
      container
      direction='row'
      className={classes.container}
      style={{ position: 'relative' }}
      // onScroll={smScreen ? debounce(handleScroll, 250) : () => null}
    >
      <Grid
        item
        container
        alignItems='center'
        direction='column'
        className={classes.left}
        wrap='nowrap'
      >
        <Fab
          variant='extended'
          className={`${classes.floatingButton} mob-only`}
          onClick={() =>
            history.push({
              pathname: '/uploads',
              state: {
                deleteId,
                isEmail: true,
              },
            })
          }
        >
          <img src={image.upload} style={{ width: '20px' }} alt='' /> Upload
          Video
        </Fab>
        <Grid item className={classes.userInfo}>
          <Grid container direction='column' spacing={2}>
            <Grid item className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={`${process.env.REACT_APP_API_URL}/${user.profile_image}`}
              />
            </Grid>
            <Grid item container direction='column' alignItems='center'>
              <Typography
                style={{ textTransform: 'capitalize' }}
                className={classes.userName}
              >
                {user.first_name}, {user.date_of_birth.age}
              </Typography>
              <Typography className={classes.userCity}>
                {`@${user.username}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='column'>
          <List className={classes.list}>
            {user.identify.visible && user.identify.gender && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pGender}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={user.identify.gender}
                />
              </ListItem>
            )}

            {user.location.visible && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pLocation}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={city} />
              </ListItem>
            )}

            {user.height.visible && user.height.height && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pHeight}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={toFeet(user.height.height)}
                />
              </ListItem>
            )}

            {user.profession.company.c_visible &&
              user.profession.company.company && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img
                      src={image.pCompany}
                      alt=''
                      className={classes.listIcon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={user.profession.company.company}
                  />
                </ListItem>
              )}

            {user.profession.job_title.j_visible &&
              user.profession.job_title.job_title && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img src={image.pJob} alt='' className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={user.profession.job_title.job_title}
                  />
                </ListItem>
              )}

            {user.education.school.s_visible && user.education.school && (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <img
                    src={image.pSchool}
                    alt=''
                    className={classes.listIcon}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={user.education.school}
                />
              </ListItem>
            )}
          </List>
          <Grid item>
            <Typography
              className={classes.backgroundTitle}
              style={{ textTransform: 'capitalize' }}
            >
              {user.first_name}'s Background
            </Typography>
          </Grid>
          <Grid item container>
            {HandleGetCountryFlags(user.ethnicity.country_list).map(
              (country, index) => (
                <Grid
                  item
                  container
                  key={index}
                  xs={6}
                  className={classes.flagContainer}
                >
                  <div className={classes.flexInner}>
                    <img src={country.image} alt='' />
                    <Typography className={classes.countryNames}>
                      {country.name}
                    </Typography>
                  </div>
                </Grid>
              )
            )}
          </Grid>
          {/* Basic info */}
          <Grid item>
            <Typography className={clsx(classes.backgroundTitle, 'basic-info')}>
              Basic info
            </Typography>
          </Grid>
          <Grid item container className={classes.chipsContainer}>
            {zodiac && (
              <div className='chip'>
                <img src={zodiacSigns[zodiac]} alt='' className='chip-icon' />
                <p
                  className='chip-label'
                  style={{ textTransform: 'capitalize' }}
                >
                  {zodiac}
                </p>
              </div>
            )}
            {user.date_preference.visible &&
              user.date_preference.interested_audience &&
              user.date_preference.interested_audience.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.intent} alt='' className='chip-icon sm' />
                  <p className='chip-label'>
                    {user.date_preference.interested_audience}
                  </p>
                </div>
              )}

            {user.education.d_visible &&
              user.education.degree &&
              user.education.degree.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.degree} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.education.degree}</p>
                </div>
              )}

            {user.religion.r_visible &&
              user.religion.religion &&
              user.religion.religion.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.religion}
                    alt=''
                    className='chip-icon sm'
                  />
                  <p className='chip-label'>{user.religion.religion}</p>
                </div>
              )}

            {user.religion.p_visible &&
              user.religion.politics &&
              user.religion.politics.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.politics} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.religion.politics}</p>
                </div>
              )}

            {user.identify.marital_status_visible &&
              user.identify.marital_status &&
              user.identify.marital_status.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.heart} alt='' className='chip-icon ' />
                  <p className='chip-label'>{user.identify.marital_status}</p>
                </div>
              )}

            {user.vices.drink.d_visible &&
              user.vices.drink.drink &&
              user.vices.drink.drink.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.drink} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.drink.drink}</p>
                </div>
              )}

            {user.vices.drugs.dr_visible &&
              user.vices.drugs.drugs &&
              user.vices.drugs.drugs.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.drungs} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.drugs.drugs}</p>
                </div>
              )}

            {user.vices.smoke.s_visible &&
              user.vices.smoke.smoke &&
              user.vices.smoke.smoke.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.smoke} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.smoke.smoke}</p>
                </div>
              )}
            {user.vices.weed.w_visible &&
              user.vices.weed.weed &&
              user.vices.weed.weed.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.weed} alt='' className='chip-icon' />
                  <p className='chip-label'>{user.vices.weed.weed}</p>
                </div>
              )}

            {user.children.have_children &&
              user.children.visible &&
              user.children.have_children.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.haveChildren}
                    alt=''
                    className='chip-icon'
                  />
                  <p className='chip-label'>{user.children.have_children}</p>
                </div>
              )}
            {user.children.want_children &&
              user.children.visible &&
              user.children.want_children.toLowerCase() !==
                'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.wantChildren}
                    alt=''
                    className='chip-icon'
                  />
                  <p className='chip-label'>{user.children.want_children}</p>
                </div>
              )}

            {user.body_type.t_visible &&
              user.body_type.type &&
              user.body_type.type.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img
                    src={chipsIcon.bodyType}
                    alt=''
                    className='chip-icon sm'
                  />
                  <p className='chip-label'>{user.body_type.type}</p>
                </div>
              )}

            {user.body_type.d_visible &&
              user.body_type.diet &&
              user.body_type.diet.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.diet} alt='' className='chip-icon sm' />
                  <p className='chip-label'>{user.body_type.diet}</p>
                </div>
              )}
            {user.body_type.f_visible &&
              user.body_type.fitness &&
              user.body_type.fitness.toLowerCase() !== 'prefer not to say' && (
                <div className='chip'>
                  <img src={chipsIcon.health} alt='' className='chip-icon sm' />
                  <p className='chip-label'>{user.body_type.fitness}</p>
                </div>
              )}
          </Grid>
        </Grid>
        {/* <div style={{ height: '500px' }}></div> */}
        {smScreen && (
          <Grid
            item
            container
            className={clsx(classes.scrollDiv, 'noMainVideos')}
          >
            {videos.map((video, index) => (
              <MyVideo
                video={video}
                index={index}
                muted={muted}
                handleDialogOpen={handleDialogOpen}
                handleToggleMute={handleToggleMute}
              />
            ))}
            <Dialog
              className={classes.dialog}
              open={isDialogOpen}
              onClose={() => handleDialogClose}
            >
              <Grid container className={classes.dialogContainer}>
                <Grid item container direction='column' alignItems='center'>
                  <Typography className={classes.dialogTitle}>
                    Are you sure
                  </Typography>
                  {uploadFirst ? (
                    <Typography className={classes.dialogSubtitle}>
                      Please upload another video before deleting
                    </Typography>
                  ) : (
                    <Typography className={classes.dialogSubtitle}>
                      You want to delete this video
                    </Typography>
                  )}
                </Grid>
                <Grid item container direction='column' alignItems='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.dialogButtons}
                    style={{ marginBottom: '1rem' }}
                    onClick={
                      uploadFirst
                        ? () =>
                            history.push({
                              pathname: '/uploads',
                              state: {
                                deleteId,
                                isEmail: false,
                              },
                            })
                        : handleDeleteVideo
                    }
                  >
                    {uploadFirst ? 'Upload Video' : 'Yes'}
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    className={classes.dialogButtons}
                    onClick={handleDialogClose}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        direction='column'
        // alignItems='center'
        className={classes.middle}
      >
        <Fab
          variant='extended'
          className={classes.floatingButton}
          onClick={() =>
            history.push({
              pathname: '/uploads',
              state: {
                deleteId,
                isEmail: true,
              },
            })
          }
        >
          <img src={image.upload} style={{ width: '20px' }} alt='' /> Upload
          Video
        </Fab>

        <Grid
          item
          container
          // onScroll={handleScroll}
          className={clsx(classes.scrollDiv, 'mainVideos')}
          style={{ paddingTop: '3rem' }}
        >
          {!smScreen &&
            videos.map((video, index) => (
              <MyVideo
                video={video}
                index={index}
                muted={muted}
                handleDialogOpen={handleDialogOpen}
                handleToggleMute={handleToggleMute}
              />
            ))}
          <Dialog
            className={classes.dialog}
            open={isDialogOpen}
            onClose={() => handleDialogClose}
          >
            <Grid container className={classes.dialogContainer}>
              <Grid item container direction='column' alignItems='center'>
                <Typography className={classes.dialogTitle}>
                  Are you sure
                </Typography>
                {uploadFirst ? (
                  <Typography className={classes.dialogSubtitle}>
                    Please upload another video before deleting
                  </Typography>
                ) : (
                  <Typography className={classes.dialogSubtitle}>
                    You want to delete this video
                  </Typography>
                )}
              </Grid>
              <Grid item container direction='column' alignItems='center'>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.dialogButtons}
                  style={{ marginBottom: '1rem' }}
                  onClick={
                    uploadFirst
                      ? () =>
                          history.push({
                            pathname: '/uploads',
                            state: {
                              deleteId,
                              isEmail: false,
                            },
                          })
                      : handleDeleteVideo
                  }
                >
                  {uploadFirst ? 'Upload Video' : 'Yes'}
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.dialogButtons}
                  onClick={handleDialogClose}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      </Grid>
      <Grid item container className={classes.right}></Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    height: '0px',
    overflow: 'auto',
    [theme.breakpoints.down(620)]: {
      flexDirection: 'column',
    },
  },
  left: {
    flex: 1,
    boxSizing: 'border-box',
    paddingTop: '4rem',
    paddingInline: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '1.5rem',
      paddingTop: '1.5rem',
    },
    [theme.breakpoints.down(800)]: {
      flex: 1,
      paddingBottom: '10rem',
    },
    [theme.breakpoints.down(620)]: {
      height: 'unset',
      display: 'block',
    },
  },
  middle: {
    flex: 1.5,
    backgroundColor: '#fbfbfb',
    boxSizing: 'border-box',
    border: '1px solid rgba(112, 112, 112, 0.17)',
    height: '100%',
    [theme.breakpoints.down(800)]: {
      flex: 1.3,
      height: 'auto',
    },
    [theme.breakpoints.down(620)]: {
      flex: 1,
      height: 'auto',
      display: 'none',
    },
  },
  right: {
    flex: 0.7,
    [theme.breakpoints.down(800)]: {
      flex: 0,
      display: 'none',
    },
  },
  userName: {
    fontSize: '29px',
    marginBottom: 0,
    fontWeight: 'bold',
    lineHeight: '30px',
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
  },
  userCity: {
    fontSize: '20px',
    marginBottom: 0,
    margin: 0,
    color: '#4D4542',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  height: {
    fontSize: '22px',
    marginBottom: '0',
    color: '#3E3E3E',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
  },
  subtitle: {
    fontSize: '20px',
    marginBottom: 0,
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  avatar: {
    height: '202px',
    width: '202px',
    marginInline: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: '130px',
      width: '130px',
    },
  },
  anchor: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    margin: 0,
    fontWeight: 700,
    marginTop: '1rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5rem',
      fontSize: '15px',
    },
  },
  userInfo: {
    width: '100%',
    padding: '0 2.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0 1.5rem',
    },
  },
  postTitle: {
    color: '#000',
    margin: '0',
    fontSize: '20px',
    textAlign: 'left',
    width: '100%',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  postContainer: {
    width: '509px',
    height: '596px',
    borderRadius: '44px',
    position: 'relative',
    overflow: 'hidden',

    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('lg')]: {
      borderRadius: '33px',
      width: '320px',
      height: '380px',
    },
    [theme.breakpoints.down(450)]: {
      borderRadius: '33px',
      width: '280px',
      height: '380px',
    },
  },
  iconContainer: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  icons: {
    height: '100%',
    padding: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0.25rem',
      paddingBlock: '0rem',
    },
  },
  likeIcon: {
    color: '#fbfbfb',
    fontSize: '2.3rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
      '&.super': {
        width: '1.8rem',
      },
    },
  },
  muteIcon: {
    maxWidth: '30px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '20px',
    },
  },
  post: {
    marginTop: '3rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1rem',
    },
  },

  likeCount: {
    fontSize: '22px',
    color: '#fff',
    marginRight: '1rem',
    [theme.breakpoints.down(1400)]: {
      fontSize: '14px',
      marginRight: '0.5rem',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '35px',
    height: '35px',
    [theme.breakpoints.down('lg')]: {
      width: '30px',
      height: '30px',
      top: '10px',
      right: '10px',
    },
  },
  closeIcon: {
    color: '#fff',
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  },
  rootMenu: {
    '& .MuiMenu-paper': {
      borderRadius: '25px',
      marginLeft: '-2rem',
    },
  },

  scrollDiv: {
    padding: '2rem',
    overflowY: 'auto',
    paddingBottom: '10rem',
    '&.noMainVideos': {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '1rem',
      paddingBottom: '10rem',
    },
    [theme.breakpoints.down(620)]: {
      padding: '1rem',
      height: '100%',
      overflow: 'none',
      '&.noMainVideos': {
        display: 'block',
        height: 'unset',
      },
    },
  },
  dialogButtons: {
    margin: '0',
    width: '265px',
    height: '63px',
    fontSize: '22px',
    fontWeight: '500',
    textTransform: 'none',
    borderRadius: '38px',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.dark}`,
    },
    [theme.breakpoints.down('lg')]: {
      width: '220px',
      height: '50px',
      fontSize: '16px',
    },
  },
  dialogSubtitle: {
    margin: '0',
    color: '#000000',
    fontSize: '22px',
    marginTop: '18px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      marginTop: '8px',
    },
  },
  dialogTitle: {
    margin: '0',
    color: '#000000',
    fontSize: '32px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
    },
  },
  dialogContainer: {
    height: '500px',
    width: '460px',
    paddingBlock: '100px 60px',
    paddingInline: '20px',
    [theme.breakpoints.down('lg')]: {
      height: '340px',
      width: '350px',
      paddingBlock: '60px 40px',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.common.lightPink,
      borderRadius: '10px',
    },
  },
  list: {
    marginTop: '45px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px',
    },
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      margin: 0,
      color: '#000',
      textAlign: 'left',
      marginLeft: '10px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
    },
  },
  listItemIcon: {
    minWidth: '20px',
    '& img': {
      marginInline: 'auto',
    },
  },
  listItem: {
    padding: 0,
    marginBottom: '0px',
  },
  backgroundTitle: {
    margin: 0,
    color: '#7E7E7E',
    fontSize: '20px',
    textAlign: 'left',
    marginBottom: '11.5px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      marginBottom: '8px',
    },
    '&.basic-info': {
      marginTop: '17px',
      [theme.breakpoints.down('lg')]: {
        marginTop: '10px',
      },
    },
  },
  countryNames: {
    margin: 0,
    color: '#505050',
    fontSize: '14px',
    textAlign: 'left',
  },
  flexInner: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '14px',
    '& img': {
      width: '22px',
    },
  },
  chipsContainer: {
    paddingBlock: '11px',
    gridGap: '9px',
    [theme.breakpoints.down('lg')]: {
      gridGap: '6px',
    },
    '& .chip': {
      backgroundColor: theme.palette.primary.main,
      height: '40px',
      paddingInline: '11px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '20px',
      gridGap: '5px',
      [theme.breakpoints.down('lg')]: {
        height: '35px',
        paddingInline: '9px',
      },
      '& .chip-label': {
        color: '#ffffff',
        fontSize: '15px',
        margin: 0,
        maxWidth: '20ch',
        [theme.breakpoints.down('lg')]: {
          fontSize: '12px',
        },
      },
      '& .chip-icon': {
        width: '14px',
        '&.sm': {
          width: '25px',
          marginLeft: '-5px',
          marginRight: '-5px',
        },
      },
    },
  },
  floatingButton: {
    position: 'fixed',
    background: theme.palette.common.darkPink,
    color: '#ffffff',
    textTransform: 'none',
    margin: '0.5rem',
    zIndex: '100',

    '&:hover': {
      background: theme.palette.common.darkPink + 'DD',
    },
    '&.mob-only': {
      display: 'none',
      [theme.breakpoints.down(620)]: {
        display: 'flex',
        position: 'fixed',
        zIndex: '100',
        left: '10px',
        bottom: '20px',
        gap: '5px',
      },
    },
  },
}));
