import React, { useState, useRef } from 'react';

import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import VideoThumbnail from 'react-video-thumbnail';

import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setPromptData } from '../../../store/utils';

import { AddAPhotoRounded, PhotoLibraryRounded } from '@material-ui/icons';

import { Header } from '../../../components/header/Header';
import clsx from 'clsx';

// import { prompts } from '../../../data';

import promptVideos from '../../../assets/promptVideos';
import { Input } from '../../../components/Textfield/Input';

import { uploadVideo, deleteVideo } from '../../../http';
import axios from 'axios';
import image from '../../../assets';

import { useTranslation } from 'react-i18next';

const headerItems = [
  {
    label: 'Help',
    to: '/helpcenter',
  },
];

const Prompts = () => {
  const classes = useStyles();

  const [t] = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const state = location.state;
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user.data);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [video, setVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);

  const caption = useRef('');
  const [error] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  const { title } = useSelector(state => state.utils.prompt);
  const [, setImageFile] = useState(null);

  const converBase64ToFile = async base64 => {
    const res = await fetch(base64);
    const blob = await res.blob();
    const file = new File([blob], 'cover', { type: 'image/png' });
    return file;
  };

  const handleUploadVideo = async () => {
    setOpenDialog(false);
    setLoading(true);
    const thumbnail = await converBase64ToFile(videoThumbnail);
    setImageFile(thumbnail);
    const data = new FormData();
    data.append('prompt', video);
    data.append('cover', thumbnail);
    data.append('question', title);
    data.append(
      'step',
      userData.step === '/get-prompt-questions' ? '/home' : userData.step
    );
    data.append('type', 'GALLERY');
    data.append('isEmail', state?.isEmail ? false : true);
    data.append('caption', caption.current.value);

    try {
      await uploadVideo(data);
      if (state?.deleteId) {
        await deleteVideo(state.deleteId);
      }
      history.replace('/home');
    } catch (err) {
      console.log('in catch block');
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid className={classes.containerMain}>
      <Header transparent headerItems={headerItems} />
      <Grid
        className={classes.innerContainer}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <div>
          <Typography varinat='h1' className={classes.title}>
            {t('select_a_prompt')}
          </Typography>
        </div>
        <Grid item className={classes.sliderContainer}>
          <Splide
            options={{
              perPage: 3,
              perMove: 1,
              pagination: false,
              width: '100%',
              gap: '3rem',
              type: 'loop',
              breakpoints: {
                620: {
                  gap: '2rem',
                },
                400: {
                  gap: '2rem',
                  perPage: 1,

                  // margin: '-30%',
                },
              },
            }}
            className={classes.slider}
            onMoved={(_, index) => {
              if (xsScreen) {
                setCurrentIndex(index - 1);
              } else {
                if (index === promptVideos.length - 1) {
                  setCurrentIndex(-1);
                } else {
                  setCurrentIndex(index);
                }
              }
            }}
          >
            {promptVideos?.map((prompt, index) => (
              <SplideSlide key={index}>
                <div
                  className={clsx(
                    classes.slide,
                    index === currentIndex + 1 && classes.activeSlide
                  )}
                >
                  <div className='container'>
                    <div className='header'>
                      <h2 className='title'>
                        {t(prompt?.title || '(No prompt)')}
                      </h2>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        aspectRatio: '1/1',
                        borderRadius: '21px',
                        overflow: 'hidden',
                        opacity: index === currentIndex + 1 ? '1' : '0.51',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {prompt.video ? (
                        <video
                          autoPlay
                          playsInline
                          loop
                          muted
                          src={prompt?.video}
                          style={{ width: '100%' }}
                        ></video>
                      ) : (
                        <img src={image.addPrompt} alt='' />
                      )}
                    </div>
                    <div className='btn'>
                      <Button
                        variant='contained'
                        color='primary'
                        className={clsx(classes.btn, 'card-btn')}
                        onClick={() => {
                          dispatch(
                            setPromptData({
                              title: prompt?.title,
                              description: prompt?.description,
                            })
                          );
                          setOpenDialog(true);
                        }}
                      >
                        {t('answer')}
                      </Button>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        className={classes.dialog}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.container}
        >
          {!video && <h1 className='title'>Select one option</h1>}
          {video ? (
            <>
              <video
                className='uploaded-video'
                src={URL.createObjectURL(video)}
                autoPlay
                loop
              ></video>
              <div style={{ display: 'none' }}>
                <VideoThumbnail
                  videoUrl={URL.createObjectURL(video)}
                  thumbnailHandler={thumbnail => setVideoThumbnail(thumbnail)}
                />
              </div>
              <Input
                placeholder='caption'
                ref={caption}
                error={!!error}
                helperText={error}
              />
            </>
          ) : (
            <div className='row'>
              <div>
                <AddAPhotoRounded
                  className='dialog-icons'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push({
                      pathname: '/record-prompt',
                      state: {
                        deleteId: state?.deleteId || null,
                        ...(state?.isEmail && {
                          isEmail: state?.isEmail || true,
                        }),
                      },
                    })
                  }
                />
              </div>
              <label htmlFor='video-upload'>
                <PhotoLibraryRounded
                  className='dialog-icons'
                  style={{ cursor: 'pointer' }}
                />
              </label>
              <input
                type='file'
                name=''
                id='video-upload'
                style={{ display: 'none' }}
                accept='video/*'
                onChange={e => setVideo(e.target.files[0])}
              />
            </div>
          )}
          {video && (
            <div className='row justify-center'>
              <label htmlFor='second'>
                <Button
                  className={clsx(classes.btn, 'continue-btn')}
                  variant='outlined'
                  color='primary'
                  style={{ pointerEvents: 'none' }}
                >
                  Try another
                </Button>
              </label>
              <input
                type='file'
                name=''
                id='second'
                style={{ display: 'none' }}
                accept='video/*'
                onChange={e => setVideo(e.target.files[0])}
              />
              <Button
                className={clsx(classes.btn, 'continue-btn')}
                variant='contained'
                color='primary'
                onClick={handleUploadVideo}
              >
                {t('continue')}
              </Button>
            </div>
          )}
        </Grid>
      </Dialog>
      <Dialog open={loading} className={classes.loadingDialog}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className='loading-container'
        >
          <h2>Your video is uploading. Please wait...</h2>
          <CircularProgress className='loader' />
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Prompts;

const useStyles = makeStyles(theme => ({
  containerMain: {
    minHeight: '100vh',
    backgroundColor: '#fff8f5',
    backgroundSize: '554.5px 520.5px, 330px 250px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '390px 390px, 240px 190px',
    },
  },
  innerContainer: {
    gap: '89px',
    minHeight: '100vh',
  },

  title: {
    color: '#000000',
    fontSize: '35px',
    fontWeight: '600',
    margin: '0',
    position: 'absolute',
    left: '50%',
    top: '140px',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      top: '80px',
    },
  },
  sliderContainer: {
    width: '1050px',
    paddingInline: '10px',
    maxWidth: '1050px',
    [theme.breakpoints.down('lg')]: {
      width: '900px',
      maxWidth: '900px',
    },
    [theme.breakpoints.down('md')]: {
      width: '744px',
      maxWidth: '744px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '580px',
      maxWidth: '580px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  slider: {
    '& li': {
      [theme.breakpoints.down('lg')]: {
        width: '250px!important',
      },
      [theme.breakpoints.down('md')]: {
        width: '194px!important',
      },
      [theme.breakpoints.down('xs')]: {
        width: `${window.innerWidth}px!important`,
      },
    },
    '& .splide__track': {
      paddingInline: '10px',
      alignItems: 'center',
      display: 'flex',
    },
    '& .splide__arrow': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.85,
      fill: '#ffffff',
      fontSize: '1.25rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        position: 'absolute!important',
        bottom: '0px',
        fontSize: '20px',
      },
    },

    '& .splide__arrow--prev': {
      left: '-4.5rem',
      [theme.breakpoints.down('lg')]: {
        left: '-2.7rem',
      },
      [theme.breakpoints.down('md')]: {
        bottom: '0px',
        left: '50%',
        transform: 'translate(-32px, 250px)',
      },
    },
    '& .splide__arrow--next': {
      right: '-3.75rem',
      [theme.breakpoints.down('lg')]: {
        right: '-2.6rem',
      },
      [theme.breakpoints.down('md')]: {
        bottom: '0px',
        left: '50%',
        transform: 'translate(32px,250px)',
      },
    },
  },

  slide: {
    paddingBlock: '50px',
    '& div.container': {
      width: '100%',
      height: '468px',
      backgroundColor: '#FFF2EB',
      boxShadow: theme.shadows[3],
      borderRadius: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBlock: '33px 0px',
      paddingInline: '23px',
      transition: '0.3s ease',
      '& .header': {
        '& .title': {
          color: theme.palette.primary.main,
          fontSize: '20px',
          fontWeight: '300',
          textAlign: 'center',
          [theme.breakpoints.down('lg')]: {
            fontSize: '16px',
          },
        },
        '& .subtitle': {
          color: '#939393',
          fontWeight: '400px',
          fontSize: '15px',
          marginTop: '29px',
          [theme.breakpoints.down('lg')]: {
            fontSize: '13px',
            marginTop: '15px',
          },
        },
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: '380px',
        paddingBlock: '20px 0px',
        paddingInline: '20px',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '290px',
        paddingBlock: '20px 0px',
        paddingInline: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '450px',
        paddingBlock: '20px 0px',
        paddingInline: '20px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '250px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '194px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100vw',
      paddingInline: '2rem',
      paddingBlock: '10px',
    },
  },
  btn: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '700',
    borderRadius: '26px',
    border: `2px solid ${theme.palette.primary.main}`,
    height: '50px',
    width: '200px',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.card-btn': {
      marginBottom: '44px',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '20px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '150px',
      height: '40px',
    },

    '& .continue-btn': {
      marginTop: '1rem',
    },
  },
  activeSlide: {
    '& div.container': {
      width: '320px',
      height: '530px',
      marginLeft: '-10px',
      marginTop: '-30px',
      [theme.breakpoints.down('lg')]: {
        width: '270px',
        height: '430px',
        marginTop: '-25px',
      },
      [theme.breakpoints.down('md')]: {
        width: '240px',
        height: '389px',
        marginTop: '-50px',
        marginLeft: '-22.5px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '220px',
        height: '360px',
        marginTop: '-40px',
        marginLeft: '-12.5px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '450px',
        paddingBlock: '20px 0px',
        paddingInline: '20px',
        margin: '0px',
      },
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      backgroundColor: theme.palette.common.lightPink,
    },
  },
  container: {
    padding: '2rem 3rem',
    minWidth: '420px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '350px',
      padding: '1rem 1.5rem',
    },
    '& h1.title': {
      textAlign: 'center',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBlock: '3rem 1rem',
      [theme.breakpoints.down('lg')]: {
        marginBlock: '1.5rem 0.5rem',
      },
      '&.justify-center': {
        justifyContent: 'center',
        gap: '20px',
      },
      '& > div': {
        cursor: 'pointer',
      },
      '& .dialog-icons': {
        color: theme.palette.primary.main,
        fontSize: '7rem',
      },
    },
    '& .uploaded-video': {
      height: '640px',
      width: '428px',
      objectFit: 'cover',
      margin: '1rem 0',
      borderRadius: '4px',
      [theme.breakpoints.down('lg')]: {
        width: '300px',
        height: '447.97px',
      },
    },
  },
  loadingDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      backgroundColor: theme.palette.common.lightPink,
    },
    '& .loading-container': {
      height: '300px',
      paddingInline: '30px',
      gap: '30px',
    },
  },
}));
