import { createContext } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

export const AgoraContext = createContext();

const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

const AgoraClientProvider = ({ children }) => {
  return (
    <AgoraContext.Provider value={client}>{children}</AgoraContext.Provider>
  );
};

export default AgoraClientProvider;
